import React, { Component, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { FileStackFilesUploader } from './FileStackFilesUploader';
import { LabelAndDescription } from '../screens/uploadVideoWizard/LabelAndDescription';

export function FilestackFilesUploaderWrapper(props) {

  // const onDelete

  const getDocumentList = () => {
    return (
      <div className={css(Styles.uploadedDocumentOuterContainer)}>
        {props.documents && props.documents.length > 0 &&
          props.documents.map((item, index) => {
            return (
              <div className={css(Styles.uploadedDocumentContainer)}>
                <p className={css(Styles.uploadDocumentText)}>{item.name}</p>
                <div
                  className={props.disableButton === true ? css(Styles.deleteImgContainer, Styles.disabledDeleteIcon) : css(Styles.deleteImgContainer)}
                  onClick={() => props.onFileDelete(item.key)}
                >
                  <img src={require('../assets/images/delete.png')}
                    alt={Helper.getString('deleteImageAlt')}
                    className={css(Styles.img)}
                    width='35'
                    height='35'
                  />
                </div>
              </div>
            )
          })}
      </div>
    )
  }

  return (
    <Fragment>
      <LabelAndDescription
        label={props.item.label}
        optional={true}
        descr={props.item.descr}
        disabled={props.disabled}
      />
      {/* Here we should upload multiple files and results should be an array of mutiple files */}
      <FileStackFilesUploader
        contentId={props.contentId}
        uploadDisabled={props.uploadDisabled}
        onUploadUnderProgress={props.onUploadUnderProgress}
        fileSizeLimit={props.item.fileSizeLimit}
        onUploadSuccess={props.handleUploadSuccess}
        handleUploadFailure={props.handleUploadFailure}
      />
      {getDocumentList()}
    </Fragment>
  )
}

const Styles = StyleSheet.create({
  uploadedDocumentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20
  },
  uploadDocumentText: {
    fontSize: 18,
    color: '#FFFFFF80'
  },
  deleteImgContainer: {
    display: 'flex',
    cursor: 'pointer',
    width: 35,
    height: 35,
    zIndex: 130000,
  },
  disabledDeleteIcon: {
    cursor: 'unset',
  },
  uploadedDocumentOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
})