import React, { Component, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { FormControl, Input, MenuItem, Select, TextField } from '@material-ui/core';
import { CustomImageUploader } from '../../components/CustomImageUploader';
import CustomImageUploaderNew from '../../components/CustomImageUploaderNew';

export function ImagesComponent(props) {

  const getInitialValue = () => {
    const imageMap = {}
    if (props.imageUrls && props.imageUrls.length > 0) {
      // eslint-disable-next-line no-array-constructor
      Array(0, 1, 2, 3, 4).map((e, i) => {
        if (props.imageUrls[i] && props.imageUrls[i].low !== '' && props.imageUrls[i].low !== undefined) {
          imageMap[i] = props.imageUrls[i]
        } else {
          imageMap[i] = ''
        }
      })
      return imageMap
    } else {
      return { 0: '', 1: '', 2: '', 3: '', 4: '' }
    }
  }
  const [imageUrlsMap, setImageUrlsMap] = useState(getInitialValue())
  const [isUploading, setIsUploading] = useState({0: false, 1: false, 2: false, 3: false, 4: false});

  const handleUploadSuccess = (uploadId, signedUploadInfoArray) => {
    let links
    if (signedUploadInfoArray && signedUploadInfoArray.length === 3) {
      links = {
        low: signedUploadInfoArray[0].link,
        medium: signedUploadInfoArray[1].link,
        high: signedUploadInfoArray[2].link
      }
      imageUrlsMap[uploadId] = links // get the first one from array
    } else {
      imageUrlsMap[uploadId] = ''
    }

    setImageUrlsMap({ ...imageUrlsMap })
    const imageObjsArray = []
    Object.values(imageUrlsMap).map((e, i) => {
      if (e !== '' && e !== undefined) {
        imageObjsArray.push(e)
      }
    })
    setIsUploading(prevState => ({...prevState, [uploadId]: false}))
    props.handleFormDataChanges('imageObjs', imageObjsArray)
  }

  useEffect(() => {
    const isAnyUploading = Object.values(isUploading).some(value => value === true);
    props.wizardButtonsDisable(isAnyUploading);
  }, [isUploading]);

  const getContent = () => {
    return Object.keys(imageUrlsMap).map((key) => {
      return <div className={css(Styles.customImageUploadOutterContainer)}>

          <CustomImageUploaderNew
          uploadId={key}
          url={(imageUrlsMap[key].low === 'undefined' || imageUrlsMap[key].low === '') ? '' : imageUrlsMap[key].low}
          signedUploadInfo={props.signedUploadInfo}
          useSignedUploadInfo={true}
          setIsUploading={setIsUploading}
          wizardButtonsDisable={props.wizardButtonsDisable}
          onUploadSuccess={handleUploadSuccess}
          uploadDimensions={
            [
              { width: 640, height: 640 },
              { width: 1280, height: 1280 },
              { width: 1920, height: 1920 },
            ]
          }
          uploadExt={'jpg'}
          customImageUploadContainer={Styles.customImageUploadContainer}
          imageContainer={Styles.customImageUploadContainer}
          imageUploadIconContainerWidth={200}
          imageUploadIconContainerHeight={200}
          imageContainerWidth={200}
          imageContainerHeight={200}
          displayErrorInParent={false} />
      </div>
    })
  }



  return (
    <div className={css(Styles.Container)}>
      <div className={css(Styles.imagesOuterContainer)}>
        <p className={css(Styles.title)}>upload Images</p>
        <div className={css(Styles.imagesContainer)}>
          {getContent()}
        </div>
      </div>
    </div>
  )
}

const Styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 67,
    alignItems: 'center'
  },
  imagesOuterContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    fontSize: 16,
    textTransform: 'uppercase',
    color: '#ffffff',
    marginBottom: 16,
    textAlign: 'left',
    marginLeft: 20,
    '@media (max-width: 1200px)': {
      marginLeft: 10,
    }
  },
  customImageUploadContainer: {
    width: 200,
    height: 200,
    '@media (max-width: 1200px)': {
      width: 100,
      height: 100,
    },
    // marginRight: 40,
    borderRadius: 8,
    overflow: 'hidden'
  },
  customImageUploadOutterContainer: {
    width: 200,
    height: 200,
    marginRight: 20,
    marginLeft: 20,
    borderRadius: 8,
    overflow: 'hidden',
    '@media (max-width: 1200px)': {
      width: 100,
      height: 100,
      marginRight: 10,
      marginLeft: 10,
    }
  },
})