import React, { Component, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import AvatarEditor from 'react-avatar-editor'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from '../helpers/Helper';
import { UiHelper } from '../helpers/UiHelper';

import { AppStyles, AppTheme } from '../../styles/AppTheme';
import { UploadHelper } from '../helpers/UploadHelper';
import moment from 'moment';
import { AppConfig } from '../../AppConfig';
import { CustomImageUploaderStyles } from '../../styles/CustomStyles';
// import FileDrop from 'react-file-drop'

const RESIZE_DEFAULT = 1200
const IMG_RENDER_SIZE = 100
const FILE_SIZE_MB = 10000000
const HEIGHT = AppConfig.CUSTOM_STYLE ? 54 : 49
const ICON_SIZE = 35

export class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.widthExpected = this.props.width || RESIZE_DEFAULT
    this.heightExpected = this.props.height || RESIZE_DEFAULT
    //Size for canvas
    this.width = this.props.width || RESIZE_DEFAULT
    this.height = this.props.height || RESIZE_DEFAULT
    this.borderRadius = (this.props.borderRadius === 0) ? 0 : RESIZE_DEFAULT / 2
    this.currentUrl = this.props.url
    this.image = {}
    this.state = {
      message: '',
      uploadInProgress: false,
      deleteInProgress: null,
      fetchState: ApiHelper.State.READY
    };
  }

  componentDidMount() {
  }

  render() {
    if (this.props.mode === 'fit' && this.selectedImgHeight && this.selectedImgWidth) {
      if (this.selectedImgWidth < this.width) {
        this.width = this.selectedImgWidth
      }
      this.height = Math.round((this.selectedImgHeight / this.selectedImgWidth) * this.width)
      if(this.height > this.heightExpected) {
        this.height = this.heightExpected
        this.width = Math.round((this.selectedImgWidth / this.selectedImgHeight) * this.height)
      }
    }
    const ratio = this.width / this.height
    let height = HEIGHT
    let width = height * ratio
    if (width > 250) { // too wide
      width = 250
      height = width / ratio
    }
    const EditorStyles = StyleSheet.create({
      editor: {
        backgroundColor: 'white',
        height,
        width
      },
    })

    const inputTagId = 'upload-button-' + this.props.uploadId + '-' + moment().valueOf()
    const outerContainer = (!this.state.uploadInProgress && (this.currentUrl || this.image.imageUrl)) ? [Styles.outerContainer, Styles.imageOuterContainer] : [Styles.outerContainer]
    if (this.props.disabled) {
      outerContainer.push(Styles.outerContainerDisabled)
    }
    return (
      <div className={css(Styles.container)}>
        <div className={css(Styles.imgContainer)}>
          <AvatarEditor
            ref={this.setEditorRef.bind(this)}
            className={css(EditorStyles.editor)}
            image={this.state.dataURL || this.currentUrl}
            width={this.width}
            height={this.height}
            border={1}
            borderRadius={this.borderRadius}
            color={[0, 0, 0, 0.5]} // RGBA
          //scale={1}
          //rotate={0}
          />
        </div>
        {/*<div className={this.props.mode === 'fit' ? css(Styles.textContainer, Styles.fitContainer) : css(Styles.textContainer)} style={{ height: height, width: width }}>
           <input
            id={inputTagId}
            className={css(Styles.fileInput)}
            onChange={this.onFileChange.bind(this)}
            type='file'
            style={{ display: 'none' }}
            accept={this.props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'} />
          <label htmlFor={inputTagId} className={css(Styles.labelContainer)}>
            <div className={css(Styles.attachFile)}>
              {(!(this.currentUrl || this.image.imageUrl) || this.state.uploadInProgress ) &&
                <Fragment>
                  {this.state.uploadInProgress ?
                    <CircularProgress size={24} className={css(Styles.progressIcon)} />
                    :
                    <p className={css(Styles.attachFileText)}>Select Image</p>
                  }
                </Fragment>
              }
              {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
                <div className={this.props.mode === 'fit' ? css(Styles.imageContainer, Styles.fitContainer) : css(Styles.imageContainer)} style={{height: height, width: width}}>
                  <img src={this.image.imageUrl || this.currentUrl} className={css(Styles.imageContent)} />
                </div>
              }
            </div>
            <div className={css(Styles.closeIconContainer)}>
              <i className='material-icons-outlined' style={{ fontSize: 14 }}>upload</i>
            </div>
          </label>
          </div>*/}
        <input
          id={inputTagId}
          className={css(Styles.fileInput)}
          onChange={this.onFileChange.bind(this)}
          type='file'
          style={{ display: 'none' }}
          accept={this.props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'}
          disabled={this.props.disabled} />
        <div className={css(Styles.outerMainContainer)}>

          <div className={css(outerContainer)}>
            <label htmlFor={inputTagId} className={this.props.disabled ? css(Styles.labelDisableContainer) : css(Styles.labelContainer)}>
              {/* <FileDrop onDrop={!this.props.disabled && this.handleDrop.bind(this)} className={css(Styles.fileDrop)} targetClassName={css(Styles.targetFileDrop)}>
                {(!(this.currentUrl || this.image.imageUrl) || this.state.uploadInProgress) &&
                  <Fragment>
                    {this.state.uploadInProgress ?
                      <CircularProgress size={24} className={css(Styles.progressIcon)} />
                      :
                      <div className={css(Styles.imageIconOuterContainer)}>
                        <div className={css(Styles.imageIconContainer)}>
                          {UiHelper.renderImg({ key: 'imgUpload', size: ICON_SIZE })}
                        </div>
                        <p className={css(Styles.attachFileText)}>{Helper.getString('dragAndDropImage')} or <span className={css(Styles.attachFileTextBrowse)}>Browse</span></p>
                      </div>
                    }
                  </Fragment>
                }
                {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
                  <div className={this.props.mode === 'fit' ? css(Styles.textContainer, Styles.fitContainer) : css(Styles.textContainer)} style={this.props.mode !== 'fit' ? { height: height, width: width } : {}}>
                    {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
                      <div className={this.props.mode === 'fit' ? css(Styles.imageContainer, Styles.fitContainer) : css(Styles.imageContainer)} style={this.props.mode !== 'fit' ? { height: height, width: width } : {}}>
                        <img src={this.image.imageUrl || this.currentUrl} className={css(Styles.imageContent)} />
                      </div>
                    }
                  </div>
                }
              </FileDrop> */}
              {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
                <Fragment>
                  <div
                    className={this.props.disabled ? css([Styles.closeIconContainer, Styles.reuploadIconContainer, Styles.closeIconDisableContainer]) : css([Styles.closeIconContainer, Styles.reuploadIconContainer])}>
                    <i className='material-icons-outlined' style={{ fontSize: 14 }}>upload</i>
                  </div>
                </Fragment>
              }
            </label>
            {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress && !this.props.disableDeletion &&
              <Fragment>
                <div className={this.props.disabled ? css([Styles.closeIconContainer, Styles.closeIconDisableContainer, Styles.deleteIconContainer]) : css([Styles.closeIconContainer, Styles.deleteIconContainer])}
                  onClick={() => !this.props.disabled && this.onFileDelete(inputTagId)}>
                  <i className='material-icons-outlined' style={{ fontSize: 14 }}>delete</i>
                </div>
              </Fragment>
            }
          </div>
          {/* {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
            <p className={css(Styles.reuploadContainer)}><label htmlFor={inputTagId} className={css(Styles.reuploadLabel)}>Reupload</label></p>
          } */}
        </div>
        {this.state.message && !this.props.disabled && this.renderMessage()}
      </div>
    )
  }

  renderMessage() {
    let messageClass = css(Styles.textUpload, Styles.text)
    if (this.state.fetchState === ApiHelper.State.ERROR) {
      messageClass = css(Styles.textError, Styles.text)
    } else if (this.state.fetchState === ApiHelper.State.READY) {
      messageClass = css(Styles.textSuccess, Styles.text)
    }
    return (<p className={messageClass}>{this.state.message}</p>)
  }

  setEditorRef(editor) {
    this.editor = editor
  }

  _getFileExt = () => {
    if (this.state.file.type === 'image/png') {
      return 'png'
    } else if (this.state.file.type === 'image/svg+xml') {
      return 'svg'
    } else {
      return 'jpg'
    }
  }

  onUpload() {
    if (!this.editor) {
      return this.onError(Helper.getString('imageEditErr'))
    }
    if (!this.state.file) {
      return this.onError(Helper.getString('fileChoose'))
    }
    const maxSize = this.props.fileSizeLimit ? this.props.fileSizeLimit : FILE_SIZE_MB;
    if (this.state.file.size > maxSize) {
      const errMsg = maxSize / 1000000 + Helper.getString('fileSizeExceedMsg')
      return this.onError(errMsg)
    }
    this.setState(
      {
        message: '',
        fetchState: ApiHelper.State.READY
      }
    )
    const ext = this._getFileExt()
    const options = {
      uploadLocation: this.props.uploadLocation,
      type: this.props.uploadType || 'media',
      ext: this.props.uploadExt || ext // toBlob gives png by default
    }
    if (options.ext === 'svg') {
      this._fetchUploadUrl(options, this.state.file)
    } else if(this.selectedImgWidth <= this.widthExpected && this.selectedImgHeight <= this.heightExpected && ext === this.props.uploadExt) {
      this._fetchUploadUrl(options, this.state.file)
    } else {
      let format = 'image/png' // default format
      if (options.ext === 'jpg') {
        format = 'image/jpeg'
      } else if (options.ext === 'png') {
        format = 'image/png'
      }
      const scaledCanvas = this.editor.getImageScaledToCanvas()
      scaledCanvas.toBlob((blob) => {
        this._fetchUploadUrl(options, blob)
      }, format, 0.7)
    }
  }

  matchImgDimensions = () => {
    if (this.props.mode === 'exact') {
      if (this.width !== this.selectedImgWidth || this.height !== this.selectedImgHeight) {
        const errMsg = 'Image dimension should be exactly ' + this.width + ' (w) * ' + this.height + ' (h)'
        this.onError(errMsg)
        return false
      }
    }
    return true
  }

  _fetchUploadUrl = (options, file) => {
    UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
      if (err) {
        return this.onError(err)
      }
      console.log('fetchUploadUrl:', err, signedUploadInfo)
      const uploadOptions = {
        pickerResult: file,
        signedUploadInfo: signedUploadInfo,
        onEvent: (type, percent) => {
          console.log('progress:', type, percent)
          if (type === 'progress') {
            return this.onUploadProgress(percent)
          } else if (type === 'load') {
            return this.onUploadComplete(signedUploadInfo)
          } else if (type === 'error') {
            return this.onError(Helper.getString('fileUploadErr'))
          }
        }
      }
      UploadHelper.uploadFormDataWithProgress(uploadOptions)
    })
  }

  onFileDelete(inputTagId) {
    this.setState({
      dataURL: '',
      message: ''
    })
    this.currentUrl = ''
    const e = document.getElementById(inputTagId)
    e.value = ''
    this.image = {
      imageUrl: '',
      imageName: ''
    }
    const field = {
      key: ''
    }
    this.props.onUploadSuccess(this.props.uploadId, { link: '', fields: field })
  }

  onFileChange(e) {
    const file = e.target.files[0]
    const acceptArray = this.props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'
    if (file) {
      if (acceptArray.split(', ').indexOf(file.type) > -1) {
        this.readFile(file)
      } else {
        this.onError(Helper.getString('invalidFileType'))
      }
    }
  }

  readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image;
      img.src = reader.result;
      img.onload = function () {
        this.selectedImgWidth = img.width
        this.selectedImgHeight = img.height
        if (!this.matchImgDimensions()) return
        this.setDataUrl(file, e)
      }.bind(this);
    }
    reader.readAsDataURL(file)
  }

  handleDrop = (files, event) => {
    const file = files[0]
    const acceptArray = this.props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'
    if (acceptArray.split(', ').indexOf(file.type) > -1) {
      this.readFile(file)
    } else {
      this.setState({ fetchState: ApiHelper.State.ERROR, message: Helper.getString('invalidFileType') })
    }
  }


  setDataUrl = (file, e) => {
    this.setState(
      {
        file: file,
        dataURL: e.target.result,
        message: '',
        fetchState: ApiHelper.State.READY,
        uploadInProgress: true
      }, () => {
        setTimeout(() => { //NOTE: time out was given to allow re-render of Avatar Image, immediately calling onUpload had issues
          this.onUpload()
        }, 1000)
      }
    )
  }

  onUploadProgress(percent) {
    // console.log('percent:', percent);
    this.setState({
      // message: 'Uploading: ' + Number(percent).toFixed(0) + ' %',
      fetchState: ApiHelper.State.LOADING
    })
  }

  onUploadComplete(signedUploadInfo) {
    this.setState({
      // message: Helper.getString('fileUploadSuccess'),
      fetchState: ApiHelper.State.READY,
      uploadInProgress: false,
      deleteInProgress: null
    })
    this.image = {
      imageUrl: signedUploadInfo.link,
      imageName: signedUploadInfo.fields.key
    }
    signedUploadInfo.height = this.height
    signedUploadInfo.width = this.width
    if (this.props.onUploadSuccess) {
      this.props.onUploadSuccess(this.props.uploadId, signedUploadInfo)
    }
  }

  onError(err) {
    console.log('err:', err);
    this.setState({
      message: Helper.getErrorMsg(err),
      fetchState: ApiHelper.State.ERROR,
      uploadInProgress: false,
      deleteInProgress: null,

    })
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomImageUploaderStyles : StyleSheet.create({
  container: {
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
  },
  fitContainer: {
    height: HEIGHT,
    width: 'auto'
  },
  fileInput: {
    width: '100%'
  },
  textButton: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 100,
    minHeight: 24,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10
  },
  text: {
    marginTop: 10,
    marginBottom: 3,
    fontSize: 12
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  editor: {
    width: 65,
    height: 65,
  },
  imgContainer: {
    display: 'none'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageContent: {
    width: '100%',
    borderRadius: 16,
    maxWidth: '100%',
    maxHeight: '100%'
  },
  closeIconContainer: {
    backdropFilter: 'blur(6px)',
    backgroundColor: 'white',
    display: 'flex',
    cursor: 'pointer',
    border: '1px solid black',
    padding: 2,
    borderRadius: 4,
    marginLeft: 10
  },
  closeIconDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  attachFileText: {
    margin: 0,
    fontSize: 11,
    color: 'black',
    marginLeft: 13
  },
  attachFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10
  },
  labelContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    padding: 0,
    borderRadius: 0,
    alignItems: 'center'
  },
  labelDisableContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: 0,
    backgroundColor: '#f5f5f5',
    cursor: 'unset',
    borderRadius: 0,
    alignItems: 'center'
  },
  attachFileTextBrowse: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  outerMainContainer: {
    display: 'flex'
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    border: '1px dashed #E5E5E5',
    borderRadius: AppTheme.borderRadius,
    height: HEIGHT,
    position: 'relative',
    overflow: 'hidden'
  },
  outerContainerDisabled: {
    backgroundColor: '#f5f5f5'
  },
  imageOuterContainer: {
    flex: 'unset',
    border: '1px solid #E5E5E5'
  },
  imageIconContainer: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageIconOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  fileDrop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    position: 'relative'
  },

  targetFileDrop: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  reuploadContainer: {
    fontSize: 16,
    color: AppTheme.primaryColor,
    margin: 20,
    display: 'flex',
    alignItems: 'center'
  },
  reuploadLabel: {
    cursor: 'pointer'
  },
  deleteIconContainer: {
    margin: '0px 10px'
  }
})
