import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { FormControl, FormControlLabel, Input, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import { CustomImageUploader } from '../../components/CustomImageUploader';
import { RadioButton, SelectBox, TextBoxs } from '../../components/WizardTextInputs';
import { Helper } from '../../common/helpers/Helper';
import { VISIBILITY } from '../../Consts';

export function VisibilityComponent(props) {
  const handleChange = (e, key) => {
    props.handleFormDataChanges('privacy', e.target.value)
  }


  return (
    <div className={css(Styles.Container)}>
      {/* <div className={css(Styles.innerContainer)}> */}
      <RadioButton
        value={VISIBILITY.PRIVATE}
        selectedValue={props.projectData.visibility ? props.projectData.visibility.privacy : 'PRIVATE'}
        handleChange={handleChange}
        name='private'
        label={'Private'}
        labelDescr={'Only you can watch your video.'}
        radioButtonContainer={Styles.radioButtonContainer} />
      <RadioButton
        value={VISIBILITY.PUBLIC}
        selectedValue={props.projectData.visibility ? props.projectData.visibility.privacy : 'PRIVATE'}
        handleChange={handleChange}
        name='public'
        label={'Public'}
        labelDescr={'Everyone can watch your video.'} />
      {/* </div> */}
      <div className={css(Styles.visibilityInfoContainer)}>
        <p className={css(Styles.visibilityInfoTitle)}>Before you publish, check the following:</p>
        <p className={css(Styles.visibilityInfoSubTitle)}>Avoid copyright claims!</p>
        <p className={css(Styles.visibilityInfoDescr, Styles.marginBottom15)}>
          Use of copyrighted content of others without proper authorization or legally valid reason is not permitted.</p>
        <p className={css(Styles.visibilityInfoDescr, Styles.marginBottom15)}>
          Check that you have permission to use images, music, video clips or any copyrighted content used in your video.</p>
        <p className={css(Styles.visibilityInfoDescr, Styles.marginBottom5)}>
          Copyright claims against your video can lead to the following actions:</p>
        <p className={css(Styles.visibilityInfoDescr, Styles.marginBottom5)}>
          <span className={css(Styles.styledText)}>Demonetization</span> (copyright owner gets to monetize your video instead of you)</p>
        <p className={css(Styles.visibilityInfoDescr, Styles.marginBottom5)}>
          <span className={css(Styles.styledText)}>Blocked</span> video and / or account</p>
      </div>
    </div>
  )
}

const Styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 67,
    alignItems: 'center'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 700,
    marginBottom: 120
  },
  label: {
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'uppercase',
    margin: 0,
  },
  optionalText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    textTransform: 'lowercase'
  },
  labelDescr: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginTop: 4,
    marginBottom: 10
  },
  radioButtonContainer: {
    marginBottom: 52
  },
  visibilityInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 100
  },
  visibilityInfoTitle: {
    fontSize: 16,
    color: '#ffffff',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 0,
    marginBottom: 29
  },
  visibilityInfoSubTitle: {
    fontSize: 16,
    color: '#ffffff',
    textAlign: 'center',
    margin: 0,
    marginBottom: 28
  },
  visibilityInfoDescr: {
    fontSize: 14,
    color: '#ffffff',
    margin: 0,
  },
  marginBottom15: {
    marginBottom: 15
  },
  marginBottom5: {
    marginBottom: 8
  },
  styledText: {
    fontStyle: 'italic',
    fontWeight: 'bold'
  }
})