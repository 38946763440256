import { AppConfigGen } from './AppConfigGen'

export const AppConfig = {
  ...AppConfigGen,
  ALLOWMOCK: false,
  VERSION: '1.0.24',
  SUPPORT_EMAIL: 'contactus@razzl.com',
  ANDROID_APP_LINK: '',
  IOS_APP_LINK: '',
  CDN_BASEPATH: 'https://dg175en455vh2.cloudfront.net',
  WEBSITE_LINK: 'https://www.razzl.com',
  ITUNES_ID: '1604713309',
  PACKAGE_NAME: 'com.razzl'
}
