
import { StyleSheet } from 'aphrodite';
import { AppTheme, AppStyles } from './AppTheme';
import { AppHelper } from '../helpers/AppHelper';
import { CommonTheme, CommonStyles } from './Styles';
const IMG_RENDER_SIZE = 135

export const CustomProductsStyles = StyleSheet.create({
})

export const CustomNoPermissionStyles = StyleSheet.create({
})

export const CustomMessageDialogStyles = StyleSheet.create({
})

export const CustomSearchBarStyles = StyleSheet.create({
})

export const CustomBreadCrumbsStyles = StyleSheet.create({
})

export const CustomImageUploaderStyles = StyleSheet.create({
})

export const CustomTableComponentStyles = StyleSheet.create({
})

export const CustomSEStyles = StyleSheet.create({
})

export const CustomNextPageStyles = StyleSheet.create({
})

export const CustomHeaderbarStyles = StyleSheet.create({
})

export const CustomOrgSwitcherStyles = StyleSheet.create({
})

export const CustomSettingsMenuStyles = StyleSheet.create({
})

export const CustomDynamicFormDialogStyles = StyleSheet.create({
})

export const CustomDynamicFormStyles = StyleSheet.create({
})

export const CustomDocumentUploaderStyles = StyleSheet.create({
})

export const CustomBaseEnityStyles = StyleSheet.create({
})

export const CustomPreviewDialogStyles = StyleSheet.create({
})

export const CustomAppButtonStyles = StyleSheet.create({
})