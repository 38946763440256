import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { Dialog } from '@material-ui/core';
import { VideoPlayer } from './VideoPlayer';
import { PROJECT_STATE } from '../../src/Consts'
import { VideoTimeSelector } from '../components/VideoTimeSelector'
import { UploadHelper } from '../common/helpers/UploadHelper'
import { ApiHelper } from '../common/helpers/ApiHelper';
import { CircularProgress } from '@material-ui/core';

export function VideoThumbnailSelector(props) {
  const [timeElapsed, setTimeElapsed] = useState(0)
  const [openSelector, setOpenSelector] = useState(false)
  const [imgUri, setImguri] = useState(props.thumbnailVideoFrame || props.projectFull.image)
  const [imageUploadState, setImageUploadState] = useState(ApiHelper.State.READY)

  const onThumnailUpload = () => {
    if (imageUploadState === ApiHelper.State.LOADING) {
      return
    }
    if ((props.projectFull.state === PROJECT_STATE.PROCESSINGCOMPLETE) || (props.projectFull.state === PROJECT_STATE.READY)) {
      setOpenSelector(true)
    }
  }
  const onVideoDialogClose = () => {
    setOpenSelector(false)
  }
  const onVideoDialogAccept = (timeElapsed, frame) => {
    if (frame && frame.blob) {
      //const uriFromBlob = URL.createObjectURL(frame.blob)
      const options = {
        type: 'media',
        ext: 'jpeg' // we capture as jpeg from video
      }
      setImageUploadState(ApiHelper.State.LOADING)
      _fetchUploadUrl(options, frame.blob, timeElapsed)
    }
    setOpenSelector(false)
  }

  const _fetchUploadUrl = (options, file, timeElapsed) => {
    UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
      if (err) {
        return this.onError(err)
      }
      console.log('fetchUploadUrl:', err, signedUploadInfo)
      const uploadOptions = {
        pickerResult: file,
        signedUploadInfo: signedUploadInfo,
        onEvent: (type, percent) => {
          console.log('progress:', type, percent)
          if (type === 'progress') {
            console.log('progress:', percent)
          } else if (type === 'load') {
            console.log('Upload Complete')
            setImageUploadState(ApiHelper.State.READY)
            if (props.onThumbnailUploaded) {
              props.onThumbnailUploaded(signedUploadInfo, timeElapsed)
              setImguri(signedUploadInfo.link)
            }
          } else if (type === 'error') {
            setImageUploadState(ApiHelper.State.READY)
            console.log('Upload Error')
          }
        }
      }
      UploadHelper.uploadFormDataWithProgress(uploadOptions)
    })
  }

  const renderVideoDailog = () => {
    return (<VideoTimeSelector
      open={openSelector}
      timeSelectorPopup={true}
      url={props.projectFull.streamingUri}
      duration={Math.ceil(props.projectFull.durationMs / 1000)} // Inseconds
      durationMs={props.projectFull.durationMs}
      seekToVideoTimeInSeconds={props.thumbTs || 0}
      onVideoDialogClose={onVideoDialogClose}
      onVideoDialogAccept={onVideoDialogAccept}
      getThumbnail={true}
    ></VideoTimeSelector>)
  }

  const showVideoThumbnail = (props.projectFull.state === PROJECT_STATE.PROCESSINGCOMPLETE || props.projectFull.state === PROJECT_STATE.READY)


  return (
    <div>
      <div className={css(Styles.processingStateContainer)} onClick={() => onThumnailUpload()}>
        {showVideoThumbnail &&
          <img src={imgUri}
            className={css(Styles.imageContent)} />}
        {!showVideoThumbnail && <div className={css(Styles.processingStateContainer)}>
          <p className={css(Styles.subText, Styles.videoStateText)}>{props.projectFull.state}...</p>
          {/* <p className={css(Styles.subText, Styles.videoStateText)}>{props.publishProgress}%</p> */}
          <p className={css(Styles.processingStateSubText)}>Email will be sent once processing completes.</p>
        </div>}
        {imageUploadState === ApiHelper.State.LOADING &&
          <div className={css(Styles.imagLoadingStateContainer)}><CircularProgress size={50} /></div>}
      </div>
      {renderVideoDailog()}
    </div>)
}

const Styles = StyleSheet.create({
  imageContent: {
    height: '100%',
    width: '100%',
    borderRadius: 8,
    objectFit: 'cover',
    cursor: 'pointer'
  },
  processingStateContainer: {
    display: 'flex',
    width: 250,
    height: 140,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  imagLoadingStateContainer: {
    position: 'absolute',
  },
  processingStateSubText: {
    fontSize: 12,
    color: '#FFFFFF80',
    margin: 0,
    textAlign: 'center',
    position: 'absolute',
    bottom: 20
  },
  videoStateText: {
    fontWeight: 'bold',
    color: '#FFFFFF'
  },
  subText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginBottom: 5,
    marginTop: 5
  },
  progressIcon: {
    color: '#FFFFFF',
  },
})