import { Controller } from '../Controller';
import { AppConfig } from '../../AppConfig';
import { Helper } from '../helpers/Helper';
import { RandHelper } from '../helpers/RandHelper';
import { PhoneNumberHelper } from '../helpers/PhoneNumberHelper';
import { ApiHelper } from './ApiHelper';

const uuid = require('uuid');

export class UiLoginHelper {
  static Page = Object.freeze({
    LOOKUP: 0,
    SIGNUP: 1,
    SIGNUP_OTP: 2,
    LOGIN: 3,
    RESET_PASSWORD: 4,
    RESET_PASSWORD_OTP: 5,
  })

  static UserNameType = Object.freeze({
    EMAIL: 0,
    PHONE: 1,
  })

  constructor(caller, options) {
    this.caller = caller;
    this.options = options;
  }

  toPage(page, message) {
    const previousUserName = Controller.get().userMgr().getLastLoggedInUserName() || '';
    const options = {
      currentPage: page,
      loadingAnimation: false,
    }
    if (message) {
      options.message = message;
    } else {
      options.message = '';
    }
    switch (page) {
    case UiLoginHelper.Page.LOOKUP:
    {
      options.userName = previousUserName
      break;
    }
    case UiLoginHelper.Page.SIGNUP:
      break;
    case UiLoginHelper.Page.SIGNUP_OTP:
      break;
    case UiLoginHelper.Page.LOGIN:
      if (!this.caller.state.userName) {
        options.userName = previousUserName
      }
      options.pword = ''
      options.verificationCode = ''
      break;
    case UiLoginHelper.Page.RESET_PASSWORD:
      options.pword = ''
      options.verificationCode = ''
      break;
    case UiLoginHelper.Page.RESET_PASSWORD_OTP:
      options.pword = ''
      options.verificationCode = ''
      break;
    default:
    }
    this.caller.setState(options);
  }

  onError(err) {
    //console.log('err:', err);
    this.caller.setState({
      message: Helper.getErrorMsg(err),
      loadingAnimation: false
    });
  }

  onTerms() {
  }

  preAPI(callback) {
    let err;

    if (!this.caller.state.userName.length) {
      err = this.options.userNameType === UiLoginHelper.UserNameType.PHONE ?
        Helper.getString('enterMobile') : Helper.getString('enterEmail')
      this.onError(err);
      callback(err); return;
    }
    let userName = this.caller.state.userName;
    try {
      if (this.options.userNameType === UiLoginHelper.UserNameType.PHONE) {
        userName = PhoneNumberHelper.formatNumber(this.caller.state.userName, AppConfig.DEFAULT_COUNTRY);
      } else {
        userName = Helper.validateAndFormatEmail(this.caller.state.userName);
      }
    } catch (err) {
      this.onError(Helper.getErrorMsg(err));
      callback(err); return;
    }

    if ([UiLoginHelper.Page.SIGNUP, UiLoginHelper.Page.LOGIN, UiLoginHelper.Page.RESET_PASSWORD_OTP]
      .indexOf(this.caller.state.currentPage) >= 0) {
      if (!this.caller.state.pword.length && !this.options.alwaysLoginUsingOTP) {
        err = Helper.getString('enterPassword');
        this.onError(err);
        callback(err); return;
      }
    }
    if ([UiLoginHelper.Page.SIGNUP_OTP, UiLoginHelper.Page.RESET_PASSWORD_OTP]
      .indexOf(this.caller.state.currentPage) >= 0) {
      if (!this.caller.state.verificationCode.length) {
        err = Helper.getString('enterOTP');
        this.onError(err);
        callback(err); return;
      }
    }

    const options = {
      userName: userName,
      loadingAnimation: true,
      message: ''
    }

    if ([UiLoginHelper.Page.LOOKUP].indexOf(this.caller.state.currentPage) >= 0) {
      if (this.options.autoSignUp) {
        options.pword = RandHelper.generateTempCred();
      }
    }

    if ([UiLoginHelper.Page.RESET_PASSWORD_OTP].indexOf(this.caller.state.currentPage) >= 0) {
      if (this.options.alwaysLoginUsingOTP && this.caller.state.pword.length === 0) {
        options.pword = RandHelper.generateTempCred();
      }
    }

    this.caller.setState(options, function () {
      callback(null);
    });
  }

  onSubmitLookup = () => {
    //console.log('onSubmitLookup');
    if (!this.preAPI(function (err) {
      if (err) { return; }

      const jsonBody = { ...this.options.jsonBody}
      if (this.options.userNameType === UiLoginHelper.UserNameType.PHONE) {
        jsonBody.phone = this.caller.state.userName
      } else {
        jsonBody.email = this.caller.state.userName
      }
      const options = {
        method: 'POST',
        endPoint: ApiHelper.makeUrlPath(['users']),
        jsonBody: jsonBody,
        authWithKey: true
      }
      ApiHelper.call(options, function (err, data) {
        if (err) {
          this.onError(data);
          return;
        }
        this.onSuccessLookup(data);
      }.bind(this));
    }.bind(this)));
  }

  onSuccessLookup(data) {
    //console.log('onSuccessLookup');
    if (data.state === '__SIGNUP_AWAITED') {
      this.newUserId = data.id;
      if (this.options.autoSignUp) {
        this.onSubmitSignUp()
      } else {
        this.toPage(UiLoginHelper.Page.SIGNUP);
      }
    } else {
      if (this.options.alwaysLoginUsingOTP) { // New code for always login With OTP
        this.onSubmitResetPassword()
      } else {
        this.toPage(UiLoginHelper.Page.LOGIN);
      }
    }
  }

  onSubmitSignUp = () => {
    //console.log('onSubmitSignUp');
    if (!this.preAPI(function (err) {
      if (err) { return; }

      //console.log('signUp:', this.newUserId);
      let email, phone
      if (this.options.userNameType === UiLoginHelper.UserNameType.PHONE) {
        phone = this.caller.state.userName
      } else {
        email = this.caller.state.userName
      }
      Controller.get().loginMgr().signUp(this.newUserId,
        phone, email, this.caller.state.pword, function (err, data) {
          if (!err) {
            //console.log('signUp result:', data);
            if (data.code === 'SUCCESS') {
              this.onSuccessSignUp(Helper.getString('otpToMobile') + this.caller.state.userName);
            } else {
              this.onError(data.message);
            }
          } else {
            this.onError(err)
          }
        }.bind(this));
    }.bind(this)));
  }

  onSuccessSignUp(message) {
    this.toPage(UiLoginHelper.Page.SIGNUP_OTP, message);
  }

  onSubmitSignUpConfirm = () => {
    //console.log('onSubmitSignUpConfirm');
    if (!this.preAPI(function (err) {
      if (err) { return; }

      //console.log('confirmRegistration:', this.newUserId);
      Controller.get().loginMgr().confirmRegistration(this.newUserId, this.caller.state.verificationCode,
        function (err, data) {
          if (!err) {
            //console.log('confirmRegistration result:', data);
            if (data.code === 'SUCCESS') {
              this.onSuccessSignUpConfirm();
            } else {
              this.onError(data.message);
            }
          } else {
            this.onError(err)
          }
        }.bind(this));
    }.bind(this)));
  }

  onSuccessSignUpConfirm() {
    if (this.options.autoLogin) {
      this.onSubmitLogin();
    } else {
      this.toPage(UiLoginHelper.Page.LOGIN);
    }
  }

  onSubmitLogin = () => {
    //console.log('onSubmitLogin');
    if (!this.preAPI(function (err) {
      if (err) { return; }

      Controller.get().loginMgr().signIn(this.caller.state.userName, this.caller.state.pword, function (err, data) {
        if (!err) {
          //console.log('authenticate result:', data);
          if (data.code === 'SUCCESS') {
            this.onSuccessLogin();
          } else if (data.code === 'USERNAME_OR_PASSWORD_WRONG') {
            //Show data.message and stay on NORMALPASS-PAGE
            this.onError(data.message);
          } else if (data.code === 'NEW_PASSWORD_REQUIRED') {
            //Show NEWPASS-PAGE with message data.message
            this.newPassReceivedUserAttributes = data.newPassReceivedUserAttributes;
            this.newPassCognitoUser = data.newPassCognitoUser;
            //this.showResetPwdForm();
          } else if (data.code === 'USER_NOT_FOUND') {
            //Show data.message and stay on NORMALPASS-PAGE
            this.onError(data.message);
          } else if (data.code === 'WRONG_CREDENTIALS') {
            //Show data.message and stay on NORMALPASS-PAGE
            this.onError(data.message);
          } else if (data.code === 'NETWORKING_ERROR') {
            this.onError(data.message);
          } else {
            this.onError(data.message);
          }
        } else {
          this.onError(err)
        }
      }.bind(this));
    }.bind(this)));
  }

  onSuccessLogin() {
    //console.log('UiLoginHelper::onSuccessLogin')
    Controller.get().userMgr().setCurrentLoggedInUserName(this.caller.state.userName).then((res) => {
      console.log('login::Set User mobile no');
    }).catch((err) => {
      console.log('Storing mobile number failed');
    });

    if (this.options.onSuccessLogin) {
      this.options.onSuccessLogin();
    }
  }

  onSubmitResetPassword = () => {
    //console.log('onSubmitResetPassword');
    if (!this.preAPI(function (err) {
      if (err) { return; }

      Controller.get().loginMgr().forgotPassword(this.caller.state.userName, function (err, data) {
        //console.log('forgotPassword result:', data)
        if (!err) {
          if (data.code === 'SUCCESS') {
            //Show RESETPASS-PAGE with message data.message
            this.onSuccessResetPassword(data.message);
          } else if (data.code === 'USER_NOT_FOUND') {
            this.onError(data.message);
          } else if (data.code === 'NETWORKING_ERROR') {
            this.onError(data.message);
          } else {
            this.onError(data.message);
          }
        }
      }.bind(this));
    }.bind(this)));
  }

  onSuccessResetPassword = (message) => {
    this.toPage(UiLoginHelper.Page.RESET_PASSWORD_OTP, message);
  }

  onSubmitConfirmResetPassword() {
    //console.log('onSubmitConfirmResetPassword');
    if (!this.preAPI(function (err) {
      if (err) { return; }

      Controller.get().loginMgr().confirmResetPassword(
        this.caller.state.verificationCode, this.caller.state.userName, this.caller.state.pword, function (err, data) {
          if (!err) {
            //console.log('confirmResetPassword result:', data)
            if (data.code === 'SUCCESS') {
              //Goto NORMALPASS-PAGE with message data.message
              this.onSuccessConfirmResetPassword(data.message);
            } else if (data.code === 'WRONG_VERIFICATION_CODE') {
              //Show data.message and stay on same page
              this.onError(data.message);
            } else if (data.code === 'PASSWORD_NOT_AS_PER_POLICY') {
              //Show data.message and stay on same page
              this.onError(data.message);
            } else if (data.code === 'NETWORKING_ERROR') {
              this.onError(data.message);
            } else {
              this.onError(data.message);
            }
          } else {
            this.onError(err)
          }
        }.bind(this));
    }.bind(this)));
  }

  onSuccessConfirmResetPassword = (message) => {
    if (this.options.autoLogin) {
      this.onSubmitLogin();
    } else {
      this.toPage(UiLoginHelper.Page.LOGIN, message);
    }
  }


  onSubmitResentResetPassword = (message) => { // Resent OTP
    console.log('onSubmitResetPassword');
    const options = {
      loadingAnimation: true,
      message: ''
    }
    this.caller.setState(options, function () {
      Controller.get().loginMgr().forgotPassword(this.caller.state.userName, function (err, data) {
        console.log('forgotPassword result:', data)
        if (!err) {
          if (data.code === 'SUCCESS') {
            //Show RESETPASS-PAGE with message data.message
            this.onSuccessResentResetPassword(message || data.message);
          } else if (data.code === 'USER_NOT_FOUND') {
            this.onError(data.message);
          } else if (data.code === 'NETWORKING_ERROR') {
            this.onError(data.message);
          } else {
            this.onError(data.message);
          }
        }
      }.bind(this));
    }.bind(this));
  }

  onSuccessResentResetPassword = (message) => {
    const options = {
      loadingAnimation: false,
    }
    if (message) {
      options.message = message;
    }
    this.caller.setState(options);
  }
}
