import React, { Component, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppStyles, AppTheme } from '../styles/AppTheme';
import moment from 'moment';
import { AppConfig } from '../AppConfig';
import { CustomImageUploaderStyles } from '../styles/CustomStyles';
import { ImageUploader } from '../common/components/ImageUploader';
import { UiHelper } from '../common/helpers/UiHelper';
import { CircularProgress } from '@material-ui/core';
import { Helper } from '../common/helpers/Helper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { UploadHelper } from '../common/helpers/UploadHelper'
import { ThreeSixty } from '@material-ui/icons';
import { AppHelper } from '../helpers/AppHelper';

const uuid = require('uuid');

const FILE_SIZE_MB = 10000000
export class CustomImageUploader extends ImageUploader {
  render() {
    if (this.props.mode === 'fit' && this.selectedImgHeight && this.selectedImgWidth) {
      if (this.selectedImgWidth < this.width) {
        this.width = this.selectedImgWidth
      }
      this.height = Math.round((this.selectedImgHeight / this.selectedImgWidth) * this.width)
      if (this.height > this.heightExpected) {
        this.height = this.heightExpected
        this.width = Math.round((this.selectedImgWidth / this.selectedImgHeight) * this.height)
      }
    }
    const ratio = this.width / this.height
    let height = 120
    let width = height * ratio
    if (width > 250) { // too wide
      width = 250
      height = width / ratio
    }
    const EditorStyles = StyleSheet.create({
      editor: {
        backgroundColor: 'white',
        height,
        width
      },
    })

    const inputTagId = 'upload-button-' + this.props.uploadId + '-' + moment().valueOf()
    const outerContainer = (!this.state.uploadInProgress && this.props.url) ? css(Styles.outerContainer, Styles.imageOuterContainer) : css(Styles.outerContainer)
    return (
      <div className={css(Styles.container)}>
        <input
          id={inputTagId}
          className={css(Styles.fileInput)}
          onChange={this.onFileChange.bind(this)}
          type='file'
          style={{ display: 'none' }}
          accept={this.props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'}
          disabled={this.props.disabled || this.state.uploadInProgress} />
        <div className={css(Styles.outerMainContainer)}>

          <div className={outerContainer}>
            <label htmlFor={inputTagId} className={(this.props.disabled || this.state.uploadInProgress) ? css(Styles.labelDisableContainer) : css(Styles.labelContainer)}>
              {/* <FileDrop onDrop={!this.props.disabled && this.handleDrop.bind(this)} className={css(Styles.fileDrop)} targetClassName={css(Styles.targetFileDrop)}> */}
              {(!this.props.url || this.state.uploadInProgress) &&
                <Fragment>
                  <div className={css(Styles.uploadImageIconContainer, this.props.customImageUploadContainer)}>
                    <img src={require('../assets/images/imagetemplatenoborder.png')}
                      alt={Helper.getString('uploadImageIconAlt')}
                      className={css(Styles.uploaderImg)}
                      width={this.props.imageUploadIconContainerWidth}
                      height={this.props.imageUploadIconContainerHeight}
                    />
                    {this.state.uploadInProgress ?
                      <CircularProgress size={50} className={css(Styles.progressIcon)} />
                      :
                      <p className={css(Styles.attachFileText, this.props.cutomImageUploaderIcon)}>+</p>

                    }
                  </div>

                </Fragment>
              }
              {this.props.url && !this.state.uploadInProgress &&
                <div className={css(this.props.imageContainer)}>
                  <img src={this.props.url}
                    alt={Helper.getString('uploadedThumImageAlt')}
                    className={css(Styles.img)}
                    width={this.props.imageContainerWidth}
                    height={this.props.imageContainerHeight}
                  />
                </div>
              }
            </label>
            {this.props.url && !this.state.uploadInProgress &&
              <Fragment>
                <div className={this.props.disabled ? css(Styles.closeIconContainer, Styles.closeIconDisableContainer) : css(Styles.closeIconContainer)}
                  onClick={() => !this.props.disabled && this.onFileDelete(inputTagId)}>
                  {/* <i className='material-icons-outlined' style={{ fontSize: 14 }}>delete</i> */}
                  <img src={require('../assets/images/deleteasset.png')}
                    alt={Helper.getString('deleteAssetIconAlt')}
                    className={css(Styles.img)}
                    width='45'
                    height='45'
                  />
                </div>
              </Fragment>
            }
          </div>
        </div>
        {this.state.message && (this.state.fetchState === ApiHelper.State.ERROR) && !this.props.disabled &&
          // this.renderMessage()
          this.props.handleImageUploadErrorMsg(this.state.message)
        }
      </div>
    )
  }

  readFile(file) {
    this.props.wizardButtonsDisable(true)
    super.readFile(file)
  }

  async onUpload() {
    if (!this.state.file) {
      return this.onError(Helper.getString('fileChoose'))
    }
    const maxSize = this.props.fileSizeLimit ? this.props.fileSizeLimit : FILE_SIZE_MB;
    if (this.state.file.size > maxSize) {
      const errMsg = maxSize / 1000000 + Helper.getString('fileSizeExceedMsg')
      return this.onError(errMsg)
    }
    this.setState(
      {
        message: '',
        fetchState: ApiHelper.State.READY
      }
    )
    const ext = this._getFileExt()
    const options = {
      uploadLocation: this.props.uploadLocation,
      type: this.props.uploadType || 'media',
      ext: this.props.uploadExt || ext
    }


    this.onUploadProgress()
    try {
      const proms = []
      for (const dimen of this.props.uploadDimensions) {
        proms.push(this._rezizeAndUpload(options, this.state.file, dimen, ext))
      }
      const [result1, result2, result3] = await Promise.all(proms)
      console.log('Custom Image Uploader result1, result2, result3 , ', result1, result2, result3)
      this.onUploadComplete([result1, result2, result3])
    } catch (err) {
      this.onError(err)
    }
  }

  onUploadComplete(signedUploadInfoArray) {
    this.setState({
      fetchState: ApiHelper.State.READY,
      uploadInProgress: false,
      deleteInProgress: null
    })
    if (this.props.onUploadSuccess) {
      this.props.onUploadSuccess(this.props.uploadId, signedUploadInfoArray)
    }
  }

  async _rezizeAndUpload(options, file, dimen, actulaFileExt) {
    let resize = true
    if (options.ext === 'svg') {
      resize = false
    } else if (this.selectedImgWidth <= dimen.width && this.selectedImgHeight <= dimen.height && actulaFileExt === this.props.uploadExt) {
      resize = false
    }

    let compressFormat = 'PNG' // default format
    if (options.ext === 'jpg') {
      compressFormat = 'JPEG'
    } else if (options.ext === 'png') {
      compressFormat = 'PNG'
    }

    let resizeResult
    if (resize) {
      console.log('Custom Image Uploader going for resize')
      resizeResult = await AppHelper.resizeFile({
        file,
        maxWidth: dimen.width,
        maxHeight: dimen.height,
        compressFormat: compressFormat,
        quality: 70,
        outputType: 'blob'
      })
    }

    const fileResult = resizeResult || file
    let signedUploadInfoResult

    if (this.props.signedUploadInfo) {
      signedUploadInfoResult = await this._uploadAssetWithSignedUploadInfo(options, fileResult)
    } else {
      signedUploadInfoResult = await this._fetchUploadUrl(options, fileResult)
    }

    return signedUploadInfoResult
  }

  _fetchUploadUrl = async (options, file) => {
    return new Promise((resolve, reject) => {
      UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
        if (err) {
          reject(err)
        }
        console.log('fetchUploadUrl:', err, signedUploadInfo)
        const uploadOptions = {
          pickerResult: file,
          signedUploadInfo: signedUploadInfo,
          onEvent: (type, percent) => {
            console.log('progress:', type, percent)
            if (type === 'progress') {
              //return this.onUploadProgress(percent)
            } else if (type === 'load') {
              resolve(signedUploadInfo)
            } else if (type === 'error') {
              reject(Helper.getString('fileUploadErr'))
            }
          }
        }
        UploadHelper.uploadFormDataWithProgress(uploadOptions)
      })
    })
  }

  onFileChange(e) {
    if (this.props.useSignedUploadInfo) {
      if (!this.props.signedUploadInfo) {
        super.onError(Helper.getString('fileCouldNotBeUploaded'))
        return
      }
    }
    super.onFileChange(e)
  }



  _uploadAssetWithSignedUploadInfo = (options, file) => {

    return new Promise((resolve, reject) => {
      let signedUploadInfo
      try {
        signedUploadInfo = JSON.parse(JSON.stringify(this.props.signedUploadInfo))
      } catch (e) {
        console.log('Json parse error', e)
        this.onError(Helper.getString('fileUploadErr'))
      }
      const types = file.type.split('/')
      let fileExtn = ''
      if (types.length === 2) {
        fileExtn = types[1] || options.ext
      }
      signedUploadInfo.fields.key = signedUploadInfo.fields.key + uuid.v4() + '.' + fileExtn
      signedUploadInfo.link = AppConfig.CDN_BASEPATH + '/' + signedUploadInfo.fields.key
      signedUploadInfo.fields['Content-Type'] = file.type
      const uploadOptions = {
        pickerResult: file,
        signedUploadInfo: signedUploadInfo,
        onEvent: (type, percent) => {
          console.log('progress:', type, percent)
          if (type === 'progress') {
            //this.onUploadProgress(percent)
          } else if (type === 'load') {
            resolve(signedUploadInfo)
          } else if (type === 'error') {
            return reject(Helper.getString('fileUploadErr'))
          }
        }
      }
      UploadHelper.uploadFormDataWithProgress(uploadOptions)
    })
  }


  onError(err) {
    this.props.wizardButtonsDisable(false)
    super.onError(err)
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomImageUploaderStyles : StyleSheet.create({
  container: {
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
  },
  fitContainer: {
    height: 120,
    width: 'auto'
  },
  fileInput: {
    width: '100%'
  },
  textButton: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 100,
    minHeight: 24,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10
  },
  text: {
    marginTop: 10,
    marginBottom: 3,
    fontSize: 12
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  editor: {
    width: 65,
    height: 65,
  },
  imgContainer: {
    display: 'none'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageContent: {
    borderRadius: 0,
    maxWidth: '100%',
    maxHeight: '100%'
  },
  closeIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    // backdropFilter: 'blur(6px)',
    // backgroundColor: 'white',
    display: 'flex',
    cursor: 'pointer',
    // border: '1px solid black',
    // padding: 2,
    // borderRadius: 4,
    width: 45,
    height: 45
  },
  closeIconDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  attachFileText: {
    margin: 0,
    fontSize: 100,
    color: '#FFFFFFB3',
    position: 'absolute',
    // top: 0,
    // right: 0,
    // left: 0,
    textAlign: 'center',
    '@media (max-width: 1024px) and (min-width: 768px)': {
      fontSize: 80,
    }
  },
  attachFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10
  },
  labelContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    padding: 0,
    borderRadius: 0,
  },
  labelDisableContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: 0,
    //backgroundColor: '#f5f5f5',
    cursor: 'unset',
    borderRadius: 0,
  },
  attachFileTextBrowse: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  outerMainContainer: {
    display: 'flex'
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    // border: '1px dashed #E5E5E5',
    // borderRadius: AppTheme.borderRadius,
    minHeight: 100,
    position: 'relative',
    overflow: 'hidden'
  },
  imageOuterContainer: {
    flex: 'unset',
    // border: '1px solid #E5E5E5'
  },
  imageIconContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageIconOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  reuploadIconContainer: {
    right: 34
  },
  fileDrop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    position: 'relative'
  },

  targetFileDrop: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  reuploadContainer: {
    fontSize: 16,
    color: AppTheme.primaryColor,
    margin: 20,
    display: 'flex',
    alignItems: 'center'
  },
  reuploadLabel: {
    cursor: 'pointer'
  },
  uploadImageIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    position: 'relative'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover'
  },
  uploaderImg: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  progressIcon: {
    position: 'absolute',
    // margin: 'auto',
    // top: 0,
    // right: 0,
    // left: 0,
    // bottom: 0
  }
})