import React from 'react';
import { Controller } from '../common/Controller';
import { Helper } from '../common/helpers/Helper';
import { AppConfig } from '../AppConfig';
import moment from 'moment';
import { StyleSheet, css } from 'aphrodite';
import { CircularProgress } from '@material-ui/core';
import { PROJECT_STATE } from '../Consts';
import { isUri } from 'valid-url'
import { AppButton } from '../common/components/AppButton';
import { PAYPAL_CONSTS_SANDBOX_AZ } from '../Consts'
import { PAYPAL_CONSTS_SANDBOX } from '../Consts'
import { PAYPAL_CONSTS_PRODUCTION } from '../Consts'
import Resizer from 'react-image-file-resizer';

const vsprintf = require('sprintf-js').vsprintf

export class AppHelper {

  static getDrawerMenuItems() {
    return AppHelper.isDrawerMenuEnabled()
  }

  static getAdminDrawerMenuItems() {
    return ([
      [
        {
          //route: Controller.get().getHomeByRole(),
          // route: vsprintf('/admin'),
          route: '/admin',
          title: Helper.getString('dashboard'),
          grayIcon: require('../assets/images/dashboard-gray.png'),
          whiteIcon: require('../assets/images/dashboard-white.png'),
          key: 'adminOrgs',
        },
        {
          route: '/admin/profile',
          title: Helper.getString('profile'),
          grayIcon: require('../assets/images/profilecirclegray.png'),
          whiteIcon: require('../assets/images/profilecircle.png'),
          key: 'profile',
        },
        {
          // route: Controller.get().getHomeByRole() + '/cbdefs',
          route: '/admin/videos',
          title: Helper.getString('videos'),
          grayIcon: require('../assets/images/uploaded-videos-gray.png'),
          whiteIcon: require('../assets/images/uploaded-videos-white.png'),
          key: 'videos',
        },
        // {
        //   route: '/payout',
        //   title: Helper.getString('payout'),
        //   grayIcon: require('../assets/images/payout-settings-gray.png'),
        //   whiteIcon: require('../assets/images/payout-settings-white.png'),
        //   key: 'payout',
        // }
      ]
    ])
  }

  static formatDateTime(str) { return moment(str).format('MMM D, YYYY. ') }

  static getDurationText(duration) {
    if (duration === null || duration === undefined || isNaN(duration)) {
      return '0'
    }
    if (duration < 60000) {
      return Math.ceil(duration / 1000) + 's. '
    } else {
      return Math.ceil(duration / 60000) + 'm. '
    }
  }

  // static getAdminDrawerMenuItems() {
  //   return ([
  //     [
  //       {
  //         route: Controller.get().getHomeByRole(),
  //         title: Helper.getString('orgs'),
  //         icon: 'dashboard',
  //         key: 'adminOrgs',
  //       },
  //       {
  //         route: Controller.get().getHomeByRole() + '/cbdefs',
  //         title: Helper.getString('globalBlocks'),
  //         icon: 'space_dashboard',
  //         key: 'globalBlocks',
  //       },
  //     ],
  //     [
  //       {
  //         route: vsprintf('/admin/users'),
  //         title: Helper.getString('productusers'),
  //         icon: 'supervisor_account',
  //         key: 'usersOfOrg',
  //       }
  //     ]
  //   ])
  // }

  static getOrgUserDrawerMenuItems(orgId) {
    return ([
      [
        {
          key: 'orgDashboard',
          route: vsprintf('/admin/orgs/%s', [orgId]),
          title: Helper.getString('dashboard'),
          icon: 'dashboard'
        },
        {
          key: 'catalogSection',
          title: Helper.getString('catalog'),
          subItems: [
            {
              key: 'products',
              route: vsprintf('/admin/orgs/%s/products', [orgId]),
              title: Helper.getString('products'),
              icon: 'layers'
            },
            {
              key: 'catsL1',
              route: vsprintf('/admin/orgs/%s/cats', [orgId]),
              title: Helper.getString('cats'),
              icon: 'category'
            },
            {
              key: 'brands',
              route: vsprintf('/admin/orgs/%s/brands', [orgId]),
              title: Helper.getString('brands'),
              icon: 'loyalty'
            },
            {
              key: 'banners',
              route: vsprintf('/admin/orgs/%s/banners', [orgId]),
              title: Helper.getString('banners'),
              icon: 'view_carousel',
            },
            {
              key: 'prodLists',
              route: vsprintf('/admin/orgs/%s/lists', [orgId]),
              title: Helper.getString('lists'),
              icon: 'collections_bookmark'
            },
            {
              key: 'orders',
              route: vsprintf('/admin/orgs/%s/orders', [orgId]),
              title: Helper.getString('orders'),
              icon: 'local_mall'
            },
            {
              key: 'coupons',
              route: vsprintf('/admin/orgs/%s/coupons', [orgId]),
              title: Helper.getString('coupons'),
              icon: 'local_offer'
            },
            {
              key: 'testimonials',
              route: vsprintf('/admin/orgs/%s/testimonials', [orgId]),
              title: Helper.getString('testimonials'),
              icon: 'supervisor_account'
            },
            {
              key: 'benefits',
              route: vsprintf('/admin/orgs/%s/benefits', [orgId]),
              title: Helper.getString('benefits'),
              icon: 'supervisor_account'
            },
            {
              key: 'faqs',
              route: vsprintf('/admin/orgs/%s/faqs', [orgId]),
              title: Helper.getString('faqs'),
              icon: 'supervisor_account',
            },
            {
              key: 'faqSections',
              route: vsprintf('/admin/orgs/%s/faqsections', [orgId]),
              title: Helper.getString('faqsections'),
              icon: 'supervisor_account'
            }
          ]
        },
        {
          key: 'blogSection',
          title: Helper.getString('blogs'),
          subItems: [
            {
              key: 'blogs',
              route: vsprintf('/admin/orgs/%s/blogs', [orgId]),
              title: Helper.getString('blogs'),
              icon: 'supervisor_account'
            },
            {
              key: 'blogTags',
              route: vsprintf('/admin/orgs/%s/btags', [orgId]),
              title: Helper.getString('blogtags'),
              icon: 'supervisor_account'
            },
            {
              key: 'blogLists',
              route: vsprintf('/admin/orgs/%s/blists', [orgId]),
              title: Helper.getString('bloglists'),
              icon: 'supervisor_account'
            },
          ]
        },
        {
          key: 'cborgs',
          route: vsprintf('/admin/orgs/%s/cborgs', [orgId]),
          title: Helper.getString('contentblocks'),
        },
        {
          key: 'content-blocks',
          route: vsprintf('/admin/orgs/%s/content-blocks', [orgId]),
          title: Helper.getString('contentblockslegacy'),
        },
        {
          key: 'pages',
          route: vsprintf('/admin/orgs/%s/pages', [orgId]),
          title: Helper.getString('pages'),
        },
        {
          key: 'menus',
          route: vsprintf('/admin/orgs/%s/menus', [orgId]),
          title: Helper.getString('menus'),
        },
      ],
      [
        {
          key: 'settings',
          route: vsprintf('/admin/orgs/%s/settings', [orgId]),
          title: Helper.getString('settings'),
          icon: 'supervisor_account'
        },
        {
          key: 'usersOfOrg',
          route: vsprintf('/admin/orgs/%s/users', [orgId]),
          title: Helper.getString('admins'),
          icon: 'supervisor_account'
        },
      ]
    ])
  }

  static isDrawerMenuEnabled() {
    return AppHelper.getAdminDrawerMenuItems()
    // let orgId = Controller.get().userMgr().getOrgId()
    // const pName = window.location.pathname
    // let menu = []
    // if (pName.startsWith('/admin/orgs/')) {
    //   if (pName.endsWith('/preview')) {
    //     return null
    //   }
    //   orgId = pName.split('/')[3]
    //   if (Controller.get().userMgr().isProductSU()) {
    //     menu = AppHelper.getOrgUserDrawerMenuItems(orgId)
    //   } else if (AppConfig.ORGSU_ACCESS_ALLOWED && Controller.get().userMgr().hasOrgRole(orgId, 'SUPERUSER')) {
    //     menu = AppHelper.getOrgUserDrawerMenuItems(orgId)
    //   }
    // } else if (pName.startsWith('/admin')) {
    //   if (Controller.get().userMgr().isProductSU()) {
    //     menu = AppHelper.getAdminDrawerMenuItems()
    //   }
    // }
    // if (AppConfig.ADMIN_DRAWER_MENU_ENABLED && menu.length > 0) {
    //   return menu
    // }
    // return null
  }

  static getPublicDrawerMenuItems() {
    return []
  }

  static loadingView() {
    return (
      <div className={css(Styles.container)}>
        <CircularProgress size={48} className={css(Styles.progress)} />
      </div>
    )
  }

  static errorView(errMsg, buttonStyle, buttonText, handleTryAgain) {
    return (
      <div className={css(Styles.errorViewContainer)}>
        <p className={css(Styles.errorText)}>{errMsg}</p>
        <AppButton buttonStyle={buttonStyle}
          onClick={handleTryAgain}
          buttonText={buttonText} />
      </div>
    )
  }

  static getFormattedDuration = (durationInseconds) => { // Expeted in seconds
    if (isNaN(durationInseconds)) {
      return '00:00';
    }

    const currentTime = Math.round(durationInseconds * 100) / 100
    const currentTimeInteger = Math.floor(currentTime)
    const ms = Math.round((currentTime - currentTimeInteger) * 100)

    const seconds = currentTimeInteger % 60
    const minutesTotal = Math.floor(currentTimeInteger / 60)
    const minutes = minutesTotal % 60
    const hours = Math.floor(minutesTotal / 60)
    return {
      ms: ms < 10 ? '0' + ms : ms,
      seconds: seconds < 10 ? '0' + seconds : seconds,
      minutes: minutes < 10 ? '0' + minutes : minutes,
      hours: hours < 10 ? '0' + hours : hours,
    }
  }

  static ifEditInPublishedState(project) {
    if (project.state === PROJECT_STATE.READY) {
      return true
    }
    return false
  }

  static ifEditInPublishedAndPurchasedState(project) {
    if (project && project.state === PROJECT_STATE.READY && project.stats && project.stats.buyCount > 0) {
      return true
    }
    return false
  }

  static getValidatedUrl(url) {
    if (!url) {
      return
    }
    if (isUri(url)) {
      return url
    }
    if (isUri('http://' + url)) {
      return 'http://' + url
    }
    return url
  }

  static getPaypalConnsts() {
    return PAYPAL_CONSTS_PRODUCTION
  }

  static getCurrencyInHigherDenomination(value) {
    const higherCurrencyValue = (value / 100)
    return higherCurrencyValue.toFixed(2)
  }

  static resizeFile = async (options) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        options.file,
        options.maxWidth,
        options.maxHeight,
        options.compressFormat || 'JPEG',
        options.quality || 70,
        0,
        (uri) => {
          resolve(uri);
        },
        options.outputType
      );
    });
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    // height: '100vh',
  },
  progress: {
    color: '#ffffff'
  },
  errorViewContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 20,
    color: '#FF0000',
  }
})
