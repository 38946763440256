/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
export const StringTableCommon = [
  {
    "key": "login",
    "en": "Login"
  },
  {
    "key": "signup",
    "en": "Sign Up"
  },
  {
    "key": "version",
    "en": "Version"
  },
  {
    "key": "permissionErr",
    "en": "Your permissions does not allow accessing this page. Contact customer care team if you did not expect this."
  },
  {
    "key": "defaultApiErrMsg",
    "en": "We are experiencing a technical issue. Contact customer care team if problem persists."
  },
  {
    "key": "fileChoose",
    "en": "Choose a file"
  },
  {
    "key": "fileUploadErr",
    "en": "Upload failed. You can try again"
  },
  {
    "key": "fileUploadSuccess",
    "en": "Upload successful"
  },
  {
    "key": "successful",
    "en": "Successful"
  },
  {
    "key": "unknownFieldType",
    "en": "Unknown field type:"
  },
  {
    "key": "pendingAppr",
    "en": "Pending approval"
  },
  {
    "key": "imageEditErr",
    "en": "Unable to edit the image"
  },
  {
    "key": "startAgain",
    "en": "Start again from dashboard"
  },
  {
    "key": "paymentConfirmMsg",
    "en": "Thank you for the payment"
  },
  {
    "key": "orderNumberMsg",
    "en": "Your order number is:"
  },
  {
    "key": "convenienceFee",
    "en": "Convenience fees:"
  },
  {
    "key": "convenienceFeeGST",
    "en": "Convenience fees(GST):"
  },
  {
    "key": "pay",
    "en": "Pay"
  },
  {
    "key": "paymentErr",
    "en": "Payment could not be completed"
  },
  {
    "key": "emptyMsg",
    "en": "Nothing to show here yet"
  },
  {
    "key": "otp",
    "en": "OTP"
  },
  {
    "key": "enterPassword",
    "en": "Enter your password"
  },
  {
    "key": "enterOTP",
    "en": "Enter the OTP"
  },
  {
    "key": "enterMobile",
    "en": "Enter your mobile number"
  },
  {
    "key": "enterEmail",
    "en": "Enter your email"
  },
  {
    "key": "loginErr",
    "en": "ERROR: User not Logged in!"
  },
  {
    "key": "customerCareErr",
    "en": "An error occurred. Contact customer care team if the problem persists"
  },
  {
    "key": "invalidNumber",
    "en": "Invalid phone number"
  },
  {
    "key": "serviceErr",
    "en": "This service is currently unavailable."
  },
  {
    "key": "otpToMobile",
    "en": "The OTP has been sent to:"
  },
  {
    "key": "cognitoMgrGetAccessToken",
    "en": "CognitoMgr: callback in getAccessToken is null...returning"
  },
  {
    "key": "cognitoMgrGetIdToken",
    "en": "CognitoMgr: callback in getIdToken is null...returning"
  },
  {
    "key": "cognitoMgrGetRefreshToken",
    "en": "CognitoMgr: callback in getRefreshToken is null...returning"
  },
  {
    "key": "cloudPermissionErr",
    "en": "A cloud permission problem occurred"
  },
  {
    "key": "internetErr",
    "en": "Unable to connect. Check your internet conection and try again."
  },
  {
    "key": "cloudResponseErr",
    "en": "Unable to read cloud response"
  },
  {
    "key": "permissionPrblmErr",
    "en": "A permission problem occurred"
  },
  {
    "key": "authenticationErr",
    "en": "Authentication error. Check your internet connection and try again."
  },
  {
    "key": "signupFailire",
    "en": "signUp onFailire"
  },
  {
    "key": "otpSent",
    "en": "The OTP has been sent to you"
  },
  {
    "key": "OTPResetSent",
    "en": "One-time password was resent to your email. ",
  },
  {
    "key": "authenticateUserFailire",
    "en": "authenticateUser onFailire"
  },
  {
    "key": "setNewPassword",
    "en": "You have to set a new password"
  },
  {
    "key": "mfaRequired",
    "en": "mfaRequired: Not Implemented"
  },
  {
    "key": "customChallenge",
    "en": "customChallenge: Not Implemented"
  },
  {
    "key": "userNull",
    "en": "user cannot be null"
  },
  {
    "key": "getSessionErr",
    "en": "getSession error"
  },
  {
    "key": "sessionInValid",
    "en": "Session is not valid"
  },
  {
    "key": "passwordResetSent",
    "en": "The password reset code has been sent to you"
  },
  {
    "key": "passwordResetSentSpecific",
    "en": "The password reset code has been sent to:"
  },
  {
    "key": "passwordRestLogin",
    "en": "You have successfully reset your password. Login with your new password"
  },
  {
    "key": "passwordSetLogin",
    "en": "You have successfully set your password. Login with your new password."
  },
  {
    "key": "completeNewPasswordChallengeFailire",
    "en": "completeNewPasswordChallenge onFailire"
  },
  {
    "key": "completeNewPasswordChallengeMfaRequired",
    "en": "completeNewPasswordChallenge::mfaRequired: Not Implemented"
  },
  {
    "key": "completeNewPasswordChallengeCustomChallenge",
    "en": "completeNewPasswordChallenge::customChallenge: Not Implemented"
  },
  {
    "key": "userNotFoundException",
    "en": "We are not able to identify your email/phone. Please signup and then login."
  },
  {
    "key": "notAuthorizedException",
    "en": "Your username or password is wrong. Try again."
  },
  {
    "key": "codeMismatchException",
    "en": "Invalid OTP provided. Try again."
  },
  {
    "key": "invalidPasswordException",
    "en": "Password should be minumum 8 characters with special characters, numbers, uppercase and lowercase letters."
  },
  {
    "key": "networkingError",
    "en": "Network problem. Check your internet connection."
  },
  {
    "key": "aliasExistsException",
    "en": "You already have an account. You can login instead of signup."
  },
  {
    "key": "usernameExistsException",
    "en": "You already have an account. You can directly login. Reset the password if required."
  },
  {
    "key": "tooManyTries",
    "en": "You have tried too many times. Try after some time",
  },
  {
    "key": "internalError",
    "en": "An internal problem occurred. Check your username, password and other parameters and try again."
  },
  {
    "key": "home",
    "en": "Home"
  },
  {
    "key": "our",
    "en": "Our"
  },
  {
    "key": "dashboard",
    "en": "Dashboard"
  },
  {
    "key": "tenants",
    "en": "Tenants"
  },
  {
    "key": "orgs",
    "en": "Organisations"
  },
  {
    "key": "productusers",
    "en": "Administrators"
  },
  {
    "key": "privacy",
    "en": "Privacy Policy"
  },
  {
    "key": "contactUs",
    "en": "Contact Us"
  },
  {
    "key": "faqs",
    "en": "FAQs"
  },
  {
    "key": "complete",
    "en": "Complete your profile"
  },
  {
    "key": "completeProfile",
    "en": "Complete your profile to get started."
  },
  {
    "key": "enterFirstName",
    "en": "Enter first name"
  },
  {
    "key": "enterLastName",
    "en": "Enter last name"
  },
  {
    "key": "enterDob",
    "en": "Enter date of birth"
  },
  {
    "key": "signUp",
    "en": "Sign Up"
  },
  {
    "key": "email",
    "en": "Email"
  },
  {
    "key": "password",
    "en": "Password"
  },
  {
    "key": "setPassword",
    "en": "Set password"
  },
  {
    "key": "startOver",
    "en": "Start over"
  },
  {
    "key": "enterVerificationCode",
    "en": "Enter verification code"
  },
  {
    "key": "verificationCode",
    "en": "Verification code"
  },
  {
    "key": "submit",
    "en": "Submit"
  },
  {
    "key": "resetPswd",
    "en": "Reset password? Get OTP"
  },
  {
    "key": "getOtp",
    "en": "Get OTP"
  },
  {
    "key": "mobile",
    "en": "Mobile"
  },
  {
    "key": "faqsections",
    "en": "FAQ Sections"
  },
  {
    "key": "testimonials",
    "en": "Testimonials"
  },
  {
    "key": "benefits",
    "en": "Benefits"
  },
  {
    "key": "settings",
    "en": "Settings"
  },
  {
    "key": "fileSizeExceedMsg",
    "en": "MB is the maximum file size that can be attached."
  },
  {
    "key": "deleteConfirmMsg",
    "en": "Are you sure you want to delete?"
  },
  {
    "key": "mobileView",
    "en": "Mobile"
  },
  {
    "key": "desktopView",
    "en": "Desktop"
  },
  {
    "key": "refresh",
    "en": "Refresh"
  },
  {
    "key": "logout",
    "en": "Logout"
  },
  {
    "key": "invalidFileType",
    "en": "Invalid file type"
  },
  {
    "key": "preview",
    "en": "Preview"
  },
  {
    "key": "openFile",
    "en": "Open file"
  },
  {
    "key": "admins",
    "en": "Admins"
  },
  {
    "key": "confirmDeleteMsg",
    "en": "Type 'delete' to confirm Deletion"
  },
  {
    "key": "uploadFile",
    "en": "Drag & Drop a file here or"
  },
  {
    "key": "videos",
    "en": "Videos"
  },
  {
    "key": "payout",
    "en": "Payout Settings"
  },
  {
    "key": "resendOtp",
    "en": "Resend one-time password"
  },
  {
    "key": "LinkPopover",
    "en": "Link Popover"
  },
]
