import React, { Component } from 'react';

import { StyleSheet, css } from 'aphrodite';
import { AppTheme } from '../../styles/AppTheme';
import { AppBar, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Helper } from '../helpers/Helper';

export function Header() {
  return (
    <div className={css(Styles.toolbar)}>
      <Link to={'/'}>
        <img src={require('../../assets/images/logo-group.png')} alt={Helper.getString('logoAlt')} className={css(Styles.logoImage)} height='32' width='158' />
      </Link>
    </div>
  );
}

const Styles = StyleSheet.create({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    minHeight: AppTheme.toolbarHeight,
    position: 'fixed',
    zIndex: 2,
    minWidth: '100%',
    backgroundColor: '#000'
  },
  logoImage: {
    height: 32,
    width: 'auto',
    marginRight: 10
  },
})