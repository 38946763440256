import React from 'react';
import * as filestack from 'filestack-js';

const TAG = 'ProjectHelper::'

export class ProjectHelper {

  static uploadProject(uploadCallback, errorCallback) {
    const client = filestack.init('AqtfqA0QpRGeU5ad7NXYtz');
    const options = {
      fromSources: ['local_file_system'],
      maxFiles: 1,
      accept: ['video/mp4', 'video/quicktime', 'video/x-msvideo'],
      storeTo: {
        location: 's3',
      },
      customText: {
        'Select Files to Upload': 'Select (or drag/drop) a video file to upload',
        'or Drag and Drop, Copy and Paste Files': 'Under 5GB (.mp4, .mov or .avi) | 16:9 (720p, 1080p or 4K) | 1 min to 2 hrs duration',
        Files: 'File',
        'Selected Files': 'Selected File',
      },
      uploadConfig: {
        retry: 5,
        timeout: 60000
      },
      onFileSelected: (file) => {
        console.log(TAG + ' onFileSelected: ', file)
        if (file.size > 5 * 1000 * 1000 * 1000) {
          return Promise.reject('File size exceeds 5GB limit')
        }
      },
      acceptFn: (file, options) => {
        return options.mimeFromMagicBytes(file.originalFile).then((res) => {
          const mimeFromMagicBytes = res
          const mimeTypeFromExtn = options.mimeFromExtension(file.originalFile.name)
          console.log('res', res, mimeTypeFromExtn);
          if (options.accept && options.accept.length > 0) {
            if (!(options.accept.includes(mimeFromMagicBytes) || options.accept.includes(mimeTypeFromExtn))) {
              return Promise.reject('Supported file formats are .mp4, .mov and .avi')
            }
          }
        });
      },
      onUploadStarted: () => {
        console.log(TAG + ' Upload Started')
      },

      onFileUploadProgress: (data, event) => {
        console.log(TAG + ' onFileUploadProgress' + event.totalBytes + '  ' + event.totalPercent)
      },
      onClose: (data) => {
        console.log(TAG + ' onClose', data)
      },
      onUploadDone: (uploadResponse) => {
        console.log(TAG + ' onUploadDone', uploadResponse)
        uploadCallback(uploadResponse)
      },
      onFileUploadFailed: (pickerFileMetadata, error) => {
        errorCallback(pickerFileMetadata, error)
      }

    };
    client.picker(options).open();
  }

  static uploadFiles(uploadCallback, errorCallback, closeCallback) {
    const client = filestack.init('AqtfqA0QpRGeU5ad7NXYtz');
    const options = {
      transformations: {
        crop: false,
        circle: false,
        rotate: false
      },
      fromSources: ['local_file_system'],
      maxFiles: 100,
      storeTo: {
        location: 's3',
      },
      customText: {
        'Select Files to Upload': 'Select (or drag/drop) files to upload',
        'or Drag and Drop, Copy and Paste Files': 'Each file’s size must be under 50MB',
        Files: 'Files',
        'Selected Files': 'Selected Files',
      },
      uploadConfig: {
        retry: 5,
        timeout: 60000
      },
      onFileSelected: (file) => {
        console.log(TAG + ' onFileSelected: ', file)
        if (file.size > 50 * 1000 * 1000) {
          return Promise.reject('File size exceeds 50MB limit')
        }
      },
      acceptFn: (file, options) => {
      },
      onUploadStarted: () => {
        console.log(TAG + ' Upload Started')
      },

      onFileUploadProgress: (fileMetaData, event) => {
        console.log(TAG + ' fileMetaData', fileMetaData)
        console.log(TAG + ' onFileUploadProgress' + event.totalBytes + '  ' + event.totalPercent)
      },
      onClose: (data) => {
        console.log(TAG + ' onClose', data)
        if (closeCallback) {
          closeCallback(data);
        }
      },
      onUploadDone: (uploadResponse) => {
        console.log(TAG + ' onUploadDone', uploadResponse)
        uploadCallback(uploadResponse)
      },
      onFileUploadFailed: (pickerFileMetadata, error) => {
        console.log(TAG + ' onFileUploadFailed pickerFileMetadata', pickerFileMetadata)
        console.log(TAG + ' onFileUploadFailed error', error)
        //errorCallback(pickerFileMetadata, error)
      },
      onFileUploadFinished: (pickerFileMetadata) => {
        console.log(TAG + ' onFileUploadFinished pickerFileMetadata', pickerFileMetadata)
      }

    };
    client.picker(options).open();
  }
}
