import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { FormControl, Input, MenuItem, Select, TextField } from '@material-ui/core';
import { CustomImageUploader } from '../../components/CustomImageUploader';
import { SelectBox, TextBoxs } from '../../components/WizardTextInputs';
import { RichtextEditor } from '../../common/components/RichtextComponent/RichtextEditor';
import { LabelAndDescription } from './LabelAndDescription';
import { CustomLinkPopover } from '../../components/CustomLinkPopover';
import { HTML_FORMATTING, MAX_TEXT_LENGTH } from '../../Consts';

export function Materials(props) {

  const callToAction = {
    key: 'callToAction',
    placeholder: 'Select Call-to-Action',
    items: props.ctas
  }
  const textInput = {
    key: 'callToActionUrl',
    type: 'TextField',
    placeHolder: 'Call-to-Action URL (ex: razzl.com)',
    textBoxValue: ''
  }

  const descrItem = {
    key: 'descr',
    label: 'Material Description*',
    descr: 'List of materials, ingredients or tools used. Link to sources as needed.',
    type: 'RichTextField',
    placeholder: 'This is a required field for instructional videos.',
    charachterLimit: MAX_TEXT_LENGTH.MATERIAL_DESCR_MAX_TEXT_LENGTH,
    textBoxValue: ''
  }
  //custom ToolBar for RichTextEditor
  const editorToolbar = {
    options: ['inline', 'list', 'link'],
    inline: {
      options: ['bold', 'italic'],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      options: ['left', 'center', 'right', 'justify'],
    },
    link: {
      component: CustomLinkPopover,
      showOpenOptionOnHover: true,
    }
  }

  const handleTextBox = (key, e) => {
    props.handleFormDataChanges(key, e.target.value)
  }

  const handleSelectBox = (e, value, key) => {
    props.handleFormDataChanges(key, value ? value : '')
  }

  const handleRichtextInputChange = (data) => {
    console.log('handleRichtextInputChange rich text format data', data)
    props.handleFormDataChanges('descr', data)
  }

  const onTab = (e) => {
    return true;
  }

  return (
    <div className={css(Styles.Container)}>
      <div className={css(Styles.innerContainer)}>
        <LabelAndDescription
          label='Call-To-Action'
          optional={true}
          descr='If applicable, select a Materials Call-to-Action (enter a corresponding URL).'
        />
        <div className={css(Styles.inputContainer)}>
          <SelectBox item={callToAction}
            handleSelectBox={handleSelectBox}
            value={props.projectData.materials.callToAction} />
          <TextBoxs item={textInput}
            handleTextBox={handleTextBox}
            textBoxStyle={Styles.textBoxStyle}
            value={props.projectData.materials.callToActionUrl} />
        </div>
        <LabelAndDescription
          label={descrItem.label}
          optional={false}
          descr={descrItem.descr}
        />
        <div className={css(Styles.richTextContainer)}>
          <RichtextEditor
            handleRichtextInputChange={handleRichtextInputChange}
            current={descrItem}
            fullEditor={editorToolbar}
            toolbarClassName='sticky-toolbar-materials'
            onTab={onTab}
            value={props.projectData.materials[descrItem.key]}
            placeholder={descrItem.placeholder}
            maxLength={descrItem.charachterLimit}
            htmlInlineStyle={HTML_FORMATTING}
          />
        </div>
      </div>
    </div>
  )
}

const Styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 27,
    alignItems: 'center'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 700,
    marginBottom: 30
  },
  label: {
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'uppercase',
    margin: 0,
  },
  optionalText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    textTransform: 'lowercase'
  },
  labelDescr: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginTop: 4,
    marginBottom: 10
  },
  inputContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  textBoxStyle: {
    minHeight: 35,
    minWidth: 345,
    marginLeft: 7
  },

  textFeildStyle: {
    minHeight: 79,
    borderRadius: 4,
    border: '0.5px solid #FFFFFF80',
    marginBottom: 28
  },
  textInputStyles: {
    border: 'none',
    padding: '9px 8px',
    flex: 1,
    textAlign: 'left',
    // minHeight: 50,
    fontSize: 16,
    color: '#FFFFFF80',
    '::placeHolder': {
      color: '#FFFFFF80',
    }
  },
  richTextContainer: {
    width: 700
  },
})