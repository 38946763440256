import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';

import { AppConfig } from '../AppConfig';
import { CommonStyles } from '../styles/Styles';
import { HelmetComponent } from '../common/components/HelmetComponent';
import { Header } from '../common/components/Header';
import { Link } from 'react-router-dom';
import { Footer } from '../common/components/Footer';

export class IntellectualPolicy extends Component {
  render() {
    return (
      <div className={css(CommonStyles.container)}>
        <Header />
        <Grid container className={css(CommonStyles.textfulPageContent)}>

          <HelmetComponent page={'intellectualPolicy'} />
          <div className={css(CommonStyles.textfulPageContentInnerContainer)}>
            <h1 className={css(CommonStyles.title)}>Intellectual Property Policy</h1>
            <p className={css(CommonStyles.textfulPagePara)}>
              Razzl respects the intellectual property rights of others, and we expect you to do the same. Razzl’s <Link to='/terms' className={css(CommonStyles.linkText)}>Terms of Service</Link> and <Link to='/community-guidelines' className={css(CommonStyles.linkText)}>Community Guidelines</Link> do not allow posting, sharing, or sending any content that violates or infringes someone else’s copyrights, trademarks or other intellectual property rights
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Copyright
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Copyright is a legal right that protects original works of authorship (e.g., music, videos, etc.). Generally, copyright protects an original expression of an idea (e.g., the specific way a video or music is expressed or created) but does not protect underlying ideas or facts.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Copyright Infringement
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We do not allow any content that infringes copyright. The use of copyrighted content of others without proper authorization or legally valid reason may lead to a violation of Razzl's policies.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              At the same time, not all unauthorized uses of copyrighted content constitute an infringement. In many countries, exceptions to copyright infringement allow the use of copyrighted works under certain circumstances without authorization. These include the fair use doctrine in the United States and permitted acts of fair dealing in the European Union (and other equivalent exceptions under applicable local laws in other countries).
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Removal of Content; Suspension or Termination of Account
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Any user content that infringes other person’s copyright may be removed. The account may be suspended or terminated for multiple copyright violations in connection with the use of the Razzl site or app, or other violations of the <Link to='/terms' className={css(CommonStyles.linkText)}>Terms of Service</Link> and <Link to='/community-guidelines' className={css(CommonStyles.linkText)}>Community Guidelines</Link>. We reserve the right to refuse any account holder whose account was used for improper activities from opening a new account on Razzl’s site or app, or otherwise hosted by Razzl.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Information for Rightsholders:
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Copyright Infringement Notification
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Contacting the user directly may resolve your complaint more quickly and in a way that is more beneficial to you, the user, and our community. You may also file a copyright infringement report to request the removal of the alleged infringing content from Razzl.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Please provide the following information in writing to Razzl Copyright Agent: (i) a physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that is allegedly infringed; (ii) identification of the copyrighted work claimed to have been infringed; (iii) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material; (iv) information reasonably sufficient to permit us to contact you, such as your address, telephone number, and email address; (v) a statement that you believe in good faith that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; (vi) a statement that the information in the notification is accurate and, under penalty of perjury, that you are the copyright owner or are authorized to act on behalf of the owner of a copyright that is allegedly infringed.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              All notifications must be sent to our DMCA Agent at: <a href={'mailto:' + AppConfig.SUPPORT_EMAIL}>copyright@razzl.com</a>.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We may provide the account holder with your contact information, including the email address and the name of the copyright owner, and/or details of the complaint.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Before submitting a notification, please be aware that intentionally submitting a misleading or fraudulent report may lead to liability for damages under section 512(f) of the United States Digital Millennium Copyright Act (DMCA) or similar laws as may be applicable in other countries.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If you submit a report or infringement notification to us, we may contact you if we have additional questions about your report or notification. Please note that Razzl is not in a position to adjudicate disputes between third parties, and may not be able to remove the content or suspend the account you reported. As an alternative, you may want to contact the person who posted the content or owns the account to try to resolve your issue directly.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Information for Users: Copyright Infringement Counter-Notification
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If you receive a copyright infringement notification that you believe to be in error or believe that you are authorized to use the content, you can reach out to the copyright owner directly to request a retraction.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You may also provide us with a counter-notification at: <a href={'mailto:' + AppConfig.SUPPORT_EMAIL}>copyright@razzl.com</a>.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              The counter-notification process will take time to complete, please be patient. During this time, the copyright claimant may file an action seeking a court order to keep the content down pursuant to the United States Digital Millennium Copyright Act (DMCA) or similar laws in other countries. Please note that, where appropriate and authorized by law, we will forward the entire counter-notification to the original reporter, including any contact information you provide, in accordance with our <Link to='/terms' className={css(CommonStyles.linkText)}>Terms of Service</Link> and <Link to='/policy' className={css(CommonStyles.linkText)}>Privacy Policy</Link>. The claimant may use this information to file a lawsuit against you.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If we do not receive notice that the original reporter is seeking a court order to prevent further infringement of the material at issue, we may replace or cease disabling access to the material that was removed if the material does not infringe on third-party copyright. The decision to re-post any material is at Razzl’s sole discretion.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Trademark
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              A trademark is a word, symbol, slogan, design, or combination of any of the foregoing that identifies the source of a product or service and distinguishes it from other products or services.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Trademark Infringement
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Trademark laws prohibit trademark infringement, which is generally the unauthorized use of a trademark or service mark in connection with goods or services in a way that is likely to cause confusion, deception or mistake about the source, origin, sponsorship or affiliation of the associated goods and/or services.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              At the same time, the use of another’s trademark for purposes of accurately referencing, lawfully commenting, criticizing, parodying, or reviewing the trademark owner’s products or services, or for purposes of comparing them to other products or services, where the mark is not used to designate the user’s own goods or services or those of a third party, is generally not considered a violation of our policies. Likewise, it is generally permissible to make a fan page about a brand, even without the brand’s permission, provided that you do not claim to speak for or be associated with the brand or otherwise violate the brand’s intellectual property rights.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Removal of Content; Suspension or Termination of Account
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Any content that violate another’s trademark rights may be taken down. Repeat violations of trademark rights in connection with the use of the Razzl site or app, or other violations of the <Link to='/terms' className={css(CommonStyles.linkText)}>Terms of Service</Link> and <Link to='/community-guidelines' className={css(CommonStyles.linkText)}>Community Guidelines</Link> may result in the suspension or termination of the user account. We reserve the right to refuse any account holder whose account was used for improper activities from opening a new account on Razzl’s site or app, or otherwise hosted by Razzl.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Trademark Complaint and Notification
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Contacting the user directly may resolve your complaint more quickly and in a way that is more beneficial to you, the user, and our community.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You may also file a trademark infringement report to request the removal of the alleged infringing content from Razzl. All notifications must be sent to our DMCA Agent at: <a href={'mailto:' + AppConfig.SUPPORT_EMAIL}>copyright@razzl.com</a>.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We may provide the account holder with your contact information, including the email address and the name of the trademark owner, and/or details of the complaint, in accordance with our Terms of Services and Privacy Policy.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Before submitting a notification, please be aware that intentionally submitting a misleading or fraudulent report may lead to liability for damages under laws as may be applicable in some countries.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Trademark Infringement Counter-Notification
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If you receive a trademark infringement notification that you believe to be in error or believe that you are authorized to use the content, you can reach out to the trademark owner directly to request a retraction.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You may also provide us with a counter-notification at: <a href={'mailto:' + AppConfig.SUPPORT_EMAIL}>copyright@razzl.com</a>.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              General Note
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              As a Razzl user, you are responsible for the content you post. If you have questions about copyright law or trademark law, such as questions about whether your content or your use of another person’s name or brand infringes or otherwise violates another person’s rights, you may want to contact an attorney. If you are unsure whether the material you plan to report to us is infringing or otherwise violating another person’s right, you may also want to first seek legal advice before reporting such content to us.
            </p>
          </div>
        </Grid>
        <div className={css([CommonStyles.footerContainer, CommonStyles.footerContainerV2])}>
          <Footer />
        </div>
      </div>
    );
  }
}

const Styles = StyleSheet.create({

})
