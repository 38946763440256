import React, { Component, Fragment, useState } from 'react';

import { StyleSheet, css } from 'aphrodite';
import { Footer } from '../common/components/Footer';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Helper } from '../common/helpers/Helper';
import { AppConfig } from '../AppConfig';
import { Link, useHistory } from 'react-router-dom';
import { HelmetComponent } from '../common/components/HelmetComponent';
import { AppButton } from '../common/components/AppButton';
import { LoginDialog } from '../components/LoginDialog';
import { Controller } from '../common/Controller';
import { ApiHelper } from '../common/helpers/ApiHelper';

export function Home() {
  const [state, setState] = useState({
    openLogin: true,
    currentUser: Controller.get().loginMgr().getCurrentUser(),
  })
  const matches = useMediaQuery('(max-width: 767px)');

  const devicesOS = Helper.getMobileOperatingSystem()

  const history = useHistory()

  const renderContent = () => {
    return (
      <div className={css(Styles.contentContainer)}>
        <HelmetComponent page={'home'} />
        <div className={css(Styles.logoContainer)}>
          <img className={css(Styles.appLogoImg)} alt={Helper.getString('logoAlt')} src={require('../assets/images/logo.png')} width='80' height='85' />
        </div>
        <h1 className={css(Styles.contentText)}>Razzl <span className={css(Styles.contentText, Styles.colorYellow)}>Video</span> App</h1>

        <div className={css(Styles.storeContainer)}>
          {devicesOS === 'unknown' &&
            <Fragment>
              {appleBadge()}
              {googleBadge()}
            </Fragment>
          }

          {devicesOS === 'iOS' && appleBadge()}

          {devicesOS === 'Android' && googleBadge()}
        </div>
      </div>
    )
  }

  const appleBadge = () => {
    return (
      <Link to={AppConfig.IOS_APP_LINK} className={css(Styles.linkStyle)}>
        <div className={css(Styles.storeImgContainer)}>
          <img className={css(Styles.logoImg)} alt={Helper.getString('playStoreAlt')} src={require('../assets/images/apple-badge.png')} width='177' height='59' />
        </div>
      </Link>)
  }

  const googleBadge = () => {
    return (
      <Link to={AppConfig.ANDROID_APP_LINK} className={css(Styles.linkStyle)}>
        <div className={css(Styles.storeImgContainer)}>
          <img className={css(Styles.logoImg)} alt={Helper.getString('appStoreAlt')} src={require('../assets/images/google-badge.png')} width='199' height='59' />
        </div>
      </Link>)
  }

  const renderFooter = () => {
    return (
      <div className={css(Styles.footerContainer)}>
        <Footer />
      </div>
    )
  }

  const openDailog = () => {
    setState({
      openLogin: true
    })
  }

  const onDailogClose = () => {
    setState({
      openLogin: false
    })
  }

  const onLogoutClick = () => {
    Controller.get().logout()
    setState({
      currentUser: null
    })
    // toggleDrawer(false)
    // userContextStateHandler({ reFetchUser: moment().valueOf() })
  }

  const onDashboardClick = () => {
    history.push('/admin');
  }

  const renderLoginButton = () => {
    console.log('userdata: ', state.currentUser)
    return (
      <div className={css(Styles.loginButtonContainer)}>
        <AppButton
          buttonText={Helper.getString('creatorPortal')}
          buttonStyle={Styles.buttonStyle}
          buttonTextStyle={Styles.buttonTextStyle}
          onClick={state.currentUser ? onDashboardClick : () => openDailog()} />
      </div>
    )
  }

  const getMinHeight = () => {
    if (window.innerWidth < 768) {
      return { minHeight: window.innerWidth / 0.51 }
    } else if (window.innerWidth > 1024) {
      return { minWidth: window.innerHeight * 1.35 }
    } else {
      return {}
    }
  }

  return (
    <div className={css(Styles.container)}>
      <div className={css(Styles.backgroundContainer)}>
        <div className={css(Styles.backgroundInnerContainer)} style={getMinHeight()}>
          {/* <div className={css(Styles.backgroundInnerContainer)} style={window.innerWidth < 768 ? { minHeight: window.innerWidth / 0.51 } : { minWidth: window.innerHeight * 1.35 }}> */}
          {/* {(window.innerWidth < 768 || matches) ? <img className={css(Styles.backImg)} src={require('../assets/images/mobile-bg.png')} width='365' height='667' /> : <img className={css(Styles.backImg)} src={require('../assets/images/main-bg.png')} width='1417' height='1050' />} */}
          {/* {(window.innerWidth < 768 || matches) ?
            <img className={css(Styles.backImg)} alt={Helper.getString('bgImageAlt')} src={require('../assets/images/mobile-bg.jpeg')} />
            :
            <img className={css(Styles.backImg)} alt={Helper.getString('bgImageAlt')} src={require('../assets/images/main-bg.jpeg')} />
          } */}

          {/* {!(window.innerWidth < 768 || matches) ? renderFooter() : ''} */}

          {/* {renderContent()}

          {renderLoginButton()} */}
          {state.openLogin &&
            <LoginDialog openDialog={state.openLogin} onClose={onDailogClose} />
          }
        </div>
        {(window.innerWidth < 768 || matches) ? renderFooter() : ''}
      </div>

    </div>
  )
}

const Styles = StyleSheet.create({
  container: {
    dispaly: 'flex',
    flex: 1,
    backgroundColor: '#000000',
  },
  backgroundContainer: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 1024px)': {
      flexDirection: 'column'
    }
  },
  backgroundInnerContainer: {
    position: 'relative',
    height: '100vh',
    '@media (max-width: 1024px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '@media (max-width: 767px)': {
      height: '100%'
    }
  },
  backImg: {
    width: 'auto',
    height: '100%',
    '@media (max-width: 1024px)': {
      height: 'auto',
      width: '100%',
    }
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logoContainer: {
    height: 85,
    width: 80,
    marginBottom: 25,
    '@media (max-width: 767px)': {
      marginBottom: 42
    }
  },
  logoImg: {
    width: 'auto',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  appLogoImg: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  contentText: {
    fontSize: 65,
    fontWeight: 600,
    color: '#fff',
    textTransform: 'uppercase',
    margin: 0,
    textAlign: 'center',
    lineHeight: '70px',
    maxWidth: 334,
    '@media (max-width: 768px)': {
      maxWidth: 250,
      fontSize: 55,
      lineHeight: '60px',
    }
  },
  colorYellow: {
    color: '#FEE25D'
  },
  storeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 25,
    alignItems: 'center',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      marginTop: 42
    }
  },
  storeImgContainer: {
    //width: 200,
    height: 59,
    '@media (max-width: 768px)': {
      marginBottom: 25,
      height: 53
    }
  },
  footerContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 12,
    zIndex: 9,
    '@media (max-width: 767px)': {
      display: 'flex',
      position: 'unset',
      backgroundColor: '#000',
      width: 'unset',
    }
  },
  linkStyle: {
    marginLeft: 12.5,
    marginRight: 12.5,
    textDecoration: 'none',
    ':active': {
      '-webkit-tap-highlight-color': 'transparent'
    }
  },
  loginButtonContainer: {
    display: 'flex',
    position: 'absolute',
    right: 15,
    top: 20,
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonStyle: {
    maxHeight: 40,
    minWidth: 160,
    borderRadius: 40,
    backgroundColor: '#223DE2',
    padding: '11px 19px'
  },
  logoutButton: {
    backgroundColor: '#FFFFFF4D'
  },
  buttonTextStyle: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'unset'
  }
})