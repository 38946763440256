import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { StyleSheet, css } from 'aphrodite';

import { Controller } from './common/Controller';
import { AppTheme, AppStyles } from './styles/AppTheme';

import { Header } from './components/Header';
// import { Footer } from './components/Footer';
import { NoPermission } from './common/components/NoPermission';

import { DashboardUser } from './screens/admin/dashboard/DashboardUser';

import { NotFound } from './screens/NotFound';
import { Terms } from './screens/Terms';
import { Privacy } from './screens/Privacy';
import { UiHelper } from './common/helpers/UiHelper';
import { Home } from './screens/Home';
import { CommunityGuidelines } from './screens/CommunityGuidelines';
import { IntellectualPolicy } from './screens/IntellectualPolicy';
import { LoginPage } from './screens/LoginPage';

import { AppConfig } from './AppConfig';
import { AppHelper } from './helpers/AppHelper';
import { UploadedVideos } from './screens/UploadedVideos';
import { UserProfile } from './screens/profile/UserProfile';
import { PPCallback } from './screens/PPCallback';
import ProfilePage from './screens/ProfilePage';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: AppTheme.primaryColor
    },
    secondary: {
      main: AppTheme.secondaryColor
    }
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ].join(',')
  }
});

const PublicRoute = (props) => {
  if (Controller.get().loginMgr().getCurrentUser()) {
    if (props && props.path === '/login') {
      return <Redirect to={Controller.get().getHomeByRole()} />
    }
  }
  if (props && props.path === '/') {
    return <Redirect to={'/admin'} />
  }
  return <Route {...props} />
}

const ProtectedRoute = (props) => {
  if (Controller.get().loginMgr().getCurrentUser()) {
    if (props.userLoaded) {
      return <Route {...props} />
    }
    if (props.noPermission) {
      return <Redirect
        to={{
          pathname: '/no-permission',
          state: { redirectedfrom: props.path }
        }}
      />
    }
    Controller.get().loginMgr().bootstrap((err, data) => {
      if (err) {
        console.error('bootstrap err:', err)
        return
      }

      if (props.path.startsWith('/admin/orgs')) {
        // Any path starts with /admin/orgs - users  has to have a role in that Org or a user of PRODUCT
        if (!Controller.get().userMgr().isProductSU() &&
          !Controller.get().userMgr().hasOrgRole(props.computedMatch.params.orgId, 'SUPERUSER')) {
          props.setNoPermission()
          return
        }
      } else if (props.path.startsWith('/admin')) {
        // Any path starts with Admin - users has to have a role in PRODUCT Org
        // if (!Controller.get().userMgr().isInOrg('PRODUCT')) {
        //   props.setNoPermission()
        //   return
        // }
      }
      props.setUserLoaded && props.setUserLoaded()
    })
    return UiHelper.loadingView()
  } else {
    return <Redirect
      to={{
        pathname: '/login',
        state: { redirectedfrom: props.path }
      }}
    />
  }
}

class App extends Component {

  constructor(props) {
    //console.log('App::constructor')
    super(props);
    this.state = {
      currentUser: Controller.get().loginMgr().getCurrentUser()
    };
  }

  isPublic = () => {
    const pName = window.location.pathname
    if (pName.startsWith('/admin')) {
      return false
    }
    return true
  }

  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <Route path={'/'} component={({ location, history }) => {
            return (
              <div className={css(Styles.page)} style={this.isPublic() ? { backgroundColor: AppTheme.secondaryBg } : { backgroundColor: AppTheme.primaryBg }}>
                {!this.isPublic() && <Header
                  className={css(Styles.header)}
                  currentUser={this.state.currentUser}
                  onDashboardClick={() => this.onDashboardClick()}
                  onLogoutClick={() => this.onLogoutClick()} />}
                {/* {this.isPublic() && <PublicHeader
                  className={css(Styles.header)}
                  currentUser={this.state.currentUser}
                  onDashboardClick={() => this.onDashboardClick()}
                  onLogoutClick={() => this.onLogoutClick()} />} */}
                <div className={css(Styles.content)}>
                  {this.renderRoute(history)}
                </div>
              </div>
            )
          }}
          />
        </MuiThemeProvider>
      </Router>
    );
  }

  setUserLoaded = () => {
    this.setState({ userLoaded: true });
  };

  setNoPermission = () => {
    this.setState({ noPermission: true });
  };

  getProtectedRoute(path, component) {
    return (
      <ProtectedRoute
        userLoaded={this.state.userLoaded}
        setUserLoaded={this.setUserLoaded}
        noPermission={this.state.noPermission}
        setNoPermission={this.setNoPermission}
        exact
        path={path}
        component={component}
      />
    );
  }

  renderRoute(history) {
    Controller.get().setNavigator(history);
    window.scrollTo(0, 0)
    return (
      <Switch>
        {this.getProtectedRoute('/admin', DashboardUser)}
        {this.getProtectedRoute('/admin/videos', UploadedVideos)}
        {this.getProtectedRoute('/admin/profile', UserProfile)}
        <PublicRoute exact path={'/terms'} component={Terms} />
        <PublicRoute exact path={'/privacy'} component={Privacy} />
        <PublicRoute exact path={'/community-guidelines'} component={CommunityGuidelines} />
        <PublicRoute exact path={'/intellectual-property-policy'} component={IntellectualPolicy} />
        <PublicRoute exact path={'/'} component={Home} />
        <PublicRoute exact path={'/no-permission'} component={NoPermission} />
        {/* <ProtectedRoute exact path={'/admin'} component={DashboardUser} /> */}
        {/* <ProtectedRoute exact path={'/admin/videos'} component={UplodedVideos} /> */}
        <PublicRoute exact path={'/login'} component={Home} />
        <PublicRoute exact path={'/ppcb'} component={PPCallback} />
        <PublicRoute exact path={'/c/:slug'} component={ProfilePage} />
        <PublicRoute component={NotFound} />
      </Switch>
    )
  }

  onLogoutClick() {
    //console.log('onLogoutClick');
    Controller.get().loginMgr().signOut();
    this.setState({
      currentUser: null
    })
  }

  onDashboardClick() {
    this.props.history.push('/admin');
  }
}

const Styles = StyleSheet.create({
  page: {
    display: 'flex',
    position: 'relative',
    backgroundColor: AppTheme.secondaryBg,
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },
  header: {
    flex: '0 0 auto'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    overflowX: 'hidden',
    flex: 1,
    backgroundColor: '#000000',
  },
  footer: {
    flex: '0 0 auto'
  },
  drawerFiller: {
    width: AppTheme.drawerWidth,
    '@media (max-width: 767px)': {
      width: 'unset'
    }
  }
})

export default App;
