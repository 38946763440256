import React, { Component, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../../common/helpers/Helper';

import { Controller } from '../../common/Controller';
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Dialog, Tooltip } from '@material-ui/core';
import { Headerbar } from '../../common/components/Headerbar';
import { AppContext } from '../../common/contexts/AppContext';
import { dataItem } from '../../data/data'
import { AppHelper } from '../../helpers/AppHelper';
import { TextBoxs } from '../../components/WizardTextInputs';
import { AppButton } from '../../common/components/AppButton';
import { ProfileImageUploader } from './ProfileImageUploader';
import { WarningPopup } from '../../components/WarningPopup';
import { PayoutSetup } from '../../components/PayoutSetup';
import { LoginMgr } from '../../common/mgrs/LoginMgr';
import { AppTheme } from '../../styles/AppTheme';
import copy from 'copy-to-clipboard';

const TEXTBOXBORDER = .5
const TEXTBOXMARGINRIGHT = 10
const FORMWIDTH = 800
const PAUOUTCONTAINERWIDTH = FORMWIDTH + TEXTBOXMARGINRIGHT + (TEXTBOXBORDER * 4)
export function UserProfile(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
  })
  const [user, setUser] = React.useState({});
  const currentUserId = Controller.get().userMgr().getUserId()
  const [fetchState, setFetchState] = React.useState();
  const [saveState, setSaveState] = React.useState();
  const [errMsg, setErrMsg] = React.useState(null);
  const [openSaveWarningDailog, setOpenSaveWarningDailog] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState();
  const [openSaveSuccessDailog, setOpenSaveSuccessDailog] = React.useState(false);
  const userProfileData = dataItem.userProfile
  const [validationWarningMessages, setValidationWarningMessages] = React.useState();
  const [doNotShowLoading, setDoNotShowLoading] = React.useState(false);
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState(null);
  const [creatorEarningPercent, setCreatorEarningPercent] = useState()
  const [profileLinkFetchState, setProfileLinkFetchState] = useState(ApiHelper.State.READY);
  const [profileLinkErrMsg, setProfileLinkErrMsg] = React.useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  useEffect(() => {
    appContextStateHandler({ pageKey: 'profile' })
    fetchData()
  }, [])

  async function fetchAppUser() {
    return await Helper.promisify(Helper.bootstrap)()
  }

  async function fetchData() {
    if (!doNotShowLoading) {
      setFetchState(ApiHelper.State.LOADING)
    }
    setErrMsg(null)
    try {
      const proms = []
      proms.push(ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'appconfig'], {})
      }))
      proms.push(fetchAppUser())
      const [appconfig, response] = await Promise.all(proms)

      const earningPercent = appconfig.systemConfig && appconfig.systemConfig.creatorEarningPercent
        ? (appconfig.systemConfig.creatorEarningPercent + '%') : '35%'
      setCreatorEarningPercent(earningPercent)

      const appUser = Controller.get().userMgr().getAppUser()
      console.log('userUpdatedData', appUser)
      setUser(
        {
          name: appUser.channelName && appUser.channelName !== '' ? appUser.channelName : '',
          userName: appUser.slug && appUser.slug !== '' ? appUser.slug : '',
          email: appUser.email && appUser.email !== '' ? appUser.email : '',
          bio: appUser.bio && appUser.bio !== '' ? appUser.bio : '',
          picture: appUser.picture && appUser.picture !== '' ? appUser.picture : '',
          pictureName: appUser.pictureName && appUser.pictureName !== '' ? appUser.pictureName : '',
          instagramLink: appUser.instaLink && appUser.instaLink !== '' ? appUser.instaLink : '',
          linkedInLink: appUser.linkedInLink && appUser.linkedInLink !== '' ? appUser.linkedInLink : ''
        }
      )
      setDoNotShowLoading(true)
      setFetchState(ApiHelper.State.READY)
    } catch (e) {
      setFetchState(ApiHelper.State.ERROR)
      setDoNotShowLoading(false)
      setErrMsg(Helper.getErrorMsg(e))
    }
  }

  const handleTextBox = (key, e) => {
    if (key === 'name') {
      setUser({
        ...user,
        name: e.target.value
      })
    }
    if (key === 'username') {
      if (containOnlyPermissibleCharcters(e.target.value)) {
        setUser({
          ...user,
          userName: e.target.value.toLowerCase()
        })
      }
    }
    if (key === 'instagramLink') {
      setUser({
        ...user,
        instagramLink: e.target.value
      })
    }
    if (key === 'linkedinLink') {
      setUser({
        ...user,
        linkedInLink: e.target.value
      })
    }
    if (key === 'bio') {
      setUser({
        ...user,
        bio: e.target.value
      })
    }

  }

  const handleSaveButtonDisable = (value) => {
    setDisableSaveButton(value)
  }

  //checking the special characters
  function containOnlyPermissibleCharcters(str) {
    const letters = /^[0-9a-zA-Z]+$/;
    if (str === '') {
      return true
    } else {
      if (letters.test(str)) {
        return true;
      } else {
        return false;
      }
    }
  }


  const validateProfileData = () => {
    const validationFields = {
      messages: []
    }
    if (!user.name || user.name === '') {
      validationFields.messages.push('a Name')
    }
    if (!user.userName || user.userName === '') {
      validationFields.messages.push('a Username')
    }
    if (!user.bio || user.bio === '') {
      validationFields.messages.push('a BIO')
    }
    if (validationFields.messages.length > 0) {
      setValidationWarningMessages(validationFields)
      return false
    }
    return true // validation is success
  }

  async function handleSave() {
    const validationSuccess = validateProfileData()
    if (!validationSuccess) {
      setOpenSaveWarningDailog(true)
      return
    }
    setSaveState(ApiHelper.State.LOADING)
    try {
      const body = {}
      const REMOVE_ATTRS = []
      onSavePrepare('channelName', user.name, body, REMOVE_ATTRS)
      onSavePrepare('slug', user.userName, body, REMOVE_ATTRS)
      onSavePrepare('pictureName', user.pictureName, body, REMOVE_ATTRS)
      onSavePrepare('bio', user.bio, body, REMOVE_ATTRS)
      onSavePrepare('instaLink', user.instagramLink, body, REMOVE_ATTRS)
      onSavePrepare('linkedInLink', user.linkedInLink, body, REMOVE_ATTRS)
      if (REMOVE_ATTRS.length > 0) {
        body.REMOVE_ATTRS = REMOVE_ATTRS
      }

      const options = {
        method: 'PUT',
        paths: ['users', 'me'],
        jsonBody: body,
      }
      await ApiHelper.callAwait(options)
      setSaveState(ApiHelper.State.READY)
      setErrMsg(null)
      fetchData()
      setSaveSuccessMsg(Helper.getString('profileSaveSuccess'))
      setTimeout(() => setSaveSuccessMsg(null), 3000)
      // setOpenSaveSuccessDailog(true)
      // setTimeout(() => onSuccessDialogClose(true), 3000)
    } catch (e) {
      setSaveState(ApiHelper.State.ERROR)
      setErrMsg(Helper.getErrorMsg(e))
    }
  }

  const onSuccessDialogClose = () => {
    setOpenSaveSuccessDailog(false)
  }

  const onSavePrepare = (fieldName, fieldValue, body, REMOVE_ATTRS) => {
    if (fieldValue && fieldValue !== '') {
      body[fieldName] = fieldValue
    } else {
      REMOVE_ATTRS.push(fieldName)
    }
  }


  //success completed Dialog
  const successDailog = () => {
    return (
      <Dialog open={openSaveSuccessDailog}
        classes={{
          paper: css(Styles.successDialog),
        }}
        className={css(Styles.successDialogContainer)}>
        <div className={css(Styles.publishImageContainer)}>
          <img src={require('../../assets/images/processanimated.gif')}
            width='50' height='50' alt={Helper.getString('processImageAlt')}
            className={css(Styles.img)} />
        </div>
        <p className={css(Styles.successDialogTitle)}>Profile Information Saved</p>
      </Dialog>
    )
  }

  //Required info warning Dialog
  const publichWarningDailog = () => {
    return (
      <WarningPopup dialogOpen={openSaveWarningDailog}
        image={require('../../assets/images/error.png')}
        popUpTitle='Required Information Missing'
        popUpDescr='Please complete these required fields before saving:'
        validationWarningMessages={validationWarningMessages}
        popUpDialogStyle={Styles.warningPopupStyle}
        onPopUpClose={() => onWarningClose()} />
    )
  }

  const onWarningClose = () => {
    setOpenSaveWarningDailog(false)
  }

  const handleUploadSuccess = (uploadId, signedUploadInfo) => {
    handleSaveButtonDisable(false)
    const link = signedUploadInfo.link
    const key = signedUploadInfo.fields.key
    let pictureName;
    if (key === '') {
      pictureName = ''
    } else {
      pictureName = key
    }
    setUser({
      ...user,
      picture: link,
      pictureName: pictureName
    })
  }

  const handleCopyProfileLink = async () => {
    setProfileLinkFetchState(ApiHelper.State.LOADING);
    try {
      const queryParams = {
        channelId: currentUserId,
      }
      const response = await ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'channellink'], queryParams)
      })
      const url = response.channelLink;
      copy(url, { format: 'text/plain' });
      setProfileLinkFetchState(ApiHelper.State.READY);
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 3000);
    } catch (error) {
      setProfileLinkErrMsg(Helper.getErrorMsg(error))
      setProfileLinkFetchState(ApiHelper.State.ERROR);
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 3000);
    }
  };

  const getContent = () => {
    return (
      <div className={css(Styles.formContainer)}>
        <div className={css(Styles.profieImageUploaderContainer)}>
          <div className={css(Styles.dummyDiv)} />
          <ProfileImageUploader
            url={user.picture}
            onUploadSuccess={handleUploadSuccess}
            handleSaveButtonDisable={handleSaveButtonDisable}
            customImageUploadContainer={Styles.customImageUploadContainer}
            imageContainer={Styles.customImageUploadContainer}
            imageContainerWidth={135}
            imageContainerHeight={135}
            uploadExt='jpg'
            width={500}
            height={500}
          />
          <Tooltip
            title={profileLinkFetchState === ApiHelper.State.ERROR ? profileLinkErrMsg : 'Profile link copied to clipboard'}
            open={tooltipOpen}
            classes={{ tooltip: css(Styles.tooltip) }}
            placement='bottom'>
            <div>
              <AppButton
                inProcess={profileLinkFetchState === ApiHelper.State.LOADING}
                onClick={handleCopyProfileLink}
                buttonStyle={Styles.copyProfileLinkButton}
                buttonTextStyle={Styles.copyProfileLinkText}
                buttonText='Copy Profile Link'
              />
            </div>
          </Tooltip>
        </div>
        <div className={css(Styles.rowContainer)}>
          <TextBoxs item={userProfileData.name} handleTextBox={handleTextBox} value={user.name}
            textBoxStyle={[Styles.leftTextBox, Styles.textBoxStyle]}
            textBoxInputStyle={Styles.textBoxInputStyle}
            helperTextStyle={Styles.helperTextStyle} />
          <TextBoxs item={userProfileData.userName} handleTextBox={handleTextBox} value={user.userName}
            textBoxStyle={Styles.textBoxStyle}
            textBoxInputStyle={Styles.textBoxInputStyle}
            helperTextStyle={Styles.helperTextStyle} />
        </div>
        <div className={css(Styles.rowContainer)}>
          <TextBoxs item={userProfileData.instagramLink} handleTextBox={handleTextBox} value={user.instagramLink}
            textBoxStyle={[Styles.leftTextBox, Styles.textBoxStyle]}
            textBoxInputStyle={Styles.textBoxInputStyle} />
          <TextBoxs item={userProfileData.linkedInLink} handleTextBox={handleTextBox} value={user.linkedInLink}
            textBoxStyle={Styles.textBoxStyle}
            textBoxInputStyle={Styles.textBoxInputStyle} />
        </div>
        <TextBoxs item={userProfileData.bio} handleTextBox={handleTextBox} value={user.bio}
          textBoxInputStyle={Styles.textAreaBoxInputStyle}
          textBoxStyle={Styles.textAreaBoxStyle}
          helperTextStyle={Styles.helperTextStyle}
          inputStyle={{ height: 57 }} />
        <div className={css(Styles.buttonContainer)}>
          <AppButton
            disabled={disableSaveButton}
            buttonStyle={Styles.buttonStyle}
            inProcess={saveState === ApiHelper.State.LOADING ? true : false}
            onClick={() => handleSave()}
            buttonText={'Save'}
          />
        </div>
        <div className={css(Styles.ErrorContainer)}>
          {errMsg &&
            <p className={css(Styles.errorText)}>{errMsg}</p>
          }
          {saveSuccessMsg &&
            <p className={css(Styles.errorText, Styles.saveMsg)}>{saveSuccessMsg}</p>
          }
        </div>
      </div>
    )
  }


  const title = userProfileData && userProfileData.title
  return (
    <div className={css(Styles.container)}>
      {title &&
        <Headerbar title={title} />
      }


      {fetchState === ApiHelper.State.LOADING &&
        AppHelper.loadingView()
      }
      {fetchState === ApiHelper.State.READY &&
        <div className={css(Styles.innerContainer)}>
          {getContent()}
          <PayoutSetup payoutContainerWidth={Styles.payoutContainer} creatorEarningPercent={creatorEarningPercent} />
          {successDailog()}
          {publichWarningDailog()}
        </div>
      }
      {fetchState === ApiHelper.State.ERROR &&
        AppHelper.errorView(errMsg, Styles.buttonStyle, 'Try Again', () => fetchData())
      }

    </div>
  )
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#02121D',
    paddingLeft: 10,
    marginTop: 63,
    overflow: 'hidden'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 95
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  profieImageUploaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 25,
    gap: 20
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  textBoxInputStyle: {
    minWidth: FORMWIDTH / 2
  },
  leftTextBox: {
    marginRight: TEXTBOXMARGINRIGHT
  },
  textAreaBoxInputStyle: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    minHeight: 85
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonStyle: {
    borderRadius: 4,
    maxWidth: 120
  },
  customImageUploadContainer: {
    width: 135,
    height: 135,
    // marginBottom: 25,
    borderRadius: '50%',
    overflow: 'hidden'
  },
  customImageUploadOutterContainer: {
    width: 100,
    height: 100,
    borderRadius: 50,
    overflow: 'hidden',
  },
  errorText: {
    fontSize: 14,
    color: '#FF0000',
    textAlign: 'center',
    maxWidth: 700,
    wordBreak: 'normal',
    margin: '10px 0px'
  },
  // dailog style
  successDialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000',
    width: 492,
    // height: 400,
    paddingTop: 60,
    paddingBottom: 50,
    border: '1px solid #FFFFFF80',
    borderRadius: 8
  },
  successDialogContainer: {
    display: 'flex',
    left: AppTheme.drawerWidth,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1300
  },
  successDialogImageContainer: {
    height: 50,
    width: 50,
  },
  successDialogTitle: {
    color: '#ffffff',
    fontSize: 18,
    margin: 0,
    fontWeight: 'bold',
    marginTop: 5,
    marginBottom: 10
  },
  successDialogDescr: {
    color: '#ffffff',
    fontSize: 18,
    textAlign: 'center',
    margin: 0,
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 340
  },
  textBoxStyle: {
    marginBottom: 22,
    border: TEXTBOXBORDER + 'px solid #FFFFFF80'
  },
  textAreaBoxStyle: {
    marginBottom: 20
  },
  helperTextStyle: {
    bottom: -25
  },
  ErrorContainer: {
    minHeight: 55,
    display: 'flex',
    justifyContent: 'center'
  },
  warningPopupStyle: {
    left: AppTheme.drawerWidth,
  },
  payoutContainer: {
    minWidth: PAUOUTCONTAINERWIDTH
  },
  saveMsg: {
    color: '#FFFFFF'
  },
  copyProfileLinkButton: {
    border: '1px solid #223DE2',
    background: 'transparent',
    borderRadius: 4,
    padding: '12px 10px',
    minWidth: 131
  },
  copyProfileLinkText: {
    color: '#FFFFFF',
    fontSize: 13,
    fontWeight: 'normal'
  },
  tooltip: {
    padding: 14,
    backgroundColor: '#000000',
    borderRadius: 8,
    fontSize: 18,
    color: '#ffffff',
    fontWeight: '600',
    border: '1px solid #FFFFFF80',
    width: 286,
    textAlign: 'center'
  },
  dummyDiv: {
    width: 131,
  }
})