import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ReactPlayer from 'react-player/file';
import { Helper } from '../common/helpers/Helper';
import { Dialog, Slider, Tooltip, withStyles } from '@material-ui/core';
import { AppHelper } from '../helpers/AppHelper';
import { TimeSelectorPopup } from './TimeSelectorPopup';

export function VideoPlayer(props) {
  const [playing, setPlaying] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(props.seekToVideoTimeInSeconds);
  const [seekBarPosition, setSeekBarPosition] = React.useState(0);
  const playerRef = useRef(null);
  const backwardsImage = require('../assets/images/rewind.png')
  const forwardsImage = require('../assets/images/fwd.png')
  const playOrPause = !playing ? require('../assets/images/play.png') : require('../assets/images/pause.png')
  const [openTimeSelectorDailog, setOpenTimeSelectorDailog] = useState(false);
  const elapsedTimeObj = AppHelper.getFormattedDuration(elapsedTime)
  const [elapsedTimeFormatted, setElapsedTimeFormatted] = useState();


  useEffect(() => {
    const elapsedTimeObj = AppHelper.getFormattedDuration(elapsedTime)
    setElapsedTimeFormatted(`${elapsedTimeObj.hours}:${elapsedTimeObj.minutes}:${elapsedTimeObj.seconds}`)
  }, [elapsedTime])

  const renderPlayerButton = (buttonAction, buttonImage, buttonAlt, buttonStyle) => {
    return (
      <div onClick={() => buttonAction()} className={css(Styles.playerButtonContainer, buttonStyle)}>
        <img src={buttonImage}
          alt={buttonAlt}
          className={css(Styles.img)}
          width='35'
          height='35'
        />
      </div>
    )
  }

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleProgress = (changeState) => {
    console.log('VP:: handleProgress', changeState.played)
    setElapsedTime(changeState.playedSeconds) // In seconds
    setSeekBarPosition((changeState.played * 100)) // Value is always from 1 to 100
    props.onTimeElapsed(changeState.played * props.duration)
  };

  // Slider Events
  const handleSeekChange = (event, newValue) => {
    //console.log('VP:: handleSeekChange', newValue, props.duration * (newValue / 100))
    console.log('newValue', newValue)
    playerRef.current.seekTo(props.duration * (newValue / 100)) // Seek in seconds
    setSeekBarPosition(newValue)
  };

  const onReady = () => {
    playerRef.current.seekTo(elapsedTime) // In case there is alreadu a time set
    setSeekBarPosition((elapsedTime / props.duration) * 100) // Value is always from 1 to 100
    //console.log('VP::  onReady')
  }

  const onBuffer = () => {
    //console.log('VP:: onBuffer')
  }

  const onDuration = () => {
    if (props.setVideoRef) {
      props.setVideoRef(playerRef.current)
    }
    //console.log('VP:: onDuration')
  }

  const VideoSeeker = withStyles({
    root: {
      height: 1,
      marginTop: '23px'
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: '#223DE2',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 1,
      borderRadius: 4,
    },
    rail: {
      height: 1,
      borderRadius: 4,
      color: '#FFFFFF66'
    },
  })(Slider);

  const totalDurationObj = AppHelper.getFormattedDuration(props.duration)
  const totalDurationFormatted = `${totalDurationObj.hours}:${totalDurationObj.minutes}:${totalDurationObj.seconds}`

  const getTimerDailog = () => {
    setOpenTimeSelectorDailog(true)
  }

  const onTimerDailogClose = () => {
    setOpenTimeSelectorDailog(false)
  }


  const onTimeSelectorApply = (selectedTime) => {
    const arr = selectedTime.split(':'); // splitting the string by colon
    const timeInSeconds = arr[0] * 3600 + arr[1] * 60 + (+arr[2])
    playerRef.current.seekTo(timeInSeconds) // We will just seek, handleProgress will handle rest
    setOpenTimeSelectorDailog(false)
  }

  const renderTimeSelectorPopup = () => {

    if (openTimeSelectorDailog) {
      return (<TimeSelectorPopup open={openTimeSelectorDailog}
        videoDuration={props.duration}
        elapsedTimeFormatted={elapsedTimeFormatted !== undefined ? elapsedTimeFormatted : ''}
        onTimerDailogClose={onTimerDailogClose}
        onTimeSelectorApply={onTimeSelectorApply} />
      )
    }

  }

  console.log('elapsedTime', elapsedTimeObj)
  return (
    <div className={css(Styles.playerContainer)}>
      <div className={css(Styles.playerWrapper)}>
        <ReactPlayer
          ref={playerRef}
          url={props.url}
          playing={playing}

          config={{
            file: {
              forceHLS: true
            }
          }}
          onDuration={onDuration}
          onBuffer={onBuffer}
          onProgress={handleProgress}
          onReady={onReady}
          width='100%'
          height='100%' />
      </div>
      <div className={css(Styles.controlsContainer)}>
        <div className={css(Styles.playerButtons)}>
          <div className={props.timeSelectorPopup ? css(Styles.timerContainer, Styles.timerPopupEnable) : css(Styles.timerContainer)}
            onClick={props.timeSelectorPopup ? () => getTimerDailog() : ''}>
            <p className={css(Styles.timer)}>{elapsedTimeFormatted}</p>
          </div>
          <div className={css(Styles.playerButtons)}> {/*TODO ICON Change and func*/}
            {renderPlayerButton(handleRewind, backwardsImage, Helper.getString('forwardImageAlt'))}
            {renderPlayerButton(handlePlayPause, playOrPause, Helper.getString('forwardImageAlt'), Styles.buttonStyle)}
            {renderPlayerButton(handleFastForward, forwardsImage, Helper.getString('forwardImageAlt'))}
          </div>
        </div>
        <p className={css(Styles.timeHelptext)}>hh:mm:ss</p>
        <Slider aria-label='Volume' value={seekBarPosition} onChange={handleSeekChange}
          classes={{
            root: css(Styles.sliderRoot),
            thumb: css(Styles.thumb),
            track: css(Styles.track),
            rail: css(Styles.rail)
          }} />
        <div className={css(Styles.totaltimeContainer)}>
          <p className={css(Styles.totalTime)}>{totalDurationFormatted}</p>
        </div>
      </div>
      {renderTimeSelectorPopup()}
    </div>
  )
}

const Styles = StyleSheet.create({
  playerContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF0F',
    borderRadius: 8,
    padding: '48px 28px 12px 28px'
  },
  playerWrapper: {
    width: 325,
    height: 184,
    borderRadius: '8px',
    overflow: 'hidden'
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20
  },
  timerContainer: {
    display: 'flex',
    minWidth: 85,
    minHeight: 35,
    backgroundColor: '#FFFFFF1A',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 9px',
    marginRight: '9px',
    cursor: 'none'
  },
  timerPopupEnable: {
    cursor: 'pointer'
  },
  timer: {
    color: '#ffffff',
    fontSize: 13,
    margin: 0
  },
  playerButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  playerButtonContainer: {
    width: 35,
    height: 35,
    cursor: 'pointer'
  },
  buttonStyle: {
    margin: '0px 20px'
  },
  totalTime: {
    color: '#ffffff',
    fontSize: 13,
    margin: 0,
    marginTop: 2
  },
  totaltimeContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  track: {
    height: 1,
    borderRadius: 4,
  },
  rail: {
    height: 1,
    borderRadius: 4,
    color: '#FFFFFF66'
  },
  sliderRoot: {
    height: 1,
    marginTop: '23px'
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#223DE2',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  timeDailogContainer: {
    display: 'flex',
    minWidth: 400,
    minHeight: 200,
    backgroundColor: '#1C1C1E',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center'
  },
  timeDailogOuterContainer: {
    zIndex: 13002
  },
  timeHelptext: {
    fontSize: 12,
    color: '#FFFFFF',
    opacity: '50%',
    maxWidth: 103,
    margin: 0,
    marginTop: 5,
    textAlign: 'center'
  }
})