import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Controller } from '../common/Controller';
import { useLocation } from 'react-router-dom'
import { AppHelper } from '../helpers/AppHelper';
export function PPCallback(props) {

  const [fetchState, setFetchState] = useState({
    fetchState: ApiHelper.State.LOADING,
  })
  const [errMsg, setErrMsg] = useState()
  const search = useLocation().search

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    console.log('searchParams::', searchParams)
    if (searchParams) {
      const authorizationCode = searchParams.get('code')
      console.log('authorizationCode::', authorizationCode)
      if (!authorizationCode) {
        window.self.close()
        return
      }
      window.opener.postMessage({ authorizationCode: authorizationCode }, '*');
      window.self.close()
    } else {
      window.self.close()
    }
  }, [])

  return (
    <div className={css(Styles.container)}>
      {fetchState.fetchState === ApiHelper.State.LOADING &&
        AppHelper.loadingView()
      }
      {fetchState.fetchState === ApiHelper.State.ERROR &&
        (<div className={css(Styles.errorViewContainer)}>
          <p className={css(Styles.errorText)}>{errMsg}</p>
        </div>
        )
      }
    </div>
  )
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#02121D',
    paddingLeft: 13,
    marginTop: 0,
    overflow: 'hidden'
  },
  errorViewContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 20,
    color: '#FF0000',
  },
  contentTitle: {
    fontSize: 18,
    color: '#FFFFFF',
    opacity: 1,
    textAlign: 'left',
    textTransform: 'uppercase',
    margin: 0,
    marginBottom: 20
  },
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 1256,
    marginTop: 95//38,
  },
  innerOuterContent: {
    // paddingTop: 20
  },
  contentTile: {
    backgroundColor: '#02121D',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    width: 274,
    margin: 17
  },
  tileImageContainer: {
    width: 274,
    height: 154,
    borderRadius: 8,
    overflow: 'hidden'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover'
  },
  detailsContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    marginTop: 14.75
  },
  detailsInnerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  statusTitle: {
    fontSize: 12,
    color: '#ffffff',
    margin: '2.5px 0px'
  },
  videoTitle: {
    fontSize: 12,
    color: '#ffffff',
    margin: '2.5px 0px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 230
  },
  textUpperCase: {
    textTransform: 'uppercase'
  },
  createDateTime: {
    fontSize: 12,
    color: '#FFFFFFCC',
    margin: '2.5px 0px'
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  statusText: {
    fontSize: 12,
    color: '#FFFFFFBF',
    margin: '2.5px 0px'
  },
  starImageContainer: {
    width: 15,
    height: 15
  },
  eyeImageContainer: {
    width: 20,
    height: 20,
    marginLeft: 9.83,
    marginRight: 2.5
  },
  emptyView: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyViewText: {
    fontSize: 20,
    color: '#FFFFFF80',
    // textTransform: 'capitalize'
  },
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    border: '0.5px solid #FFFFFF33',
    borderRadius: 4,
    padding: 1,
    cursor: 'pointer'
  },
  dotsImageContainer: {
    width: 4,
    height: 20
  },
  popoverContainer: {
    backgroundColor: '#000000',
    width: 280,
    height: 234,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    border: '2px solid #FFFFFF66',
    borderRadius: 8
  },
  menuItemIconContainer: {
    width: 35,
    height: 35,
    marginRight: 23
  },
  menuItemContent: {
    margin: 0,
    color: '#FFFFFF',
    fontSize: 18,
    textTransform: 'uppercase'
  },
  closeIconContainer: {
    width: 35,
    height: 35,
    position: 'absolute',
    top: 6,
    right: 7,
    cursor: 'pointer'
  },
  priceText: {
    color: '#ffffff',
    fontSize: 12,
    margin: 0,
    marginLeft: 3
  },
  menuItemContainer: {
    padding: '20px 0px'
  },
  timeAndPriceContaineer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #FFFFFF4D',
    borderRadius: 10,
    padding: '0px 8px 0px 4px',
    marginLeft: '10px',
    minWidth: 42
  },
  coinIconContainer: {
    width: 19,
    height: 19
  },
  boldText: {
    fontWeight: 'bold'
  },
  helpTextStyle: {
    color: '#ffffff',
    fontSize: 18,
    margin: 0,
    lineHeight: '23px'
  },
  errorToast: {
    backgroundColor: '#000000'
  }
})
