import React, { Component } from 'react';
import { Controller } from '../../common/Controller';
import { UiLoginHelper } from '../../common/helpers/UiLoginHelper';
import { Helper } from '../../common/helpers/Helper';
import { UsernameInput, PasswordInput, NumericOTPInput } from '../../common/components/TextInputs';
import { StyleSheet, css } from 'aphrodite';

import { AppConfig } from '../../AppConfig';
import { AppTheme } from '../../styles/AppTheme'
import { HelmetComponent } from '../components/HelmetComponent';
import { AppButton } from './AppButton';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.userNameType = AppConfig.USER_PRIMARY === 'email' ?
      UiLoginHelper.UserNameType.EMAIL : UiLoginHelper.UserNameType.PHONE
    this.state = {
      currentPage: UiLoginHelper.Page.LOOKUP,
      loadingAnimation: false,
      message: '',
      userName: '',
      pword: '',
      verificationCode: ''
    };
    this.loginHelper = new UiLoginHelper(this, {
      autoSignUp: true,
      autoLogin: true,
      alwaysLoginUsingOTP: true,
      userNameType: this.userNameType,
      jsonBody: this.props.jsonBody ? this.props.jsonBody : {},
      onSuccessLogin: this.onSuccessLogin.bind(this)
    });
  }

  onSuccessLogin() {
    Controller.get().userMgr().setGuestUser(false);
    //console.log('onSuccessLogin');
    if (this.props.onSuccessLogin) {
      this.props.onSuccessLogin()
      return
    }
    window.location.href = Controller.get().roleHelper().getHome()
  }

  render() {
    return (
      <div className={css(Styles.container)}>
        <div className={css([Styles.loginPanel, this.props.loginPanelStyle])}>

          <HelmetComponent page={'login'} />

          <h2>{Helper.getString('login')}</h2>
          <div className={css(Styles.loginMessageContainer)}>
            {this.state.message.length > 0 &&
              <p className={css(Styles.loginMessage)}>{this.state.message}</p>
            }
          </div>

          {this.state.currentPage === UiLoginHelper.Page.LOOKUP &&
            this.welcomeView()
          }
          {this.state.currentPage === UiLoginHelper.Page.SIGNUP &&
            this.signUpView()
          }
          {this.state.currentPage === UiLoginHelper.Page.SIGNUP_OTP &&
            this.signUpOTPView()
          }
          {this.state.currentPage === UiLoginHelper.Page.LOGIN &&
            this.loginView()
          }
          {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD &&
            this.resetPasswordView()
          }
          {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD_OTP &&
            this.resetPasswordOTPView()
          }
        </div>
      </div>
    );
  }

  welcomeView() {  // TODO Needs styling
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLookup()
            }
          }}
        />

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={Styles.button}
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLookup.bind(this.loginHelper)}
            buttonText={'Continue'}
            inProcess={this.state.loadingAnimation}
          />
        </div>
        {this.props.showTerms &&
          <div className={css(Styles.termsContainer)}>
            <span>By continuing, you agree to our </span><a href={AppConfig.TERMS}>terms</a>
          </div>
        }
      </div>
    )
  }

  signUpView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          placeholder='Set a new password'
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUp()
            }
          }}
        />

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={Styles.button}
            disabled={this.state.loadingAnimation}
            inProcess={this.state.loadingAnimation}
            buttonText={'Signup'}
            onClick={this.loginHelper.onSubmitSignUp.bind(this.loginHelper)}
          />
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  signUpOTPView() { // TODO Needs Styling
    return (
      <div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          onChange={(event) => this.setState({ verificationCode: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUpConfirm()
            }
          }} />

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={Styles.button}
            disabled={this.state.loadingAnimation}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitSignUpConfirm.bind(this.loginHelper)}
            buttonText={'Submit'}
          />
        </div>
        {/* TODO Needs Styling to add an OTP resend Button with progress
        onPress={() => this.loginHelper.onSubmitResentResetPassword(Helper.getString('OTPResetSent'))}*/}
        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  loginView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          id='pword'
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLogin()
            }
          }} />

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={Styles.button}
            disabled={this.state.loadingAnimation}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLogin.bind(this.loginHelper)}
            buttonText={'Login'}
          />
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onResetPasswordClicked.bind(this)}><p>Reset password? Get OTP</p></a>
        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    );
  }

  resetPasswordView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitResetPassword()
            }
          }} />

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={Styles.button}
            disabled={this.state.loadingAnimation}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitResetPassword.bind(this.loginHelper)}
            buttonText={'Get OTP'}
          />
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  resetPasswordOTPView() { // TODO Needs styling
    return (
      <div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          onChange={(event) => this.setState({ verificationCode: event.target.value })} />

        {!(this.loginHelper.options && this.loginHelper.options.alwaysLoginUsingOTP) &&
          <PasswordInput
            id='pword'
            value={this.state.pword}
            onChange={(event) => this.setState({ pword: event.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.loginHelper.onSubmitConfirmResetPassword()
              }
            }} />
        }
        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={Styles.button}
            disabled={this.state.loadingAnimation}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitConfirmResetPassword.bind(this.loginHelper)}
            buttonText={'Submit'}
          />
        </div>

        {/* TODO Needs Styling to add an OTP resend Button with progress
        onPress={() => this.loginHelper.onSubmitResentResetPassword(Helper.getString('OTPResetSent'))}
        */}
        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  onStartoverClicked() {
    this.loginHelper.toPage(UiLoginHelper.Page.LOOKUP);
  }

  onResetPasswordClicked() {
    this.loginHelper.toPage(UiLoginHelper.Page.RESET_PASSWORD);
  }
}

const Styles = StyleSheet.create({
  container: {
    backgroundColor: AppTheme.secondaryBg,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  loginPanel: {
    borderRadius: 8,
    backgroundColor: AppTheme.primaryBg,
    textAlign: 'center',
    width: 300,
    padding: 20,
    //margin: 10,
    '@media (max-width: 767px)': {
      width: 280,
      padding: 10,
      margin: 5,
    },
    '@media (max-width: 320px)': {
      width: 240,
      padding: 10,
      margin: 5
    }
  },
  loginMessageContainer: {
    margin: 'auto'
  },
  loginMessage: {
    marginTop: 10,
    fontSize: 14,
    color: 'red'
  },
  loginLink: {
    marginTop: 10,
    cursor: 'pointer',
    color: 'black',
    fontSize: 14
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    marginTop: 20
  },
  marginLeft: {
    marginLeft: 10
  },
  termsContainer: {
    marginTop: 10,
    fontSize: 14
  },
  buttonStyle: {
    boxShadow: 'none',
    backgroundColor: AppTheme.buttonBg,
    color: AppTheme.buttonTextColor,
    borderRadius: AppTheme.pagePadding,
    textAlign: 'center',
    letterSpacing: 0,
    textTransform: 'uppercase',
    opacity: 1,
  },
  button: {
    flex: 1
  }
})