import React, { Component, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../../../common/helpers/Helper';

import { Controller } from '../../../common/Controller';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Tooltip } from '@material-ui/core';
import { Headerbar } from '../../../common/components/Headerbar';
import { AppContext } from '../../../common/contexts/AppContext';
import { dashboardContent } from '../../../data/data'
import { AppHelper } from '../../../helpers/AppHelper';
import { CommonStyles } from '../../../styles/Styles';
import { GetStartedPopup } from '../../../components/GetStartedPopup';
import { DONOTSHOW_GETSTARTED } from '../../../Consts';

export function DashboardUser(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    fetchState: ApiHelper.State.LOADING
  })
  const [errMsg, setErrMsg] = useState()
  const [creatorEarningPercent, setCreatorEarningPercent] = useState()
  const [showGetStartedPopup, setShowGetStartedPopup] = useState(false);
  const [isGetStartedDoNotShow, setIsGetStartedDoNowShow] = useState();
  const currentUserId = Controller.get().userMgr().getUserId()

  useEffect(() => {
    setShowGetStartedPopup(true);
    const storedValue = localStorage.getItem(currentUserId + DONOTSHOW_GETSTARTED);
    setIsGetStartedDoNowShow(storedValue === 'true' ? true : false);
    appContextStateHandler({ pageKey: 'adminOrgs' })
    fetchData()
  }, [])


  async function fetchData() {
    console.log('user:', currentUserId)
    setState({ ...state, fetchState: ApiHelper.State.LOADING})

    try {
      const proms = []
      proms.push(ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'appconfig'], {})
      }))
      proms.push(ApiHelper.callAwait({
        method: 'GET', paths: ['miscs', 'channel'], queryParams: { channelId: currentUserId, allvisible: true }
      }))
      const [appconfig, response] = await Promise.all(proms)
      console.log('back end data::', response, appconfig.systemConfig)
      //creatorEarningPercent

      const earningPercent = appconfig.systemConfig && appconfig.systemConfig.creatorEarningPercent
        ? (appconfig.systemConfig.creatorEarningPercent + '%') : '35%'
      setCreatorEarningPercent(earningPercent)
      setState({ ...state, fetchState: ApiHelper.State.READY, data: response })
    } catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR})
      setErrMsg(Helper.getErrorMsg(e))
    }
  }

  const getValue = (stats, content) => {
    const value = stats && stats[content.key] ? stats[content.key] : content.value
    if (content.key === 'earningsSum' || content.key === 'earningsPayoutSum' || content.key === 'earningsBalance') {
      const valueInNumber = Number(value)
      const valueInString = AppHelper.getCurrencyInHigherDenomination(valueInNumber)
      return ('$' + valueInString)
    } else {
      return value
    }
  }

  const getContent = (content, stats) => {
    // if (content.key === 'earningsSum') {
    //   content.helpText = content.helpText.replace('pvalue',creatorEarningPercent);
    // }
    return (
      <div key={content.id} className={css(Styles.contentTile)}>
        {content.helpText &&
          <Tooltip title={content.helpText} placement='bottom-end' classes={{ tooltip: css(Styles.tooltip) }}>
            <div className={css(Styles.helpContainer)}>
              <img src={require('../../../assets/images/help.png')}
                width='25' height='25' alt={Helper.getString('helpImageAlt')}
                className={css(Styles.img)} />
            </div>
          </Tooltip>
        }

        <p className={css(Styles.contentTileValue)}>{getValue(stats, content)}
          {content.key === 'ratingAverage' && <span className={css(Styles.contentSubTileValue)}> ({stats.ratingCount})</span>}
        </p>

        <p className={css(Styles.contentTileTitle)}>{content.contentTitle}</p>
      </div>
    )
  }


  const title = dashboardContent && dashboardContent.title

  const onClose = () => {
    setShowGetStartedPopup(false);
  }

  return (
    <div className={css(Styles.container)}>
      {title &&
        <Headerbar title={title} />
      }


      {state.fetchState === ApiHelper.State.LOADING &&
        AppHelper.loadingView()
      }
      <div className={css(Styles.innerContainer)}>
        {dashboardContent && dashboardContent.items.map((item, index) => {

          const stats = state.data && state.data.channel && state.data.channel.stats
          return (
            stats &&
            <div key={item.id} className={css(Styles.contentInnerContainer)}>
              <p className={css(Styles.contentTitle)}>{item.title}</p>
              <div className={css(Styles.contentOuterContainer)}>
                {item && item.items.map((content, index) => {
                  return getContent(content, stats)
                })}
              </div>
            </div>
          )
        })}
      </div>
      {state.fetchState === ApiHelper.State.ERROR &&
        AppHelper.errorView(errMsg, CommonStyles.buttonStyle, 'Try Again', () => fetchData())
      }
      {!isGetStartedDoNotShow && <GetStartedPopup showGetStartedPopup={showGetStartedPopup} onClose={onClose} showCheckboxContainer />}
    </div>
  )
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#02121D',
    paddingLeft: 10,
    marginTop: 63,
    overflow: 'hidden'
  },
  innerContainer: {
    marginTop: 40
  },
  contentOuterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 960,
  },
  contentInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 40
  },
  contentTitle: {
    fontSize: 18,
    color: '#FFFFFF',
    opacity: 1,
    textAlign: 'left',
    textTransform: 'uppercase',
    margin: 0,
    marginLeft: 20,
    // marginBottom: 20
  },
  innerOuterContent: {
    // paddingTop: 20
  },
  contentTile: {
    backgroundColor: '#FFFFFF0F',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    height: 157,
    width: 200,
    margin: 20,
    position: 'relative'
  },
  contentTileTitle: {
    fontSize: 15,
    color: '#FFFFFF',
    margin: 0
  },
  contentTileValue: {
    fontSize: 25,
    color: '#FFFFFF',
    margin: 0,
    marginBottom: 12
  },
  contentSubTileValue: {
    fontSize: 23,
    color: '#FFFFFF99',
    margin: 0,
    marginBottom: 12
  },
  helpContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    width: 25,
    // borderRadius: 25,
    // border: '1px solid #ffffff',
    margin: 4,
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer'
  },
  helpIcon: {
    color: '#ffffff',
    fontSize: 25
  },
  tooltip: {
    // display: 'flex',
    padding: 30,
    backgroundColor: '#000000',
    borderRadius: 8,
    fontSize: 18,
    whiteSpace: 'pre-wrap',
    lineHeight: '23px',
    color: '#ffffff'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%'
  }
})
