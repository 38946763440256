import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { Dialog } from '@material-ui/core';
import { VideoPlayer } from '../components/VideoPlayer';

export function VideoTimeSelector(props) {
  const [timeElapsed, setTimeElapsed] = useState(0)
  const playerRef = useRef(null);

  const setElapsedTimeInSeconds = (timeElapsed) => {
    console.log('StepOrChapter:: setElapsedTimeInSeconds', timeElapsed)
    setTimeElapsed(timeElapsed)
  }

  const captureVideoFrame = (video, format, quality) => {
    if (typeof video === 'string') {
      video = document.getElementById(video);
    }

    format = format || 'jpeg';
    quality = quality || 0.7;

    if (!video || (format !== 'png' && format !== 'jpeg')) {
      return false;
    }

    const canvas = document.createElement('CANVAS');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    canvas.getContext('2d').drawImage(video, 0, 0);

    const dataUri = canvas.toDataURL('image/' + format, quality);
    const data = dataUri.split(',')[1];
    const mimeType = dataUri.split(';')[0].slice(5)

    const bytes = window.atob(data);
    // eslint-disable-next-line no-undef
    const buf = new ArrayBuffer(bytes.length);
    // eslint-disable-next-line no-undef
    const arr = new Uint8Array(buf);

    for (let i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    const blob = new Blob([arr], { type: mimeType });
    return { blob: blob, dataUri: dataUri, format: format };
  };

  const videoAccept = (timeElapsed) => {
    let frame
    if (props.getThumbnail && playerRef.current) {
      frame = captureVideoFrame(playerRef.current.getInternalPlayer())
    }
    props.onVideoDialogAccept(timeElapsed, frame)
  }

  const setVideoRef = (playerReference) => {
    playerRef.current = playerReference
  }

  return (
    <Dialog
      open={props.open}
      classes={{
        paper: css(Styles.videoDailogContainer),
      }} className={css(Styles.videoDailogOuterContainer)}>
      <div className={css(Styles.dialogButtonContainer)}>
        <div onClick={() => props.onVideoDialogClose()}
          className={css(Styles.closeIconContainer)}>
          <img src={require('../assets/images/close.png')}
            alt={Helper.getString('closeImageAlt')}
            className={css(Styles.img)}
            width='40'
            height='40'
          />
        </div>
        <div onClick={() => videoAccept(timeElapsed)}
          className={css(Styles.acceptIconContainer)}>
          <img src={require('../assets/images/accept.png')}
            alt={Helper.getString('closeImageAlt')}
            className={css(Styles.img)}
            width='40'
            height='40'
          />
        </div>
      </div>
      <VideoPlayer
        url={props.url}
        timeSelectorPopup={props.timeSelectorPopup}
        duration={props.duration} // Inseconds
        durationMs={props.durationMs}
        seekToVideoTimeInSeconds={props.seekToVideoTimeInSeconds}
        onTimeElapsed={setElapsedTimeInSeconds}
        setVideoRef={setVideoRef}
      />
    </Dialog>
  )
}

const Styles = StyleSheet.create({
  playerContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF0F',
    borderRadius: 8,
    padding: '48px 28px 12px 28px'
  },
  playerWrapper: {
    width: 325,
    height: 184,
    borderRadius: '8px',
    overflow: 'hidden'
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20
  },
  timerContainer: {
    display: 'flex',
    minWidth: 85,
    minHeight: 35,
    backgroundColor: '#FFFFFF1A',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 9px',
    marginRight: '9px'
  },
  timer: {
    color: '#ffffff',
    fontSize: 13,
    margin: 0
  },
  playerButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  playerButtonContainer: {
    width: 35,
    height: 35,
    cursor: 'pointer'
  },
  buttonStyle: {
    margin: '0px 20px'
  },
  totalTime: {
    color: '#ffffff',
    fontSize: 13,
    margin: 0,
    marginTop: 2
  },
  totaltimeContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  track: {
    height: 1,
    borderRadius: 4,
  },
  rail: {
    height: 1,
    borderRadius: 4,
    color: '#FFFFFF66'
  },
  sliderRoot: {
    height: 1,
    marginTop: '23px'
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#223DE2',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  videoDailogContainer: {
    display: 'flex',
    minWidth: 525,
    minHeight: 490,
    backgroundColor: '#1C1C1E',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center'
  },
  videoDailogOuterContainer: {
    zIndex: 13002
  },
  dialogButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: 5,
    right: 5
  },
  closeIconContainer: {
    width: 40,
    height: 40,
    cursor: 'pointer'
  },
  acceptIconContainer: {
    width: 40,
    height: 40,
    marginLeft: 20,
    cursor: 'pointer'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover'
  },
})