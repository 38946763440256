import React, { Component, Fragment, useContext, useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../../common/helpers/Helper';
import { Dialog, DialogContent, DialogTitle, MenuItem, Popover, Step, StepLabel, Stepper, Tooltip } from '@material-ui/core';
import { DetailsComponent } from './DetailsComponent';
import { AppButton } from '../../common/components/AppButton';
import { ImagesComponent } from './ImagesComponent';
import { dataItem, projectData2Default } from '../../data/data'
import { Materials } from './Materials';
import { PriceComponent } from './PriceComponent';
import { VisibilityComponent } from './VisibilityComponent';
import { StepOrChapter } from './StepOrChapter';
import { MODES, PRICINGTYPES, PROJECT_STATE } from '../../Consts';
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { AppHelper } from '../../helpers/AppHelper';
import { ContactsOutlined } from '@material-ui/icons';
import { VISIBILITY } from '../../../src/Consts'
import { WarningPopup } from '../../components/WarningPopup';
import { ErrorPopup } from '../../components/ErrorPopup';
import { Controller } from '../../common/Controller';
import { InfoPopup } from '../../components/InfoPopup';
import { EditWarningPopup } from '../../components/EditWarningPopup';

export function UploadedVideoWizardDialog(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [projectData, setProjectData] = React.useState({});
  const [fetchState, setFetchState] = React.useState(ApiHelper.State.LOADING);
  const [projectFull, setprojectFull] = React.useState(null);
  const [publishProgress, setPublishProgress] = React.useState(0);
  const [errMsg, setErrMsg] = React.useState(null);
  const [onSaveState, setOnSaveState] = React.useState();
  const [openPublishDailog, setOpenPublishDailog] = React.useState(false);
  const [openPublishWarningDailog, setOpenPublishWarningDailog] = React.useState(false);
  const [validationWarningFields, setValidationWarningFields] = React.useState([]);
  const [openVideoProcessingComplete, setvideoProcessingComplete] = React.useState(false);
  const [openPublishedAndPurchasedWarning, setOpenPublishedAndPurchasedWarning] = React.useState(false);
  const [openVideoProcessingErrorOrWarning, setOpenVideoProcessingErrorOrWarning] = React.useState(false);
  const [videoProcessingErrorOrWarningContent, setVideoProcessingErrorOrWarningContent] = React.useState({ errTitle: '', errorDescr: '', buttonText: '' });
  const [editingInPublishedAndPurchasedState, setEditingInPublishedAndPurchasedState] = React.useState(false);
  const intervalId = useRef(null);
  const dropDownValues = useRef({});
  const mode = useRef(MODES.CREATE);
  const refreshOnClose = useRef(false);
  const [isInstructionalVideo, setIsInstructionalVideo] = React.useState(); //isIntr
  const [disableWizardButtons, setDisableWizardButtons] = React.useState(false);
  const [openPayoutSetupIncomplete, setOpenPayoutSetupIncomplete] = useState(false);
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);
  const [infoPopupContentType, setInfoPopupContentType] = useState('');

  const appUser = Controller.get().userMgr().getAppUser()

  const stepperItems = [
    {
      key: 'Details',
      label: 'Details',
    },
    {
      key: 'Images',
      label: 'Images',
      descr: 'Upload one or more images associated with this video.\nImages could include photos of the finished project or product.\nJPG, GIF, or PNG formats and multiple aspect ratios (horizontal, vertical or square) supported.\nImages assist in viewer purchase decisions and are included in a downloadable PDF.'
    },
    {
      key: 'Materials',
      label: 'Materials',
      descr: 'Describe any materials, ingredients, supplies or tools required for the project.\nMaterials information is included in a PDF available for viewer download.',
      helpText: 'List any materials, supplies, ingredients or tools that were used in your project / video.\n\nTo help your viewers purchase materials, link to sources as needed in Materials Description field.\n\nAlternatively, a Call-To-Action can be created to enable a single purchase for all materials.'
    },
    {
      key: 'Steps',
      label: 'Steps',
      descr: 'Describe in detail steps or instructions related to this video.\nUpload supporting files or digital products.\nSteps information is included in a PDF available for viewer download.',
      helpText: 'Provide detailed steps or instructions related to this video. If the video is a course, describe its chapters or lessons.\n\nThe Steps section also allows you to upload any files or digital products for sale.\n\nFor each step, you can select a video start time. This creates a corresponding Video Chapter in the Razzl App, enabling viewers to navigate through video segments.\n\nPlease note that Video Chapters are an optional feature and may not be suitable for very short videos. If you don\'t require a Video Chapter list, keep the start time for all steps as 00:00:00.'
    },
    {
      key: 'pricing',
      label: 'pricing',
      descr: 'Create a pricing plan for this video.'
    },
    {
      key: 'Visibility',
      label: 'Visibility',
      descr: 'Choose who can see your video.'
    },
  ]
  const handleNext = () => {
    checkPostProcessingErrors(projectFull)
    if (activeStep === 2) {
      if (projectFull.state !== PROJECT_STATE.PROCESSINGCOMPLETE && projectFull.state !== PROJECT_STATE.READY) {
        setvideoProcessingComplete(true)
        return
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  //handle step click on the stepper label
  const handleStep = (index) => {
    // if (editingInPublishedAndPurchasedState && index < 4) {
    //   return
    // }
    checkPostProcessingErrors(projectFull)
    if (index > 2) {
      if (projectFull.state !== PROJECT_STATE.PROCESSINGCOMPLETE && projectFull.state !== PROJECT_STATE.READY) {
        setvideoProcessingComplete(true)
        return
      } else {
        setActiveStep((prevActiveStep) => index);
      }
    } else {
      setActiveStep((prevActiveStep) => index);
    }
  }

  function performUnmount() {
    console.log('performUnmount')
    if (intervalId.current) {
      clearInterval(intervalId.current)
    }
  }

  useEffect(() => {
    initializeData()
    return () => {
      performUnmount()
    }
  }, [props.project])

  useEffect(() => {
    if (document.getElementById('scroll') !== null) {
      document.getElementById('scroll').scrollTo(0, 0)
    }
  }, [activeStep])

  /* ---------------------------------------Video Post processing errors---------------------------------------*/
  const checkPostProcessingErrors = (project) => {
    if (project.videoWidth && project.videoHeight && project.durationMs) {
      if ((project.durationMs < (1 * 60 * 1000)) || (project.durationMs > (2 * 60 * 60 * 1000))) {
        setOpenVideoProcessingErrorOrWarning(true)
        setVideoProcessingErrorOrWarningContent({
          errTitle: Helper.getString('uploadVideoLengthError'),
          errorDescr: Helper.getString('uploadVideoLengthErrorStr')
        })
      } else {
        // // Video Resolution Check
        // if ((project.videoWidth === 1920 && project.videoHeight === 1080) ||
        //   (project.videoWidth === 1280 && project.videoHeight === 720) ||
        //   (project.videoWidth === 3840 && project.videoHeight === 2160) ||
        //   (project.videoWidth === 4096 && project.videoHeight === 2160)) {
        //   // Do nothing
        // } else {
        //   setOpenVideoProcessingErrorOrWarning(true)
        //   setVideoProcessingErrorOrWarningContent({
        //     errTitle: Helper.getString('uploadVideoResolutionError'),
        //     errorDescr: Helper.getString('uploadVideoResolutionErrorStr')
        //   })
        // }
      }
    }
  }

  const showPublishedAndPurchasedWarning = () => {
    return (
      <EditWarningPopup
        dialogOpen={openPublishedAndPurchasedWarning}
        title={Helper.getString('publishedAndPurchasedVideoEditWarning')}
        descr={Helper.getString('publishedAndPurchasedVideoEditWarningDescr')}
        buttonTitle={'Continue'}
        buttonAction={() => {
          setOpenPublishedAndPurchasedWarning(false)
        }}
        onClose={() => {
          props.uploadVideoClose(true)
        }} />
    )
  }

  const showVideoProcessingErrorOrWarning = () => {
    return (
      <ErrorPopup
        dialogOpen={openVideoProcessingErrorOrWarning}
        title={videoProcessingErrorOrWarningContent.errTitle}
        descr={videoProcessingErrorOrWarningContent.errorDescr}
        buttonText={videoProcessingErrorOrWarningContent.buttonText}
        onPopUpClose={() => {
          setOpenVideoProcessingErrorOrWarning(false)
          setVideoProcessingErrorOrWarningContent({ errTitle: '', errorDescr: '', buttonText: '' })
          if (!editingInPublishedAndPurchasedState) {
            props.uploadVideoClose(true)
          }
        }} />
    )
  }

  /* ------------------------------10 econd polling to get video processig state--------------------------------*/
  const setTimerToProjectStateCheck = (projectId, projectFull) => {
    try {
      intervalId.current = setInterval(async () => {
        const response = await ApiHelper.callAwait({
          method: 'GET', paths: ['contents', projectId]
        })
        console.log('UplodedVideoWizardDialog:: setTimerToProjectStateCheck response ', response)
        // response.publishProgress
        if (response.publishProgress) {
          setPublishProgress(response.publishProgress)
        }
        if (response.state === PROJECT_STATE.PROCESSINGCOMPLETE) {
          setPublishProgress(100)
          setprojectFull({ ...response, _uploadable: projectFull._uploadable })
          console.log('UplodedVideoWizardDialog:: setTimerToProjectStateCheck projectFull ', projectFull)
          clearInterval(intervalId.current)
          checkPostProcessingErrors(response)
        }
      }, 8000);
    } catch (e) {
      console.log('UplodedVideoWizardDialog:: setTimerToProjectStateCheck', e)
    }
  }

  const getDropdownValues = (appconfig) => {
    console.log('appconfig', appconfig)
    const levels = appconfig.levels.map((item) => {
      return { key: item.id, value: item.title }
    })

    const cats = appconfig.cats.map((item) => {
      return { key: item.id, value: item.title }
    })

    const ctas = appconfig.ctas.map((item) => {
      return { key: item.id, value: item.title }
    })
    const pricetiers = appconfig.pricetiers.map((item) => {
      return { key: item.id, value: item.title }
    })
    const contentpricetiers = appconfig.contentpricetiers.map((item) => {
      return { key: item.id, value: item.title }
    })

    return { levels, cats, ctas, pricetiers, contentpricetiers }
  }

  async function initializeData() {
    let projectId = null
    try {
      // Populate dropdowns from appconfig data
      const appconfig = await ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'appconfig'], {})
      })
      console.log('appconfig', appconfig)
      dropDownValues.current = getDropdownValues(appconfig)


      let response
      // Set Up project
      if (!props.project) {
        // Create Scenario
        const projectData2 = projectData2Default
        projectData2.filename = props.fileStackUploadResponse.filename
        projectData2.details.title = props.fileStackUploadResponse.filename.substring(0, dataItem.detailsFormItem[0].charachterLimit);
        if (dropDownValues.current.pricetiers && dropDownValues.current.pricetiers.length > 0) {
          projectData2.pricing.price = dropDownValues.current.pricetiers[0]
        }
        if (dropDownValues.current.contentpricetiers && dropDownValues.current.contentpricetiers.length > 0) {
          projectData2.pricing.contentprice = dropDownValues.current.contentpricetiers[0]
        }
        const body = {
          title: projectData2.details.title,
          privacy: VISIBILITY.PRIVATE,
          projectData2: JSON.stringify(projectData2),
          fsImportedVideoName: props.fileStackUploadResponse.key // Name from FS Import
        }
        const options = {
          method: 'POST',
          paths: ['contents'],
          jsonBody: body,
        }
        const draftProject = await ApiHelper.callAwait(options)
        mode.current = MODES.CREATE
        refreshOnClose.current = true

        // Read newly created project
        projectId = draftProject.id
        console.log('projectId:::', projectId)
        response = await ApiHelper.callAwait({
          method: 'GET', paths: ['contents', projectId], queryParams: { uploadable: true }
        })
      } else {
        // Edit Scenario
        mode.current = MODES.EDIT

        // Read existing project
        projectId = props.project.id
        console.log('projectId:::', projectId)
        response = await ApiHelper.callAwait({ method: 'GET', paths: ['contents', projectId] })

        if (response.state === 'DELETED') {
          throw (Helper.getString('contentNotAvailable'));
        }

        // The below check should happen at the updated contents(latest read status of a project, NOT from the list data)
        if (AppHelper.ifEditInPublishedAndPurchasedState(response)) {
          // Directly jump to pricing tab
          // setActiveStep(4)
          setEditingInPublishedAndPurchasedState(true)
          setOpenPublishedAndPurchasedWarning(true)
        } else if (AppHelper.ifEditInPublishedState(response)) {
          const options = {
            method: 'PUT',
            paths: ['contents', response.contentId],
            jsonBody: { updateAction: 'unpublish' }
          }
          const result = await ApiHelper.callAwait(options)
        }

        response = await ApiHelper.callAwait({
          method: 'GET', paths: ['contents', projectId], queryParams: { uploadable: true }
        })
      }
      console.log('UplodedVideoWizardDialog:: project response', response)
      // Set the project data
      if (response.projectData2) {
        try {
          const projectData2 = JSON.parse(response.projectData2)
          _doMigrationForImagesIfNeeded(projectData2)
          setProjectData(projectData2)
          handleVideoType(projectData2.details.instructionalVideo)
        } catch (e) {
          console.log('Json parse error', e)
          const projectData2 = projectData2Default
          projectData2.filename = response.title
          setProjectData(projectData2)
          handleVideoType(projectData2.details.instructionalVideo)
        }
      } else {
        console.log('its new project')
        const projectData2 = projectData2Default
        projectData2.filename = response.title
        setProjectData(projectData2)
        handleVideoType(projectData2.details.instructionalVideo)
      }

      setprojectFull(response)
      // handleVideoType()
      setFetchState(ApiHelper.State.READY)
      setErrMsg(null)
      if (response.state === PROJECT_STATE.PROCESSING) {
        // 10 second polling to check whether the PROCESSING is complete for this video
        setTimerToProjectStateCheck(projectId, response)
      } else {
        // If it is processed, check for video post processing errors
        checkPostProcessingErrors(response)
      }
    } catch (e) {
      setprojectFull(null)
      setProjectData(null)
      setFetchState(ApiHelper.State.ERROR)
      setErrMsg(Helper.getErrorMsg(e))
    }
  }

  function _doMigrationForImagesIfNeeded(projectData2) {
    // Already contains images, but got in Image Objects
    const imageObjs = []
    if (projectData2 && projectData2.images && projectData2.images.length > 0 && !projectData2.imageObjs) {
      for (const image of projectData2.images) {
        imageObjs.push(
          {
            low: image,
            medium: image,
            high: image
          }
        )
      }
      projectData2.imageObjs = imageObjs
    }
    if (projectData2 && projectData2.steps && projectData2.steps.length > 0) {
      projectData2.steps.forEach(step => {
        if (step.images && step.images.length > 0 && !step.imageObjs) {
          const imageObjs = []
          for (const image of step.images) {
            imageObjs.push(
              {
                low: image,
                medium: image,
                high: image
              }
            )
          }
          step.imageObjs = imageObjs
        }
      });
    }
  }

  function _storeImagesInOldFormat(projectData) {
    if (projectData && projectData.imageObjs && projectData.imageObjs.length > 0) {
      projectData.images = []
      projectData.imageObjs.forEach((image => {
        projectData.images.push(image.high)
      }))
    }
    if (projectData && projectData.steps && projectData.steps.length > 0) {
      projectData.steps.forEach((step => {
        if (step.imageObjs && step.imageObjs.length > 0) {
          step.images = []
          step.imageObjs.forEach((image => {
            step.images.push(image.high)
          }))
        }
      }))
    }
  }

  async function onSave() {
    checkPostProcessingErrors(projectFull)

    setOnSaveState(ApiHelper.State.LOADING)

    _storeImagesInOldFormat(projectData)
    console.log('_storeImagesInOldFormat::', projectData)

    if (projectData.materials.callToActionUrl) {
      projectData.materials.callToActionUrl = AppHelper.getValidatedUrl(projectData.materials.callToActionUrl)
      setProjectData({ ...projectData })
    }

    try {
      const projectDataJSONString = JSON.stringify(projectData)
      const validatedProjectData = JSON.parse(projectDataJSONString)

      // Double check whether project Data is valid
      if (!validatedProjectData || validatedProjectData === '') {
        return
      }

      // Consolidate Data for saving
      const body = {
        projectData2: projectDataJSONString,
      }
      if (projectData.details.title) {
        body.title = projectData.details.title
      }
      if (projectData.details.descr) {
        body.descr = projectData.details.descr
      }
      if (projectData.visibility && projectData.visibility.privacy) {
        body.privacy = projectData.visibility.privacy
      }
      if (!Number.isNaN(projectData.details.thumbTs) && Number(projectData.details.thumbTs) >= 0) {
        body.thumbTs = Math.floor(projectData.details.thumbTs)
      }
      if (projectData.details.thumbnailType) {
        body.thumbnailType = projectData.details.thumbnailType
      }
      if (projectData.details.thumbnailName) {
        body.thumbnailName = projectData.details.thumbnailName
      }
      if (projectData.details.thumbnailVideoFrameName) {
        body.thumbnailVideoFrameName = projectData.details.thumbnailVideoFrameName
      }
      if (projectData.pricing) {
        body.pricing = projectData.pricing
      }

      const options = {
        method: 'PUT',
        paths: ['contents', projectFull.id],
        jsonBody: body,
      }
      const response = await ApiHelper.callAwait(options)
      setOnSaveState(ApiHelper.State.READY)
      setErrMsg(null)
    } catch (e) {
      setOnSaveState(ApiHelper.State.ERROR)
      setErrMsg(Helper.getErrorMsg(e))
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleVideoType = (instructionalVideo) => {
    console.log('isInstructionalVideo', instructionalVideo)
    if (instructionalVideo === 'YES') {
      setIsInstructionalVideo(false)
    } else {
      setIsInstructionalVideo(true)
    }
  }

  const wizardButtonsDisable = (value) => {
    setDisableWizardButtons(value)
  }

  const handleFormDataChanges = (key, value, componentName) => {
    if (activeStep === 0) {
      projectData.details[key] = value
      if (key === 'instructionalVideo') {
        handleVideoType(projectData.details[key])
      }
    }
    if (activeStep === 1) {
      projectData[key] = value
    }
    if (activeStep === 2) {
      projectData.materials[key] = value
    }
    if (activeStep === 3) {
      projectData[key] = value
    }
    if (activeStep === 4) {
      projectData.pricing[key] = value
    }
    if (activeStep === 5) {
      if (key === 'privacy') {
        refreshOnClose.current = true
      }
      projectData.visibility[key] = value
    }
    setProjectData({ ...projectData })
    console.log('UplodedVideoWizardDialog:: handleFormDataChanges projectData::', projectData)
  }

  const getStepperComponent = (step) => {
    switch (step) {
    case 0:
      return (<DetailsComponent
        projectData={projectData}
        projectFull={projectFull}
        levels={dropDownValues.current.levels}
        cats={dropDownValues.current.cats}
        publishProgress={publishProgress}
        wizardButtonsDisable={wizardButtonsDisable}
        handleFormDataChanges={handleFormDataChanges} />)
    case 1:
      return (<ImagesComponent
        signedUploadInfo={projectFull._uploadable}
        imageUrls={projectData.imageObjs}
        wizardButtonsDisable={wizardButtonsDisable}
        handleFormDataChanges={handleFormDataChanges} />)
    case 2:
      return (<Materials
        projectData={projectData}
        ctas={dropDownValues.current.ctas}
        handleFormDataChanges={handleFormDataChanges} />)
    case 3:
      return (<StepOrChapter
        projectData={projectData}
        projectFull={projectFull}
        isInstructionalVideo={isInstructionalVideo}
        wizardButtonsDisable={wizardButtonsDisable}
        handleFormDataChanges={handleFormDataChanges} />
      )
    case 4:
      return (<PriceComponent
        projectData={projectData}
        pricetiers={dropDownValues.current.pricetiers}
        contentpricetiers={dropDownValues.current.contentpricetiers}
        handleFormDataChanges={handleFormDataChanges} />)
    case 5:
      return (<VisibilityComponent
        projectData={projectData}
        handleFormDataChanges={handleFormDataChanges} />)
    default:
      return null
    }
  }

  const renderOnSaveProcessing = () => {
    if (onSaveState === ApiHelper.State.LOADING) {
      return (<p className={css(Styles.saveText)}>Saving...</p>)
    }
  }

  const renderOnSaveError = () => {
    if (onSaveState === ApiHelper.State.ERROR) {
      return (<p className={css(Styles.saveErrorText)}>{errMsg}</p>)
    }
  }

  const validateStepsStarts = () => {
    const { steps } = projectData;
    const startTsErrorMessages = [];

    // Check if all the step has zero startTs; returns nothing, no need to validate
    const allStepsHaveZeroStartTs = steps.every(step => step.startTs === 0);
    if (allStepsHaveZeroStartTs) {
      return
    }

    // Check if there is at least one step with a non-zero startTs
    const anyStepHasNonZeroStartTs = steps.some(step => step.startTs !== 0);
    if (anyStepHasNonZeroStartTs) {
      // Display error for steps with zero startTs (excluding the first step and steps with non-zero startTs)
      const stepsWithZeroStartTs = steps.slice(1).filter(step => step.startTs === 0);
      if (stepsWithZeroStartTs.length > 0) {
        stepsWithZeroStartTs.forEach(step =>
          startTsErrorMessages.push(`Step #${step.stepIndex + 1} must have a Video Timestamp`));
      }
    }

    if (startTsErrorMessages.length > 0) {
      return startTsErrorMessages
    }

    // Check the order of startTs for all steps
    for (let i = 1; i < steps.length; i++) {
      const currentStepStartTs = steps[i].startTs;
      const prevStepStartTs = steps[i - 1].startTs || 0

      if (currentStepStartTs <= prevStepStartTs) {
        startTsErrorMessages.push(`Step #${i + 1} Video Timestamp should be after Step #${i} Timestamp`);
      }
    }
    return startTsErrorMessages

  };

  //Form validation
  const validateProjectData = () => {
    const validationFieldsMap = {
      details: [],
      images: [],
      materials: [],
      steps: [],
      stepsStartTsErrorMessages: []
    }
    for (const key in projectData) {
      if (key === 'details') {
        if (!projectData.details.title || projectData.details.title === '') {
          validationFieldsMap.details.push('a Video Title')
        }
        if (!projectData.details.category ||
          !projectData.details.category.value
          || projectData.details.category.value === '') {
          validationFieldsMap.details.push('a Video Category')
        }
        if (!projectData.details.descr || projectData.details.descr === '') {
          validationFieldsMap.details.push('a Video Description')
        }
      }
      if (projectData.details.instructionalVideo !== 'NO') {
        if (key === 'imageObjs') {
          if (!projectData.imageObjs || !projectData.imageObjs.length || projectData.imageObjs.length === 0) {
            validationFieldsMap.images.push('at least one uploaded image in Images tab')
          }
        }
        if (key === 'materials') {
          if (!projectData.materials.descr || projectData.materials.descr === '') {
            validationFieldsMap.materials.push('a Materials Description')
          }
          if (projectData.materials.callToAction && projectData.materials.callToAction.value) { // Only if call to action is present
            if (!projectData.materials.callToActionUrl || projectData.materials.callToActionUrl === '') {
              validationFieldsMap.materials.push('a Call-to-Action URL on MATERIALS tab')
            }
          }
        }
        if (key === 'steps') {
          for (let i = 0; i < projectData.steps.length; i++) {
            // if (projectData.steps[i]) {
            if (!projectData.steps[i].title || projectData.steps[i].title === '') {
              if (!validationFieldsMap.steps.includes('a Step Title')) {
                validationFieldsMap.steps.push('a Step Title')
              }
            }
            if (!projectData.steps[i].descr || projectData.steps[i].descr === '') {
              if (!validationFieldsMap.steps.includes('a Step Description')) {
                validationFieldsMap.steps.push('a Step Description')
              }
            }

          }
          if (projectData.steps.length === 0) {
            validationFieldsMap.steps.push('a Step Title')
            validationFieldsMap.steps.push('a Step Description')
          }
          // }
          // Loop through steps and if any one of them missing title or description, break and loop and add the error message

          const stepsStartsErrorMessages = validateStepsStarts();
          if (stepsStartsErrorMessages && stepsStartsErrorMessages.length > 0) {
            // Map through startTsErrorMessages and add each message to validationFieldsMap.steps
            stepsStartsErrorMessages.forEach(errorMessage => {
              validationFieldsMap.stepsStartTsErrorMessages.push(errorMessage);
            });
          }
        }
      }
    }
    console.log('UplodedVideoWizardDialog:: warning array validationFieldsMap', validationFieldsMap)
    if (validationFieldsMap.details.length > 0 ||
      validationFieldsMap.images.length > 0 ||
      validationFieldsMap.materials.length > 0 ||
      validationFieldsMap.steps.length > 0 ||
      validationFieldsMap.stepsStartTsErrorMessages.length > 0) {
      setValidationWarningFields(validationFieldsMap)
      return false
    }
    return true // validation is success
  }

  const checkPayoutSetupComplete = () => {
    const isPaidVideo = projectData.pricing && projectData.pricing.type === PRICINGTYPES.ALL_IN_ONE_PRICE

    const isContentPricedVideo = projectData.pricing && projectData.pricing.type === PRICINGTYPES.FREE
      && projectData.pricing.contentprice && projectData.pricing.contentprice.value && projectData.pricing.contentprice.value !== '0.00'

    if (isPaidVideo || isContentPricedVideo) {
      if (!appUser.ppInfo || !appUser.ppInfo.email) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  const showPayoutSetupIncompletePoup = () => {
    return (
      <ErrorPopup
        dialogOpen={openPayoutSetupIncomplete}
        title={'Payout Setup Incomplete'}
        descr={'Please complete Payout Setup under your Profile before publishing video.'}
        onPopUpClose={() => {
          setOpenPayoutSetupIncomplete(false)
        }} />
    )
  }

  const handlePublish = async () => {
    const validationSuccess = validateProjectData()
    const payoutSetupComplete = checkPayoutSetupComplete()
    if (!payoutSetupComplete) {
      setOpenPayoutSetupIncomplete(true)
      return
    }
    if (!validationSuccess) {
      setOpenPublishWarningDailog(true)
      return
    }
    // First save the data
    await onSave()
    try {
      setOpenPublishDailog(true) // Start showing publish dialog
      const options = {
        method: 'PUT',
        paths: ['contents', projectFull.id],
        jsonBody: { updateAction: 'publish' }
      }
      const response = await ApiHelper.callAwait(options)
      //If publish is success, then close the poup after few more seconds
      setTimeout(() => onPublishDialogClose(true), 8000)
      setErrMsg(null)
    } catch (e) {
      setOnSaveState(ApiHelper.State.ERROR)
      setErrMsg(Helper.getErrorMsg(e))
      onPublishDialogClose()
    }
  }

  const onPublishDialogClose = (publishSuccess) => {
    setOpenPublishDailog(false)
    if (publishSuccess) {
      props.uploadVideoClose(publishSuccess)
    }
  }

  const getContentState = (state) => {
    if (fetchState === ApiHelper.State.LOADING) {
      return ''
    }
    if (state === 'READY') {
      return 'Published'
    } else {
      return 'Draft'
    }
  }

  const onWarningClose = () => {
    setOpenPublishWarningDailog(false)
    setValidationWarningFields([])
  }

  const onvideoIncompleteWarningClose = () => {
    setvideoProcessingComplete(false)
  }

  //Required info warning Dialog
  const publichWarningDailog = () => {
    return (
      <WarningPopup dialogOpen={openPublishWarningDailog}
        image={require('../../assets/images/error.png')}
        popUpTitle='Required Information Missing'
        popUpDescr='Please complete these required fields before publishing:'
        validationContent={validationWarningFields}
        onPopUpClose={() => onWarningClose()} />
    )
  }

  //Video Processing warning Dialog
  const videoIncompleteWarning = () => {
    const descr = 'Please wait for video processing to complete before proceeding to next step.\n\nProcessing status can be viewed on DETAILS tab.'
    return (
      <WarningPopup dialogOpen={openVideoProcessingComplete}
        image={require('../../assets/images/error.png')}
        popUpTitle='Video Processing Incomplete'
        popUpDescr={descr}
        publishWarningDialogDescr={Styles.publishWarningDialogDescr}
        onPopUpClose={() => onvideoIncompleteWarningClose()} />
    )
  }

  //Publish completed Dialog
  const publichDailog = () => {
    return (
      <Dialog open={openPublishDailog}
        classes={{
          paper: css(Styles.publishDialog),
        }}
        className={css(Styles.publishDialogContainer)}>
        <div className={css(Styles.publishImageContainer)}>
          <img src={require('../../assets/images/processanimated.gif')}
            width='50' height='50' alt={Helper.getString('processImageAlt')}
            className={css(Styles.img)} />
        </div>
        <p className={css(Styles.publishDialogTitle)}>{Helper.getString('publishVideo')}</p>
        <p className={css(Styles.publishDialogDescr)}>Use <span className={css(Styles.boldText)}>
          Razzl App</span> to preview this video (under YOUR VIDEOS).</p>
        <p className={css(Styles.publishDialogDescr)}>Use same login email to access <span className={css(Styles.boldText)}>
          Razzl App</span> as used here.</p>
      </Dialog>
    )
  }

  const disableFooterBack = () => {
    const disable = activeStep === 0 || (onSaveState === ApiHelper.State.LOADING) || disableWizardButtons
    return disable
  }

  const disableFooterNext = () => {
    const disable = (onSaveState === ApiHelper.State.LOADING)
      || (fetchState === ApiHelper.State.LOADING) || disableWizardButtons
    return disable
  }

  const disableFooterPublish = () => {
    if (activeStep === stepperItems.length - 1 && editingInPublishedAndPurchasedState) {
      return true
    }
    return false
  }

  const getFooter = () => {
    return (
      <div className={css(Styles.footerContainer)}>
        <div className={css(Styles.footerLeftContainer)}>
          <p className={css(Styles.footerText)}>Status: <span className={css(Styles.progressText)}>
            {getContentState(projectFull && projectFull.state)}</span></p>
          <p className={css(Styles.footerText)}>Once published, use <span className={css(Styles.boldText)}>
            Razzl App</span> to preview this video (under YOUR VIDEOS).</p>
        </div>
        <div className={css(Styles.footerRightContainer)}>
          <div className={css(Styles.footerButtonContainer)}>
            {renderOnSaveProcessing()}
            <AppButton
              disabled={onSaveState === ApiHelper.State.LOADING ? true : false
                || fetchState === ApiHelper.State.LOADING ? true : false || disableWizardButtons}
              buttonStyle={[Styles.buttonStyle, Styles.borderButtonStyle]}
              buttonTextStyle={[Styles.buttonLabel, Styles.saveButtonLabelColor]}
              buttonText={'Save'}
              onClick={() => onSave()}
            />
            <AppButton
              disabled={disableFooterBack()}
              buttonStyle={[Styles.buttonStyle, Styles.borderButtonStyle]}
              buttonTextStyle={[Styles.buttonLabel, activeStep === 0 ?
                Styles.backButtonDisableLabelColor : Styles.backButtonActiveLabelColor]}
              onClick={() => handleBack()}
              buttonText={'Back'}
            />
            <AppButton
              disabled={disableFooterNext() || disableFooterPublish()}
              buttonStyle={disableFooterPublish() ? [Styles.buttonStyle, Styles.disabledButtonStyle] : Styles.buttonStyle}
              buttonTextStyle={Styles.buttonLabel}
              buttonText={activeStep === stepperItems.length - 1 ? 'Publish' : 'Next'}
              onClick={activeStep === stepperItems.length - 1 ? () => handlePublish() : () => handleNext()}
            />
          </div>
          {renderOnSaveError()}
        </div>
        {publichDailog()}
        {publichWarningDailog()}
        {videoIncompleteWarning()}
      </div>
    )
  }

  const renderCustomDescription = (line1, line2, line3, buttons) => {
    return (
      <Fragment>
        <p className={css(Styles.titleDescr)}>
          {line1}
          <AppButton buttonStyle={Styles.titleDescrLink} buttonText={'See pricing guidelines...'} onClick={() => {
            setInfoPopupOpen(true);
            setInfoPopupContentType('pricingGuidelines')
          }} />
        </p>
        <p className={css(Styles.pricingTitleDescr)}>
          {line2}
        </p>
        <p className={css(Styles.pricingTitleDescr)}>
          <AppButton buttonStyle={Styles.titleDescrLink} buttonText={'Learn more'} onClick={() => {
            setInfoPopupOpen(true);
            setInfoPopupContentType('license')
          }} />
          {line3}
        </p>
      </Fragment>
    );
  }

  const getDialogContent = () => {
    if (fetchState === ApiHelper.State.LOADING) {
      return (<div className={css(Styles.container)}>
        {AppHelper.loadingView()}
      </div>)
    } else if (fetchState === ApiHelper.State.READY) {
      return (<Fragment>
        {stepperItems.map((item, index) => {
          if (activeStep === index) {
            return (
              <div className={css(Styles.titleOuterContainer)} >
                <div className={css(Styles.titleContainer)}>
                  <p className={css(Styles.title)}>{item.label}</p>
                  {item.helpText &&
                    <Tooltip title={item.helpText} classes={{
                      tooltip: css(Styles.titleTooltip),
                      popper: css(Styles.tooltipPopper)
                    }}>
                      <div className={css(Styles.helpContainer)}>
                        <img src={require('../../assets/images/help.png')}
                          width='25' height='25' alt={Helper.getString('helpImageAlt')}
                          className={css(Styles.img)} />
                      </div>
                    </Tooltip>
                  }
                </div>
                {item.descr && activeStep === 4 ? renderCustomDescription('Create a pricing plan for this video and downloads.', 'Downloads include any uploaded files, digital products and project PDF.', 'about LICENSE granted to viewers for downloads.') : <p className={css(Styles.titleDescr)}>{item.descr}</p>}
              </div>
            )
          }
        })}
        {getStepperComponent(activeStep)}
      </Fragment>)
    } else if (fetchState === ApiHelper.State.ERROR) {
      return (<div className={css(Styles.container)}>
        <p className={css(Styles.errorText)}>{errMsg}</p>
      </div>)
    }
  }

  const disableClickOnStepLabel = (index) => {
    const disable = (onSaveState === ApiHelper.State.LOADING)
      || (fetchState === ApiHelper.State.LOADING) || disableWizardButtons
    // if (editingInPublishedAndPurchasedState && index < 4) {
    //   disable = true
    // }
    return disable
  }

  const getStepLabelStyle = (index) => {
    const disabled = disableClickOnStepLabel(index)
    if (disabled) {
      return css([Styles.stepperLabel, Styles.notActiveStep, Styles.stepperLabelDisabledForPurchase])
    }
    if (activeStep === index) {
      return css(Styles.stepperLabel)
    } else {
      return css([Styles.stepperLabel, Styles.notActiveStep])
    }
  }

  const onSteplabelClick = (index) => {
    const disabled = disableClickOnStepLabel(index)
    if (!disabled) {
      handleStep(index)
    }
  }

  return (
    <Dialog
      fullScreen={true}
      open={props.uploadVideo}
      classes={{
        paper: css(Styles.wizardContainer),
      }} className={css(Styles.wizardOuterContainer)}>
      {/* <div className={css(Styles.wizardContainer)}> */}
      <Tooltip title={Helper.getString('wizardClose')} placement={'left'}
        classes={{
          tooltip: css(Styles.tooltip),
          popper: css(Styles.tooltipPopper)
        }}>
        <div onClick={() => {
          // setProjectData(JSON.parse(JSON.stringify(projectData2Default))) // Clear the create Data
          // onSave()
          props.uploadVideoClose(true)
        }} className={css(Styles.closeIconContainer)}>
          <img src={require('../../assets/images/close.png')}
            alt={Helper.getString('closeImageAlt')}
            className={css(Styles.img)}
            width='35'
            height='35'
          />
        </div>
      </Tooltip>
      <Stepper activeStep={activeStep} className={css(Styles.stepperContainer)}>
        {stepperItems.map((item, index) => {
          return (
            <Step>
              <StepLabel classes={{ label: getStepLabelStyle(index) }}
                icon={false} onClick={() => onSteplabelClick(index)}>{item.label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <DialogContent id={'scroll'} classes={{ root: css(Styles.dialogContentContainer) }}>
        {getDialogContent()}
      </DialogContent>
      {/* </div> */}
      {getFooter()}
      {showPublishedAndPurchasedWarning()}
      {showVideoProcessingErrorOrWarning()}
      {showPayoutSetupIncompletePoup()}
      <InfoPopup open={infoPopupOpen} setOpen={setInfoPopupOpen} contentType={infoPopupContentType} />
    </Dialog>
  )
}

const Styles = StyleSheet.create({
  closeIconContainer: {
    width: 35,
    height: 35,
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer'
  },
  tooltip: {
    border: '1px solid #FFFFFF80',
    padding: '14px 12px',
    backgroundColor: '#000000',
    borderRadius: 8,
    zIndex: 13000,
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  wizardOuterContainer: {
    zIndex: 12001
  },
  wizardContainer: {
    display: 'flex',
    //flex: 1,
    // width: '100%',
    // minWidth: 1444,
    // minHeight: 975,
    flexDirection: 'column',
    backgroundColor: '#1C1C1E',
    position: 'relative',
    padding: '0px 114px',
    minHeight: '100vh',
    '@media (max-width: 768px)': {
      padding: '0px 60px',
    },
    '@media (max-width:1024px)': {
      padding: '0px 80px',
    },
    '@media (max-width:1200px)': {
      padding: '0px 80px',
    },
  },
  stepperContainer: {
    backgroundColor: '#1C1C1E',
    padding: '55px 0px 40px',
  },
  stepperLabel: {
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  stepperLabelDisabledForPurchase: {
    cursor: null
  },
  title: {
    fontSize: 25,
    color: '#ffffff',
    fontWeight: 600,
    textAlign: 'center',
    margin: 0,
    textTransform: 'uppercase'
  },
  notActiveStep: {
    color: '#FFFFFF80'
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #707070',
    backgroundColor: '#1C1C1E',
    padding: '21px 27px',
    // marginTop: 81,
  },
  footerText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: '5px 0px'
  },
  footerLeftContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  footerRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  footerButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonStyle: {
    maxWidth: 120,
    minHeight: 53,
    borderRadius: 4
  },
  borderButtonStyle: {
    backgroundColor: 'unset',
    border: '1px solid #FFFFFF80',
    marginRight: 20,
    maxWidth: 118,
    minHeight: 53,
  },
  disabledButtonStyle: {
    backgroundColor: 'unset',
    border: '1px solid #FFFFFF1A',
    color: '#FFFFFF33'
  },
  buttonLabel: {
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: 'unset'
  },
  backButtonDisableLabelColor: {
    color: '#FFFFFF80'
  },
  backButtonActiveLabelColor: {
    color: '#FFFFFFff'
  },
  saveButtonLabelColor: {
    color: '#037BFE'
  },
  titleDescr: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginTop: 10,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    lineHeight: 1.4
  },
  pricingTitleDescr: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginTop: 4,
    whiteSpace: 'pre-wrap',
    textAlign: 'center'
  },
  titleDescrLink: {
    display: 'inline-block',
    backgroundColor: 'unset',
    color: '#037BFE',
    textTransform: 'none',
    height: 'unset',
    padding: 'unset',
    margin: '0 5px',
    minWidth: 'unset',
    lineHeight: 'unset'
  },
  progressText: {
    color: '#037BFE',
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorText: {
    fontSize: 20,
    color: '#FF0000',
    textTransform: 'capitalize'
  },
  saveText: {
    fontSize: 16,
    color: '#FF5500',
    margin: 0,
    marginRight: 50
  },
  saveErrorText: {
    fontSize: 16,
    color: '#FF0000',
    margin: 0,
    marginTop: 10,
    textAlign: 'left'
  },
  titleOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  titleContainer: {
    display: 'flex',
    position: 'relative'
  },
  helpContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    width: 25,
    margin: 4,
    position: 'absolute',
    top: -14,
    right: -30,
    cursor: 'pointer'
  },
  titleTooltip: {
    padding: 30,
    backgroundColor: '#000000',
    fontSize: 18,
    lineHeight: '23px',
    whiteSpace: 'pre-wrap'
  },
  tooltipPopper: {
    zIndex: 13001
  },
  publishDialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000',
    width: 492,
    // height: 400,
    paddingTop: 60,
    paddingBottom: 50,
    border: '1px solid #FFFFFF80',
    borderRadius: 8
  },
  publishDialogContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 13000
  },
  publishImageContainer: {
    height: 50,
    width: 50,
  },
  publishDialogTitle: {
    color: '#ffffff',
    fontSize: 18,
    margin: 0,
    fontWeight: 'bold',
    marginTop: 5,
    marginBottom: 10
  },
  publishDialogDescr: {
    color: '#ffffff',
    fontSize: 18,
    textAlign: 'center',
    margin: 0,
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 340
  },
  boldText: {
    fontWeight: 'bold'
  },
  publishWarningDialog: {
    display: 'flex',
    position: 'relative',
    backgroundColor: '#000000',
    // width: 443,
    paddingTop: 35,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 48,
    border: '1px solid #FFFFFF80',
    borderRadius: 8
  },
  publishWarningTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  publishWarningDialogDescr: {
    // textAlign: 'left',
    // marginBottom: 13,
    whiteSpace: 'pre-wrap'
  },
  publishWarningTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 13,
    marginBottom: 13
  },
  publishWarningText: {
    fontSize: 18,
    color: '#ffffff',
    margin: 0,
    maxWidth: 298
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8
  }
})