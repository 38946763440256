import React, { Component, Fragment, useContext, useEffect, useState, useRef } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Dialog, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, FormControlLabel, Input, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@material-ui/core';
import { CustomImageUploader } from '../../components/CustomImageUploader';
import { RadioButton, SelectBox, TextBoxs } from '../../components/WizardTextInputs';
import { Helper } from '../../common/helpers/Helper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AppButton } from '../../common/components/AppButton';
import { VideoPlayer } from '../../components/VideoPlayer';
import { RichtextEditor } from '../../common/components/RichtextComponent/RichtextEditor';
import { AppHelper } from '../../helpers/AppHelper';
import { LabelAndDescription } from './LabelAndDescription';
import { VideoTimeSelector } from '../../components/VideoTimeSelector'
import { CustomLinkPopover } from '../../components/CustomLinkPopover';
import { HTML_FORMATTING, MAX_TEXT_LENGTH } from '../../Consts';
import { CustomDocumentUploader } from '../../components/CustomDocumentUploader';
import { CustomDocumentUploaderWrapper } from '../../components/CustomDocumentUploaderWrapper';
import { FilestackFilesUploaderWrapper } from '../../components/FilestackFilesUploaderWrapper';
import { ErrorPopup } from '../../components/ErrorPopup';
import { StepsImageUploader } from '../../components/StepsImageUploader';

const documentArray = []

export function StepOrChapter(props) {
  const [state, setState] = useState({
    textBoxValue: '',
    selectedPanelId: null,
    expanded: false,
    openVideo: false
  })
  const [focusedStepIndex, setFocusedStepIndex] = useState(-1)
  const [fileUploadFailedErrorPopup, setFileUploadFailedErrorPopup] = useState(false)

  const [disableButton, setDisableButton] = useState(false)
  const [disableWizardButtons, setDisableWizardButtons] = React.useState(false);

  const descrItem = {
    key: 'descr',
    label: 'Description',
    descr: 'Brief list of instructions in this step.',
    type: 'RichTextField',
    placeholder: 'This is a required field for instructional videos.',
    charachterLimit: MAX_TEXT_LENGTH.STEP_DESCR_MAX_TEXT_LENGTH,
    textBoxValue: ''
  }


  const tipsItem = {
    key: 'tips',
    label: 'Tips',
    descr: 'List any tips or best practices for this step.',
    type: 'RichTextField',
    optional: 'optional',
    placeholder: '',
    charachterLimit: MAX_TEXT_LENGTH.STEP_TIP_MAX_TEXT_LENGTH,
    textBoxValue: ''
  }

  const docs = {
    key: 'docs',
    label: 'UPLOAD FILES',
    descr: 'Upload any supporting files or digital products.',
    optional: 'optional',
    uploadAccept: '*.*',//'application/pdf,text/*,image/png, image/jpeg',
    fileSizeLimit: 50000000
  }


  const videoUrl = props.projectFull.streamingUri

  //'https://youtu.be/4BGGcFrsZDU'
  //'https://dg175en455vh2.cloudfront.net/contents/68a78f00a12b11ec97d0b50892e9a54b/streaming/videoazvs.m3u8'
  // const [stepCounter, setStepCounter] = useState(projectDataJSON.steps.length)

  const title = {
    key: 'title',
    label: 'Title',
    type: 'TextField',
    charachterLimit: MAX_TEXT_LENGTH.STEP_TITLE_MAX_TEXT_LENGTH,
    title: ''
  }

  //custom ToolBar for RichTextEditor
  const editorToolbar = {
    options: ['inline', 'list', 'link'],
    inline: {
      options: ['bold', 'italic'],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      options: ['left', 'center', 'right', 'justify'],
    },
    link: {
      component: CustomLinkPopover,
      showOpenOptionOnHover: true,
    }
  }

  //adding Expansion
  const onAddAction = () => {
    //expansionHandleChange()
    const stepCounter = props.projectData.steps.length
    const step = {
      stepIndex: stepCounter,
      title: '',
      startTs: 0,
      descr: '',
      tips: '',
      documents: [],
      images: [],
      imageObjs: []
    }
    const newStepsArray = [...props.projectData.steps, step]
    props.handleFormDataChanges('steps', newStepsArray)
  }

  //add or editin title
  const handleTextBox = (key, e, expansionID) => {
    const temparray = props.projectData.steps
    for (let i = 0; i < props.projectData.steps.length; i++) {
      if (props.projectData.steps[i].stepIndex === expansionID) {
        temparray[i][key] = e.target.value
      }
    }
    props.handleFormDataChanges('steps', temparray)
  }

  const handleRichtextInputChange = (data, key, expansionID) => {
    const temparray = props.projectData.steps
    for (let i = 0; i < props.projectData.steps.length; i++) {
      if (props.projectData.steps[i].stepIndex === expansionID) {
        temparray[i][key] = data
      }
    }
    props.handleFormDataChanges('steps', temparray)
  }


  //deleting the expansion
  const onDeleteAction = (e, index) => {
    e.stopPropagation()
    const currentArray = props.projectData.steps
    const updateArray = currentArray.filter(obj => {
      return obj.stepIndex !== index;
    })
    updateArray.map((e, i) => {
      e.stepIndex = i
    })
    props.handleFormDataChanges('steps', updateArray)
  }

  //Expansion Expanding handling
  const expansionHandleChange = (id) => {
    if (id !== state.selectedPanelId) {
      setState({
        ...state,
        selectedPanelId: id,
        expanded: !state.expanded
      })
      const root = document.getElementById('dialog-root');
      const targetOffset = id * 81 // 81 is the height of the expansion panel + margin below it
      setTimeout(() => root.scrollTo({ top: targetOffset - 10, behavior: 'smooth' }), 200)
    } else {
      setState({
        ...state,
        selectedPanelId: null,
        expanded: !state.expanded
      })
    }
  }

  const openVideoDailog = (stepIndex) => {
    setFocusedStepIndex(stepIndex)
    setState({
      ...state,
      openVideo: true
    })
  }

  const onVideoDialogClose = () => {
    setState({
      ...state,
      openVideo: false,
    })
  }

  const onVideoDialogAccept = (timeElapsed) => {
    setState({
      ...state,
      openVideo: false,
    })

    // Set the timeStampt for the current Step
    const temparray = props.projectData.steps
    for (let i = 0; i < props.projectData.steps.length; i++) {
      if (props.projectData.steps[i].stepIndex === focusedStepIndex) {
        // eslint-disable-next-line dot-notation
        temparray[i]['startTs'] = timeElapsed
      }
    }
    props.handleFormDataChanges('steps', temparray)
    setFocusedStepIndex(-1)
  }

  const renderVideoDailog = () => {
    return (<VideoTimeSelector open={state.openVideo}
      url={videoUrl}
      timeSelectorPopup={true}
      duration={Math.ceil(props.projectFull.durationMs / 1000)} // Inseconds
      durationMs={props.projectFull.durationMs}
      seekToVideoTimeInSeconds={(focusedStepIndex > -1 && props.projectData.steps[focusedStepIndex]) ?
        props.projectData.steps[focusedStepIndex].startTs : 0}
      onVideoDialogClose={onVideoDialogClose}
      onVideoDialogAccept={onVideoDialogAccept}
    ></VideoTimeSelector>
    )
  }

  const getFormattedDuration = (durationInSeconds) => {
    const duration = AppHelper.getFormattedDuration(durationInSeconds)
    return `${duration.hours}:${duration.minutes}:${duration.seconds}`
  }

  const onTab = (e) => {
    return true;
  }

  //Deletion of document
  const onFileDelete = (docsKey, expansionID) => {
    const currentStepsArray = props.projectData.steps
    let currentDoumentArray = []
    let currentStepIndex
    for (let i = 0; i < currentStepsArray.length; i++) {
      if (currentStepsArray[i].stepIndex === expansionID) {
        currentStepIndex = currentStepsArray[i].stepIndex
        currentDoumentArray = currentStepsArray[i].documents
      }
    }
    const updateDocumentArray = currentDoumentArray.filter(obj => {
      return obj.key !== docsKey;
    })
    currentStepsArray[currentStepIndex].documents = updateDocumentArray
    props.handleFormDataChanges('steps', currentStepsArray)
  }

  const handleUploadSuccess = (uploadedFiledList, expansionID) => {
    console.log('handleUploadSuccess uploadedFiledList', uploadedFiledList)

    let currentDocumetArray = []
    let currentStepIndex
    for (let i = 0; i < props.projectData.steps.length; i++) {
      if (props.projectData.steps[i].stepIndex === expansionID) {
        currentStepIndex = props.projectData.steps[i].stepIndex
        if (props.projectData.steps[i].documents !== undefined) {
          currentDocumetArray = props.projectData.steps[i].documents
        }
      }
    }
    if (!currentDocumetArray) {
      currentDocumetArray = []
    }
    const newArray = currentDocumetArray.concat(uploadedFiledList);

    const temparray = props.projectData.steps
    temparray[currentStepIndex].documents = newArray
    props.handleFormDataChanges('steps', temparray)
  }

  const handleStepImagesChange = (key, imageObjs, expansionID) => {
    const temparrayStep = props.projectData.steps
    temparrayStep[expansionID].imageObjs = imageObjs && imageObjs
    console.log('temp array', temparrayStep)
    setDisableButton(false)
    // props.wizardButtonsDisable(false)
    props.handleFormDataChanges('steps', temparrayStep)
  }

  const renderFileSizeErrorDialog = () => {
    return (
      <ErrorPopup
        dialogOpen={fileUploadFailedErrorPopup}
        title={Helper.getString('filestackUploadFailed')}
        descr={Helper.getString('filestackUploadFailedDescr')}
        onPopUpClose={() => setFileUploadFailedErrorPopup(false)} />
    )
  }

  const fileUploadUnderProgress = (value) => {
    setDisableButton(value) // This is for diasbling other buttions in steps and chapters
    props.wizardButtonsDisable(value)
  }

  const getDocumentUploader = (expansionID, documents) => {
    return (
      <FilestackFilesUploaderWrapper
        contentId={props.projectFull.contentId}
        item={docs} // Same
        //uploadDisabled={props.isInstructionalVideo}
        documents={documents}
        onFileDelete={(docsKey) => onFileDelete(docsKey, expansionID)}
        onUploadUnderProgress={fileUploadUnderProgress}
        handleUploadSuccess={(uploadedFiledList) => {
          handleUploadSuccess(uploadedFiledList, expansionID)
          fileUploadUnderProgress(false)
        }}
        handleUploadFailure={() => {
          setFileUploadFailedErrorPopup(true)
          fileUploadUnderProgress(false)
        }}
      />
    )
  }

  const stepImageWizardButtonsDisable = (value) => {
    props.wizardButtonsDisable(value)
    setDisableButton(value)
  }

  const refs = useRef([]);

  useEffect(() => {
    refs.current = refs.current.slice(0, props.projectData.steps.length);
  }, [props.projectData.steps.length]);

  const renderStepOrChapter = () => {
    console.log('step', props.projectData.steps)
    return props.projectData.steps && props.projectData.steps.map((e, index) =>
      <ExpansionPanel key={index} ref={el => refs.current[index] = el}
        expanded={state.selectedPanelId === e.stepIndex}
        onChange={() => expansionHandleChange(e.stepIndex)}
        classes={{ root: css(Styles.expansion) }}>
        <ExpansionPanelSummary expandIcon={<div className={css(Styles.expandMoreImageContainer)}>
          <img src={require('../../assets/images/downarrow.png')}
            width='35' height='35' alt={Helper.getString('downarrowImageAlt')}
            className={css(Styles.img)} />
        </div>}>
          <div className={css(Styles.expantionTitleContainer)}>
            <p className={css(Styles.expantionTitle)}>Step {index + 1}</p>
            <div onClick={(event) => onDeleteAction(event, e.stepIndex)} className={css(Styles.deleteIconContainer)}>
              <img src={require('../../assets/images/delete.png')}
                alt={Helper.getString('deleteImageAlt')}
                className={css(Styles.img)}
                width='35'
                height='35'
              />
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: css(Styles.expansionDetails) }} >
          <TextBoxs item={title}
            handleTextBox={handleTextBox}
            expansionID={e.stepIndex}
            value={e.title && e.title}
            textBoxStyle={Styles.textFeildStyle} />
          <p className={css(Styles.formTitiles)}>Video TimeStamp</p>
          <div className={css(Styles.timeStampContainer)}>
            <p className={css(Styles.labelDescr)}>Select a start time on the video for this step.</p>
            <div onClick={() => openVideoDailog(e.stepIndex)} className={css(Styles.timestamp)}>
              <div className={css(Styles.timestampIconContainer)}>
                <img src={require('../../assets/images/jumpto.png')}
                  alt={Helper.getString('timestampImageAlt')}
                  className={css(Styles.img)}
                  width='30'
                  height='30'
                />
              </div>
              <p className={css(Styles.timestampText)}> Select TimeStamp</p>
            </div>
            <div className={css(Styles.timeContainer)}>
              <p className={css(Styles.startTime)}>{getFormattedDuration(e.startTs)}</p> {/*TODO timestamp*/}
              {/* <p className={css(Styles.startTimeDescr)}>(Keep the timestamp as 00:00:00 for
                <span className={css(Styles.semiBoldText)} > all</span> steps if a Video Chapter list in the App is not required.)</p> */}
            </div>
          </div>
          {e.imageObjs &&
            <Fragment>
              <LabelAndDescription
                label={'IMAGES'}
                optional={true}
                descr={'Upload one or more images associated with this step.'}
              // disabled={props.isInstructionalVideo}
              />
              <StepsImageUploader
                stepLength={props.projectData.steps.length}
                signedUploadInfo={props.projectFull._uploadable}
                imageUrls={e.imageObjs}
                wizardButtonsDisable={stepImageWizardButtonsDisable}
                handleStepImagesChange={(key, imageObjs) => handleStepImagesChange(key, imageObjs, e.stepIndex)} />
            </Fragment>
          }
          <LabelAndDescription
            label={descrItem.label}
            optional={false}
            descr={descrItem.descr}
          // disabled={props.isInstructionalVideo}
          />
          <div className={css(Styles.richTextContainer)}>
            <RichtextEditor
              handleRichtextInputChange={(data, key) => handleRichtextInputChange(data, key, e.stepIndex)}
              current={descrItem}
              fullEditor={editorToolbar}
              toolbarClassName='sticky-toolbar-steps'
              onTab={onTab}
              value={e.descr}
              // disabled={props.isInstructionalVideo}
              placeholder={descrItem.placeholder}
              maxLength={descrItem.charachterLimit}
              htmlInlineStyle={HTML_FORMATTING}
            />
          </div>
          <LabelAndDescription
            label={tipsItem.label}
            optional={true}
            descr={tipsItem.descr}
          // disabled={props.isInstructionalVideo}
          />
          <div className={css(Styles.richTextContainer)}>
            <RichtextEditor
              handleRichtextInputChange={(data, key) => handleRichtextInputChange(data, key, e.stepIndex)}
              current={tipsItem}
              fullEditor={editorToolbar}
              toolbarClassName='sticky-toolbar-steps'
              onTab={onTab}
              value={e.tips}
              // disabled={props.isInstructionalVideo}
              maxLength={tipsItem.charachterLimit}
              htmlInlineStyle={HTML_FORMATTING}
            />
          </div>
          {getDocumentUploader(e.stepIndex, e.documents)}
        </ExpansionPanelDetails>
      </ExpansionPanel>)
  }

  return (
    <div className={css(Styles.container)} id='dialog-root'>
      <div className={css(Styles.innerContainer)}>
        {renderStepOrChapter()}
        <div className={css(Styles.buttonContainer)}>
          <AppButton
            key={'addStep'}
            disabled={disableButton}
            buttonStyle={Styles.buttonStyle}
            buttonTextStyle={Styles.buttonLabel}
            onClick={onAddAction}
            buttonText={'+ add step'}
          />
        </div>
      </div>
      {renderVideoDailog()}
      {renderFileSizeErrorDialog()}
    </div>
  )
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 35,
    alignItems: 'center',
    overflow: 'auto'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 775,
    marginBottom: 120,
  },
  expansion: {
    backgroundColor: '#1C1C1E',
    borderRadius: 12,
    border: '0.5px solid #FFFFFF33',
    // minWidth: 775,
    minHeight: 55,
    padding: 0,
    margin: 0,
    marginBottom: 20
  },
  expantionTitleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  expantionTitle: {
    fontSize: 18,
    flex: 1,
    color: '#ffffff',
    textTransform: 'uppercase',
    margin: 0
  },
  expansionDetails: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderTop: '0.5px solid #FFFFFF33',
    padding: '20px 30px'
  },
  formTitiles: {
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'uppercase',
    margin: 0,
    marginBottom: 5
  },
  timeStampContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 29
  },
  labelDescr: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    // marginTop: 4,
    // marginBottom: 10,
    maxWidth: 200
  },
  timestamp: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0.5px solid #FFFFFF33',
    borderRadius: 4,
    minWidth: 170,
    height: 80,
    marginLeft: 20,
    marginRight: 20,
    padding: 10,
    cursor: 'pointer'
  },
  timestampText: {
    fontSize: 14,
    color: '#FFFFFFFF',
    textTransform: 'uppercase',
    margin: 0,
    marginTop: 10,
    textAlign: 'center'
  },
  timestampIconContainer: {
    width: 30,
    height: 30,
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover'
  },

  textFeildStyle: {
    minHeight: 35,
    borderRadius: 4,
    border: '0.5px solid #FFFFFF80',
    marginBottom: 28
  },
  textInputStyles: {
    border: 'none',
    padding: '9px 8px',
    flex: 1,
    textAlign: 'left',
    // minHeight: 50,
    fontSize: 16,
    color: '#FFFFFF80',
    '::placeHolder': {
      color: '#FFFFFF80',
    }
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  },
  buttonStyle: {
    border: '0.5px solid #FFFFFF33',
    borderRadius: 4,
    backgroundColor: '#1C1C1E',
    minHeight: 35
  },
  buttonLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'unset',
    color: '#037BFE',
  },
  deleteIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 35,
    height: 35,
    zIndex: 130001
  },
  moreIcon: {
    fontSize: 35,
    color: '#ffffff'
  },
  videoDailogContainer: {
    display: 'flex',
    minWidth: 525,
    minHeight: 490,
    backgroundColor: '#1C1C1E',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center'
  },
  videoDailogOuterContainer: {
    zIndex: 13002
  },
  closeIconContainer: {
    width: 40,
    height: 40,
    cursor: 'pointer'
  },
  label: {
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'uppercase',
    margin: 0,
  },
  optionalText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    textTransform: 'lowercase'
  },
  richTextContainer: {
    marginBottom: 30,
    width: 716
  },
  expandMoreImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 35,
  },
  dialogButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: 5,
    right: 5
  },
  acceptIconContainer: {
    width: 40,
    height: 40,
    marginLeft: 20,
    cursor: 'pointer'
  },
  uploadedDocumentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20
  },
  uploadDocumentText: {
    fontSize: 18,
    color: '#FFFFFF80'
  },
  deleteImgContainer: {
    display: 'flex',
    cursor: 'pointer',
    width: 35,
    height: 35,
    zIndex: 130000,
  },
  uploadedDocumentOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  startTime: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
  },
  startTimeDescr: {
    fontSize: 12,
    color: '#FFFFFF80',
    margin: 0,
    marginTop: 5
  },
  semiBoldText: {
    fontWeight: 600
  }
})