import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { AppButton } from '../common/components/AppButton';

export function DeletePopup(props) {
  return (
    <Dialog open={props.openDelete}
      classes={{
        paper: css(Styles.deleteWarningDialog),
      }}
      className={css(Styles.deleteDialogContainer)}>
      <div className={css(Styles.deleteWarningContentContainer)}>
        <p className={css(Styles.deleteDialogTitle)}>{props.title}</p>
        <p className={css(Styles.deleteDialogDescr)}>{props.descr}</p>
        <p className={css(Styles.deleteDialogDescr)}>{props.descr2}</p>
        <p className={css(Styles.deleteDialogDescr, Styles.warnngText)}>{props.warningText}</p>
      </div>
      <div className={css(Styles.deleteButtonContainer)}>
        <AppButton
          disabled={props.deleteState === ApiHelper.State.LOADING ? true : false}
          buttonStyle={[Styles.buttonStyle,Styles.leftButton]}
          buttonTextStyle={Styles.buttonText}
          progressIcon={Styles.progressIcon}
          inProcess={props.deleteState === ApiHelper.State.LOADING ? true : false}
          buttonText={'Delete'}
          onClick={props.onDelete}
        />
        <AppButton
          disabled={props.deleteState === ApiHelper.State.LOADING ? true : false}
          buttonStyle={Styles.buttonStyle}
          buttonTextStyle={Styles.buttonText}
          buttonText={'Cancel'}
          onClick={props.onDeleteCancel}
        />
      </div>
    </Dialog>
  )
}

const Styles = StyleSheet.create({
  deleteDialogContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 13000
  },
  deleteWarningDialog: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    minWidth: 300,
    borderRadius: 8
  },
  deleteWarningContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 15,
    width: 300,
  },
  deleteImageContainer: {
    height: 50,
    width: 50,
  },
  deleteDialogTitle: {
    color: '#000000',
    fontSize: 16,
    margin: 0,
    fontWeight: 'bold',
    marginTop: 5,
    marginBottom: 10
  },
  deleteDialogDescr: {
    color: '#000000',
    fontSize: 14,
    textAlign: 'center',
    margin: 0,
    marginTop: 5,
    lineHeight: '19px',
    fontWeight: 600
  },
  warnngText: {
    fontWeight: 'bold',
    color: '#FF0000',
    marginTop: 8,
  },
  deleteButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #0000004D'
  },
  buttonStyle: {
    backgroundColor: '#ffffff',
    minWidth: 150,
    borderRadius: 0,
    display:'flex',
    flex: 1
  },
  leftButton: {
    borderRight: '1px solid #0000004D'
  },
  buttonText: {
    color: '#037BFE',
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  progressIcon: {
    color: '#037BFE'
  }
})

