import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helmet } from 'react-helmet';
import { MetaDataHelper } from '../helpers/MetaDataHelper';

export class HelmetComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    this.metaData = MetaDataHelper.processMetaData(this.props.page, this.props.metaData)
    return (
      <Helmet>
        <title>{this.metaData.title}</title>
        <meta name='description' content={this.metaData.description} />
        <meta name='keywords' content={this.metaData.keywords} />
      </Helmet>
    );
  }
}

const Styles = StyleSheet.create({

})
