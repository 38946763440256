import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

export function WarningPopup(props) {


  //rendering Warning Messages from here
  const getRequirdFieldMessages = () => {
    return (
      <Fragment>
        {props.validationContent.details && props.validationContent.details.length > 0 &&
          <div className={css(Styles.publishWarningTextContainer)}>
            {props.validationContent.details.map((item, index) => {
              return <p className={css(Styles.publishWarningText)}>Must have {item}</p>
            })
            }

          </div>
        }
        {props.validationContent.images && props.validationContent.images.length > 0 &&
          <div className={css(Styles.publishWarningTextContainer)}>
            {props.validationContent.images.map((item, index) => {
              return <p className={css(Styles.publishWarningText)}>Must have {item}</p>
            })
            }
          </div>
        }
        {props.validationContent.materials && props.validationContent.materials.length > 0 &&
          <div className={css(Styles.publishWarningTextContainer)}>
            {props.validationContent.materials.map((item, index) => {
              return <p className={css(Styles.publishWarningText)}>Must have {item}</p>
            })
            }
          </div>
        }
        {props.validationContent.steps && props.validationContent.steps.length > 0 &&
          <div className={css(Styles.publishWarningTextContainer)}>
            {props.validationContent.steps.map((item, index) => {
              return <p className={css(Styles.publishWarningText)}>Must have {item}</p>
            })}
          </div>
        }
        {props.validationContent.stepsStartTsErrorMessages &&
          props.validationContent.stepsStartTsErrorMessages.length > 0 &&
          <div className={css([Styles.publishWarningTextContainer, Styles.topMarginStyles])}>
            {props.validationContent.stepsStartTsErrorMessages.map((item, index) => {
              return <p className={css(Styles.publishWarningText)}>{item}</p>
            })}
          </div>
        }
      </Fragment >)
  }

  const showValidationMessages = () => {
    return (
      <Fragment>
        {props.validationWarningMessages.messages && props.validationWarningMessages.messages.length > 0 &&
          <div className={css(Styles.publishWarningTextContainer)}>
            {props.validationWarningMessages.messages.map((item, index) => {
              return <p className={css(Styles.publishWarningText)}>Must have {item}</p>
            })
            }

          </div>
        }
      </Fragment>
    )
  }

  return (
    <Dialog open={props.dialogOpen}
      classes={{
        paper: css(Styles.publishWarningDialog),
      }}
      className={css(Styles.publishDialogContainer, props.popUpDialogStyle)}>
      <div onClick={props.onPopUpClose} className={css(Styles.closeIconContainer)}>
        <img src={require('../assets/images/close.png')}
          alt={Helper.getString('closeImageAlt')}
          className={css(Styles.img)}
          width='35'
          height='35'
        />
      </div>
      <div className={css(Styles.publishWarningTitleContainer)}>
        <div className={css(Styles.publishImageContainer)}>
          <img src={props.image}
            width='50' height='50' alt={Helper.getString('processImageAlt')}
            className={css(Styles.img)} />
        </div>
        <p className={css(Styles.publishDialogTitle)}>{props.popUpTitle}</p>
      </div>
      <p className={css(Styles.publishDialogDescr, props.publishWarningDialogDescr)}>{props.popUpDescr}</p>
      {props.validationSingleContent &&
        <div className={css(Styles.publishWarningTextContainer)}>
          <p className={css(Styles.publishWarningText)}>{props.validationSingleContent}</p>
        </div>
      }
      {props.validationContent &&
        getRequirdFieldMessages()
      }
      {props.validationWarningMessages &&
        showValidationMessages()
      }
    </Dialog>
  )
}

const Styles = StyleSheet.create({
  publishDialogContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 13000,
    marginTop: 55 + 22 + 40, //marginTop above horizontal stepper + horizontal stepper height + 40
    marginBottom: 55 + 22 + 40,
  },
  publishWarningDialog: {
    display: 'flex',
    position: 'relative',
    backgroundColor: '#000000',
    // width: 443,
    paddingTop: 35,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 48,
    border: '1px solid #FFFFFF80',
    borderRadius: 8
  },
  closeIconContainer: {
    width: 35,
    height: 35,
    position: 'absolute',
    top: 6,
    right: 7,
    cursor: 'pointer'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  publishWarningTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  publishImageContainer: {
    height: 50,
    width: 50,
  },
  publishDialogTitle: {
    color: '#ffffff',
    fontSize: 18,
    margin: 0,
    fontWeight: 'bold',
    marginTop: 5,
    marginBottom: 10
  },
  publishDialogDescr: {
    color: '#ffffff',
    fontSize: 18,
    textAlign: 'left',
    margin: 0,
    marginTop: 10,
    marginBottom: 13,
    maxWidth: 360
  },
  publishWarningTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 13,
    marginBottom: 13
  },
  topMarginStyles: {
    marginTop: 0
  },
  publishWarningText: {
    fontSize: 18,
    color: '#ffffff',
    margin: 0,
    maxWidth: 360 //298
  },
})