import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Login } from './Login'
import { CommonStyles } from '../styles/Styles';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import { Helper } from '../common/helpers/Helper';

export class LoginDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Dialog
        open={this.props.openDialog}
        disableBackdropClick={true}
        scroll='paper'
        classes={{
          paperWidthSm: css(Styles.dialog),
          scrollPaper: css(Styles.scrollPaper)
        }}
        onClose={this.props.onClose}>
        {/* <div className={(css(Styles.dialogClosebuttonContainer))}>
          <Icon onClick={() => this.props.onClose()}>close</Icon>
        </div> */}

        {/* <div onClick={() => this.props.onClose()}
          className={css(Styles.closeIconContainer)}>
          <img src={require('../assets/images/close.png')}
            alt={Helper.getString('closeImageAlt')}
            className={css(Styles.img)}
            width='30'
            height='30'
          />
        </div> */}
        <Login
          jsonBody={this.props.jsonBody}
          showTerms={true}
          loginPanelStyle={Styles.loginContainer}
          onSuccessLogin={this.props.onSuccessLogin}>
        </Login>
      </Dialog>
    )
  }
}

const Styles = StyleSheet.create({
  loginContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#02121D',
    padding: '39px 80px 20px'
  },
  dialog: {
    backgroundColor: '#02121D',
    borderRadius: 8,
  },
  dialogClosebuttonContainer: {
    paddingRight: 10,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: -40,
    zIndex: 1,
    cursor: 'pointer'
  },
  closeIconContainer: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: 9,
    right: 8,
    cursor: 'pointer'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
})