/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
import { StringTableCommon } from './common/StringTableCommon'
export const StringTable = [
  ...StringTableCommon,
  {
    "key": "sampleStrKey",
    "en": "Sample"
  },
  {
    "key": "logoAlt",
    "en": "Logo"
  },
  {
    "key": "playStoreAlt",
    "en": "Playstore image"
  },
  {
    "key": "appStoreAlt",
    "en": "IOS Appstore image"
  },
  {
    "key": "bgImageAlt",
    "en": "Background image"
  },
  {
    "key": "pageNotFoundTitle",
    "en": "Page you are trying to access, doesn't exist."
  },
  {
    "key": "pageNotFoundSubtitle",
    "en": "Check the link and try again."
  },
  {
    "key": "videoImageAlt",
    "en": "Video thumbnail"
  },
  {
    "key": "ratingImageAlt",
    "en": "Rating Icon Image"
  },
  {
    "key": "viewImageAlt",
    "en": "View Icon Image"
  },
  {
    "key": "dotImageAlt",
    "en": "Dot Icon Image"
  },
  {
    "key": "helpImageAlt",
    "en": "Help Icon Image"
  },
  {
    "key": "edit",
    "en": "EDIT"
  },
  {
    "key": "delete",
    "en": "DELETE"
  },
  {
    "key": "closeImageAlt",
    "en": "Close"
  },
  {
    "key": "editImageAlt",
    "en": "Edit"
  },
  {
    "key": "deleteImageAlt",
    "en": "Delete"
  },
  {
    "key": "profileIconImageAlt",
    "en": "Profile Icon Image"
  },
  {
    "key": "profilePicAlt",
    "en": "Profile Pic"
  },
  {
    "key": "emptyMsg",
    "en": "No content available"
  },
  {
    "key": "wizardClose",
    "en": "Close"
  },
  {
    "key": "logout",
    "en": "LOG OUT"
  },
  {
    "key": "login",
    "en": "Log In / Sign Up"
  },
  {
    "key": "loginWelcome",
    "en": "RAZZL CREATOR PORTAL"
  },
  {
    "key": "welcomeNote",
    "en": "Enter your email address to log in or sign up."
  },
  {
    "key": "weSendyouEmail",
    "en": "WE SENT YOU AN EMAIL"
  },
  {
    "key": "otpNote",
    "en": "Please enter the one-time password we sent you at "
  },
  {
    "key": "backImageAlt",
    "en": "Back Arrow Image"
  },
  {
    "key": "uploadedThumImageAlt",
    "en": "Uploaded Thumb Image"
  },
  {
    "key": "deleteAssetIconAlt",
    "en": "Delete asset"
  },
  {
    "key": "coinImageAlt",
    "en": "Razzl Coin"
  },
  {
    "key": "dollarIconImageAlt",
    "en": "Dollar Image"
  },
  {
    "key": "forwardImageAlt",
    "en": "Forward"
  },
  {
    "key": "backwardImageAlt",
    "en": "Backward"
  },
  {
    "key": "playImageAlt",
    "en": "Play"
  },
  {
    "key": "pauseImageAlt",
    "en": "Pause"
  },
  {
    "key": "processImageAlt",
    "en": "Process Image"
  },
  {
    "key": "publishVideo",
    "en": "Publishing Video"
  },
  {
    "key": "dropdownarrowImageAlt",
    "en": "Drop Down Arrow"
  },
  {
    "key": "downarrowImageAlt",
    "en": "Down Arrow"
  },
  {
    "key": "creatorPortal",
    "en": "Creator Portal"
  },
  {
    "key": "settingsImageAlt",
    "en": "Settings"
  },
  {
    "key": "permanentlyDeleteVideo",
    "en": "Permanently delete video?"
  },
  {
    "key": "codeMismatchException",
    "en": "The one-time password you’ve entered is incorrect."
  },
  {
    "key": "uploadVideoLengthError",
    "en": "Uploaded video length error"
  },
  {
    "key": "uploadVideoLengthErrorStr",
    "en": "Video length should be between 1 minute and 2 hours."
  },
  {
    "key": "uploadVideoResolutionError",
    "en": "Uploaded video resolution error"
  },
  {
    "key": "uploadVideoResolutionErrorStr",
    "en": "Video resolution should be 720p, 1080p or 4K."
  },
  {
    "key": "linkImageAlt",
    "en": "Link"
  },
  {
    "key": "payPalImageAlt",
    "en": "PayPal Logo"
  },
  {
    "key": "profileSaveSuccess",
    "en": "Profile information saved"
  },
  {
    "key": "downloadsArrowImageAlt",
    "en": "Downloads Arrow"
  },
  {
    "key": "publishedAndPurchasedVideoEditWarning",
    "en": "This video or its downloads were purchased!"
  },
  {
    "key": "publishedAndPurchasedVideoEditWarningDescr",
    "en": "Viewers have purchased either this video or its downloads.\nPlease make only necessary changes to the video information."
  },
  {
    "key": "purchasesAgainstVideoWarningDescr",
    "en": "There are purchases against this video."
  },
  {
    "key": "purchasesAgainstVideoWarning",
    "en": "Video cannot be deleted."
  },
  {
    "key": "secondsLimitExceeded",
    "en": "Seconds should not exceed 59"
  },
  {
    "key": "minutesLimitExceeded",
    "en": "Minutes should not exceed 59"
  },
  {
    "key": "hoursLimitExceed",
    "en": "Hours should not exceed 2"
  },
  {
    "key": "selectedTimeExceedOverVideoDuration",
    "en": "Selected time exceeds video duration"
  },
  {
    "key": "fileCouldNotBeUploaded",
    "en": "File could not be uploaded"
  },
  {
    "key": "filestackUploadFailed",
    "en": "Upload failed",
  },
  {
    "key": "filestackUploadFailedDescr",
    "en": "One or more files failed to upload."
  },
  {
    "key": "getStarted",
    "en": "Get started"
  },
  {
    "key": "fileSizeExceedMsg",
    "en": "MB is the maximum image file size that can be uploaded."
  },
  {
    "key": "contentNotAvailable",
    "en": "Content is not available."
  },
]