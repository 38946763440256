import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export const Toast = (props) => {
  console.log('close log value of openToast in toast', props.openToast)
  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={props.handleClose}
    >
      <Alert
        // onClose={props.handleClose}
        classes={{
          filledInfo: css(Styles.info),
          filledSuccess: css(Styles.success),
          filledError: css(Styles.error,props.error)
        }}
        severity={props.severity}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}

const Styles = StyleSheet.create({
  success: {
    color: 'white',
    borderRadius: 4
  },
  error: {
    backgroundColor: '#D50000',
    color: 'white',
    borderRadius: 4
  },
  info: {
    backgroundColor: '#000000',
    color: '#ffffff',
    borderRadius: 4
  }
})
