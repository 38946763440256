import { StyleSheet } from 'aphrodite';
import { AppTheme, AppStyles } from '../styles/AppTheme';

// Styles to be reused between more than one component
export const CommonTheme = {
  textColor: '#222222',
  subTextColor:'#7D7D7D',
  containerBorderColor: '1px solid #E2EAFC',
  outerPaddingWeb: 80,
  outerPaddingMob: 20
}

export const CommonStyles = StyleSheet.create({
  textfulPageContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    textAlign: 'left',
    maxWidth: AppTheme.viewPort,
    backgroundColor: '#02121d',
    borderRadius: 4,
    position: 'relative',
    marginTop: 70
  },
  textfulPageContentInnerContainer: {
    margin: '39px 123px',
    '@media (max-width: 768px)': {
      margin: '20px 15px',
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      margin: '20px 32px',
    }
  },
  textfulPageSubtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
    marginTop: 20,
    marginBottom: 20,
    '@media (max-width: 767px)': {
      fontSize: 15
    },
  },
  textfulPagePara: {
    fontSize: 15,
    color: '#fff',
    marginTop: 20,
    textAlign: 'left',
    '@media (max-width: 767px)': {
      fontSize: 14
    },
  },
  boldText: {
    fontWeight: 'bold'
  },
  logoImage: {
    height: 40
  },
  linkTextColor: {
    color: '#fff'
  },
  title: {
    fontSize: 25,
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'normal',
    margin: 0,
    marginBottom: 32,
    '@media (max-width: 767px)': {
      fontSize: 22
    }
  },
  dotColor: {
    color: '#fff'
  },
  container: {
    position: 'relative',
    paddingBottom: 0,
    padding: '0px 60px',
    '@media (max-width: 1024px)': {
      padding: '0px 20px',
    }
  },
  footerContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 767px)': {
      position: 'unset',
      bottom: '0',
      backgroundColor: '#000',
      width: 'unset',
    },
  },
  footerContainerV2: {
    marginRight: -20,
    marginLeft: -20
  },
  linkText:{
    color: '#FEE25D',
    ':active': {
      '-webkit-tap-highlight-color': 'transparent'
    }
  },
  formTitiles: {
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'uppercase',
    margin: 0,
    marginBottom: 10
  },
  buttonStyle: {
    borderRadius: 4,
    maxWidth: 120
  },
});
