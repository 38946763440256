import React, { useState, useEffect, useContext, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Controller } from '../common/Controller';
import { AppConfig } from '../AppConfig';
import { AppTheme } from '../styles/AppTheme';
import clsx from 'clsx';
import { Helper } from '../common/helpers/Helper';
import Collapse from '@material-ui/core/Collapse';
import { AppContext } from '../common/contexts/AppContext';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { AppStyleHelper } from '../common/helpers/AppStyleHelper';
import { UiHelper } from '../common/helpers/UiHelper';
import { AppButton } from '../common/components/AppButton';

const ITEM_FONT_SIZE = AppConfig.DRAWER_STYLE === 'TYPE1' ? 30 : 16
const MENU_TITLE_SIZE = AppConfig.DRAWER_STYLE === 'TYPE1' ? 12 : 16
const SUPPORT_EMAIL = 'creatorsupport@razzl.com'

const TEXT_COLOUR = '#FFFFFF80'
const useStyles = makeStyles((theme) => {
  const st = {
    drawer: {
      width: AppTheme.drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      backgroundColor: '#FFFFFF',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: AppTheme.drawerWidth,
        flexShrink: 0,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      position: 'relative'
    },
    drawerOpen: {
      width: AppTheme.drawerWidth,
      backgroundColor: '#000000',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflow: 'visible',
      zIndex: 12000,
      [theme.breakpoints.between('100', '1025')]: {
        width: '100%',
        overflowY: 'scroll',
      }
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(10) + 20,
      },
      [theme.breakpoints.between('100', '1025')]: {
        width: 0
      }
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }
  return AppStyleHelper.modifyStyleSheet(st, { theme, comp: 'drawerMenuDashboard', type: 'drawerStyle' })
});

export function DrawerMenuDashboard(props) {
  const [state, setState] = useState({
    drawerIsOpen: window.innerWidth <= 1024 ? false : true,
  })

  const { appData, appContextStateHandler } = useContext(AppContext)


  const classes = useStyles();
  const theme = useTheme();

  // useEffect(() => {
  //   props.menuItems.map(items => {
  //     items.map(listItems => {
  //       listItems.subItems && listItems.subItems.map(subListItems => {
  //         subListItems.key === appData && appData.pageKey &&
  //           setState({
  //             ...state,
  //             openId: listItems.key
  //           })
  //       })
  //     })
  //   })
  // }, [appData && appData.pageKey])

  const onMenuClick = (item, closeCollapse) => {
    // if (closeCollapse)
    //   setState({
    //     ...state,
    //     openId: null
    //   })
    // if (window.innerWidth <= 1024) {
    //   toggleDrawer()
    // }
    // if (item.type === 'LOGOUT') {
    //   Controller.get().logout()
    // } else {
    Controller.get().pushRoute(item.route)
    // }
  }

  const toggleDrawer = () => {
    props.drawerIsOpen(!state.drawerIsOpen)
    setState({
      ...state,
      drawerIsOpen: !state.drawerIsOpen
    })
  }

  const styleConditionCheck = (key) => {
    console.log('appdata::', appData && appData.pageKey)
    if (appData && appData.pageKey === key) {
      return true
    } else {
      return false
    }
  }

  const accordingListClick = (e) => {
    const openId = state.openId
    if (openId === e) {
      setState({
        ...state,
        openId: null
      });
    } else {
      setState({
        ...state,
        openId: e
      });
    }
  };

  const menuRowItem = (item) => {
    console.log('menurow app data ::', appData && appData.pageKey)
    return (
      <Fragment>
        {/* {AppConfig.DRAWER_STYLE !== 'TYPE1' &&
          <div className={styleConditionCheck(item.key) ? css([Styles.listStyle, Styles.listSelectedStyle]) : css(Styles.listStyle)} />
        } */}
        <ListItem button className={getListStyle(item)}
          classes={{ root: css(Styles.listItemPadding) }}>
          {/* {AppConfig.DRAWER_STYLE === 'TYPE1' && */}
          <div className={css(Styles.iconImageContainer)}>
            <img src={getListIcon(item)}
              width='35' height='35' alt='todo'
              className={css(Styles.img)} />
          </div>
          {/* } */}
          <ListItemText
            disableTypography
            className={css(Styles.listItemText)}
            primary={<Typography type='body2' className={getListTitleStyle(item)}>
              {item.title}
            </Typography>} />
        </ListItem>
      </Fragment>
    )
  }

  // const accordingListItems = (data, index) => {
  //   return (
  //     <div key={index}>
  //       <div onClick={() => accordingListClick(data.key)} className={css(Styles.listInnerContainer)}>
  //         {menuRowItem(data)}
  //       </div>
  //       <Collapse
  //         className={css(Styles.accordingListContainer)}
  //         key={data.key}
  //         component='li'
  //         in={state.openId === data.key}
  //         timeout='auto'
  //         unmountOnExit>
  //         <List disablePadding>
  //           {data.subItems.map((nsItem, index) => {
  //             return (
  //               <div onClick={() => onMenuClick(nsItem)} key={index} className={css(Styles.listInnerContainer)}>
  //                 {menuRowItem(nsItem)}
  //               </div>
  //             )
  //           })}
  //         </List>
  //       </Collapse>
  //     </div>
  //   )
  // }

  const getListStyle = (data) => {
    // console.log('data key::', data)
    if (styleConditionCheck(data.key)) {
      if (AppConfig.DRAWER_STYLE === 'TYPE1') {
        return css([Styles.listButton2, Styles.listSelectedButton2])
      } else {
        return css([Styles.listButton, Styles.listSelectedButton])
      }
    } else {
      if (AppConfig.DRAWER_STYLE === 'TYPE1') {
        return css(Styles.listButton2)
      } else {
        return css(Styles.listButton)
      }
    }
  }

  const getListTitleStyle = (data) => {
    if (styleConditionCheck(data.key)) {
      // if (AppConfig.DRAWER_STYLE === 'TYPE1') {
      //   return css([Styles.listColor, Styles.listSelectedColor2])
      // } else {
      return css([Styles.listColor, Styles.listSelectedColor])
      // }
    } else {
      return css(Styles.listColor)
    }
  }

  const getListIcon = (data) => {
    console.log('styled condition check key::', styleConditionCheck(data.key))
    if (styleConditionCheck(data.key)) {
      // if (AppConfig.DRAWER_STYLE === 'TYPE1') {
      //   return css([Styles.listColor, Styles.listSelectedColor2])
      // } else {
      return data.whiteIcon
      // }
    } else {
      // console.log('data key::', data.grayIcon)
      return data.grayIcon
    }
  }

  const getListIconStyle = (data) => {
    const iconStyle = { fontSize: ITEM_FONT_SIZE }
    if (styleConditionCheck(data.key)) {
      if (AppConfig.DRAWER_STYLE === 'TYPE1') {
        iconStyle.color = AppTheme.primaryColor
        iconStyle.marginBottom = 10
        return iconStyle
      } else {
        return iconStyle
      }
    } else {
      if (AppConfig.DRAWER_STYLE === 'TYPE1') {
        iconStyle.marginBottom = 10
        return iconStyle
      } else {
        return iconStyle
      }
    }
  }

  const listItems = (data, index) => {
    return (
      <div
        onClick={() => onMenuClick(data, true)}
        key={index} className={css(Styles.listInnerContainer)}>
        {menuRowItem(data)}
      </div>
    )
  }

  const renderList = () => {
    const items = props.menuItems ? props.menuItems : []
    const appUser = Controller.get().userMgr().getAppUser()
    return (
      state.drawerIsOpen &&
      <List className={css(Styles.list)}>
        {items.map((item, index) => {
          return (
            <div key={index}>
              {item.map((data, index) => {
                // if (data.subItems && data.subItems.length > 0) {
                //   return accordingListItems(data, index)
                // } else {
                return listItems(data, index)
                // }
              })}
              <Divider className={css(Styles.divider)} />
            </div>
          )
        })}

        {props.onLogoutClick &&
          <Fragment>
            {/* <div button className={css(Styles.listButton, Styles.noHover)}>
              <ListItemIcon className={css(Styles.linkcolor)}>
                <i className='material-icons-outlined' style={{ fontSize: ITEM_FONT_SIZE }}>person_outline</i>
              </ListItemIcon>
              <ListItemText
                className={css(Styles.listitem)}
                disableTypography
                primary={<Typography type='body2' className={css(Styles.userDetail)}>
                  {appUser.email || appUser.phone}
                </Typography>} />
            </div> */}

            <div onClick={props.onLogoutClick} className={css(Styles.listInnerContainer)}>
              {/* <div className={css(Styles.listStyle)} />
              <ListItem button className={css(Styles.listButton)}>
                {AppConfig.DRAWER_STYLE === 'TYPE1' &&
                  <ListItemIcon className={css(Styles.linkcolor)}>
                    <i className='material-icons-outlined' style={{ fontSize: ITEM_FONT_SIZE }}>exit_to_app</i>
                  </ListItemIcon>
                }
                <ListItemText disableTypography className={css(Styles.listItemText)}
                  primary={<Typography type='body2' className={css(Styles.listColor)}>Logout</Typography>} />
              </ListItem> */}
              {/* {menuRowItem({
                key: 'logout',
                title: Helper.getString('logout'),
                icon: 'exit_to_app',
              })} */}
            </div>

            {/* <Divider className={css(Styles.divider)} /> */}
          </Fragment>
        }
        {/* <Link to='/'>
          <div className={css(Styles.homeButtonContainer)}>
            <div className={css(Styles.iconImageContainer)}>
              <img src={require('../assets/images/home-gray.png')}
                width='35' height='35' alt='todo'
                className={css(Styles.img)} />
            </div>
            <Typography type='body2' className={css(Styles.listColor)}>
              {'Home'}
            </Typography>
          </div>
        </Link> */}
        <div className={css(Styles.supportTextContainer)}>
          {/* <AppButton buttonStyle={Styles.buttonStyle} buttonTextStyle={Styles.buttonTextStyle} buttonText='Beta Release' /> */}
          <p className={css(Styles.supportText)}>For assistance from our support team, please contact us at
            <a href={'mailto:' + SUPPORT_EMAIL} className={css(Styles.supportEmail)}> creatorsupport@razzl.com</a></p>
        </div>
        {/* <div className={css(Styles.versionContainer)}>{Helper.getString('version')}
          <span className={css(Styles.version)}>{AppConfig.VERSION}</span>
        </div> */}

      </List>
    )
  }

  return (
    <Fragment>
      <div className={css(Styles.drawerMobileView)} >
        <div className={css(Styles.drawerButtonMobileView)}>
          <div className={css(Styles.drawerButtonContainer)} onClick={() => toggleDrawer()}>
            {UiHelper.renderImg({ key: 'drawerMenu', drawerIsOpen: state.drawerIsOpen })}
          </div>
        </div>
      </div>
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: state.drawerIsOpen,
          [classes.drawerClose]: !state.drawerIsOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: state.drawerIsOpen,
            [classes.drawerClose]: !state.drawerIsOpen,
          }),
        }}
        variant='permanent'
      >
        {state.drawerIsOpen &&
          <div className={css(Styles.drawerButtonFixContainer)}>
            <div className={css(Styles.drawerButtonContainer)} onClick={() => toggleDrawer()}>
              {UiHelper.renderImg({ key: 'drawerClose' })}
            </div>
          </div>
        }
        {state.drawerIsOpen ?
          <Link to={Controller.get().getHomeByRole()}>
            <div className={css(Styles.drawerImageContainer)}>
              <div className={css(Styles.drawerImageInnerContainer)}>
                <img
                  src={require('../assets/images/logo-group.png')}//{AppConfig.LOGO}
                  alt='Logo' className={css(Styles.drawerImage)} />
              </div>
            </div>
          </Link>
          :
          <div className={css(Styles.drawerCloseButtonContainer)}>
            <div className={css(Styles.drawerButtonContainer)} onClick={() => toggleDrawer()}>
              {UiHelper.renderImg({ type: 'drawerOpen' })}
            </div>
          </div>
        }

        <div tabIndex={0}
          role='button'
          className={AppConfig.DRAWER_STYLE === 'TYPE1' ? css(Styles.switchableDrawer2) : css(Styles.switchableDrawer)}>
          {renderList()}
        </div>
      </Drawer>
    </Fragment>
  );
}


const DRAWER_BUTTON_MARGN_TOP = 55//38
const Styles = AppStyleHelper.modifyStyleSheet({
  switchableDrawer: {
    overflowY: 'auto',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0px 30px',
    outline: 'none',
    '@media (max-width: 1024px)': {
      justifyContent: 'center',
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  switchableDrawer2: {
    overflowY: 'auto',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0px 0px',
    outline: 'none',
    '@media (max-width: 1024px)': {
      justifyContent: 'center',
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  switchableListDrawer: {
    paddingLeft: 0,
  },
  menuButtonAlign: {
    '@media (max-width: 767px)': {
      position: 'fixed',
      right: 0,
      top: 10,
      width: '100%'
    }
  },
  menuButton: {
    color: AppTheme.primaryColor,
    marginLeft: 0,
    marginRight: 5,
  },
  swipe: {
    zIndex: 100000000
  },
  swipedrawer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 20,
    paddingRight: 20,
    overflowX: 'auto',
  },
  list: {
    // maxWidth: 215,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    '@media (max-width: 768px)': {
      width: 'unset',
    }
  },
  drawer: {
    marginTop: 35
  },
  versionContainer: {
    padding: '8px 30px',
    margin: 0,
    fontSize: 12,
    color: TEXT_COLOUR,
    paddingBottom: 30,
  },
  link: {
    textDecoration: 'none',
  },
  linkcolor: {
    color: '#6E84A3',
    marginRight: 0,
    minWidth: 30
  },
  listitem: {
  },
  listButton: {
    // padding: '13px 20px',
    alignItems: 'left',
    color: TEXT_COLOUR,
    borderRadius: 10,
    ':active': {
      backgroundColor: 'none'
    }
  },
  listButton2: {
    // padding: '13px 20px',
    color: TEXT_COLOUR,
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    ':active': {
      backgroundColor: 'none'
    }
  },
  listSelectedButton: {
    // backgroundColor: AppTheme.primaryColor
  },
  listSelectedButton2: {
    borderRight: '2px solid ' + AppTheme.primaryColor
  },
  appVersion: {
    color: '#6E84A3',
    fontSize: MENU_TITLE_SIZE,
    paddingLeft: 28
  },
  divider: {
    color: '#edf2f9',
    margin: '10px 0px'
  },
  version: {
    backgroundColor: 'whitesmoke',
    borderRadius: 8,
    color: 'black',
    marginLeft: 5,
    padding: 5,
    fontSize: 12
  },
  userDetail: {
    margin: 0,
    fontSize: 12,
    color: '#6E84A3'
  },
  noHover: {
    cursor: 'text',
    padding: '0px 30px'
  },
  drawerImageContainer: {
    // minHeight: AppTheme.toolbarHeight,
    display: 'flex',
    alignItems: 'center',
    padding: '21px 30px',
    justifyContent: 'left'
  },
  drawerImageInnerContainer: {
    width: 160,
    height: 32
  },
  drawerCloseButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: DRAWER_BUTTON_MARGN_TOP
  },
  drawerButtonContainer: {
    width: 34,
    height: 34,
    cursor: 'pointer'
  },
  drawerButtonImage: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  drawerImage: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  drawerButtonFixContainer: {
    position: 'absolute',
    right: -20,
    top: DRAWER_BUTTON_MARGN_TOP,
    display: 'none'
  },

  listInnerContainer: {
    display: 'flex',
    // alignItems: 'center',
    margin: '5px 0px'
  },
  listStyle: {
    width: 4,
    height: 16,
    borderRadius: 2,
    marginRight: 6,
  },
  listSelectedStyle: {
    backgroundColor: AppTheme.primaryColor,
  },
  listItemText: {
    lineHeight: 'unset',
    margin: 0,
    display: 'grid'
  },
  listSelectedColor: {
    color: AppTheme.secondaryBg,
  },
  listSelectedColor2: {
    color: AppTheme.primaryColor,
  },
  listColor: {
    color: TEXT_COLOUR,
    textTransform: 'uppercase',
    fontSize: 20,//MENU_TITLE_SIZE,
    lineHeight: 'unset',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 7
  },
  accordingListContainer: {
    marginLeft: 20
  },
  drawerMobileView: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block'
    }
  },
  drawerButtonMobileView: {
    position: 'absolute',
    right: 60,
    top: 5,
    zIndex: 100000000,
    '@media (max-width: 767px)': {
      right: 10,
    }
  },
  homeButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 84
  },
  listItemPadding: {
    padding: '20px 0px 14px'
  },
  iconImageContainer: {
    width: 35,
    height: 35
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  supportTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 39,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 105
  },
  buttonStyle: {
    backgroundColor: '#FFFFFF1A',
    height: 37,
    minWidth: 150,
    borderRadius: 35,
    padding: '9px 16px',
    cursor: 'default'
  },
  buttonTextStyle: {
    color: '#037BFE',
    textTransform: 'uppercase',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 500
  },
  supportText: {
    margin: 0,
    fontSize: 14,
    textAlign: 'left',
    color: '#FFFFFF80',
    whiteSpace: 'normal',
    lineHeight: '19px'
  },
  supportEmail: {
    color: '#037BFE',
    textDecoration: 'none'
  }
}, { comp: 'drawerMenuDashboard' })
