import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { AppTheme, AppStyles } from '../styles/AppTheme';
import { Helper } from '../common/helpers/Helper';

import { ApiHelper } from '../common/helpers/ApiHelper';
import { Button, CircularProgress, Dialog, MenuItem, Popover, Step, StepLabel, Stepper, Tooltip } from '@material-ui/core';
import { Headerbar } from '../common/components/Headerbar';
import { Satellite } from '@material-ui/icons';
import { AppContext } from '../common/contexts/AppContext';
// import { UploadedVideoWizardDialog } from './uploadVideoWizard/UploadedVideoWizardDialog';
import { Controller } from '../common/Controller';
import { AppHelper } from '../helpers/AppHelper';
import { ProjectHelper } from '../helpers/ProjectHelper';
import { HIDE_DONOTREMIND_VIDEOEDIT, MODES, PRICINGTYPES } from '../Consts';
import { Toast } from '../components/Toast';
import { ErrorPopup } from '../components/ErrorPopup';
import { AppButton } from '../common/components/AppButton';
import { DeletePopup } from '../components/DeletePopup';
import { UploadedVideoWizardDialog } from './uploadVideoWizard/UplodedVideoWizardDialog';
import { EditWarningPopup } from '../components/EditWarningPopup';
import { CommonStyles } from '../styles/Styles';
import copy from 'copy-to-clipboard';

export function UploadedVideos(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const [state, setState] = useState({
    fetchState: ApiHelper.State.LOADING,
  })
  const [selectedProject, setSelectedProject] = useState(null)
  const [openOption, setOpenOption] = useState(false)
  const [openVideoWizard, setOpenVideoWizard] = useState(false)
  const [fileStackResponse, setFileStackResponse] = useState({})
  const [openToast, setOpenToast] = useState(false)
  const [openErrorPopup, setOpenErrorPopup] = React.useState(false);
  const [fileUploadErrMsg, setFileUploadErrMsg] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false)
  const [deleteState, setDeleteState] = useState()
  const [errMsg, setErrMsg] = useState()
  const [openDeleteErrorToast, setDeleteErrorToast] = useState(false)
  const [openEditWarningPopup, setEditWarningPopup] = useState(false)
  const [checked, setIsChecked] = useState();

  const [activeTooltipContentId, setActiveTooltipContentId] = useState(null);
  const [linkFetchState, setLinkFetchState] = useState(ApiHelper.State.READY);
  const [linkFetchError, setLinkFetchError] = useState(null);

  const [openProfileIncompleteWarning, setOpenProfileIncompleteWarning] = useState(false)
  const [openWarning, setOpenWarning] = React.useState(false);
  const [warningContent, setWarningContent] = React.useState({ errTitle: '', errorDescr: '', errorWarning: '', buttonText: '' });

  const currentUserId = Controller.get().userMgr().getUserId()
  const appUser = Controller.get().userMgr().getAppUser()
  console.log('appuser', appUser)
  useEffect(() => {
    appContextStateHandler({ pageKey: 'videos' })
    fetchData()
  }, [])

  async function fetchData() {
    setState({ ...state, fetchState: ApiHelper.State.LOADING })
    try {
      const response = await ApiHelper.callAwait({
        method: 'GET', paths: ['/contents'], queryParams: { creatorId: currentUserId }
      })
      console.log('back end videos data::', response)
      setState({ ...state, fetchState: ApiHelper.State.READY, data: response })
    } catch (e) {
      setState({ ...state, fetchState: ApiHelper.State.ERROR })
      setErrMsg(Helper.getErrorMsg(e))
    }
  }
  const getStatus = (content) => {
    return (
      <div className={css(Styles.statusContainer)}>
        {/* {content.stats.rating && */}
        <Fragment>
          <div className={css(Styles.starImageContainer)}>
            <img src={require('../assets/images/rating-on.png')}
              alt={Helper.getString('ratingImageAlt')}
              className={css(Styles.img)}
              width='15'
              height='15'
            />
          </div>
          <p className={css([Styles.statusTitle,])}>{content.stats.ratings ? content.stats.ratings.average : '0'}
            <span className={css(Styles.statusText)}>
              ({content.stats.ratings ? content.stats.ratings.count : '0'})
            </span></p>
        </Fragment>
        {/* } */}
        {content.stats.viewCount &&
          <Fragment>
            <div className={css(Styles.eyeImageContainer)}>
              <img src={require('../assets/images/views.png')}
                alt={Helper.getString('viewImageAlt')}
                className={css(Styles.img)}
                width='20'
                height='20'
              />
            </div>
            <p className={css([Styles.statusText,])}>{content.stats.viewCount}</p>
          </Fragment>
        }
      </div>
    )
  }

  const getPrice = (content) => {
    const isContentPricedVideo = content.pricing && content.pricing.type === PRICINGTYPES.FREE &&
      content.pricing.contentprice && content.pricing.contentprice.value && content.pricing.contentprice.value !== '0.00'
    const isPaidVideo = content.pricing && content.pricing.type === PRICINGTYPES.ALL_IN_ONE_PRICE
    let price
    if (isContentPricedVideo) {
      price = content.pricing.contentprice.value
    }

    if (isPaidVideo) {
      price = content.pricing.price.value
    }


    if (isContentPricedVideo || isPaidVideo) {
      return (
        <div className={css(Styles.priceContainer)}>
          {isContentPricedVideo &&
            <div className={css(Styles.downloadsArrowContainer)}>
              <img src={require('../assets/images/downloadsarrow.png')}
                alt={Helper.getString('downloadsArrowImageAlt')}
                className={css(Styles.img)}
                width='12'
                height='12'
              />
            </div>
          }
          <div className={css(Styles.coinIconContainer)}>
            <img src={require('../assets/images/razzlcoin.png')}
              alt={Helper.getString('coinImageAlt')}
              className={css(Styles.img)}
              width='19'
              height='19'
            />
          </div>
          <p className={css(Styles.priceText)}>{price}</p>
        </div>
      )
    }
  }

  const getVideoDetails = (content) => {
    return (
      <div className={css(Styles.detailsInnerContainer)}>
        <p className={css([Styles.videoTitle, Styles.textUpperCase])}>{content.title}</p>
        <div className={css(Styles.timeAndPriceContaineer)}>
          <p className={css([Styles.createDateTime,])}>
            {AppHelper.formatDateTime(content.modifiedTs)}
            {AppHelper.getDurationText(content.durationMs)}
          </p>
          {getPrice(content)}
        </div>
        <p className={css([Styles.createDateTime, Styles.textUpperCase])}>
          {getContentState(content.state)} - {content.privacy}
        </p>
        {getStatus(content)}
      </div >
    )
  }

  const getContentState = (state) => {
    if (state === 'READY') {
      return 'Published'
    } else {
      return 'Draft'
    }
  }

  const getVideoThumbnail = (content) => {
    // if (content.thumbnailType === 'USER_VIDEO_FRAME') {
    //   return content.thumbnailVideoFrame || content.image
    // }
    return content.thumbnail || content.image
  }

  const handleCopyVideoLink = async (videoId) => {
    // Set the contentId for which link fetch is in progress
    // We keep this value from the start of a fetch till the tooltip
    // is shown for 3 seconds
    setActiveTooltipContentId(videoId);
    setLinkFetchState(ApiHelper.State.LOADING)
    try {
      const queryParams = {
        contentId: videoId,
      }
      const response = await ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'videolink'], queryParams)
      })
      const url = response.shortLink;
      copy(url, { format: 'text/plain' });
      setLinkFetchState(ApiHelper.State.READY)
      // Reset All variables after 3 seconds
      setTimeout(() => {
        setActiveTooltipContentId(null)
        setLinkFetchState(ApiHelper.State.READY)
        setLinkFetchError(null)
      }, 3000);
    } catch (error) {
      setLinkFetchState(ApiHelper.State.ERROR)
      setLinkFetchError(Helper.getErrorMsg(error))
      // Reset All variables after 3 seconds
      setTimeout(() => {
        setActiveTooltipContentId(null)
        setLinkFetchState(ApiHelper.State.READY)
        setLinkFetchError(null)
      }, 3000);
    }
  };

  const getTooltipTitle = (videoId) => {
    if (linkFetchState === ApiHelper.State.ERROR) {
      return linkFetchError;
    }
    return 'Video link copied to clipboard';
  };

  const getContent = (data) => {
    return (
      <div key={data.id} className={css(Styles.innerContainer)}>
        {data && data.items.map((content, index) => {
          // content.price = '2.49'
          return (
            <div className={css(Styles.contentTile)}>
              <div className={css(Styles.tileImageContainer)} onClick={() => onVideoTileClick(content)}>
                <img src={getVideoThumbnail(content)}
                  alt={Helper.getString('videoImageAlt')}
                  className={css(Styles.img)}
                  width='274'
                  height='154'
                />
              </div>
              <div className={css(Styles.detailsContainer)}>

                {getVideoDetails(content)}

                <div className={css(Styles.detailsContainerRight)}>
                  <div className={css(Styles.dotsContainer)}
                    onClick={(e) => getOptions(e, content)}>
                    <div className={css(Styles.dotsImageContainer)}>
                      <img src={require('../assets/images/dots.png')}
                        alt={Helper.getString('dotImageAlt')}
                        className={css(Styles.img)}
                        width='4'
                        height='20'
                      />
                    </div>
                  </div>
                  {content.state === 'READY' && (
                    <Tooltip
                      title={getTooltipTitle(content.id)}
                      open={activeTooltipContentId === content.id && linkFetchState !== ApiHelper.State.LOADING}
                      classes={{ tooltip: css(Styles.tooltip) }}
                      placement='bottom'>
                      <div>
                        <Button
                          disableRipple
                          // disable the button for all videos if there is an active video in process
                          disabled={activeTooltipContentId ? true : false}
                          onClick={() => handleCopyVideoLink(content.id)}
                          className={css(Styles.copyProfileLinkButton)}>
                          {(activeTooltipContentId === content.id) && (linkFetchState === ApiHelper.State.LOADING)
                            ? <CircularProgress size={18} className={css(Styles.progressIcon)} />
                            : <img src={require('../assets/images/link.png')} className={css(Styles.linkIcon)} />
                          }
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          )
        })}
        {renderOptionDialog()}
        {showWarningPopup()}
      </div>
    )
  }

  const onVideoTileClick = (content) => {
    setSelectedProject(content)
    openVideoEditMode(content)
  }

  const getEmptyView = () => {
    return (
      <div className={css(Styles.emptyView)}>
        <p className={css(Styles.emptyViewText)}>{Helper.getString('emptyMsg')}</p>
      </div>
    )
  }

  const getOptions = (e, content) => {
    setSelectedProject(content)
    setOpenOption(e.currentTarget)
  }

  const optionClose = () => {
    setSelectedProject(null)
    setOpenOption(null)
  }
  const videoWizardClose = (publishSuccess) => {
    setOpenVideoWizard(false)
    setSelectedProject(null)
    if (publishSuccess) {
      setState({ ...state, fetchState: ApiHelper.State.LOADING })
      fetchData()
    }
  }

  const handleDelete = () => {
    // setOpenToast(true)
    setOpenOption(false)
    if (selectedProject && selectedProject.state === 'READY' && selectedProject.stats && selectedProject.stats.buyCount > 0) {
      setOpenWarning(true)
      setWarningContent(
        {
          errTitle: Helper.getString('publishedAndPurchasedVideoEditWarning'),
          errorDescr: Helper.getString('purchasesAgainstVideoWarningDescr'),
          errorWarning: Helper.getString('purchasesAgainstVideoWarning'),
          buttonText: 'Close'
        })
    } else {
      setOpenDelete(true)
    }
  }

  const onDeleteCancel = () => {
    setSelectedProject(null)
    setOpenDelete(false)
  }

  const showError = () => {
    return (
      <ErrorPopup
        dialogOpen={openErrorPopup}
        title='Upload failed'
        descr={fileUploadErrMsg || 'File upload failed'}
        onPopUpClose={() => onErrorPopupClose()} />
    )
  }

  const onErrorPopupClose = () => {
    setOpenErrorPopup(false)
  }

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false)
  };

  const handleErrorToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteErrorToast(false)
  };

  const getDeleteErrorToast = () => {
    return (
      <Toast open={openDeleteErrorToast}
        severity='error'
        error={Styles.errorToast}
        message={errMsg}
        handleClose={handleErrorToastClose}
      />
    )
  }

  const getToast = () => {
    console.log('close log value of openToast in upload', openToast)
    return (
      <Toast open={openToast}
        severity='info'
        message='Coming Soon'
        handleClose={handleToastClose}
      />
    )
  }

  async function onDelete() {
    setDeleteState(ApiHelper.State.LOADING)
    try {
      const ApiOptions = {
        method: 'DELETE',
        paths: ['contents', selectedProject.id]
      }
      const response = await ApiHelper.callAwait(ApiOptions)
      setDeleteState(ApiHelper.State.READY)
      setSelectedProject(null)
      setErrMsg(null)
      setOpenDelete(false)
      setState({
        ...state,
        fetchState: ApiHelper.State.LOADING
      })
      fetchData()
    } catch (e) {
      setDeleteState(ApiHelper.State.ERROR)
      setErrMsg(Helper.getErrorMsg(e))
      setDeleteErrorToast(true)
    }
  }

  const showWarningPopup = () => {
    return (
      <ErrorPopup
        dialogOpen={openWarning}
        title={warningContent.errTitle}
        descr={warningContent.errorDescr}
        warning={warningContent.errorWarning}
        buttonText={warningContent.buttonText}
        onPopUpClose={() => {
          setOpenWarning(false)
          setWarningContent({ errTitle: '', errorDescr: '', buttonText: '' })
        }} />
    )
  }

  const getDelete = () => {
    return (
      <DeletePopup
        openDelete={openDelete}
        title={Helper.getString('permanentlyDeleteVideo')}
        descr={'This will permanently delete your video.'}
        descr2={'Deleting this video will remove associated views, shares or ratings information.'}
        warningText='This action CANNOT be UNDONE.'
        deleteState={deleteState}
        onDeleteCancel={onDeleteCancel}
        onDelete={onDelete}
      />
    )
  }

  const handleDoNotShowAgain = (e) => {
    setIsChecked(e.target.checked);
    localStorage.setItem(currentUserId + HIDE_DONOTREMIND_VIDEOEDIT, e.target.checked);
  }

  const renderEditWarningPopup = () => {
    return (
      <EditWarningPopup dialogOpen={openEditWarningPopup}
        buttonTitle='CONTINUE'
        buttonAction={() => openVideoEditWizardDialog()}
        onClose={() => onEditWarningPopupClose()}
        doNotShowAgain={checked}
        handleDoNotShowAgain={handleDoNotShowAgain}
        title='Editing a PUBLISHED video will change its status to DRAFT'
        descr={'Please note that DRAFT status videos are not visible on Razzl App.\nVideo will be available on Razzl App once PUBLISHED.'}
      />
    )
  }

  const onEditWarningPopupClose = () => {
    setSelectedProject(null)
    setEditWarningPopup(false)
  }

  const openVideoEditWizardDialog = () => {
    setEditWarningPopup(false)
    openVideoWizardDialog()
  }

  const openVideoEditMode = (selectedContent) => {
    const content = selectedContent || selectedProject // Can be either the clicked project of the seleced Project set by 3 dot menu
    const modalStorage = localStorage.getItem(currentUserId + HIDE_DONOTREMIND_VIDEOEDIT, checked);
    if (content && content.state === 'READY' && content.stats && content.stats.buyCount > 0) {
      // published and purchased Video
      openVideoWizardDialog()
    } else if (content && content.state === 'READY' && modalStorage !== 'true') {
      // published Video
      setOpenOption(null)
      setEditWarningPopup(true)
    } else {
      openVideoWizardDialog()
    }
  }


  const openVideoWizardDialog = (fileStackResponse) => {
    setOpenOption(null)
    setFileStackResponse(fileStackResponse)
    setOpenVideoWizard(true)
  }

  const rederVideoWizard = () => {
    console.log('selectedProject.id::', (selectedProject ? selectedProject.id : null))
    if (!openVideoWizard) {
      return null
    } else {
      return (
        <UploadedVideoWizardDialog
          uploadVideo={openVideoWizard}
          fileStackUploadResponse={fileStackResponse}
          project={selectedProject ? { id: selectedProject.id, state: selectedProject.state, stats: selectedProject.stats } : null}
          uploadVideoClose={videoWizardClose} />
      )
    }
  }

  const renderOptionDialog = () => {
    return (
      <Popover
        id={Helper.getString('settings')}
        open={Boolean(openOption)}
        classes={{ paper: css(Styles.popoverContainer) }}
        anchorEl={openOption}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => optionClose()}
      >
        <div className={css(Styles.popoverContent)}>
          <div onClick={() => optionClose()} className={css(Styles.closeIconContainer)}>
            <img src={require('../assets/images/close.png')}
              alt={Helper.getString('closeImageAlt')}
              className={css(Styles.img)}
              width='35'
              height='35'
            />
          </div>
          <MenuItem
            onClick={() => openVideoEditMode()}//{() => openVideoWizardDialog()}
            className={css(Styles.menuItemContainer)}>
            <div className={css(Styles.menuItemIconContainer)}>
              <img src={require('../assets/images/edit.png')}
                alt={Helper.getString('editImageAlt')}
                className={css(Styles.img)}
                width='35'
                height='35'
              />
            </div>
            <p className={css(Styles.menuItemContent)}>{Helper.getString('edit')}</p>
          </MenuItem>
          <MenuItem
            onClick={() => handleDelete()}
            className={css(Styles.menuItemContainer)}>
            <div className={css(Styles.menuItemIconContainer)}>
              <img src={require('../assets/images/delete.png')}
                alt={Helper.getString('deleteImageAlt')}
                className={css(Styles.img)}
                width='35'
                height='35'
              />
            </div>
            <p className={css(Styles.menuItemContent)}>{Helper.getString('delete')}</p>
          </MenuItem>
        </div>
      </Popover>
    )
  }

  const getHelpTextContent = () => {
    return (
      <p className={css(Styles.helpTextStyle)}>
        Video length should be between 1 minute and 2 hours. Resolution should be 720p, 1080p or 4K. 16:9 aspect ratio (horizontal).
        File size should be less than 5GB and in .mp4, .mov or .avi format. Videos must include an audio track. <br /><br />
        Default status for uploaded videos is DRAFT.
        <br /><br />
        Once a video is PUBLISHED, it becomes available in<span className={css(Styles.boldText)}> Razzl App </span>
        (under YOUR VIDEOS). Use same login email on <span className={css(Styles.boldText)}>Razzl App
        </span> as used here to access published videos.
      </p>
    )
  }

  const showProfileIncompleteWarning = () => {
    return (
      <ErrorPopup
        dialogOpen={openProfileIncompleteWarning}
        title={'Profile Incomplete'}
        descr={'Profile information missing. Please complete Profile setup.'}
        onPopUpClose={() => {
          setOpenProfileIncompleteWarning(false)
        }} />
    )
  }

  const title = state.data && state.data.title ? state.data.title : 'Videos'
  //const helpText = state.data && state.data.helpText
  const helpText = getHelpTextContent()
  const actions = [
    {
      id: 1,
      type: 'button',
      label: '+ Upload video',
      onClick: async () => {
        if (appUser.bio && appUser.bio !== '') {
          ProjectHelper.uploadProject((uploadResponse) => {
            if (uploadResponse && uploadResponse.filesUploaded && uploadResponse.filesUploaded.length > 0 && uploadResponse.filesUploaded[0].key) {
              // Upload success
              openVideoWizardDialog(uploadResponse.filesUploaded[0])
            } else if (uploadResponse && uploadResponse.filesFailed && uploadResponse.filesFailed.length > 0) {
              // Handle error scenario
              setOpenErrorPopup(true)
            } else {
              setOpenErrorPopup(true)
            }
          }, (pickerFileMetadata, error) => {
            if (error && error.message) {
              setFileUploadErrMsg(error.message)
              setOpenErrorPopup(true)
            }
          })
        }
        else {
          setOpenProfileIncompleteWarning(true)
          return
        }
      }
    }
  ]


  return (
    <div className={css(Styles.container)}>
      {title &&
        <Headerbar title={title} helpText={helpText}
          actions={actions} />
      }
      {state.fetchState === ApiHelper.State.LOADING &&
        AppHelper.loadingView()
      }
      {state.fetchState === ApiHelper.State.READY && state.data && state.data.items &&

        getContent(state.data)

      }
      {state.fetchState === ApiHelper.State.READY && state.data && state.data.items && state.data.items.length <= 0 &&
        getEmptyView()
      }

      {state.fetchState === ApiHelper.State.ERROR &&
        AppHelper.errorView(errMsg, CommonStyles.buttonStyle, 'Try Again', () => fetchData())
      }
      {rederVideoWizard()}

      {getToast()}
      {showError()}
      {getDelete()}
      {getDeleteErrorToast()}
      {renderEditWarningPopup()}
      {showProfileIncompleteWarning()}
    </div>
  )
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#02121D',
    paddingLeft: 13,
    marginTop: 63,
    overflow: 'hidden'
  },
  contentTitle: {
    fontSize: 18,
    color: '#FFFFFF',
    opacity: 1,
    textAlign: 'left',
    textTransform: 'uppercase',
    margin: 0,
    marginBottom: 20
  },
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 1256,
    marginTop: 95//38,
  },
  innerOuterContent: {
    // paddingTop: 20
  },
  contentTile: {
    backgroundColor: '#02121D',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    width: 274,
    margin: 17
  },
  tileImageContainer: {
    width: 274,
    height: 154,
    borderRadius: 8,
    overflow: 'hidden',
    cursor: 'pointer'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover'
  },
  detailsContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    marginTop: 14.75
  },
  detailsInnerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  statusTitle: {
    fontSize: 12,
    color: '#ffffff',
    margin: '2.5px 0px'
  },
  videoTitle: {
    fontSize: 12,
    color: '#ffffff',
    margin: '2.5px 0px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 230
  },
  textUpperCase: {
    textTransform: 'uppercase'
  },
  createDateTime: {
    fontSize: 12,
    color: '#FFFFFFCC',
    margin: '2.5px 0px'
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  statusText: {
    fontSize: 12,
    color: '#FFFFFFBF',
    margin: '2.5px 0px'
  },
  starImageContainer: {
    width: 15,
    height: 15
  },
  eyeImageContainer: {
    width: 20,
    height: 20,
    marginLeft: 9.83,
    marginRight: 2.5
  },
  emptyView: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyViewText: {
    fontSize: 20,
    color: '#FFFFFF80',
    // textTransform: 'capitalize'
  },
  detailsContainerRight: {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'space-between',
  },
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    border: '0.5px solid #FFFFFF33',
    borderRadius: 4,
    padding: 1,
    cursor: 'pointer'
  },
  dotsImageContainer: {
    width: 4,
    height: 20
  },
  popoverContainer: {
    backgroundColor: '#000000',
    width: 280,
    height: 234,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    border: '2px solid #FFFFFF66',
    borderRadius: 8
  },
  menuItemIconContainer: {
    width: 35,
    height: 35,
    marginRight: 23
  },
  menuItemContent: {
    margin: 0,
    color: '#FFFFFF',
    fontSize: 18,
  },
  closeIconContainer: {
    width: 35,
    height: 35,
    position: 'absolute',
    top: 6,
    right: 7,
    cursor: 'pointer'
  },
  priceText: {
    color: '#ffffff',
    fontSize: 12,
    margin: 0,
    marginLeft: 3
  },
  menuItemContainer: {
    padding: '20px 0px'
  },
  timeAndPriceContaineer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #FFFFFF4D',
    borderRadius: 10,
    padding: '0px 8px 0px 4px',
    marginLeft: '10px',
    minWidth: 42
  },
  coinIconContainer: {
    width: 19,
    height: 19
  },
  boldText: {
    fontWeight: 'bold'
  },
  helpTextStyle: {
    color: '#ffffff',
    fontSize: 18,
    margin: 0,
    lineHeight: '23px'
  },
  errorToast: {
    backgroundColor: '#000000'
  },
  downloadsArrowContainer: {
    width: 12,
    // height: 12
  },
  copyProfileLinkButton: {
    border: '1px solid #FFFFFF33',
    background: 'transparent',
    borderRadius: 4,
    minWidth: 34,
    minHeight: 34,
    padding: 3,
    height: 34,
    marginTop: 10
  },
  tooltip: {
    padding: 27,
    backgroundColor: '#000000',
    borderRadius: 8,
    fontSize: 18,
    color: '#ffffff',
    fontWeight: '600',
    border: '1px solid #FFFFFF80',
    position: 'absolute',
    transform: 'translateY(-20%) translateX(-70%)',
    width: 280,
    textAlign: 'center'
  },
  progressIcon: {
    color: '#FFFFFF',
  },
  linkIcon: {
    width: 25,
    height: 25,
  }
})
