import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { AppButton } from '../common/components/AppButton';

export function ErrorPopup(props) {

  return (
    <Dialog open={props.dialogOpen}
      classes={{
        paper: css(Styles.publishWarningDialog),
      }}
      className={css(Styles.errorDialogContainer)}>
      <div className={css(Styles.errorContentContainer)}>
        <div className={css(Styles.warningTitleContainer)}>
          {props.image &&
            <div className={css(Styles.publishImageContainer)}>
              <img src={props.image}
                width='50' height='50' alt={Helper.getString('processImageAlt')}
                className={css(Styles.img)} />
            </div>
          }
          <p className={css(Styles.publishDialogTitle)}>{props.title}</p>
        </div>
        <p className={css(Styles.publishDialogDescr)}>{props.descr}</p>
        <p className={css(Styles.publishDialogDescr, Styles.warningText)}>{props.warning}</p>
      </div>
      <div className={css(Styles.buttonContainer)}>
        <AppButton
          buttonStyle={Styles.buttonStyle}
          buttonTextStyle={Styles.buttonText}
          buttonText={props.buttonText || 'Close'}
          onClick={props.onPopUpClose}
        />
      </div>
    </Dialog>
  )
}

const Styles = StyleSheet.create({
  errorDialogContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 13000
  },
  publishWarningDialog: {
    display: 'flex',
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: 8
  },
  closeIconContainer: {
    width: 35,
    height: 35,
    position: 'absolute',
    top: 6,
    right: 7,
    cursor: 'pointer'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  warningTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  publishImageContainer: {
    height: 50,
    width: 50,
  },
  publishDialogTitle: {
    color: '#000000',
    fontSize: 16,
    margin: 0,
    fontWeight: 'bold',
    marginTop: 5,
    marginBottom: 10
  },
  publishDialogDescr: {
    color: '#000000',
    fontSize: 14,
    textAlign: 'center',
    margin: 0,
    marginTop: 10,
    marginBottom: 13,
    maxWidth: 300,
    fontWeight: 600,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #0000004D'
  },
  buttonStyle: {
    backgroundColor: '#ffffff',
    flex: 1,
    borderRadius: 0
  },
  buttonText: {
    color: '#037BFE',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase'
  },
  errorContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 15,
    width: 300,
  },
  warningText: {
    fontSize: 15,
    fontWeight: 800
  }
})