import React, { Component } from 'react';

import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Header } from '../common/components/Header';
import { Footer } from '../common/components/Footer';
import { Grid } from '@material-ui/core';
import { AppTheme } from '../styles/AppTheme';
import { Helper } from '../common/helpers/Helper';

export class NotFound extends Component {
  render() {
    return (
      <div className={css([CommonStyles.container, Styles.container])}>
        <div className={css(Styles.header)}>
          <Header />
        </div>
        <Grid container className={css([CommonStyles.textfulPageContent, Styles.textfulPageContent])}>
          <div className={css([CommonStyles.textfulPageContentInnerContainer, Styles.innerContainer])}>
            <h1 className={css(Styles.nfMiddle)}>{Helper.getString('pageNotFoundTitle')}</h1>
            <p className={css(Styles.nfMiddle)}>{Helper.getString('pageNotFoundSubtitle')}</p>
          </div>

        </Grid>
        <div className={css([Styles.footerContainer, CommonStyles.footerContainerV2])}>
          <Footer />
        </div>
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  nfMiddle: {
    fontSize: 15,
    textAlign: 'center',
    color: 'white',
    margin: 0,
    fontWeight: 'normal',
    marginBottom: 5,
    '@media (max-width: 768px)': {
      fontSize: 13
    }
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center'
  },
  textfulPageContent: {
    flex: 1
  },
  header: {
    width: '100%',
    maxWidth: AppTheme.viewPort
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '@media (max-width: 767px)': {
      position: 'unset',
      bottom: '0',
      backgroundColor: '#000',
      width: 'unset',
    },
  }
})