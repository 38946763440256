import moment from 'moment';
import { Strings } from './Strings';
import { AppConfig } from '../../AppConfig';
import { Controller } from '../Controller';

export class Helper {
  static HOUR_MS = 3600 * 1000
  static DAY_MS = 24 * 3600 * 1000
  static MILLISECOND_FOR_DAY = 24 * 60 * 60 * 1000

  static inArray(arr, value) {
    if (!arr) return false
    return arr.indexOf(value) >= 0 ? true : false
  }
  static getString(key) {
    return Strings.getString(key) || key
  }
  static setLanguage(lang) {
    Strings.setLanguage(lang)
    // if (['ar'].indexOf(lang) >= 0) {
    //   I18nManager.forceRTL(true)
    // } else {
    //   I18nManager.forceRTL(false)
    // }
  }

  static validEmail(email) { //TODO: why?
    const result = email.replace(/\s/g, '')
    if (!/\S+@\S+\.\S+/.test(result)) {
      return false
    }
    return result.toLowerCase()
  }

  static getLanguage() {
    return Strings.getLanguage();
  }

  static getLanguageText(data, key) {
    const language = Strings.getLanguage();
    return data[key + '_' + language] || data[key];
  }
  static encodeQueryString = (data) => {
    const ret = [];
    for (const d in data) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    return ret.join('&');
  }
  static formatName(item) {
    let name = '';
    if (!item) {
      return name;
    }
    if (item.nametitle) {
      name += item.nametitle
      name += ' '
    }
    if (item.givenname) {
      name += item.givenname
      name += ' '
    }
    if (item.middlename) {
      name += item.middlename
      name += ' '
    }
    if (item.familyname) {
      name += item.familyname
    }
    return name;
  }
  static getErrorMsg(error) {
    let message;
    if (typeof error === 'string') {
      message = error;
    }
    if (!message) {
      if (error) {
        if (error.original && error.original.bodyJson) {
          const body = error.original.bodyJson;
          if (body && body.code && body.message) {
            message = body.message;
          }
        }
        if (!message) {
          message = error.message;
        }
        if (!message) {
          message = error.description;
        }
      }
    }
    return message || Helper.getString('customerCareErr');
  }
  static showProgressModal(caller) {
    caller.setState({ progressModelOpen: true });
  }
  static hideProgressModal(caller) {
    caller.setState({ progressModelOpen: false });
  }
  static formatTimestamp(utcTimestamp) {
    return moment.utc(utcTimestamp).local().format('Do MMM YYYY, h:mm a');
  }
  static formatDate(utcTimestamp) {
    return moment.utc(utcTimestamp).local().format('Do MMM YYYY');
  }
  static formatDate2(options) {
    const { utcTimestamp, zone, format } = options
    return moment.unix(utcTimestamp / 1000).tz(zone || AppConfig.DEFAULT_ZONEINFO).format(format || 'DD-MMM-YYYY')
  }
  static populateFormDefinitionValues(formDefinition, values) {
    for (const field of formDefinition.steps[0].fields) {
      if (values.__STAGED && field.key in values.__STAGED) {
        field.pendingApproval = true
        Helper.assignFieldValue(field, values.__STAGED)
      } else if (field.key in values) {
        Helper.assignFieldValue(field, values)
      } else {
        delete field.value;
        delete field.link;
      }
    }
  }
  static assignFieldValue(field, values) {
    field.value = values[field.key]
    if (field.type === 'radio' && Array.isArray(field.value)) {
      if (field.value.length > 0) {
        field.value = field.value[0]
      } else {
        delete field.value
      }
    }
    if (field.linkName) {
      field.link = values[field.linkName]
    }
  }
  static deleteFormDefinitionValues(formDefinition) {
    for (const field of formDefinition.steps[0].fields) {
      delete field.value;
      if (field.imageUpload === true) {
        field.link = null;
      }
      if (field.documentUpload === true) {
        field.link = null;
      }
    }
  }
  static validateAndFormatEmail(email) {
    const result = email.replace(/\s/g, '')
    if (!/\S+@\S+\.\S+/.test(result)) {
      throw new Error('Invalid email');
    }
    return result.toLowerCase()
  }
  static formatCurrency(value) {
    return Helper.getLanguage() === 'ar'
      ? AppConfig.DEFAULT_CURRENCY + ' ' + Number(value / 100).toFixed (2)
      : Number(value / 100).toFixed(2) + ' ' + AppConfig.DEFAULT_CURRENCY;
  }
  static dictToArray(dict) {
    const arr = []
    if (!dict) {
      return arr
    }
    Object.keys(dict).forEach(k => {
      arr.push(dict[k])
    })
    return arr
  }
  static itemsToMap(items, idName) {
    const itemMap = {}
    idName = idName || 'id'
    for (const i of items) {
      itemMap[i[idName]] = i
    }
    return itemMap;
  }
  static sortDictArray(docs, attr) {
    const attrName = attr || 'order'
    return docs.sort((a, b) => {
      if (!a[attrName]) {
        return -1
      }
      if (!b[attrName]) {
        return 1
      }
      return a[attrName].localeCompare(b[attrName])
    })
  }
  static sortDictArrayRev(docs, attr) {
    const attrName = attr || 'order'
    return docs.sort((a, b) => {
      if (!a[attrName]) {
        return 1
      }
      if (!b[attrName]) {
        return -1
      }
      return b[attrName].localeCompare(a[attrName])
    })
  }

  static processCats(cats, listRootsAlso) {
    const catsById = Helper.itemsToMap(cats, 'id')
    const catsWhichAreParents = {}
    cats.forEach(i => {
      i.parents = []
      let k = i
      let depth = 0
      while (!k.parentId.startsWith('ROOT')) {
        catsWhichAreParents[k.parentId] = k // Has atleast one child
        i.parents.unshift(k.parentId)
        k = catsById[k.parentId]
        if(!k) break
        depth += 1; if (depth >= 5) break // loop braker - just in case
      }
    })

    const leafCats = []
    cats.forEach(i => {
      i.fullPath = ''
      i.parents.forEach(p => {
        if(!catsById[p]) return
        i.fullPath += catsById[p].title + '/'
      })
      i.fullPath += i.title

      i.titleOriginal = i.title
      i.title = i.fullPath
      if (!(i.id in catsWhichAreParents)) {
        leafCats.push(i)
      } else if(listRootsAlso) {
        leafCats.push(i)
      }
    })
    return Helper.sortAscending(leafCats, 'title')
  }

  static sortAscending(arr, key) {
    key = key || 'order'
    return arr.sort((a, b) => { return a[key].localeCompare(b[key]) })
  }

  static pretty(obj) {
    return JSON.stringify(obj, null, 2) + '\n';
  }

  static currentTs() {
    return Math.floor(new Date()); // Millisecs since epoch
  }

  static promisify(f) {
    const util = require('util')
    return util.promisify(f)
  }

  static selectDict(names, src) {
    const dest = {}
    const ns = names.split(',').map(v => v.trim())
    ns.forEach(n => {
      if (n in src) {
        dest[n] = src[n]
      }
    })
    return dest
  }

  static ellipsis(input, maxLength = 30) {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + '...';
    }
    return input
  }

  static fieldDefResolve(options) {
    console.log('fieldDefResolve:', options)
    const { def, values, key } = options
    let resolved = values[key]
    if (!def) return resolved
    def.steps[0].fields.forEach(field => {
      if (field.key !== key) return
      if (!field.options) return
      const optionsByKey = Helper.itemsToMap(field.options, 'key')
      const value = values[key]
      if (value) {
        if (field.type === 'radio') {
          const option = optionsByKey[value]
          if (option) {
            resolved = option.label || option.text || option.title
            return
          }
        } else if (field.type === 'check_box') {
          const resolves = []
          value.forEach(v => {
            const option = optionsByKey[v]
            if (option) resolves.push(option.label || option.text || option.title)
          })
          if (resolves.length > 0) {
            resolved = resolves.join(', ')
            return
          }
        }
      }
    })
    return resolved
  }

  static abbrNum(number) {
    // if (number >= 100000) {
    //   return (number / 100000).toFixed(1) + 'L'
    // } else if (number >= 1000) {
    //   return Math.round(number / 1000) + 'K'
    // }
    // return Math.round(number)
    return Math.round(number).toLocaleString('en-IN')
  }

  static getMobileOperatingSystem() {
    const userAgent = navigator.userAgent // || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'unknown';
  }

  static bootstrap(callback) {
    Controller.get().loginMgr()._fetchAppUser(callback) // For promisify
  }

  static getAppstoreLink() {
    const appstoreLink = 'https://apps.apple.com/app/id'.concat(AppConfig.ITUNES_ID)
    return appstoreLink
  }

  static getPlaystoreLink() {
    const playstoreLink = 'https://play.google.com/store/apps/details?id='.concat(AppConfig.PACKAGE_NAME)
    return playstoreLink
  }
}
