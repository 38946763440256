import React, { Fragment, useState, useRef, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { CommonStyles } from '../styles/Styles';
import { Helper } from '../common/helpers/Helper';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { AppButton } from '../common/components/AppButton';
import { Tooltip } from '@material-ui/core';
import { Toast } from './Toast';
import { Controller } from '../common/Controller';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppHelper } from '../helpers/AppHelper';

const allowedUsers = ['42e4e7103e9c11eb9947994f07faf2c0', '15ff9e50481c11eba7dd55840c503254']
export function PayoutSetup(props) {
  const [openToast, setOpenToast] = useState(false)
  const [fetchState, setFetchState] = useState(ApiHelper.State.READY)
  const [user, setUser] = useState(Controller.get().userMgr().getAppUser())
  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  const payoutDetails = [
    // {
    //   key: 'payoutFrequency',
    //   label: 'Payout Frequency',
    //   helpText: 'Payments to creators for their Razzl sales are processed within 45 days from the last day of the month in which the sale transaction was completed. Payments are made in U.S. dollars via PayPal.\n\nCreators receive a 35% share of the sale amount collected by Razzl for each user purchase of their content (videos, downloads).\n\nRazzl may be obligated to withhold income tax from payments due to creators. However, typically no income tax is withheld for creators based in the United States.'
    // },
    {
      key: 'processingFees',
      label: 'Processing Fees',
      helpText: 'Razzl covers PayPal transaction costs for creator payments. Creator payments are made in U.S. dollars.\n\nIf money is transferred from PayPal to a bank outside the U.S. that uses a different currency, the bank or PayPal may charge a fee for conversion to local currency using their own currency exchange rate. Razzl is not responsible for these fees.'
    },
    {
      key: 'taxForms',
      label: 'Tax Forms',
      helpText: 'For U.S. based creators, as payments are directly processed through PayPal, we recommend logging into PayPal to access your 1099 form.\n\nPayPal issues 1099s by Jan 31st of each year. If there is no 1099 available under your PayPal account, that means that you did not reach the earnings threshold in a given calendar year that requires PayPal to issue you a 1099.'
    }
  ]

  const handleMessage = async (event) => {
    console.log('event:: data', event.data)
    console.log('event:: type', event.type)
    console.log('event:: origin', event.origin)
    try {
      // Get Auth Code
      let authorizationCode
      if (event.data) {
        authorizationCode = event.data.authorizationCode
      }
      console.log('event:: authCode', authorizationCode)
      // return if no auth code
      if (!authorizationCode) {
        return
      }

      // Start calling APIs
      const payapalConstants = AppHelper.getPaypalConnsts()
      if (event.origin === payapalConstants.origin) {
        setFetchState(ApiHelper.State.LOADING)

        // Access token Api
        const cs = payapalConstants.client_id + ':' + payapalConstants.secret
        const response = await fetch(payapalConstants.accessTokenEndpoint, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Accept-Language': 'en_US',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Basic ' + btoa(cs)
          },
          body: 'grant_type=authorization_code' + '&' + 'code=' + authorizationCode
        })
        const responseBody = await response.json()
        console.log('Paypal access token::', responseBody.access_token)

        // Access user Profile Api
        const userProfileResponse = await fetch(payapalConstants.userProfileEndpoint, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + responseBody.access_token
          },
        })
        const userProfile = await userProfileResponse.json()
        console.log('Paypal user profile response::', userProfile)

        // Save to user
        const ppInfo = {}
        ppInfo.address = userProfile.address
        userProfile.emails.forEach(element => {
          if (element.primary) {
            ppInfo.email = element.value
          }
        });
        console.log('Paypal user profile data::', ppInfo)
        const body = { ppInfo: ppInfo }
        const options = {
          method: 'PUT',
          paths: ['users', 'me'],
          jsonBody: body,
        }
        await ApiHelper.callAwait(options)

        // Refetch User
        await fetchAppUser()
        const appUser = Controller.get().userMgr().getAppUser()
        setUser(appUser)
        setFetchState(ApiHelper.State.READY)
      }
    } catch (e) {
      // TODO What should happen if error comes
      console.log('Error during paypal connect ', e)
      setFetchState(ApiHelper.State.READY)
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return (() => {
      window.addEventListener('message', handleMessage);
    })
  }, [])

  const fetchAppUser = async () => {
    return await Helper.promisify(Helper.bootstrap)()
  }

  const getToast = () => {
    return (
      <Toast open={openToast}
        severity='info'
        message='Coming Soon'
        handleClose={handleToastClose}
      />
    )
  }

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false)
  };

  const handlePayPalLogin = async () => {
    // if (allowedUsers.includes(user.userId)) {
    console.log('window.width::', windowWidth.current, windowHeight.current)
    const left = (windowWidth.current - 600) / 2
    const windowFeatures = 'menubar=1,resizable=1,width=600,height=500,top=300,' + 'left=' + left
    console.log('windowFeatures::', windowFeatures)
    const paypalUrl = getPaypalUrl();
    const openedWindow = window.open(paypalUrl,
      'ppwindow', windowFeatures);
    // } else {
    //   setOpenToast(true)
    // }
  }

  const getPaypalUrl = () => {
    const pyapaConstants = AppHelper.getPaypalConnsts()
    let url = pyapaConstants.paypalConnectUrlEndpoint
    const queryParams = {
      flowEntry: 'static',
      // eslint-disable-next-line camelcase
      client_id: pyapaConstants.client_id,
      scope: 'openid email address',
      // eslint-disable-next-line camelcase
      redirect_uri: pyapaConstants.redirect_uri,
    }
    if (queryParams) {
      const qs = Helper.encodeQueryString(queryParams);
      if (qs) {
        url += '?' + qs;
      }
    }
    console.log('Paypal url::', url)
    return url
  }

  const isPyapalConnected = () => {
    if (user.ppInfo && user.ppInfo.email && user.ppInfo.address && user.ppInfo.address.country) {
      return true
    }
    return false
  }

  return (
    <div className={css(Styles.payoutConatiner, props.payoutContainerWidth)}>
      <p className={css(CommonStyles.formTitiles)}>PAYOUT SETUP</p>
      <p className={css(Styles.labelDescr)}>Connect your PayPal account to receive payments for your sales.</p>
      <div className={css(Styles.payoutInnerContainer)}>
        <div className={css(Styles.paypalLogoContainer)}>
          <img src={require('../assets/images/paypal-logo.png')}
            width='150' height='40' alt={Helper.getString('payPalImageAlt')}
            className={css(Styles.img)} />
        </div>
        {fetchState === ApiHelper.State.LOADING ? <CircularProgress size={30} className={css(Styles.progress)} /> :
          <div className={css(Styles.paypalInfoContainer)}>
            <p className={css(CommonStyles.formTitiles, Styles.payPalLabel)}>{isPyapalConnected() ? 'Connected' : 'Not Connected'}</p>
            {isPyapalConnected() && <p className={css(CommonStyles.formTitiles, Styles.paypalEmail)}>{user.ppInfo.email}</p>}
          </div>
        }
        {/* <AppButton
          buttonStyle={Styles.buttonStyle}
          onClick={handlePayPalLogin}
          buttonText={'Log in with PayPal'} /> */}
        {/* <a href={paypalUri}
          onclick={() =>{
            window.open ('http://www.javascript-coder.com',
              'mywindow','menubar=1,resizable=1,width=350,height=250');
          }}>Popup link</a> */}
        <div className={css(Styles.buttonStyle)}>
          <img src={'https://www.paypalobjects.com/devdoc/log-in-with-paypal-button.png'}
            onClick={handlePayPalLogin}
            className={css(Styles.img)} />
        </div>

      </div>
      <div className={css(Styles.payoutDetailsContainer)}>
        {payoutDetails && payoutDetails.map((item, index) => {
          // if (item.key === 'payoutFrequency') {
          //   item.helpText = item.helpText.replace('pvalue', props.creatorEarningPercent);
          // }
          return (
            <Tooltip title={item.helpText} placement='bottom-end'
              classes={{
                tooltip: css(Styles.tooltip),
                popper: css(Styles.tooltipMainContainer)
              }}>
              <p className={css(Styles.payoutDetailsLabel)}>{item.label}</p>
            </Tooltip>
          )
        })
        }
      </div>
      {getToast()}
    </div>
  )
}

const Styles = StyleSheet.create({
  payoutConatiner: {
    display: 'flex',
    flexDirection: 'column',
    // minWidth: 812,
  },
  payoutInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #FFFFFF4D',
    borderRadius: 8,
    padding: '20px 15px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  labelDescr: {
    fontSize: 14,
    margin: 0,
    color: '#FFFFFF',
    marginBottom: 10
  },
  paypalLogoContainer: {
    width: 150,
    height: 40,
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  paypalInfoContainer: {
    lineHeight: '21px',
    textAlign: 'center',
    maxWidth: 300
  },
  payPalLabel: {
    margin: 0,
    textTransform: 'capitalize',
  },
  paypalEmail: {
    margin: 0,
    textTransform: 'lowercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  buttonStyle: {
    cursor: 'pointer',
    width: 211,
    height: 48
  },
  payoutDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 50
  },
  payoutDetailsLabel: {
    fontSize: 14,
    color: '#037BFE',
    margin: 0,
    cursor: 'pointer'
  },
  tooltip: {
    padding: 30,
    backgroundColor: '#000000',
    borderRadius: 8,
    fontSize: 18,
    whiteSpace: 'pre-wrap',
    lineHeight: '23px',
    color: '#ffffff'
  },
  tooltipMainContainer: {
    zIndex: 19999 //
  }
})