export const MODES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
}
export const PROJECT_STATE = {
  PROCESSING: 'PROCESSING',
  PROCESSINGCOMPLETE: 'PROCESSINGCOMPLETE',
  READY: 'READY',
}
export const PRICINGTYPES = {
  ALL_IN_ONE_PRICE: 'ONE_TIME_PURCHASE',
  FREE: 'FREE',
}
export const VISIBILITY = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
}

export const MAX_TEXT_LENGTH = {
  VIDEO_TITLE_MAX_TEXT_LENGTH: 60,
  DESCR_MAX_TEXT_LENGTH: 1000,
  STEP_TITLE_MAX_TEXT_LENGTH: 50,
  STEP_DESCR_MAX_TEXT_LENGTH: 2500,
  STEP_TIP_MAX_TEXT_LENGTH: 1000,
  MATERIAL_DESCR_MAX_TEXT_LENGTH: 2500,
  PROFILE_NAME_MAX_TEXT_LENGTH: 30,
  PROFILE_USERNAME_MAX_TEXT_LENGTH: 30,
  PROFILE_BIO_MAX_TEXT_LENGTH: 200
}

export const HTML_FORMATTING = {
  inlineStyleFn: (styles) => {
    return {
      style: {
        lineHeight: 1.5,
        margin: 0
      },
    }
  }
}

export const HIDE_DONOTREMIND_VIDEOEDIT = 'HIDE_DONOTREMIND_VIDEOEDIT';

export const DONOTSHOW_GETSTARTED = 'DONOTSHOW_GETSTARTED';

export const PAYPAL_CONSTS_SANDBOX_AZ = { // AZY Account Sandbox
  paypalConnectUrlEndpoint: 'https://www.sandbox.paypal.com/connect',
  // eslint-disable-next-line camelcase
  client_id: 'AU_CNkxpMeWkiGWb77WhAtzY0u6_gQQlm57kUPIh-J5bEZByG5G01E5W8BaMv3UO3C4w5FiAktloCC7l',
  // eslint-disable-next-line camelcase
  redirect_uri: 'http://127.0.0.1:3000/ppcb',
  secret: 'ENECBj71nE_33IFkEtPuBGNbNTBcKoZ3k6GUJByuzEFaUJW4bxWcQ3MjYAMhdO_9CgEkTo0qq8sGQbf4',
  accessTokenEndpoint: 'https://api.sandbox.paypal.com/v1/oauth2/token',
  userProfileEndpoint: 'https://api-m.sandbox.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1',
  origin: 'http://127.0.0.1:3000'
}
export const PAYPAL_CONSTS_SANDBOX = {  // Razzl Account Sandbox
  paypalConnectUrlEndpoint: 'https://www.sandbox.paypal.com/connect',
  // eslint-disable-next-line camelcase
  client_id: 'AQD_3JuoYYXE6yEjr58FMnby6GLfNchHAKhldlZxEwNyv1wwDuHiQoE71gkaiAiXEjT-cJ2td_TPga5Q',
  // eslint-disable-next-line camelcase
  redirect_uri: 'https://creator.razzl.com/ppcb',
  secret: 'EDMwnEJ2R0_fzyLgfXOOHe2w41P9eob6fzkSDv9i9CjrOJ5lUSG0_dzhYd8-o8KvMGx_xxAEzVDzAI2u',
  accessTokenEndpoint: 'https://api.sandbox.paypal.com/v1/oauth2/token',
  userProfileEndpoint: 'https://api-m.sandbox.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1',
  origin: 'https://creator.razzl.com'
}
export const PAYPAL_CONSTS_PRODUCTION = { // Razzl Account Live
  paypalConnectUrlEndpoint: 'https://www.paypal.com/connect',
  // eslint-disable-next-line camelcase
  client_id: 'ATahhtcyfwuP5ufjdAWDBq5AnAdEsR-AqHVI55cnK5B1V_XK7PXnwBRkVHDNxgPBLvndER0meCyMPuVl',
  // eslint-disable-next-line camelcase
  redirect_uri: 'https://creator.razzl.com/ppcb',
  secret: 'EKvBRVPGUP32wUgDuHUo8ksOZJlGE_0CWjRpc_yPrvnAzy4RBJgWgXr-sgDHWFOWZ9wF0HsGuNGX4apb',
  accessTokenEndpoint: 'https://api-m.paypal.com/v1/oauth2/token',
  userProfileEndpoint: 'https://api-m.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1',
  origin: 'https://creator.razzl.com'
}
