import React, { Component } from 'react';

import { StyleSheet, css } from 'aphrodite';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AppConfig } from '../../AppConfig';

export function Footer() {

  const footerItems = [
    {
      id: 'terms',
      route: '/terms',
      title: 'Terms of Service'
    },
    {
      id: 'privacyPolicy',
      route: '/privacy',
      title: 'Privacy Policy'
    },
    {
      id: 'communityGuidelines',
      route: '/community-guidelines',
      title: 'Community Guidelines'
    },
  ]

  return (
    <div className={css(Styles.container)}>
      <Grid container className={css(Styles.gridOuterContainer)}>
        {footerItems.length > 0 &&
          footerItems.map((item, index) => {
            return (
              <Grid key={index} xs={12} sm={3} md={3} lg={3} item >
                <div className={css(Styles.linkContainer)}>
                  <Link to={item.route} className={css(Styles.linkStyle)}>
                    <p className={css(Styles.linkText)}>{item.title}</p>
                  </Link>
                </div>
              </Grid>
            )
          })}
        <Grid xs={12} sm={3} md={3} lg={3} item>
          <div className={css(Styles.linkContainer)}>
            <a href={'mailto:' + AppConfig.SUPPORT_EMAIL} className={css(Styles.linkStyle)}>
              <p className={css(Styles.linkText)}>Contact Us</p>
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
  },
  gridOuterContainer: {
    '@media (max-width: 767px)': {
      margin: '2.86px 0px'
    }
  },
  gridContainer: {
    padding: 0
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 50.5,
    paddingLeft: 20,
    '@media (max-width: 767px)': {
      borderTop: '0.5px solid #FFFFFF33',
      justifyContent: 'flex-start',
    }
  },
  linkStyle: {
    textDecoration: 'none',
    ':active': {
      '-webkit-tap-highlight-color': 'transparent'
    },
    '@media (max-width: 767px)': {
      display: 'flex',
      flex: 1
    }
  },
  linkText: {
    fontSize: 15,
    color: '#FFFFFF80',
    textTransform: 'uppercase',
    textAlign: 'center',
    lineHeight: '16px',
    '@media (max-width: 1024px)': {
      fontSize: 13,
    }
  }
})