import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { UiLoginHelper } from '../common/helpers/UiLoginHelper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { AppTheme } from '../styles/AppTheme';
import { InputAdornment, TextField } from '@material-ui/core';
import { Helper } from '../common/helpers/Helper';

export class NumericOTPInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={css(Styles.formSection)}>
        {/* <p className={css(Styles.label)}>{this.props.placeholder ? this.props.placeholder : Helper.getString('otp')}</p> */}
        <FormControl
          fullWidth>
          <TextField
            {...this.props}
            className={css(Styles.formControl)}
            placeholder={this.props.placeholder}
            label={''}
            InputProps={{
              disableUnderline: true,
              type: 'password',
              htmlFor: Helper.getString('otp'),
              classes: {
                root: css(Styles.textInputStyles)
              },
              endAdornment: (
                <InputAdornment position='end'>
                  <div
                    onClick={() => this.props.onClose()}
                    className={css(Styles.closeIconContainer)}>
                    <img src={require('../assets/images/close.png')}
                      alt={Helper.getString('closeImageAlt')}
                      className={css(Styles.img)}
                      width='15'
                      height='15'
                    />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
    )
  }
}

export class PasswordInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={css(Styles.formSection)}>
        <p className={css(Styles.label)}>{this.props.placeholder ? this.props.placeholder : Helper.getString('enterPassword')}</p>
        <FormControl
          fullWidth>
          <TextField
            {...this.props}
            className={css(Styles.formControl)}
            label={''}
            InputProps={{
              disableUnderline: true,
              type: 'password',
              htmlFor: 'password',
              classes: {
                root: css(Styles.textInputStyles)
              }
            }}
          />
        </FormControl>

      </div>
    )
  }
}

export class UsernameInput extends Component {
  constructor(props) {
    super(props);
    this.userNameType = this.props.userNameType
  }

  render() {
    return (
      <div className={css(Styles.formSection)}>
        {/* <p className={css(Styles.label)}>{this.userNameType === UiLoginHelper.UserNameType.PHONE ? Helper.getString('enterMobile') : Helper.getString('enterEmail')}</p> */}
        <FormControl
          fullWidth
          className={this.props.formControl}>
          <TextField
            {...this.props}
            className={css(Styles.formControl)}
            placeholder={this.props.placeholder}
            label={''}
            InputProps={{
              disableUnderline: true,
              htmlFor: this.userNameType === UiLoginHelper.UserNameType.PHONE ? 'mobile-number' : 'email',
              classes: {
                root: css(Styles.textInputStyles)
              },
              endAdornment: (
                <InputAdornment position='end'>
                  <div
                    onClick={() => this.props.onClose()}
                    className={css(Styles.closeIconContainer)}>
                    <img src={require('../assets/images/close.png')}
                      alt={Helper.getString('closeImageAlt')}
                      className={css(Styles.img)}
                      width='15'
                      height='15'
                    />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

      </div>
    )
  }
}

const Styles = StyleSheet.create({
  formSection: {
    //margin: '15px 0px',
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    fontSize: 16,
    margin: 0,
    color: '#222222',
    fontWeight: 400,
    marginBottom: 12,
  },
  textInputStyles: {
    borderBottom: '0.5px solid #FFFFFF40',
    // padding: '10px 15px',
    flex: 1,
    textAlign: 'left',
    minHeight: 25,
    fontSize: 18,
    color: '#FFFFFF99',
    '::placeHolder': {
      color: '#FFFFFF99',
    }
  },
  formControl: {
    flex: 1,
    // backgroundColor: AppTheme.secondaryBg,
    // borderRadius: 16,
    // border: '1px solid ' + AppTheme.borderColor
  },
  formControlMargin: {
    marginTop: 20
  },

  closeIconContainer: {
    width: 15,
    height: 15,
    cursor: 'pointer'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
})
