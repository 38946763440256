import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { FormControl, FormControlLabel, Input, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import { CustomImageUploader } from '../../components/CustomImageUploader';
import { RadioButton, SelectBox, TextBoxs } from '../../components/WizardTextInputs';
import { Helper } from '../../common/helpers/Helper';
import { PRICINGTYPES } from '../../../src/Consts';

export function PriceComponent(props) {
  const [priceSelectDisable, setPriceSelectDisable] = useState(props.projectData.pricing.type === PRICINGTYPES.ALL_IN_ONE_PRICE ? false : true)
  const [contentPriceSelectDisable, setcontentPriceSelectDisable] = useState(props.projectData.pricing.type === PRICINGTYPES.FREE ? false : true)
  const [selectedPriceType, setSelectedPriceType] = useState(props.projectData.pricing.type)

  const contentPriceRangeItem = {
    key: 'contentprice',
    label: 'SET A PRICE FOR DOWNLOADS',
    optional: 'optional',
    type: 'Select',
    //placeHolder: 'Select Level',
    descr: '(IN RAZZL COINS CURRENCY)',
    items: props.contentpricetiers
  }
  const priceRangeItem = {
    key: 'price',
    label: 'Set a price for Video and Downloads',
    type: 'Select',
    //placeHolder: 'Select Level',
    descr: '(IN RAZZL COINS CURRENCY)',
    items: props.pricetiers
  }

  const handleChange = (e, key) => {
    setSelectedPriceType(e.target.value)
    if (e.target.value === PRICINGTYPES.ALL_IN_ONE_PRICE) {
      setPriceSelectDisable(false)
      setcontentPriceSelectDisable(true)
    }
    if (e.target.value === PRICINGTYPES.FREE) {
      setPriceSelectDisable(true)
      setcontentPriceSelectDisable(false)
    }
    props.handleFormDataChanges('type', e.target.value)
  }

  const handleSelectBox = (e, value, key) => {
    if (key === 'price') {
      props.handleFormDataChanges('price', value ? value : '')
    }
    if (key === 'contentprice') {
      props.handleFormDataChanges('contentprice', value ? value : '')
    }
  }

  const renderPriceDropDown = (item, value, selectBoxDisable, priceContainer,optionalText) => {
    return (
      <div className={css(priceContainer)}>
        <SelectBox item={item}
          handleSelectBox={handleSelectBox}
          value={value}
          selectStyle={Styles.selectStyle}
          disabled={selectBoxDisable}
          optionalTextStyle = {optionalText && optionalText}
          disableClearable={true}
          labelStyle={Styles.priceLabelStyle}
          descStyle={Styles.priceDescStyle} />
        <div className={css(Styles.coinContainer)}>
          <div className={css(Styles.coinIconContainer)}>
            <img src={require('../../assets/images/razzlcoin.png')}
              alt={Helper.getString('coinImageAlt')}
              className={css(Styles.img)}
              width='25'
              height='25'
            />
          </div>
          <p className={css(Styles.coinText)}>1 Razzl coin = </p>
          <div className={css(Styles.coinIconContainer)}>
            <img src={require('../../assets/images/purchases.png')}
              alt={Helper.getString('dollarIconImageAlt')}
              className={css(Styles.img)}
              width='25'
              height='25'
            />
          </div>
          <p className={css(Styles.coinText)}>1 us dollar (usd)</p>
        </div>
      </div>
    )
  }

  return (
    <div className={css(Styles.Container)}>
      <div className={css(Styles.innerContainer)}>
        <RadioButton
          value={PRICINGTYPES.FREE}
          selectedValue={selectedPriceType}
          handleChange={handleChange}
          name='Pricing'
          label={'Free Video, Priced Downloads'}
          optionalLabel={'including project PDF'}
          labelDescr={'Allow access to this video for free. Downloads including project PDF may be priced.'} />

        {renderPriceDropDown(contentPriceRangeItem, props.projectData.pricing[contentPriceRangeItem.key],
          contentPriceSelectDisable, Styles.priceContainer, Styles.optionalText)}
        <RadioButton
          value={PRICINGTYPES.ALL_IN_ONE_PRICE}
          selectedValue={selectedPriceType}
          handleChange={handleChange}
          name='Pricing'
          label={'All-In-One Price'}
          labelDescr={'Allow access to this video and any downloads by charging a one-time amount.'} />
        {renderPriceDropDown(priceRangeItem, props.projectData.pricing[priceRangeItem.key], priceSelectDisable, Styles.lastPriceContainer)}
      </div>
    </div>
  )
}

const Styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
    alignItems: 'center'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 700,
    marginBottom: 20
  },
  label: {
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'uppercase',
    margin: 0,
  },
  labelDescr: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginTop: 4,
    marginBottom: 10
  },
  priceDescStyle: {
    fontSize: 14,
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 60,
    marginTop: 20,
    marginBottom: 43
  },
  lastPriceContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 60,
    marginTop: 20,
    marginBottom: 0
  },
  coinContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  coinText: {
    fontSize: 14,
    color: '#FFFFFF80',
    textTransform: 'uppercase',
    margin: 0
  },
  pricingInfoText: {
    fontSize: 18,
    color: '#FFFFFFFF',
    margin: 0,
    textAlign: 'center',
    maxWidth: 437
  },
  selectStyle: {
    marginTop: 13,
    marginBottom: 10,
    maxWidth: 242
  },
  priceLabelStyle: {
    fontSize: 14,
    marginBottom: 5
  },
  coinIconContainer: {
    width: 25,
    height: 25
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover'
  },
  optionalText: {
    fontSize: 14,
    color: '#FFFFFF',
    textTransform: 'none'
  }
})