import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';

export function LabelAndDescription(props) {
  return (
    <Fragment>
      <p className={props.disabled ? css(Styles.label, props.labelStyle, Styles.labelDisable)
        : css(Styles.label, props.labelStyle)}>
        {props.label}
        {props.optional && <span className={css(Styles.optionalText)}> (optional)</span>}</p>
      {props.descr && <p className={css(Styles.labelDescr)}>{props.descr}</p>}
    </Fragment >
  )

}

const Styles = StyleSheet.create({
  label: {
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'uppercase',
    margin: 0,
  },
  optionalText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    textTransform: 'lowercase'
  },
  labelDescr: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginTop: 4,
    marginBottom: 10
  },
  labelDisable: {
    color: '#FFFFFF80'
  }
})