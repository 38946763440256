import React, { Component, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';
import { DocumentUploader } from '../common/components/DocumentUploader';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { Helper } from '../common/helpers/Helper';
import { UiHelper } from '../common/helpers/UiHelper';
import { UploadHelper } from '../common/helpers/UploadHelper';
import { AppConfig } from '../AppConfig';
import { AppTheme } from '../styles/AppTheme';
import { CustomDocumentUploaderStyles } from '../styles/CustomStyles';
const uuid = require('uuid');

const FILE_SIZE_MB = 10000000
export class CustomDocumentUploader extends DocumentUploader {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      fetchState: ApiHelper.State.READY,
      uploadInProgress: false
    };
  }

  componentDidMount() {
  }

  render() {
    const inputTagId = 'upload-button-' + moment().valueOf()
    return (
      <div>
        <div className={css(Styles.container)}>
          <input
            id={inputTagId}
            className={css(Styles.fileInput)}
            onChange={this.onFileChange.bind(this)}
            type='file'
            style={{ display: 'none' }}
            accept={this.props.uploadAccept || 'application/pdf'}
            disabled={this.props.disabled || this.props.uploadDisabled} />
          <div className={css(Styles.buttonContainer)}>
            <label htmlFor={inputTagId}
              className={this.props.disabled === true || this.props.uploadDisabled === true ?
                css(Styles.labelContainer, Styles.labelDisableContainer) : css(Styles.labelContainer)}>
              <div className={this.props.disabled === true || this.props.uploadDisabled === true ?
                css(Styles.uploadButton, Styles.uploadButtonDisable) : css(Styles.uploadButton)}>
                {this.state.uploadInProgress ?
                  <CircularProgress size={24} className={css(Styles.progressIcon)} />
                  :
                  <p className={css(Styles.uploadButtonText)}>upload</p>
                }
              </div>
            </label>
            <p className={css(Styles.buttonInfoText)}>Each file’s size must be under {this.props.fileSizeLimit / 1000000}MB.</p>
          </div>
        </div>
        {this.state.message && !this.props.disabled && this.renderMessage()}
      </div>
    )
  }

  renderMessage() {
    let messageClass = css(Styles.textUpload, Styles.text)
    if (this.state.fetchState === ApiHelper.State.ERROR) {
      messageClass = css(Styles.textError, Styles.text)
    } else if (this.state.fetchState === ApiHelper.State.READY) {
      messageClass = css(Styles.textSuccess, Styles.text)
    }
    return (
      <div className={css(Styles.msgContainer)}>
        <p className={messageClass}>{this.state.message}</p>
        {this.props.link &&
          <a className={css(Styles.openDoc)} href={this.props.link} target='_blank'>{Helper.getString('openFile')}</a>
        }
      </div>
    )
  }

  onFileChange(e) {
    const file = e.target.files[0]
    e.target.value = ''  //To support same file upload then delete then Upload same file
    // TODO: validate Extension & Size
    if (file !== undefined) {
      this.readFile(file)
    }
  }

  readFile(file) {
    this.props.handleDocumentUploadState(true)
    super.readFile(file)
  }

  onUpload() {
    if (!this.state.file) {
      return this.onError(Helper.getString('fileChoose'))
    }
    const maxSize = this.props.fileSizeLimit ? this.props.fileSizeLimit : FILE_SIZE_MB;
    if (this.state.file.size > maxSize) {
      const errMsg = maxSize / 1000000 + Helper.getString('fileSizeExceedMsg')
      this.onError(errMsg)
      this.setState({
        message: ''
      })
      return this.props.onUploadSizeError()
    }
    this.setState(
      {
        message: '',
        fetchState: ApiHelper.State.READY
      }
    )
    const expansionId = this.props.expansionID && this.props.expansionID
    let ext = null;
    if (this.state.file && this.state.file.name) {
      const userInputFilename = this.state.file.name;
      ext = userInputFilename.split('.').pop()
    }

    const options = {
      uploadLocation: this.props.uploadLocation,
      type: this.props.uploadType || 'media',
      ext: ext || this.props.uploadExt || 'pdf'
    }
    if (this.props.signedUploadInfo) {
      this._uploadAssetWithSignedUploadInfo(options, this.state.file, expansionId) // All these files are uploaded to content specific folder
    } else {
      this._fetchUploadUrl(options, this.state.file, expansionId)
    }
  }
  _uploadAssetWithSignedUploadInfo = (options, file, expansionId) => {
    let signedUploadInfo
    try {
      signedUploadInfo = JSON.parse(JSON.stringify(this.props.signedUploadInfo))
    } catch (e) {
      console.log('Json parse error', e)
      this.onError(Helper.getString('fileUploadErr'))
    }
    const types = file.type.split('/')
    let fileExtn = ''
    if (types.length === 2) {
      fileExtn = types[1] || options.ext
    }
    signedUploadInfo.fields.key = signedUploadInfo.fields.key + uuid.v4() + '.' + fileExtn
    signedUploadInfo.link = AppConfig.CDN_BASEPATH + '/' + signedUploadInfo.fields.key
    signedUploadInfo.fields['Content-Type'] = file.type
    const uploadOptions = {
      pickerResult: file,
      signedUploadInfo: signedUploadInfo,
      onEvent: (type, percent) => {
        console.log('progress:', type, percent)
        if (type === 'load') {
          return this.onUploadComplete(signedUploadInfo, file, expansionId)
        } else if (type === 'error') {
          return this.onError(Helper.getString('fileUploadErr'))
        }
      }
    }
    UploadHelper.uploadFormDataWithProgress(uploadOptions)
  }

  _fetchUploadUrl = (options, file, expansionId) => {
    UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo, expansionId) => {
      if (err) {
        return this.onError(err)
      }
      console.log('fetchUploadUrl:', err, signedUploadInfo)
      const uploadOptions = {
        pickerResult: file,
        signedUploadInfo: signedUploadInfo,
        onEvent: (type, percent) => {
          console.log('progress:', type, percent)
          if (type === 'load') {
            return this.onUploadComplete(signedUploadInfo, file)
          } else if (type === 'error') {
            return this.onError(Helper.getString('fileUploadErr'))
          }
        }
      }
      UploadHelper.uploadFormDataWithProgress(uploadOptions)
    })
  }

  onUploadComplete(signedUploadInfo, pickerResult) {
    this.setState({
      fetchState: ApiHelper.State.READY,
      uploadInProgress: false,
    })
    if (this.props.onUploadSuccess) {
      this.props.onUploadSuccess(this.props.uploadId, signedUploadInfo, pickerResult)
    }
  }

  onError(err) {
    this.props.handleDocumentUploadState(false)
    super.onError(err)
  }

}

const Styles = AppConfig.CUSTOM_STYLE ? CustomDocumentUploaderStyles : StyleSheet.create({

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 15
  },
  fileInput: {
    width: '100%'
  },
  button: {
    backgroundColor: AppTheme.primaryColor,
    borderRadius: AppTheme.borderRadius,
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
    fontSize: 10,
    color: AppTheme.secondaryBg
  },
  text: {
    fontSize: 10,
    margin: 0,
    marginRight: 10,
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer',
    borderRadius: AppTheme.borderRadius
  },
  labelDisableContainer: {
    cursor: 'unset',
  },
  attachFile: {
    marginRight: 10,
    color: AppTheme.primaryColor,
    minWidth: 160
  },
  closeIconContainer: {
    color: AppTheme.primaryColor,
    marginRight: 10
  },
  openDoc: {
    marginTop: 0,
    marginBottom: 0,
    color: AppTheme.primaryColor,
    fontSize: 10,
    textDecoration: 'none',
    borderBottom: '0.5px solid ' + AppTheme.primaryColor
  },
  msgContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5
  },

  imageIconContainer: {
    width: 38,
    height: 38,
    display: 'flex',
    marginRight: 10
  },
  imageContent: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: '25px 20px'
  },
  targetContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },

  documentContainer: {
    display: 'flex',
    flex: 1,
  },
  documentImageContainer: {
    width: 40,
    height: 40
  },
  closeImgContainer: {
    display: 'flex',
    cursor: 'pointer',
    width: 35,
    height: 35,
    zIndex: 130000,
  },
  closeImgDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  documentNameContainer: {
    fontSize: 16,
    color: '#222222',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  },
  attachFileText: {
    fontSize: 16,
    color: '#222222',
    margin: 0
  },
  attachFileTextBrowse: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  reuploadIconContainer: {
    right: 36
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #FFFFFF80',
    cursor: 'pointer',
    borderRadius: 4,
    minWidth: 118,
    minHeight: 53,
  },
  uploadButtonDisable: {
    cursor: 'unset'
  },
  uploadButtonText: {
    fontSize: 18,
    color: '#FFFFFF80',
    textTransform: 'uppercase',
    margin: 0
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover'
  },
  uploadedDocumentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadDocumentText: {
    fontSize: 18,
    color: '#FFFFFF80'
  },
  buttonInfoText: {
    margin: 0,
    fontSize: 18,
    color: '#FFFFFF80',
    marginLeft: 15
  }
})
