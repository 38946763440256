import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { AppButton } from '../common/components/AppButton';
import { Checkbox, Dialog } from '@material-ui/core';
import { Controller } from '../common/Controller';
import { DONOTSHOW_GETSTARTED } from '../Consts';

export function GetStartedPopup(props) {
  const [isChecked, setIsChecked] = useState(false);

  const currentUserId = Controller.get().userMgr().getUserId();

  useEffect(() => {
    const storedValue = localStorage.getItem(currentUserId + DONOTSHOW_GETSTARTED);
    setIsChecked(storedValue === 'true' ? true : false);
  }, [])

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    localStorage.setItem(currentUserId + DONOTSHOW_GETSTARTED, e.target.checked);
  }

  return (
    <Dialog open={props.showGetStartedPopup} onClose={() => props.onClose()} classes={{ paper: css(Styles.paper) }} className={css(Styles.dialog)}>
      <div className={css(Styles.topContainer)}>
        <p className={css(Styles.headingText)}>Get started with Razzl interactive videos</p>
        <AppButton buttonStyle={Styles.stepsButtonStyles} buttonTextStyle={Styles.stepsButtonTextStyles} buttonText={<><span className={css(Styles.stepsButtonNumberStyles)}>1.</span> Setup your profile (under PROFILE)</>} />
        <AppButton buttonStyle={Styles.stepsButtonStyles} buttonTextStyle={Styles.stepsButtonTextStyles} buttonText={<><span className={css(Styles.stepsButtonNumberStyles)}>2.</span> Link your PayPal account (under PROFILE)</>} />
        <AppButton buttonStyle={Styles.stepsButtonStyles} buttonTextStyle={Styles.stepsButtonTextStyles} buttonText={<><span className={css(Styles.stepsButtonNumberStyles)}>3.</span> Upload and publish videos (under VIDEOS)</>} />
        <p className={css(Styles.bottomText)}>Completing Steps 1 & 2 before Step 3 is recommended.</p>
      </div>
      <div className={css(Styles.bottomContainer)}>
        {props.showCheckboxContainer &&
          <div className={css(Styles.checkboxContainer)}>
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} classes={{ root: css(Styles.checkbox) }} />
            <p className={css(Styles.dontShowText)}>Do not show this message again</p>
          </div>}
        <AppButton buttonStyle={Styles.closeButtonStyles} buttonTextStyle={Styles.closeButtonTextStyles} buttonText='Close' onClick={() => props.onClose()} />
      </div>
    </Dialog>
  )
}

const Styles = StyleSheet.create({
  paper: {
    position: 'absolute',
    backgroundColor: '#000000',
    color: 'white',
    overflowY: 'auto',
    border: '1px solid #FFFFFF80',
    borderRadius: 8,
  },
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '13002 !important',
    fontSize: 18,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 30,
    padding: '30px 45px 25px 45px',
  },
  headingText: {
    margin: 0,
    fontWeight: 600
  },
  stepsButtonStyles: {
    backgroundColor: '#FFFFFF1A',
    height: 50,
    width: 410,
    borderRadius: 35,
    padding: '9px 16px',
    cursor: 'default'
  },
  stepsButtonTextStyles: {
    fontSize: 16,
    fontWeight: 'normal'
  },
  stepsButtonNumberStyles: {
    color: '#037BFE',
    fontWeight: 'bold',
  },
  bottomText: {
    margin: 0,
    fontSize: 14
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px 45px 30px 45px',
    borderTop: '1px solid #FFFFFF80'
  },
  checkboxContainer: {
    display: 'flex',
    gap: 10
  },
  checkbox: {
    color: '#707070',
    width: 20,
    height: 20,
    borderRadius: '6px',
    padding: 0
  },
  dontShowText: {
    margin: 0,
    marginBottom: 20,
    color: '#FFFFFF80',
    fontSize: 15
  },
  closeButtonStyles: {
    backgroundColor: '#223DE2',
    borderRadius: '4px',
    width: 125,
    height: 50
  },
  closeButtonTextStyles: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 400,
    textTransform: 'uppercase'
  }
})