export const testdata = {
  dashboardContent: {
    id: 1,
    title: 'Dashboard',
    items: [
      {
        id: 1,
        title: 'Content',
        items: [
          {
            key: 'buyCount',
            contentTitle: 'SALES',
            value: '0'
          },
          {
            key: 'earningsSum',
            contentTitle: 'EARNINGS',
            value: '0',
            helpText: 'Creators receive 35% of the revenue from their Razzl sales minus any withheld income tax.\n\nRazzl does not withhold income tax for US based creators. Razzl is required to withhold income tax for non-US based creators.'
          },
          {
            key: 'earningsPayoutSum',
            contentTitle: 'PAYOUT',
            value: '0',
            helpText: 'Complete Payout Setup under your Profile to receive payments for your sales.'
          },
          {
            key: 'earningsBalance',
            contentTitle: 'BALANCE',
            value: '0',
            helpText: 'Your account balance since last payout.'
          },
          {
            key: 'postCount',
            contentTitle: 'POSTS',
            value: '0'
          },
          {
            key: 'viewCount',
            contentTitle: 'VIEWS',
            value: '0'
          },
          {
            key: 'shareCount',
            contentTitle: 'SHARES',
            value: '0'
          },
          {
            key: 'reviewCount',
            contentTitle: 'RATINGS',
            value: '0'
          },
        ]
      },
      {
        id: 2,
        title: 'Social',
        items: [
          {
            key: 'followCount',
            contentTitle: 'FOLLOWERS',
            value: '0'
          },
          {
            key: 'profileviewCount',
            contentTitle: 'PROFILE VIEWS',
            value: '0'
          }
        ]
      }
    ]
  },

  uploadedVideos: {
    id: 1,
    title: 'Videos',
    helpText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    actions: [
      {
        id: 1,
        type: 'button',
        label: '+ Upload video'
      }
    ],
    items: [
      {
        tileImage: require('../assets/images/temp-tile -image.jpg'),
        contentTitle: 'CHANGE DRYER CORD',
        createdDateTime: 'Jun 25, 2022. 25M.', //TODO
        price: '$0.49',
        state: 'private',
        rating: '4',
        reviewCount: '125',
        viewCount: '1.5k',
        ratingIcon: require('../assets/images/rating-on.png'),
        viewIcon: require('../assets/images/views.png'),
      },
      {
        tileImage: require('../assets/images/temp-tile -image.jpg'),
        contentTitle: 'CHANGE DRYER CORD',
        createdDateTime: 'Jun 25, 2022. 25M.',
        price: '$0.49',
        state: 'private',
        rating: '4',
        reviewCount: '125',
        viewCount: '1.5k',
        ratingIcon: require('../assets/images/rating-on.png'),
        viewIcon: require('../assets/images/views.png'),
      },
      {
        tileImage: require('../assets/images/temp-tile -image.jpg'),
        contentTitle: 'CHANGE DRYER CORD',
        createdDateTime: 'Jun 25, 2022. 25M.',
        price: '$0.49',
        state: 'private',
        rating: '4',
        reviewCount: '125',
        viewCount: '1.5k',
        ratingIcon: require('../assets/images/rating-on.png'),
        viewIcon: require('../assets/images/views.png'),
      },
      {
        tileImage: require('../assets/images/temp-tile -image.jpg'),
        contentTitle: 'CHANGE DRYER CORD',
        createdDateTime: 'Jun 25, 2022. 25M.',
        price: '$0.49',
        state: 'private',
        rating: '4',
        reviewCount: '125',
        viewCount: '1.5k',
        ratingIcon: require('../assets/images/rating-on.png'),
        viewIcon: require('../assets/images/views.png'),
      },
      {
        tileImage: require('../assets/images/temp-tile -image.jpg'),
        contentTitle: 'CHANGE DRYER CORD',
        createdDateTime: 'Jun 25, 2022. 25M.',
        price: '$0.49',
        state: 'private',
        rating: '4',
        reviewCount: '125',
        viewCount: '1.5k',
        ratingIcon: require('../assets/images/rating-on.png'),
        viewIcon: require('../assets/images/views.png'),
      },
      {
        tileImage: require('../assets/images/temp-tile -image.jpg'),
        contentTitle: 'CHANGE DRYER CORD',
        createdDateTime: 'Jun 25, 2022. 25M.',
        price: '$0.49',
        state: 'private',
        rating: '4',
        reviewCount: '125',
        viewCount: '1.5k',
        ratingIcon: require('../assets/images/rating-on.png'),
        viewIcon: require('../assets/images/views.png'),
      },

    ]
  }
  // pages: {
  //   title: 'test',
  //   image: '/images/bannermock.svg',
  //   entities: [
  //     {
  //       id: 'dashboardContent',
  //     }
  //   ],
  //   __entities: {
  //     dashboardContent: {
  //       title: 'Dashboard',
  //       items: [
  //         {
  //           id: 1,
  //           title: 'Content',
  //           items: [
  //             {
  //               contentTitle: 'VIDEOS SOLD',
  //               value: '10'
  //             },
  //             {
  //               contentTitle: 'EARNINGS',
  //               value: '$25.75'
  //             },
  //             {
  //               contentTitle: 'PAYOUT',
  //               value: '$16.75'
  //             },
  //             {
  //               contentTitle: 'BALANCE',
  //               value: '$9.00'
  //             },
  //             {
  //               contentTitle: 'POSTS',
  //               value: '10'
  //             },
  //             {
  //               contentTitle: 'VIEWS',
  //               value: '10'
  //             },
  //             {
  //               contentTitle: 'SHARES',
  //               value: '20'
  //             },
  //             {
  //               contentTitle: 'REVIEWS',
  //               value: '15'
  //             },
  //           ]
  //         },
  //         {
  //           title: 'Social',
  //           items: [
  //             {
  //               contentTitle: 'FOLLOWERS',
  //               value: '20'
  //             },
  //             {
  //               contentTitle: 'PROFILE VIEWS',
  //               value: '115'
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   }
  // }
}
