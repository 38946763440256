import React, { Component, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DocumentUploader } from '../common/components/DocumentUploader';
import { AppConfig } from '../AppConfig';
import { CustomDocumentUploaderStyles } from '../styles/CustomStyles';
import { ProjectHelper } from '../helpers/ProjectHelper';
import { ApiHelper } from '../common/helpers/ApiHelper';

export class FileStackFilesUploader extends DocumentUploader {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async uploadToS3(fileStackResponse) {
    const fileStackResponseMap = {}
    const fileStackResponseArray = []
    let result = []
    fileStackResponse.map((element) => {
      fileStackResponseMap[element.key] = {
        fileStackUploadResponseKey: element.key,
        name: element.originalFile.name,
        type: element.originalFile.type
      }
      fileStackResponseArray.push(element.key)
    })
    const body = {
      contentId: this.props.contentId,
      fsImportedFileNames: fileStackResponseArray,
    }
    console.log('FileStackFilesUploader:: body ', body)
    const options = {
      method: 'POST',
      paths: ['miscs', 'fstocontent'],
      jsonBody: body,
    }
    const uploadToS3FilesMap = await ApiHelper.callAwait(options)

    if (uploadToS3FilesMap.copied) {
      for (const key in uploadToS3FilesMap.copied) {
        uploadToS3FilesMap.copied[key].name = fileStackResponseMap[key].name
      }
      result = Object.values(uploadToS3FilesMap.copied)
    }

    console.log('FileStackFilesUploader:: copied result ', result)
    return result
  }

  onUpload() {
    // Mark as upload under progress in the parent component
    if (this.props.onUploadUnderProgress) {
      this.props.onUploadUnderProgress(true)
    }
    ProjectHelper.uploadFiles(async (uploadResponse) => {
      if (uploadResponse && uploadResponse.filesFailed && uploadResponse.filesFailed.length > 0) {
        // Upload Failed at least for one
        this.props.handleUploadFailure()
      } else if (uploadResponse && uploadResponse.filesUploaded && uploadResponse.filesUploaded.length > 0) {
        // Success
        this.setState({ uploadInProgress: true })
        // Call next API to coy to S3, show progress
        let uploadToS3FilesList = []
        try {
          uploadToS3FilesList = await this.uploadToS3(uploadResponse.filesUploaded)
          this.setState({ uploadInProgress: false })
          this.props.onUploadSuccess(uploadToS3FilesList)
        } catch (e) {
          console.log('FileStackFilesUploader:: upload to s3 failed', e)
          this.setState({ uploadInProgress: false })
          this.props.handleUploadFailure()
          return
        }
      }
    }, (pickerFileMetadata, error) => {
      // do nothing
    }, (data) => {
      this.props.onUploadUnderProgress(false)
    }
    )
  }



  render() {
    return (
      <div>
        <div className={css(Styles.container)}>
          <div onClick={this.onUpload.bind(this)} className={css(Styles.buttonContainer)}>
            <div className={this.props.uploadDisabled === true ?
              css(Styles.uploadButton, Styles.uploadButtonDisable) : css(Styles.uploadButton)}>
              {this.state.uploadInProgress ? <CircularProgress size={24} className={css(Styles.progressIcon)} /> : <p className={css(Styles.uploadButtonText)}>upload</p>
              }
            </div>
            <p className={css(Styles.buttonInfoText)}>Each file’s size must be under {this.props.fileSizeLimit / 1000000}MB.</p>
          </div>
        </div>
      </div>
    )
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomDocumentUploaderStyles : StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 15
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #FFFFFF80',
    cursor: 'pointer',
    borderRadius: 4,
    minWidth: 118,
    minHeight: 53,
  },
  uploadButtonDisable: {
    cursor: 'unset'
  },
  uploadButtonText: {
    fontSize: 18,
    color: '#FFFFFF80',
    textTransform: 'uppercase',
    margin: 0
  },
  buttonInfoText: {
    margin: 0,
    fontSize: 18,
    color: '#FFFFFF80',
    marginLeft: 15
  }
})
