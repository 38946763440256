import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';

export class MetaDataHelper {
  static getMetaData(key) {
    switch (key) {
    case 'privacy':
      return {
        descr: 'Privacy Policy | Razzl',
        searchable: 'Razzl',
        title: 'Privacy Policy | Razzl'
      }
    case 'terms':
      return {
        descr: 'Terms of Service | Razzl',
        searchable: 'Razzl',
        title: 'Terms of Service | Razzl'
      }
    case 'guidelines':
      return {
        descr: 'Community Guidelines | Razzl',
        searchable: 'Razzl',
        title: 'Community Guidelines | Razzl'
      }
    case 'intellectualPolicy':
      return {
        descr: 'Intellectual Property Policy | Razzl',
        searchable: 'Razzl',
        title: 'Intellectual Property Policy | Razzl'
      }
    default:
      return {
        descr: 'Razzl Creator Portal',
        searchable: 'Razzl',
        title: 'Razzl Creator Portal'
      }
    }
  }

  static processMetaData = (page, data) => {
    const defaultMetadata = MetaDataHelper.getMetaData(page)
    const metaData = data || {}

    if (!metaData.title) {
      metaData.title = defaultMetadata.title
    }
    if (!metaData.searchable) {
      metaData.searchable = defaultMetadata.searchable
    }
    if (!metaData.descr) {
      metaData.descr = defaultMetadata.descr
    }
    return {
      title: metaData.title,
      description: metaData.descr,
      keywords: metaData.searchable
    }
  }
}