import { parseNumber, formatNumber } from 'libphonenumber-js'
import { BaseError } from '../errors';
import { Helper } from '../helpers/Helper';

export class PhoneNumberHelper {
  static formatNumber(number, defaultCountry) {
    const parsed = parseNumber(number, defaultCountry);
    if (!parsed || !parsed.country || !parsed.phone) {
      throw new BaseError(Helper.getString('invalidNumber'));
    }
    console.log('parsed number:', parsed);
    return formatNumber(parsed, 'E.164');
  }
  static formatNumberV2(number, defaultCountry) { //TODO: why we need
    const parsed = parseNumber(number, defaultCountry);
    if (!parsed || !parsed.country || !parsed.phone) {
      return false
    }
    return formatNumber(parsed, 'E.164');
  }
}
