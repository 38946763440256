
import { Consts, VISIBILITY, PRICINGTYPES, MAX_TEXT_LENGTH } from '../../src/Consts'
export const dataItem = {
  detailsFormItem: [
    {
      key: 'title',
      label: 'Video Title*',
      type: 'TextField',
      placeHolder: 'This is a required field.',
      charachterLimit: MAX_TEXT_LENGTH.VIDEO_TITLE_MAX_TEXT_LENGTH,
      textBoxValue: ''
    },
    {
      key: 'category',
      label: 'Category*',
      type: 'Select',
      placeholder: 'Select Category',
      items: [
        {
          key: '3dPrinting',
          value: '3D Printing'
        },
        {
          key: 'architecture',
          value: 'Architecture'
        },
        {
          key: 'art',
          value: 'Art'
        },
        {
          key: 'assembly',
          value: 'Assembly'
        },
        {
          key: 'beautyAndMakeup ',
          value: 'Beauty and Makeup '
        },
        {
          key: 'business',
          value: 'Business'
        },
        {
          key: 'cad',
          value: 'CAD'
        },
        {
          key: 'circuits',
          value: 'Circuits'
        },
        {
          key: 'design',
          value: 'Design'
        },
        {
          key: 'diy',
          value: 'DIY'
        },
        {
          key: 'education',
          value: 'Education'
        },
        {
          key: 'engineering',
          value: 'Engineering'
        },
        {
          key: 'entertainment',
          value: 'Entertainment'
        },
        {
          key: 'fashion',
          value: 'Fashion'
        },
        {
          key: 'finance&Accounting',
          value: 'Finance & Accounting'
        },
        {
          key: 'food&Drink',
          value: 'Food & Drink'
        },
        {
          key: 'gameDevelopment',
          value: 'Game Development'
        },
        {
          key: 'gaming',
          value: 'Gaming'
        },
        {
          key: 'gardening',
          value: 'Gardening'
        },
        {
          key: 'health&Fitness ',
          value: 'Health & Fitness '
        },
        {
          key: 'history',
          value: 'History'
        },
        {
          key: 'homeDécor',
          value: 'Home Décor'
        },
        {
          key: 'homeImprovement',
          value: 'Home Improvement'
        },
        {
          key: 'it&Software',
          value: 'IT & Software'
        },
        {
          key: 'lifestyle',
          value: 'Lifestyle'
        },
        {
          key: 'marketing',
          value: 'Marketing'
        },
        {
          key: 'media',
          value: 'Media'
        },
        {
          key: 'music',
          value: 'Music'
        },
        {
          key: 'nature',
          value: 'Nature'
        },
        {
          key: 'outdoors',
          value: 'Outdoors'
        },
        {
          key: 'personalDevelopment',
          value: 'Personal Development'
        },
        {
          key: 'petCare',
          value: 'Pet Care'
        },
        {
          key: 'photography&Video',
          value: 'Photography & Video'
        },
        {
          key: 'productivity',
          value: 'Productivity'
        },
        {
          key: 'products',
          value: 'Products'
        },
        {
          key: 'programmingLanguages',
          value: 'Programming Languages'
        },
        {
          key: 'repairs',
          value: 'Repairs'
        },
        {
          key: 'science',
          value: 'Science'
        },
        {
          key: 'sports',
          value: 'Sports'
        },
        {
          key: 'softwareDevelopment ',
          value: 'Software Development '
        },
        {
          key: 'technology',
          value: 'Technology'
        },
        {
          key: 'teaching&Academics',
          value: 'Teaching & Academics'
        },
        {
          key: 'travel',
          value: 'Travel'
        },
        {
          key: 'workshop',
          value: 'Workshop'
        },
        {
          key: 'other',
          value: 'Other'
        },

      ]
    },
    {
      key: 'level',
      label: 'Level',
      type: 'Select',
      placeholder: 'Select Level',
      optional: 'optional',
      items: [
        {
          key: 'beginnerLevel',
          value: 'Beginner level'
        },
        {
          key: 'intermediateLevel',
          value: 'Intermediate level'
        },
        {
          key: 'expertLevel',
          value: 'Expert level'
        },
        {
          key: 'allLevels',
          value: 'All levels'
        },
      ]
    },
    {
      key: 'descr',
      label: 'Video Description*',
      type: 'TextField',
      rows: 5,
      multiLine: true,
      placeHolder: 'This is a required field.',
      charachterLimit: MAX_TEXT_LENGTH.DESCR_MAX_TEXT_LENGTH,
      textBoxValue: ''
    },
  ],
  userProfile: {
    key: 'user',
    title: 'Profile',
    name: {
      key: 'name',
      label: 'Name*',
      placeHolder: 'Your channel name',
      textBoxValue: '',
      charachterLimit: MAX_TEXT_LENGTH.PROFILE_NAME_MAX_TEXT_LENGTH,

    },
    userName: {
      key: 'username',
      label: 'UserName*',
      placeHolder: 'user123',
      textBoxValue: '',
      charachterLimit: MAX_TEXT_LENGTH.PROFILE_USERNAME_MAX_TEXT_LENGTH,

    },
    email: {
      key: 'email',
      label: 'Email',
      placeHolder: 'Email',
      textBoxValue: ''
    },
    instagramLink: {
      key: 'instagramLink',
      label: 'Instagram Link',
      placeHolder: 'Instagram Link',
      textBoxValue: ''
    },
    linkedInLink: {
      key: 'linkedinLink',
      label: 'LinkedIn Link',
      placeHolder: 'LinkedIn Link',
      textBoxValue: ''
    },
    bio: {
      key: 'bio',
      label: 'bio*',
      charachterLimit: MAX_TEXT_LENGTH.PROFILE_BIO_MAX_TEXT_LENGTH,
      multiLine: true,
      textBoxValue: ''
    }
  }
}


// Default value during project creation
export const projectData2Default = {
  filename: '',
  details: {
    instructionalVideo: 'YES',
    title: '',
    descr: '',
    category: '',
    level: '',
    thumbTs: 0,
    thumbnailType: 'USER_VIDEO_FRAME', //'USER_UPLOAD/USER_VIDEO_FRAME'
    thumbnail: '',
    thumbnailVideoFrame: '',
    thumbnailName: ''
  },
  images: [],
  imageObjs: [],
  materials: {
    callToAction: '',
    callToActionUrl: '',
    descr: '',
  },
  steps: [
    {
      stepIndex: 0,
      title: '',
      startTs: 0,
      descr: '',
      tips: '',
      documents: [],
      images: [],
      imageObjs: [],
    }
  ],
  pricing: {
    type: PRICINGTYPES.FREE, //'ALL_IN_ONE_PRICE', // 'FREE'
    price: '',
    contentprice: ''
  },
  visibility: {
    privacy: VISIBILITY.PRIVATE
  }
}

export const dashboardContent = {
  id: 1,
  title: 'Dashboard',
  items: [
    {
      id: 1,
      title: 'Content',
      items: [
        {
          key: 'buyCount',
          contentTitle: 'SALES',
          value: '0'
        },
        {
          key: 'earningsSum',
          contentTitle: 'EARNINGS',
          value: '0',
          // helpText: 'Creators receive a 35% share of the sale amount collected by Razzl for each user purchase of their content (videos, downloads). \n\nRazzl may be obligated to withhold income tax from payments due to creators. However, typically no income tax is withheld for creators based in the United States.'
        },
        {
          key: 'earningsPayoutSum',
          contentTitle: 'PAYOUT',
          value: '0',
          helpText: 'Complete Payout Setup under your Profile to receive payments for your sales.'
        },
        {
          key: 'earningsBalance',
          contentTitle: 'BALANCE',
          value: '0',
          helpText: 'Your account balance since last payout.'
        },
        {
          key: 'postCount',
          contentTitle: 'POSTS',
          value: '0'
        },
        {
          key: 'viewCount',
          contentTitle: 'VIEWS',
          value: '0'
        },
        {
          key: 'shareCount',
          contentTitle: 'SHARES',
          value: '0'
        },
        {
          key: 'ratingAverage',
          contentTitle: 'RATING',
          value: '0'
        },
      ]
    },
    {
      id: 2,
      title: 'Social',
      items: [
        {
          key: 'followCount',
          contentTitle: 'FOLLOWERS',
          value: '0'
        },
        {
          key: 'profileviewCount',
          contentTitle: 'PROFILE VIEWS',
          value: '0'
        }
      ]
    }
  ]
}


