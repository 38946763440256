/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
export const AppConfigGen = {
  "ADMIN_DRAWER_MENU_ENABLED": true,
  "APPAK": "app-aa17b4b4-7ff8-45ea-8083-c373d7c723f9",
  "CLOUD_REGION": "us-east-1",
  "COGNITO_USER_POOL": "us-east-1_a8R4gLfT0",
  "COGNITO_USER_POOL_CLIENT_ID": "40lunpj306lpme7ra9oa8c2018",
  "COMPANY_NAME": "TODO",
  "DEFAULT_COUNTRY": "TODO",
  "DEFAULT_CURRENCY": "TODO",
  "DEFAULT_LANG": "en",
  "DEFAULT_ZONEINFO": "TODO",
  "DESCR": "TODO",
  "ENDPOINT": "https://si4nkh75f7.execute-api.us-east-1.amazonaws.com/Prod",
  "NAME": "TODO",
  "PASSWORD_POLICY": "TODO",
  "SUPPORT_EMAIL": "TODO",
  "TINT_COLOUR": "#0246E5",
  "TITLE": "TODO",
  "USER_PRIMARY": "email",
  "WEBAPP_LINK": "https://creator.razzl.com"
}
