import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { createStyles, FormControl, FormControlLabel, Input, makeStyles, MenuItem, Radio, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Helper } from '../common/helpers/Helper';
import Switch from 'react-switch';
import { CommonStyles } from '../styles/Styles';


export function TextBoxs(props) {
  const expansionID = props.expansionID && props.expansionID
  return (
    <FormControl className={css(Styles.formContainer, props.formControlStyle)}>
      {props.item.label &&
        <p className={css(CommonStyles.formTitiles,props.labelStyle)}>{props.item.label}</p>
      }
      <TextField
        key={props.item.key}
        multiline={props.item.multiLine}
        disabled={props.disable && props.disable}
        className={css(Styles.textFeildStyle, props.textBoxStyle)}
        placeholder={props.item.placeHolder}
        inputProps={{
          maxLength: props.item.charachterLimit && props.item.charachterLimit,
          style: props.inputStyle,
        }}
        value={props.value}
        helperText={props.item.charachterLimit && `${props.value.length}/${props.item.charachterLimit}`}
        FormHelperTextProps={{
          className: css(Styles.textFieldHelperText, props.helperTextStyle)
        }}
        onChange={(e) => props.handleTextBox(props.item.key, e, expansionID)}
        onBlur={props.onBlur ? (e) => props.onBlur(e, props.item.key, expansionID) : undefined}
        onKeyPress={props.onKeyboardPress && props.onKeyboardPress}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: css(Styles.textInputStyles, props.textBoxInputStyle)
          }
        }} />
    </FormControl>
  )
}

export function SelectBox(props) {
  const useStyle = makeStyles(theme =>
    createStyles({
      option: {
        // Hover
        // '&[data-focus="true"]': {
        //   backgroundColor: '#171717',
        // },
        // Selected
        '&[aria-selected="true"]': {
          backgroundColor: '#037BFE',
        },
      }
    }))

  const highlightedOption = useStyle()
  return (
    <FormControl className={css(Styles.autoSelectStyle, props.selectFormControl)}>
      {props.item.label &&
        <p className={css(CommonStyles.formTitiles, props.labelStyle)}>{props.item.label}
          {props.item.optional && <span className={css(Styles.optionalText, props.optionalTextStyle)}> ({props.item.optional})</span>}</p>
      }
      {props.item.descr &&
        <p className={css(Styles.labelDescr, props.descStyle)}>{props.item.descr}</p>
      }
      <Autocomplete
        fullWidth
        value={props.value}
        key={props.item.key}
        name={props.item.key}
        options={props.item.items} // What needs to be shwon in dropdown
        disabled={props.disabled}
        getOptionLabel={(option) => option.value} // What needs to be shown in the select box once a value is selected
        onChange={(e, val) => props.handleSelectBox(e, val, props.item.key)} // This is the field key, not dropdown list key
        getOptionSelected={(option) => option.key === props.value.key ? true : false} // getting the selected value from dropdown. This should be based on key.
        classes={{
          paper: css(Styles.paper),
          popper: css(Styles.listBoxPaper),
          popupIndicator: css(Styles.dropDownIndicator),
          clearIndicatorDirty: css(Styles.clearIndicator),
          endAdornment: css(Styles.endAdornmentStyle),
          option: highlightedOption.option
        }}
        disableClearable={props.disableClearable && props.disableClearable === true ? true : false}
        popupIcon={<div className={css(Styles.dropdownarrowImageContainer)}>
          <img src={require('../assets/images/dropdownarrow.png')}
            width='20' height='20' alt={Helper.getString('dropdownarrowImageAlt')}
            className={css(Styles.img)} />
        </div>}
        renderOption={(option) => ( // What needs to be shown dropdownlist
          <p className={css(Styles.selectInputStyles)}>
            {option.value}
          </p>
        )}
        renderInput={(params) => <TextField {...params}
          placeholder={props.item.placeholder}
          className={css(Styles.selectTextFeild, props.selectStyle)}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            classes: {
              root: css(Styles.textInputStyles, Styles.selectInputStyle)
            }
          }} />}
      />
    </FormControl>
  )
}

export function SwitchComponent(props) {


  return (
    <Switch
      key={'checked'}
      checked={props.checked}
      onChange={(e) => props.handleSwitch(e)}
      value='instructionalVideo'
      onColor='#FFFFFF'
      offColor='#535758'
      offHandleColor='#000000'
      onHandleColor='#000000'
      handleDiameter={15}
      boxShadow='0px 1px 5px #535758'
      activeBoxShadow='0px 0px 1px 5px #535758'
      height={20}
      width={51}
      uncheckedIcon={
        <div className={css(Styles.switchText, Styles.switchOffText)}>
          no
        </div>
      }
      checkedIcon={
        <div className={css(Styles.switchText, Styles.switchOntText)}>
          yes
        </div>
      }
      className={css(Styles.switch)}
    />
  )
}

export function RadioButton(props) {
  const useStyle = makeStyles(theme =>
    createStyles({
      customRadioButton: {
        '& svg': {
          width: '30px',
          height: '30px',
        },
        marginRight: '0px'
      }
    })
  )
  const radioButton = useStyle()
  return (
    <div className={css(Styles.radioButtonContainer, props.radioButtonContainer)}>
      <FormControlLabel
        value={props.value}
        control={<Radio
          checked={props.selectedValue === props.value}
          onChange={(e) => props.handleChange(e, props.name)}
          value={props.value}
          name={props.name}
          classes={{ root: css(Styles.radioButtonStyle) }}
        />}
        className={radioButton.customRadioButton}
      />

      <div className={css(Styles.radioButtonLabelContainer)}>
        <div className={css(Styles.radioButtonTopLabelContainer)}>
          <span className={css(Styles.radiobuttonLabel)}>{props.label}</span>
          {props.optionalLabel && <span>{` (${props.optionalLabel})`}</span>}
        </div>
        <p className={css(Styles.labelDescr)}>{props.labelDescr}</p>
      </div>
    </div>
  )
}


const Styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30,
    overflow: 'hidden'
  },
  formContainer: {
    display: 'flex',
    // flex: 1,
    flexDirection: 'column',
  },
  subText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginBottom: 5,
    marginTop: 5
  },
  leftSubText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginBottom: 29
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 90
  },
  thumbnailcontainer: {
    width: 340,
    height: 191,
    borderRadius: 8,
    backgroundColor: '#FFFFFF0F'
  },
  divideText: {
    fontSize: 22,
    textTransform: 'uppercase',
    marginTop: 30,
    marginBottom: 22,
    textAlign: 'center',
    color: '#ffffff'
  },
  centerText: {
    textAlign: 'center'
  },
  textFeildStyle: {
    minHeight: 35,
    borderRadius: 4,
    border: '0.5px solid #FFFFFF80',
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  textInputStyles: {
    // border: 'none',
    padding: '0px 8px',
    display: 'flex',
    flex: 1,
    textAlign: 'left',
    // minHeight: 50,
    fontSize: 16,
    color: '#FFFFFF80',
    '::placeHolder': {
      color: '#FFFFFF80',
    },
    // borderRadius: 4,
    // border: '0.5px solid #FFFFFF80',
  },
  selectInputStyle: {
    paddingRight: '56px',
  },
  selectTextFeild: {
    minHeight: 35,
    borderRadius: 4,
    border: '0.5px solid #FFFFFF80',
    // maxWidth: 345,
    marginBottom: 28
  },
  listBoxPaper: {
    borderRadius: 0,
    zIndex: 130000
  },
  paper: {
    zIndex: 130000,
    fontSize: 16,
    backgroundColor: '#1C1C1E',
    borderRadius: 4,
    border: '0.5px solid #FFFFFF80',
    padding: '0px 10px',
    // maxWidth: 345,
  },
  selectInputStyles: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0
  },
  dropDownIndicator: {
    // fontSize: 21,
    // color: '#FFFFFF80',
    marginRight: 8
  },
  textEditorStyle: {
    minHeight: 79,
    borderRadius: 4,
    border: '0.5px solid #FFFFFF80',
    //marginBottom: 28
  },
  textFieldHelperText: {
    color: '#FFFFFF66',
    fontSize: 14,
    alignSelf: 'flex-end',
    position: 'absolute',
    bottom: -30
  },
  switchText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontSize: 12,
    color: '#000000',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  switchOffText: {
    paddingRight: 4
  },
  switchOntText: {
    paddingLeft: 4,
  },
  switch: {
    marginLeft: 25,
    marginBottom: 10,
    backgroundColor: '#535758',
  },
  formTitileRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  optionalText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    textTransform: 'lowercase'
  },
  switchTrack: {
    backgroundColor: '#ffffff'
  },
  labelDescr: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: '0px',
  },
  radioButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  radioButtonLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 21
  },
  radioButtonTopLabelContainer: {
    fontSize: 16,
    color: '#ffffff',
    margin: 0,
    marginBottom: 5,
  },
  radiobuttonLabel: {
    textTransform: 'uppercase',
  },
  radioButtonStyle: {
    color: '#FFFFFF80',
  },
  clearIndicator: {
    color: '#FFFFFF79',
    padding: 2,
  },
  dropdownarrowImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  autoSelectStyle: {
    display: 'flex',
    flex: 1
  },
  endAdornmentStyle: {
    top: 'unset'
  }
})