import React from 'react';
import { Dialog } from '@material-ui/core';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { AppButton } from '../common/components/AppButton';

const renderPricingGuidelinesContent = () => {
  const Styles = StyleSheet.create({
    content: {
      maxWidth: 474
    },
    topText: {
      fontWeight: 600,
      margin: 0,
      marginBottom: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    heading: {
      fontWeight: 600,
    },
    lastText: {
      margin: 0
    }
  })

  return (
    <div className={css(Styles.content)}>
      <p className={css(Styles.topText)}>Pricing Guidelines</p>
      <p>Razzl provides flexible options for pricing your content:</p>
      <p><span className={css(Styles.heading)}>FREE VIDEO, PAID DOWNLOADS -</span> Offer videos for free and charge a one-time fee for downloads.</p>
      <p>This option is ideal for selling downloadable items such as the project PDF, designs, models, plans, and other digital products.</p>
      <p>Downloads can also be offered for free.</p>
      <p><span className={css(Styles.heading)}>ALL-IN-ONE PRICE -</span> Charge a one-time fee for both videos and downloads combined.</p>
      <p className={css(Styles.lastText)}>This option is suitable for selling videos, including courses on various topics and premium content.</p>
    </div>
  )
}

const renderLicenseContent = () => {
  const Styles = StyleSheet.create({
    content: {
      maxWidth: 575
    },
    button: {
      display: 'inline-block',
      backgroundColor: 'unset',
      color: '#037BFE',
      textTransform: 'none',
      height: 'unset',
      padding: 'unset',
      minWidth: 'unset',
      lineHeight: 'unset'
    },
    buttonLabel: {
      fontSize: 18,
      fontWeight: 600
    },
    topText: {
      color: '#FFFFFF99',
      fontStyle: 'italic',
      margin: 0,
      marginBottom: 15,
    },
    heading: {
      fontWeight: 600,
    },
    boldSpan: {
      fontWeight: 800
    },
    lastText: {
      margin: 0
    }
  })
  return (
    <div className={css(Styles.content)}>
      <p className={css(Styles.topText)}>For a complete list of rights and obligations, please read carefully our <a href={'https://www.razzl.com/content-download-license-agreement'} target='_blank'><AppButton buttonStyle={Styles.button} buttonTextStyle={Styles.buttonLabel} buttonText={'License Agreement'} /></a>.</p>
      <p>When users download content from Razzl, they are granted a license to use the downloaded content, subject to specific rights and obligations. The type of license granted varies based on whether the downloads are offered free-of-charge or for a fee.</p>
      <p className={css(Styles.heading)}>Free-of-Charge Downloads - Key Terms of Granted License:</p>
      <p>. The use of downloaded content is restricted to private, personal, non-commercial purposes.</p>
      <p>. Sharing, selling, sub-licensing, distribution or exchange of content is prohibited.</p>
      <p>. Modifying or adapting the content for the purpose of sharing, selling, sub-licensing, or exchange is prohibited.</p>
      <p className={css(Styles.heading)}>Fee-Based (Paid) Downloads - Key Terms of Granted License:</p>
      <p><span className={css(Styles.boldSpan)}>. Incorporation</span><span> of downloaded content into commercial projects is permitted, subject to certain restrictions (e.g., incorporation into games, films, advertisements, architectural visualizations is permitted).</span></p>
      <p>. Royalty-free use permitted. After full payment of one-time fees, there are no future payments, royalties, fees, costs or expenses that are required or imposed upon for the use of purchased content.</p>
      <p>. Incorporation of content into a logo, corporate name, trademark or service mark is prohibited.</p>
      <p>. Use of content in a manner that enables others to download, use, extract or access the content as a stand-alone object is prohibited.</p>
      <p>. Selling or sub-licensing of content is prohibited.</p>
      <p className={css(Styles.lastText)}>. Distribution of derivative works that closely resemble the original content is prohibited (e.g., selling prints of a 3D asset or a document, or any slightly modified versions thereof, is prohibited).</p>
    </div>
  )
}

export function InfoPopup(props) {
  return (
    <Dialog open={props.open} onClose={() => props.setOpen(false)} classes={{ paper: props.contentType === 'pricingGuidelines' ? css(Styles.pricingGuidelinesPaper) : css(Styles.licensePaper) }} className={css(Styles.dialog)}>
      <div className={css(Styles.closeButton)} onClick={() => props.setOpen(false)}>
        <img src={require('../assets/images/close.png')}
          alt={Helper.getString('closeImageAlt')}
          className={css(Styles.img)}
          width='35'
          height='35'
        />
      </div>
      {props.contentType === 'pricingGuidelines' ? renderPricingGuidelinesContent() : renderLicenseContent()}
    </Dialog>
  )
}

const Styles = StyleSheet.create({
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '13002 !important',
    fontSize: 18,
  },
  licensePaper: {
    position: 'absolute',
    top: '5%',
    bottom: '5%',
    backgroundColor: '#000000',
    color: 'white',
    padding: 30,
    overflowY: 'auto',
    border: '1px solid #FFFFFF80',
    borderRadius: 8,
    '::-webkit-scrollbar': {
      width: 0,
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  pricingGuidelinesPaper: {
    position: 'absolute',
    backgroundColor: '#000000',
    color: 'white',
    padding: 30,
    overflowY: 'auto',
    border: '1px solid #FFFFFF80',
    borderRadius: 8,
  },
  closeButton: {
    position: 'absolute',
    right: 7,
    top: 6,
    cursor: 'pointer'
  },
})