import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { TextField } from '@material-ui/core';
import { RichtextEditor } from '../../common/components/RichtextComponent/RichtextEditor';
import { dataItem } from '../../data/data'
import { SelectBox, SwitchComponent, TextBoxs } from '../../components/WizardTextInputs';
import { CustomImageUploader } from '../../components/CustomImageUploader';
import { PROJECT_STATE } from '../../../src/Consts'
import { LabelAndDescription } from './LabelAndDescription';
import { VideoThumbnailSelector } from '../../components/VideoThumbnailSelector';
import CustomImageUploaderNew from '../../components/CustomImageUploaderNew';

export function DetailsComponent(props) {
  const [imageUploadErrMsg, setImageUploadErrMsg] = useState()

  const detailsFormItem = dataItem.detailsFormItem
  dataItem.detailsFormItem.forEach((item) => {
    if (item.key === 'category') {
      item.items = props.cats
    } else if (item.key === 'level') {
      item.items = props.levels
    }
  })

  const handleTextBox = (key, e) => {
    props.handleFormDataChanges(key, e.target.value)
  }

  const handleSelectBox = (e, value, key) => {
    props.handleFormDataChanges(key, value ? value : '')
  }

  const handleSwitch = (e) => {
    props.handleFormDataChanges('instructionalVideo', e === true ? 'YES' : 'NO')
  }

  const handleThumbnailUploadSuccess = (fieldKey, signedUploadInfoArray) => {
    let signedUploadInfo
    if (signedUploadInfoArray && signedUploadInfoArray.length > 0) {
      signedUploadInfo = signedUploadInfoArray[0]
    } else {
      signedUploadInfo = ''
    }

    props.wizardButtonsDisable(false)
    if (signedUploadInfo === '') {
      props.handleFormDataChanges('thumbnailType', 'USER_VIDEO_FRAME')
      props.handleFormDataChanges('thumbnail', '')
      props.handleFormDataChanges('thumbnailName', '')
    } else {
      props.handleFormDataChanges('thumbnailType', 'USER_UPLOAD')
      props.handleFormDataChanges('thumbnail', signedUploadInfo.link)
      props.handleFormDataChanges('thumbnailName', signedUploadInfo.fields.key)
    }
  }

  const handleThumbnailUploadSuccessFromVideo = (signedUploadInfo, thumbTs) => {
    props.handleFormDataChanges('thumbnailVideoFrame', signedUploadInfo.link)
    props.handleFormDataChanges('thumbnailVideoFrameName', signedUploadInfo.fields.key)
    props.handleFormDataChanges('thumbTs', thumbTs)
    if (!props.projectData.details.thumbnail) {
      props.handleFormDataChanges('thumbnailType', 'USER_VIDEO_FRAME')
    }
  }


  const handleRichtextInputChange = (data) => {
    console.log('handleRichtextInputChange rich text format data', data)
    props.handleFormDataChanges('descr', data)
  }

  const showVideoStateAndThumbnail = () => {
    return (
      <VideoThumbnailSelector
        projectFull={props.projectFull}
        publishProgress={props.publishProgress}
        thumbnailVideoFrame={props.projectData.details.thumbnailVideoFrame}
        thumbTs={props.projectData.details.thumbTs}
        onThumbnailUploaded={handleThumbnailUploadSuccessFromVideo}>
      </VideoThumbnailSelector>)
  }

  // TODO remove
  const middleEllipsis = (str) => {
    if (str.length > 35) {
      return str.substr(0, 20) + '...' + str.substr(str.length - 10, str.length);
    }
    return str;
  }

  const handleImageUploadErrorMsg = (Err) => {
    setImageUploadErrMsg(Err)
  }

  const getRightContainer = () => {
    return (
      <div className={css(Styles.rightContainer)}>
        <p className={css(Styles.formTitiles)}>Thumbnail</p>
        <p className={css(Styles.subText)}>SELECT FROM VIDEO</p>
        <div className={css(Styles.thumbnailcontainer)}>
          {showVideoStateAndThumbnail()}
        </div>
        <div className={css(Styles.fileNameContainer)}>
          <p className={css(Styles.subText, Styles.subTitleText)}>FILENAME: </p>
          <p className={css(Styles.subText, Styles.videoFileText, Styles.subTitleText)}>
            {/* {middleEllipsis(props.projectData.filename)} */}
            {props.projectData.filename}
          </p>
        </div>
        <p className={css(Styles.divideText)}>or</p>
        <p className={css(Styles.subText)}>UPLOAD IMAGE</p>
        <div className={css(Styles.thumbnailcontainer)}>
          <CustomImageUploaderNew
            uploadId={'imageUrl'}
            url={props.projectData.details.thumbnail}
            uploadDimensions={
              [
                { width: 640, height: 640 },
              ]
            }
            uploadExt={'jpg'}
            onUploadSuccess={handleThumbnailUploadSuccess}
            wizardButtonsDisable={props.wizardButtonsDisable}
            handleImageUploadErrorMsg={handleImageUploadErrorMsg}
            cutomImageUploaderIcon={Styles.cutomImageUploaderIcon}
            customImageUploadContainer={Styles.customImageUploadContainer}
            imageContainer={Styles.thumbnailcontainer}
            imageUploadIconContainerWidth={100}
            imageUploadIconContainerHeight={100}
            imageContainerWidth={250}
            imageContainerHeight={140}
            displayErrorInParent={true} />
        </div>
        <p className={css(Styles.subText, Styles.centerText, Styles.subTitleText)}>(JPG or PNG format)</p>
        {imageUploadErrMsg &&
          <p className={css(Styles.errorText)}>{imageUploadErrMsg}</p>
        }
      </div>
    )
  }

  const getLeftContainer = () => {
    const TEXTBOX_CHARACTER_LIMIT = 60
    return (
      <div className={css(Styles.formContainer)}>

        {/* <div className={css(Styles.formTitileRow)}>
          <p className={css(Styles.formTitiles)}>IS THIS A STEP-BY-STEP INSTRUCTIONAL VIDEO?</p>
          <SwitchComponent
            checked={props.projectData.details.instructionalVideo === 'YES' ? true : false}
            handleSwitch={handleSwitch} />
        </div>
        <p className={css(Styles.leftSubText)}>(Set this option to YES if video is a course, or a step-by-step tutorial.)</p> */}
        <TextBoxs item={detailsFormItem[0]} handleTextBox={handleTextBox} value={props.projectData.details.title} />
        <div className={css(Styles.rowContainer)}>
          <SelectBox item={detailsFormItem[1]}
            handleSelectBox={handleSelectBox}
            value={props.projectData.details[detailsFormItem[1].key]}
            selectStyle={Styles.selectBoxStyle}
            selectFormControl={Styles.selectBoxLeftStyle} />
          <SelectBox item={detailsFormItem[2]}
            handleSelectBox={handleSelectBox}
            value={props.projectData.details[detailsFormItem[2].key]}
            selectStyle={Styles.selectBoxStyle} />
        </div>
        <TextBoxs item={detailsFormItem[3]}
          handleTextBox={handleTextBox} value={props.projectData.details.descr}
          textBoxInputStyle={Styles.textAreaBoxInputStyle} inputStyle={{ maxHeight: 320 }} />
      </div>
    )
  }

  return (
    <div className={css(Styles.Container)}>
      {getLeftContainer()}
      {getRightContainer()}
    </div>
  )
}

const Styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 41,
    overflow: 'hiden',
    // minHeight: 729
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginRight: 90,
    paddingBottom: 40,
    '@media(max-width:1024px)': {
      marginRight: 30,
    },
    '@media (max-width:1200px)': {
      marginRight: 30,
    },
  },
  formTitiles: {
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'uppercase',
    margin: 0,
    marginBottom: 10
  },
  subText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginBottom: 5,
    marginTop: 5
  },
  leftSubText: {
    fontSize: 16,
    color: '#FFFFFF80',
    margin: 0,
    marginBottom: 29
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 90,
    width: 'min-content',
    '@media (max-width:1024px)': {
      marginLeft: 30,
    },
    '@media (max-width:1200px)': {
      marginLeft: 30,
    },
  },
  thumbnailcontainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 250, //340,
    height: 140, //191,
    borderRadius: 8,
    backgroundColor: '#FFFFFF0F',
    overflow: 'hidden',
    '@media (max-width:1024px)': {
      width: 250,
      height: 140,
    },
    '@media (max-width:1200px)': {
      width: 250,
      height: 140,
    },
  },
  divideText: {
    fontSize: 22,
    textTransform: 'uppercase',
    marginTop: 15,
    marginBottom: 10,
    textAlign: 'center',
    color: '#ffffff'
  },
  centerText: {
    textAlign: 'center'
  },
  textFeildStyle: {
    minHeight: 79,
    borderRadius: 4,
    border: '0.5px solid #FFFFFF80',
    marginBottom: 28
  },
  textInputStyles: {
    border: 'none',
    padding: '9px 8px',
    flex: 1,
    textAlign: 'left',
    // minHeight: 50,
    fontSize: 16,
    color: '#FFFFFF80',
    '::placeHolder': {
      color: '#FFFFFF80',
    }
  },
  selectTextFeild: {
    minHeight: 34,
    borderRadius: 4,
    border: '0.5px solid #FFFFFF80',
    maxWidth: 345,
    marginBottom: 28
  },
  listBoxPaper: {
    borderRadius: 0,
    zIndex: 130000
  },
  paper: {
    zIndex: 130000,
    fontSize: 16,
    backgroundColor: '#1C1C1E',
    borderRadius: 4,
    padding: '0px 10px',
    maxWidth: 345,
  },
  dropDownIndicator: {
    fontSize: 21,
    color: '#FFFFFF80'
  },
  textEditorStyle: {
    minHeight: 79,
    borderRadius: 4,
    border: '0.5px solid #FFFFFF80',
    //marginBottom: 28
  },
  textFieldHelperText: {
    color: '#FFFFFF66',
    fontSize: 14,
    alignSelf: 'flex-end',
    position: 'absolute',
    bottom: -30
  },
  switchText: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: '100%',
    fontSize: 15,
    color: 'red',
    textTransform: 'uppercase'
  },
  switch: {
    verticalAlign: 'middle',
    marginLeft: 4,
    // height: 40,
    // width: 70,
    marginBottom: 10
  },
  formTitileRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  imageContent: {
    height: '100%',
    width: '100%',
    borderRadius: 8,
    objectFit: 'cover'
  },
  videoFileText: {
    marginLeft: 3,
    maxWidth: 230,
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    '@media (max-width:1024px)': {
      maxWidth: 150,
    },
    '@media (max-width:1200px)': {
      maxWidth: 150,
    },
  },
  videoDescriptionLabel: {
    marginBottom: 12
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  selectBoxStyle: {
    display: 'flex',
    flex: 1,
  },
  selectBoxLeftStyle: {
    marginRight: 10
  },
  videoStateText: {
    fontWeight: 'bold'
  },
  textAreaBoxInputStyle: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: 175,
  },
  customImageUploadContainer: {
    width: 100,
    height: 100,
  },
  cutomImageUploaderIcon: {
    fontSize: 90
  },
  subTitleText: {
    fontSize: 14
  },
  processingStateSubText: {
    fontSize: 12,
    color: '#FFFFFF80',
    margin: 0,
    marginTop: 15,
    textAlign: 'center'
  },
  processingStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorText: {
    marginTop: 5,
    fontSize: 14,
    color: '#f44336',
  }
  // richTextWrapper: {
  //   // display: 'flex',
  //   // flex:1,
  //   // width:'100%',
  //   wordBreak: 'break-all'
  // }
})