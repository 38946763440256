import { Helper } from './helpers/Helper';

const CARE = 'Contact customer care team if problem persists'

export class BaseError extends Error {
  constructor(description, original) {
    super();
    this.name = BaseError.name
    this.description = description;
    this.original = original;
    //Object.setPrototypeOf(this, BaseError);
  }

  toString() {
    return this.description;
  }
}

export class AuthError extends BaseError {
  constructor(description, original) {
    super(description || Helper.getString('cloudPermissionErr') + CARE, original);
    this.name = AuthError.name;
    //Object.setPrototypeOf(this, AuthError.prototype);
  }
}

export class ApiError extends BaseError {
  constructor(response) {
    super(Helper.getString('internetErr'), response);
    this.name = ApiError.name;
    //Object.setPrototypeOf(this, ApiError.prototype);
  }

  toString() {
    return '';
  }
}

export class ApiDataError extends BaseError {
  constructor(response) {
    super(Helper.getString('cloudResponseErr') + CARE, response);
    this.name = ApiDataError.name;
    //Object.setPrototypeOf(this, ApiError.prototype);
  }

  toString() {
    return '';
  }
}

export class TokenFailedError extends BaseError {
  constructor(err) {
    super(Helper.getString('permissionPrblmErr') + CARE, err);
    this.name = TokenFailedError.name;
    //Object.setPrototypeOf(this, TokenFailedError.prototype);
  }

  toString() {
    return '';
  }
}
