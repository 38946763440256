import React, { Fragment, useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Dialog, TextField } from '@material-ui/core';
import { LabelAndDescription } from '../screens/uploadVideoWizard/LabelAndDescription';
import { TextBoxs } from './WizardTextInputs';
import { AppButton } from '../common/components/AppButton';
import { Helper } from '../common/helpers/Helper';
import { Style } from '@material-ui/icons';


export function TimeSelectorPopup(props) {
  const initilTimeCode = props.elapsedTimeFormatted && props.elapsedTimeFormatted.split(':');
  const [hours, setHours] = useState(initilTimeCode[0]);
  const [minutes, setMinutes] = useState(initilTimeCode[1]);
  const [seconds, setSeconds] = useState(initilTimeCode[2]);
  const [errMsg, setErrMsg] = useState();

  const items = [
    {
      key: 'hours',
      label: 'Hours',
      type: 'Number'
    }, {
      key: 'minutes',
      label: 'Minutes',
      type: 'Number'
    },
    {
      key: 'seconds',
      label: 'Seconds',
      type: 'Number'
    }
  ]

  const handleTextBox = (key, e) => {
    if (key === 'hours') {
      setHours(e.target.value)
    }
    if (key === 'minutes') {
      setMinutes(e.target.value)
    }
    if (key === 'seconds') {
      setSeconds(e.target.value)
    }
  }

  const onKeyboardPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  const onApplyClick = () => {
    const timeSelected = `${hours ? hours : '00'}:${minutes ? minutes : '00'}:${seconds ? seconds : '00'}`
    const arr = timeSelected.split(':'); // splitting the string by colon
    const timeInSeconds = arr[0] * 3600 + arr[1] * 60 + (+arr[2])
    if (seconds > 59) {
      setErrMsg(Helper.getString('secondsLimitExceeded'))
      return
    }
    if (minutes > 59) {
      setErrMsg(Helper.getString('minutesLimitExceeded'))
      return
    }
    if (hours > 2) {
      setErrMsg(Helper.getString('hoursLimitExceed'))
      return
    }
    if (timeInSeconds > props.videoDuration) {
      setErrMsg(Helper.getString('selectedTimeExceedOverVideoDuration'))
      return
    }
    setHours()
    setMinutes()
    setSeconds()
    props.onTimeSelectorApply(timeSelected)
  }

  const handleBlur = (e, itemKey, expansionID) => {
    if (e.target.value === '') {
      handleTextBox(itemKey, { target: { value: '00' } }, expansionID);
    }
  };

  return (
    <Dialog
      open={props.open}
      classes={{
        paper: css(Styles.timeDailogContainer),
      }} className={css(Styles.timeDailogOuterContainer)}>
      <div className={css(Styles.dialogButtonContainer)}>
        <div onClick={() => props.onTimerDailogClose()}
          className={css(Styles.closeIconContainer)}>
          <img src={require('../assets/images/close.png')}
            alt={Helper.getString('closeImageAlt')}
            className={css(Styles.img)}
            width='40'
            height='40'
          />
        </div>
      </div>
      <div className={css(Styles.title)}>Enter Timestamp</div>
      <div className={css(Styles.textBoxContainer)}>
        <TextBoxs
          item={items[0]}
          textBoxStyle={Styles.textFeildStyle}
          labelStyle={Styles.labelStyle}
          formControlStyle={Styles.formControlStyle}
          onKeyboardPress={onKeyboardPress}
          textBoxInputStyle={Styles.textBoxInputStyle}
          value={hours}
          handleTextBox={handleTextBox}
          inputStyle={{ textAlign: 'center' }}
          onBlur={handleBlur}
        />
        <TextBoxs
          item={items[1]}
          textBoxStyle={Styles.textFeildStyle}
          labelStyle={Styles.labelStyle}
          formControlStyle={Styles.formControlStyle}
          onKeyboardPress={onKeyboardPress}
          textBoxInputStyle={Styles.textBoxInputStyle}
          value={minutes}
          handleTextBox={handleTextBox}
          inputStyle={{ textAlign: 'center' }}
          onBlur={handleBlur}
        />
        <TextBoxs
          item={items[2]}
          textBoxStyle={Styles.textFeildStyle}
          labelStyle={Styles.labelStyle}
          formControlStyle={Styles.formControlStyle}
          onKeyboardPress={onKeyboardPress}
          textBoxInputStyle={Styles.textBoxInputStyle}
          value={seconds}
          handleTextBox={handleTextBox}
          inputStyle={{ textAlign: 'center' }}
          onBlur={handleBlur}
        />
      </div>
      <div className={css(Styles.buttonContainer)}>
        <div className={css(Styles.errorContainer)}>
          {errMsg &&
            <p className={css(Styles.errorText)}>{errMsg}</p>
          }
        </div>
        <AppButton buttonText={'APPLY'} buttonStyle={Styles.buttonStyle}
          buttonTextStyle={Styles.buttonTextStyle}
          onClick={onApplyClick} />
      </div>

    </Dialog>
  )
}

const Styles = StyleSheet.create({
  timeDailogContainer: {
    display: 'flex',
    minWidth: 400,
    // minHeight: 200,
    backgroundColor: '#1C1C1E',
    padding: 10,
    position: 'relative',
    top: '10%'
  },
  timeDailogOuterContainer: {
    zIndex: 13002
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'center',
    color: 'white'
  },
  textBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 45
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 5,
    paddingBottom: 5
  },
  buttonStyle: {
    borderRadius: 4
  },
  fieldStyle: {
    marginLeft: 10,
    marginRight: 10
  },
  formControlStyle: {
    minWidth: 82,
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10
  },
  textFeildStyle: {
    maxWidth: '50px',
    marginBottom: 0
  },
  textBoxInputStyle: {
    backgroundColor: '#FFFFFF1A',
    paddingBottom: 0
  },
  inputStyle: {
    textAlign: 'center'
  },
  dialogButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
    // position: 'absolute',
    // top: 10,
    // right: 10
  },
  closeIconContainer: {
    width: 40,
    height: 40,
    cursor: 'pointer'
  },
  buttonTextStyle: {
    textTransform: 'uppercase',
    fontWeight: 'normal'
  },
  errorContainer: {
    display: 'flex',
    flex: 1,
    marginBottom: 16,
    minHeight: 15
  },
  errorText: {
    fontSize: 12,
    color: '#FF0000',
    maxWidth: 230,
    wordBreak: 'normal',
    margin: 0
  },
  labelStyle: {
    fontSize: 14
  }
})