import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core';
import { AppButton } from '../common/components/AppButton';

export function EditWarningPopup(props) {

  return (
    <Dialog open={props.dialogOpen}
      classes={{
        paper: css(Styles.publishWarningDialog),
      }}
      className={css(Styles.errorDialogContainer)}>
      <div className={css(Styles.errorContentContainer)}>
        <div onClick={() => props.onClose()}
          className={css(Styles.closeIconContainer)}>
          <img src={require('../assets/images/close.png')}
            alt={Helper.getString('closeImageAlt')}
            className={css(Styles.img)}
            width='40'
            height='40'
          />
        </div>
        <p className={css(Styles.publishDialogTitle)}>{props.title}</p>
        <p className={css(Styles.publishDialogDescr)}>{props.descr}</p>
      </div>
      <div className={css(Styles.buttonContainer)}>
        {props.handleDoNotShowAgain && <div className={css(Styles.checkBoxContainer)}>
          <Checkbox checked={props.doNotShowAgain} className={css(Styles.checkBox)}
            onChange={(e) => props.handleDoNotShowAgain(e)}
            name='DoNotShowAgain' />
          <p className={css(Styles.label)}>Do not show this message again</p>
        </div>}
        <AppButton
          buttonStyle={Styles.buttonStyle}
          buttonTextStyle={Styles.buttonText}
          buttonText={props.buttonTitle}
          onClick={props.buttonAction}
        />
        <div className={css(Styles.emptyDiv)}>
        </div>
      </div>
    </Dialog>
  )
}

const Styles = StyleSheet.create({
  errorDialogContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 13000
  },
  publishWarningDialog: {
    display: 'flex',
    position: 'relative',
    backgroundColor: '#000000',
    border: '1px solid #FFFFFF80',
    borderRadius: 8,
    width: 585
  },
  errorContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '27px 30px'
  },
  closeIconContainer: {
    width: 40,
    height: 40,
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  warningTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  publishImageContainer: {
    height: 50,
    width: 50,
  },
  publishDialogTitle: {
    color: '#FFFFFF',
    fontSize: 18,
    margin: 0,
    fontWeight: 600,
    marginBottom: 20,
    textAlign: 'center',
    width: 471,
  },
  publishDialogDescr: {
    color: '#FFFFFF',
    fontSize: 16,
    textAlign: 'center',
    margin: 0,
    whiteSpace: 'pre-wrap',
    width: 526
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: '1px solid #FFFFFF80',
    paddingTop: 26,
    paddingBottom: 30,
    position: 'relative'
  },
  buttonStyle: {
    borderRadius: 4,
    minHeight: 50,
    minWidth: 225
  },
  buttonText: {
    color: '#FFFFFF',
    fontWeight: 'normal',
    fontSize: 18,
    textTransform: 'uppercase',
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  checkBox: {
    color: '#707070',
    padding: '0px !important',
    marginRight: 10,
    borderRadius: '6px !important',
    height: 20,
    width: 20
  },
  label: {
    color: '#FFFFFF80',
    fontSize: 15,
    margin: 0,
    padding: '0px !important',
  },
  emptyDiv: {
    width: 326
  }
})