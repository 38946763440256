import React, { Component } from 'react';
import { Controller } from '../common/Controller';
import { UiLoginHelper } from '../common/helpers/UiLoginHelper';
import { Helper } from '../common/helpers/Helper';
import { UsernameInput, PasswordInput, NumericOTPInput } from '../components/TextInputs';
import { StyleSheet, css } from 'aphrodite';

import { AppTheme } from '../styles/AppTheme'
import { HelmetComponent } from '../common/components/HelmetComponent';
import { AppButton } from '../common/components/AppButton';
import { AppConfig } from '../AppConfig';

const privacyUrl = 'https://www.razzl.com/privacy'
const termsUrl = 'https://www.razzl.com/terms'

export class Login extends Component {
  constructor(props) {
    super(props);
    this.userNameType = AppConfig.USER_PRIMARY === 'email' ?
      UiLoginHelper.UserNameType.EMAIL : UiLoginHelper.UserNameType.PHONE
    this.state = {
      currentPage: UiLoginHelper.Page.LOOKUP,
      loadingAnimation: false,
      message: '',
      userName: '',
      pword: '',
      verificationCode: ''
    };
    this.loginHelper = new UiLoginHelper(this, {
      autoSignUp: true,
      autoLogin: true,
      alwaysLoginUsingOTP: true,
      userNameType: this.userNameType,
      jsonBody: this.props.jsonBody ? this.props.jsonBody : {},
      onSuccessLogin: this.onSuccessLogin.bind(this)
    });
  }

  onSuccessLogin() {
    Controller.get().userMgr().setGuestUser(false);
    //console.log('onSuccessLogin');
    if (this.props.onSuccessLogin) {
      this.props.onSuccessLogin()
      return
    }
    window.location.href = Controller.get().roleHelper().getHome()
  }

  clearTextField() {
    this.setState({
      userName: '',
      message: ''
    })
  }

  clearOTPTextField() {
    this.setState({
      verificationCode: '',
      message: ''
    })
  }

  onBackClick() {
    this.loginHelper.toPage(UiLoginHelper.Page.LOOKUP)
    this.setState({
    })
  }

  render() {
    return (
      <div className={css(Styles.container)}>
        <div className={css([Styles.loginPanel, this.props.loginPanelStyle])}>

          <HelmetComponent page={'login'} />


          {(this.state.currentPage === UiLoginHelper.Page.SIGNUP_OTP ||
            this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD_OTP) &&
            <div onClick={() => this.onBackClick()}
              className={css(Styles.backArrowIconContainer)}>
              <img src={require('../assets/images/arrow.png')}
                alt={Helper.getString('backImageAlt')}
                className={css(Styles.img)}
                width='30'
                height='30'
              />
            </div>
          }

          <div className={css(Styles.logoContainer)}>
            <img src={require('../assets/images/logo.png')}
              alt={Helper.getString('logoAlt')}
              className={css(Styles.img)}
              width='60'
              height='64'
            />
          </div>
          {/* <h2>{Helper.getString('login')}</h2> */}
          {/* <div className={css(Styles.loginMessageContainer)}>
            {this.state.message.length > 0 &&
              <p className={css(Styles.loginMessage)}>{this.state.message}</p>
            }
          </div> */}

          {this.state.currentPage === UiLoginHelper.Page.LOOKUP &&
            this.welcomeView()
          }
          {this.state.currentPage === UiLoginHelper.Page.SIGNUP &&
            this.signUpView()
          }
          {this.state.currentPage === UiLoginHelper.Page.SIGNUP_OTP &&
            this.signUpOTPView()
          }
          {this.state.currentPage === UiLoginHelper.Page.LOGIN &&
            this.loginView()
          }
          {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD &&
            this.resetPasswordView()
          }
          {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD_OTP &&
            this.resetPasswordOTPView()
          }
        </div>
      </div>
    );
  }

  welcomeView() {  // TODO Needs styling
    return (
      <div>
        <div className={css(Styles.viewNoteContainer)}>
          <h2 className={css(Styles.viewTitle)}>{Helper.getString('loginWelcome')}</h2>
          <p className={css(Styles.titleDescr)}>{Helper.getString('welcomeNote')}</p>
        </div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          placeholder={'EMAIL'}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({
            userName: event.target.value
          })}
          onClose={() => this.clearTextField()}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLookup()
            }
          }}
        />

        <div className={css(Styles.loginMessageContainer)}>
          {this.state.message.length > 0 &&
            <p className={css(Styles.loginMessage)}>{this.state.message}</p>
          }
        </div>

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={this.state.userName.length <= 0 ? Styles.buttonDisabled : Styles.button}
            disabled={this.state.loadingAnimation ||
              (this.state.userName.length <= 0 ? true : false)}
            onClick={this.loginHelper.onSubmitLookup.bind(this.loginHelper)}
            buttonText={'Continue'}
            buttonTextStyle={Styles.buttonTextStyle}
            inProcess={this.state.loadingAnimation}
          />
        </div>

        <div className={css(Styles.resendButtonContainer)}>
        </div>

        {this.props.showTerms &&
          <p className={css(Styles.termsContainer)}>
            By continuing, you accept our <a className={css(Styles.link)} href={termsUrl} target='_blank'>Terms of Service</a> and
            <a className={css(Styles.link)} href={privacyUrl} target='_blank'> Privacy Policy.</a>
          </p>
        }
      </div>
    )
  }

  signUpView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          placeholder='Set a new password'
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUp()
            }
          }}
        />

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={Styles.button}
            disabled={this.state.loadingAnimation}
            inProcess={this.state.loadingAnimation}
            buttonText={'Signup'}
            onClick={this.loginHelper.onSubmitSignUp.bind(this.loginHelper)}
          />
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  signUpOTPView() { // TODO Needs Styling
    return (
      <div>
        <div className={css(Styles.viewNoteContainer)}>
          <h2 className={css(Styles.viewTitle)}>{Helper.getString('weSendyouEmail')}</h2>
          <p className={css(Styles.titleDescr)}>
            Enter the <span className={css(Styles.semiBoldText)}>
              one-time password
            </span> we sent you at {this.state.userName}. Don't worry about remembering your password for later.
          </p>
        </div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          onChange={(event) => this.setState({
            verificationCode: event.target.value
          })}
          placeholder={'PASSWORD'}
          onClose={() => this.clearOTPTextField()}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUpConfirm()
            }
          }} />

        <div className={css(Styles.loginMessageContainer)}>
          {this.state.message.length > 0 && !this.state.message.startsWith(Helper.getString('otpToMobile')) && // fix not to show message
            <p className={css(Styles.loginMessage)}>{this.state.message}</p>
          }
        </div>

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={this.state.verificationCode.length <= 0 ? Styles.buttonDisabled : Styles.button}
            disabled={this.state.loadingAnimation ||
              (this.state.verificationCode.length <= 0 ? true : false)}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitSignUpConfirm.bind(this.loginHelper)}
            buttonText={'Continue'}
            buttonTextStyle={Styles.buttonTextStyle}
          />
        </div>
        {/* TODO Needs Styling to add an OTP resend Button with progress
        onPress={() => this.loginHelper.onSubmitResentResetPassword(Helper.getString('OTPResetSent'))}*/}
        <div className={css(Styles.resendButtonContainer)}>
          <p className={css(Styles.loginLink)}
            onClick={this.state.loadingAnimation === false ?
              () => this.loginHelper.onSubmitResentResetPassword(Helper.getString('OTPResetSent')) : ''}>
            {Helper.getString('resendOtp')}</p>
        </div>
        {/* <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a> */}

        {this.props.showTerms &&
          <p className={css(Styles.termsContainer)}>
            By continuing, you accept our <a className={css(Styles.link)} href={termsUrl} target='_blank'>Terms of Service</a> and
            <a className={css(Styles.link)} href={privacyUrl} target='_blank'> Privacy Policy.</a>
          </p>
        }
      </div>
    )
  }

  loginView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          id='pword'
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLogin()
            }
          }} />

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={Styles.button}
            disabled={this.state.loadingAnimation}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLogin.bind(this.loginHelper)}
            buttonText={'Login'}
          />
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onResetPasswordClicked.bind(this)}><p>Reset password? Get OTP</p></a>
        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    );
  }

  resetPasswordView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitResetPassword()
            }
          }} />

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={Styles.button}
            disabled={this.state.loadingAnimation}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitResetPassword.bind(this.loginHelper)}
            buttonText={'Get OTP'}
          />
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  resetPasswordOTPView() { // TODO Needs styling
    return (
      <div>
        <div className={css(Styles.viewNoteContainer)}>
          <h2 className={css(Styles.viewTitle)}>{Helper.getString('weSendyouEmail')}</h2>
          <p className={css(Styles.titleDescr)}>
            Enter the <span className={css(Styles.semiBoldText)}>
              one-time password
            </span> we sent you at {this.state.userName}. Don't worry about remembering your password for later.
          </p>
        </div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          placeholder={'PASSWORD'}
          onClose={() => this.clearOTPTextField()}
          onChange={(event) => this.setState({
            verificationCode: event.target.value
          })} />

        {!(this.loginHelper.options && this.loginHelper.options.alwaysLoginUsingOTP) &&
          <PasswordInput
            id='pword'
            value={this.state.pword}
            onChange={(event) => this.setState({ pword: event.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.loginHelper.onSubmitConfirmResetPassword()
              }
            }} />
        }

        <div className={css(Styles.loginMessageContainer)}>
          {this.state.message.length > 0 && !this.state.message.startsWith(Helper.getString('passwordResetSentSpecific')) && // Fix not to show OTP sen message
            <p className={css(Styles.loginMessage)}>{this.state.message}</p>
          }
        </div>

        <div className={css(Styles.buttonContainer)}>
          <AppButton
            buttonStyle={this.state.verificationCode.length <= 0 ? Styles.buttonDisabled : Styles.button}
            disabled={this.state.loadingAnimation ||
              (this.state.verificationCode.length <= 0 ? true : false)}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitConfirmResetPassword.bind(this.loginHelper)}
            buttonText={'Continue'}
            buttonTextStyle={Styles.buttonTextStyle}
          />
        </div>

        {/* TODO Needs Styling to add an OTP resend Button with progress
        onPress={() => this.loginHelper.onSubmitResentResetPassword(Helper.getString('OTPResetSent'))}
        */}
        <div className={css(Styles.resendButtonContainer)}>
          <p className={css(Styles.loginLink)}
            onClick={this.state.loadingAnimation === false ?
              () => this.loginHelper.onSubmitResentResetPassword(Helper.getString('OTPResetSent')) : ''}>
            {Helper.getString('resendOtp')}</p>
        </div>
        {/* <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a> */}

        {this.props.showTerms &&
          <p className={css(Styles.termsContainer)}>
            By continuing, you accept our <a className={css(Styles.link)} href={termsUrl} target='_blank'>Terms of Service</a> and
            <a className={css(Styles.link)} href={privacyUrl} target='_blank'> Privacy Policy.</a>
          </p>
        }
      </div>
    )
  }

  onStartoverClicked() {
    this.loginHelper.toPage(UiLoginHelper.Page.LOOKUP);
  }

  onResetPasswordClicked() {
    this.loginHelper.toPage(UiLoginHelper.Page.RESET_PASSWORD);
  }
}

const Styles = StyleSheet.create({
  container: {
    backgroundColor: AppTheme.secondaryBg,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    // maxWidth: 500
  },
  loginPanel: {
    borderRadius: 8,
    backgroundColor: '#02121D',
    textAlign: 'center',
    width: 370,
    border: '2px solid #FFFFFF66',
    padding: 20,
    //margin: 10,
    '@media (max-width: 767px)': {
      width: 280,
      padding: 10,
      margin: 5,
    },
    '@media (max-width: 320px)': {
      width: 240,
      padding: 10,
      margin: 5
    }
  },
  loginMessageContainer: {
    margin: 'auto',
    minHeight: 65,
    paddingTop: 10
  },
  loginMessage: {
    // marginTop: 10,
    margin: 0,
    fontSize: 16,
    color: 'red',
    textAlign: 'left'
  },
  loginLink: {
    marginTop: 10,
    cursor: 'pointer',
    color: '#037BFE',
    fontSize: 13,
    textAlign: 'left',
    width: 'fit-content'
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    // marginTop: 20,
    marginBottom: 30
  },
  marginLeft: {
    marginLeft: 10
  },
  termsContainer: {
    color: '#ffffff',
    fontSize: 11,
    margin: 0
  },
  buttonStyle: {
    boxShadow: 'none',
    backgroundColor: AppTheme.buttonBg,
    color: AppTheme.buttonTextColor,
    borderRadius: AppTheme.pagePadding,
    textAlign: 'center',
    letterSpacing: 0,
    textTransform: 'uppercase',
    opacity: 1,
  },
  button: {
    flex: 1,
    borderRadius: 4,
    minHeight: 40
  },
  buttonDisabled: {
    flex: 1,
    borderRadius: 4,
    backgroundColor: '#FFFFFF0F',
    minHeight: 40
  },
  buttonTextStyle: {
    fontWeight: 'unset'
  },
  logoContainer: {
    width: 60,
    height: 64,
    marginBottom: 12
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  viewTitle: {
    color: '#ffffff',
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 'unset',
    margin: '13px 0px',
    minWidth: 330
  },
  titleDescr: {
    color: '#ffffff',
    fontSize: 16,
    textAlign: 'left',
    margin: 0
  },
  viewNoteContainer: {
    minHeight: 177,
  },
  resendButtonContainer: {
    minHeight: 90
  },
  backArrowIconContainer: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: 9,
    left: 4,
    cursor: 'pointer'
  },
  link: {
    color: '#ffffff',
    textDecoration: 'none',
    fontWeight: 600,
    cursor: 'pointer'
  },
  semiBoldText: {
    fontWeight: 600
  }
})
