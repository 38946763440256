import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Login } from '../common/components/Login';
import { AppTheme } from '../styles/AppTheme';

export class LoginPage extends Component {
  render() {
    return (
      <Login
        loginPanelStyle={Styles.loginPanel}
        showTerms={true}>
      </Login>
    )
  }
}
const Styles = StyleSheet.create({
  loginPanel: {
    backgroundColor: AppTheme.secondaryBg,
    textAlign: 'left'
  },
})
