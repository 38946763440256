import { Controller } from './common/Controller'
import { Helper } from './common/helpers/Helper'

export class AppController extends Controller {
  constructor() {
    super()
    const { AppRoleHelper } = require('./helpers/AppRoleHelper')
    this._roleHelper = new AppRoleHelper()

    const { MockHelper } = require('./helpers/MockHelper')
    this._mockHelper = new MockHelper()
  }
}
