import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';
import { Popover, TextField } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { AppButton } from '../common/components/AppButton';
import { Helper } from '../common/helpers/Helper';
import { AppTheme } from '../styles/AppTheme';

export class CustomLinkPopover extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    onExpandEvent: PropTypes.func,
    onChange: PropTypes.func,
    currentState: PropTypes.object,
    editorState: PropTypes.func
  };
  constructor(props) {
    super(props);
    this.state = {
      openPopover: null,
      link: this.props.currentState.link && this.props.currentState.link.target !== undefined ? this.props.currentState.link.target : ''
    }
  }

  //updating the link when the props change
  componentWillReceiveProps(nextProps) {
    const updateLink = nextProps.currentState.link && nextProps.currentState.link.target !== undefined ?
      nextProps.currentState.link.target : ''
    if (updateLink !== this.state.link) {
      this.setState({ link: updateLink });
    }
  }


  stopPropagation = (event) => {
    event.stopPropagation();
  };

  onChange = () => {
    const { onChange, config, translations, expanded, onExpandEvent, doExpand, doCollapse, currentState } = this.props;


    const title = currentState.link && currentState.link.title
    const finalLink = this.state.link //AppHelper.getValidatedUrl(this.state.link)
    if (currentState.selectionText || title) {
      if (this.state.link !== '') {
        onChange('link', currentState.selectionText || title, finalLink || '');
      }
      else {
        onChange('unlink', currentState.selectionText || title, undefined);
      }
    }
    if (currentState.selectionText === '' && currentState.link === undefined && this.state.link !== '') {
      onChange('link', this.state.link, finalLink || '');
    }
    setTimeout(() => {
      this.onPopoverClose()
    }, 500)
  }

  onLinkTextChange = (e) => {
    console.log('link', e.target.value)
    this.setState({
      ...this.state,
      link: e.target.value

    })
  }


  setOpenPopover = (e) => {
    this.setState({
      ...this.state,
      openPopover: e.currentTarget
    })
  }

  onPopoverClose = () => {
    this.setState({
      ...this.state,
      openPopover: null
    })
  }

  renderModal = (expanded) => {
    const { color } = this.props.currentState;
    //console.log('expanded', this.props)
    //console.log('state link', this.state.link)
    return (
      <div
        onClick={this.stopPropagation}
      >
        <Popover
          id={Helper.getString('LinkPopover')}
          open={Boolean(this.state.openPopover)}
          classes={{ paper: css(Styles.popoverContainer) }}
          anchorEl={this.state.openPopover}
          disableScrollLock={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className={css(Styles.popoverContent)}
          onClose={() => this.onPopoverClose()}
        >
          <div className={css(Styles.popoverContent)}>
            <p className={css(Styles.labelStyle)}>Enter Link</p>
            <TextField autoFocus onChange={(e) => this.onLinkTextChange(e)} value={this.state.link}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: css(Styles.textInputStyles)
                }
              }} />
            <div className={css(Styles.buttonContainer)}>
              <AppButton buttonText={'SAVE'} onClick={() => this.onChange()}
                buttonStyle={Styles.addButton}
                buttonTextStyle={Styles.addButtonText} />
              <AppButton buttonText={'CANCEL'} onClick={() => this.onPopoverClose()}
                buttonTextStyle={Styles.cancelButtonText}
                buttonStyle={Styles.cancelButton} />
            </div>
          </div>
        </Popover>
      </div>
    );
  };

  render() {
    const { expanded, onExpandEvent } = this.props;
    return (
      <div
        aria-haspopup='true'
        aria-expanded={expanded}
        aria-label='rdw-color-picker'
        className={css(Styles.container)}
      >
        <div className={css(Styles.linkIconContainer)} onClick={(e) => this.setOpenPopover(e)}>
          <LinkIcon />
        </div>
        {expanded ? this.renderModal(expanded) : undefined}
        {this.renderModal()}
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  popoverContainer: {
    backgroundColor: '#ffffff',
    display: 'flex',
    border: '1px solid #F1F1F1',
    padding: 15,
    boxShadow: '3px 3px 5px #bfbdbd',
    width: 235
  },
  popoverContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    zIndex: 133333333333
  },
  linkIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    width: 25,
    border: '1px solid #F1F1F1',
    marginRight: 4,
    padding: 5,
    cursor: 'pointer',
    ':hover': {
      boxShadow: '1px 1px 0px #bfbdbd'
    }
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  labelStyle: {
    color: '#000000DE',
    fontSize: 15,
    textAlign: 'left',
    margin: 0,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  textInputStyles: {
    border: '1px solid #F1F1F1',
    padding: '0px 5px',
    flex: 1,
    textAlign: 'left',
    fontSize: 15,
    color: '#000000',
    marginTop: 5,
    marginBottom: 15,
    backgroundColor: AppTheme.primaryBg,
  },
  addButton: {
    height: 30,
    minWidth: 75,
    borderRadius: 4,
    padding: 6,
    backgroundColor: '#037BFE'
  },
  addButtonText: {
    fontSize: 13
  },
  cancelButton: {
    backgroundColor: '#ffffff',
    border: '1px solid #F1F1F1',
    height: 30,
    minWidth: 75,
    borderRadius: 4,
    marginLeft: 10,
    padding: 6
  },
  cancelButtonText: {
    color: '#000000',
    fontSize: 13
  }
})
