import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  convertToRaw,
  convertFromRaw
} from 'draft-js';
import { UiHelper } from '../../helpers/UiHelper';
import { ApiHelper } from '../../helpers/ApiHelper';
import './Styles.css'
import { stateToHTML } from 'draft-js-export-html';

export class RichtextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: this.processValue(props.value),
      fetchState: ApiHelper.State.LOADING
    }
    this.defaultContent = null
  }

  componentDidMount() {
    this.setState({
      fetchState: ApiHelper.State.READY
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      let incomingContent = ''
      if (this.props.value) {
        try {
          incomingContent = JSON.parse(this.props.value).__azformatted;
        } catch (e) {
          console.log('Json parse error', e)
          incomingContent = ''
        }
      }
      // Even if the incoming props changes, the state was holding previous value
      // and hence was not getting refreshed
      // So to resolve this, on componentDidUpdate, we do a check whether incoming prop value
      // is same as the current state value and trigger a state change
      const currentContent = this.getData(this.state.editorState)
      if (JSON.stringify(incomingContent) !== JSON.stringify(currentContent)) {
        const editorState = this.processValue(this.props.value)
        this.setState({ editorState: editorState })
      }
    }
  }


  curentLength = () => {
    return this.state.editorState.getCurrentContent().getPlainText('').length
  }

  processValue = (value) => {
    if (value) {
      try {
        this.defaultContent = JSON.parse(value).__azformatted;
      } catch (e) {
        console.log('Json parse error', e)
        return EditorState.createEmpty()
      }
    }
    if (this.defaultContent) {
      return EditorState.createWithContent(convertFromRaw(this.defaultContent))
    } else {
      return EditorState.createEmpty()
    }
  }


  handleBeforeInput = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    if (currentContentLength >= this.props.maxLength) {

      return 'handled';
    }
  }

  handlePastedText = (pastedText) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    if (currentContentLength + pastedText.length >= this.props.maxLength) {

      return 'handled';
    }
  }

  getData = (editorState) => {
    if (editorState.getCurrentContent().hasText()) {
      return convertToRaw(editorState.getCurrentContent());
    } else {
      return '';
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });
    const data = this.getData(editorState)
    const html = stateToHTML(editorState.getCurrentContent(), this.props.htmlInlineStyle);
    console.log('onEditorStateChange html', data)
    console.log('onEditorStateChange html', html)
    if (data === '') {
      this.props.handleRichtextInputChange('', this.props.current.key)
    } else {
      this.props.handleRichtextInputChange(JSON.stringify({
        __azformatted: data,
        __html: html,
      }), this.props.current.key)
    }
  };

  /*uploadImage = (file) => {
    const error = null
    return new Promise((resolve, reject) => {
      if (error) reject(error);
      else resolve({ data: { link: 'TODO:link to image' } });
    });
  };

  processValueV2 = () => {
    const c = localStorage.getItem('contentData')
    if (c) {
      this.defaultContent = JSON.parse(c)
    }
    if (this.defaultContent) {
      return EditorState.createWithContent(convertFromRaw(this.defaultContent))
    } else {
      return EditorState.createEmpty()
    }
  }

  onEditorStateChangeV2 = (editorState) => {
    this.setState({
      editorState,
    });
    const data = convertToRaw(editorState.getCurrentContent())
    localStorage.setItem('contentData', JSON.stringify(data))
  };*/

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.loadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }

  readyView() {
    const toolbar = this.props.fullEditor ? this.props.fullEditor : {
      options: ['inline', 'list', 'link'],
      inline: {
        options: ['bold'],
      },
      list: {
        options: ['unordered'],
      },
    }
    const currentDateLength = this.curentLength()
    return (
      <div className={css(Styles.container)}>
        <Editor
          editorState={this.state.editorState}
          wrapperClassName='wrapper'
          editorClassName='editor'
          toolbarClassName={this.props.toolbarClassName}
          onEditorStateChange={this.onEditorStateChange}
          stripPastedStyles={true}
          toolbar={toolbar}
          readOnly={this.props.disabled}
          placeholder={this.props.placeholder}
          handleBeforeInput={this.handleBeforeInput}
          handlePastedText={this.handlePastedText}
          onTab={this.props.onTab ? this.props.onTab : null}
        />
        {this.props.maxLength &&
          <p className={css(Styles.counterText)}>
            {currentDateLength}/{this.props.maxLength}</p>
        }
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column'
  },
  counterText: {
    color: '#FFFFFF66',
    fontSize: 14,
    alignSelf: 'flex-end',
    position: 'absolute',
    margin: 0,
    // marginTop: 10,
    bottom: -25,
    right: 0
  }
})
