import React, { useRef, useState, Fragment, useEffect } from 'react';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { StyleSheet } from 'aphrodite';
import { AppConfig } from '../AppConfig';
import { CustomImageUploaderStyles } from '../styles/CustomStyles';
import { css } from 'aphrodite';
import { Helper } from '../common/helpers/Helper';
import { UploadHelper } from '../common/helpers/UploadHelper';
import { CircularProgress, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { AppHelper } from '../helpers/AppHelper';

const uuid = require('uuid');

const CustomImageUploaderNew = (props) => {

  const FILE_SIZE_MB = 10000000

  const [file, setFile] = useState()
  const [message, setMessage] = useState('')
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [fetchState, setFetchState] = useState(ApiHelper.State.READY)
  const [selectedImgWidth, setSelectedImgWidth] = useState();
  const [selectedImgHeight, setSelectedImgHeight] = useState();

  const inputTagId = 'upload-button-' + props.uploadId + '-' + moment().valueOf()
  const outerContainer = (!uploadInProgress && props.url) ? css(Styles.outerContainer, Styles.imageOuterContainer) : css(Styles.outerContainer)

  const onError = (err) => {
    console.log('err:', err);
    if (!props.disabled) {
      props.setIsUploading && props.setIsUploading(prevState => ({ ...prevState, [props.uploadId]: false }))
      setMessage(Helper.getErrorMsg(err));
      setFetchState(ApiHelper.State.ERROR);
      setUploadInProgress(false);
    }
  }

  useEffect(() => {
    if (props.displayErrorInParent === true) {
      props.handleImageUploadErrorMsg(message)
    }
  }, [message])

  const _getFileExt = () => {
    if (file.type === 'image/png') {
      return 'png'
    } else if (file.type === 'image/svg+xml') {
      return 'svg'
    } else {
      return 'jpg'
    }
  }

  const onUploadComplete = (signedUploadInfoArray) => {
    setFetchState(ApiHelper.State.READY);
    setUploadInProgress(false);
    if (props.onUploadSuccess) {
      props.onUploadSuccess(props.uploadId, signedUploadInfoArray)
    }
  }

  const _fetchUploadUrl = async (options, file) => {
    return new Promise((resolve, reject) => {
      UploadHelper.fetchUploadUrl({}, options, (err, signedUploadInfo) => {
        if (err) {
          reject(err)
        }
        console.log('fetchUploadUrl:', err, signedUploadInfo)
        const uploadOptions = {
          pickerResult: file,
          signedUploadInfo: signedUploadInfo,
          onEvent: (type, percent) => {
            console.log('progress:', type, percent)
            if (type === 'progress') {
              //return this.onUploadProgress(percent)
            } else if (type === 'load') {
              resolve(signedUploadInfo)
            } else if (type === 'error') {
              reject(Helper.getString('fileUploadErr'))
            }
          }
        }
        UploadHelper.uploadFormDataWithProgress(uploadOptions)
      })
    })
  }

  const onUploadProgress = (percent) => {
    setFetchState(ApiHelper.State.LOADING)
  }

  const _uploadAssetWithSignedUploadInfo = (options, file) => {

    return new Promise((resolve, reject) => {
      let signedUploadInfo
      try {
        signedUploadInfo = JSON.parse(JSON.stringify(props.signedUploadInfo))
      } catch (e) {
        console.log('Json parse error', e)
        onError(Helper.getString('fileUploadErr'))
      }
      const types = file.type.split('/')
      let fileExtn = ''
      if (types.length === 2) {
        fileExtn = types[1] || options.ext
      }
      signedUploadInfo.fields.key = signedUploadInfo.fields.key + uuid.v4() + '.' + fileExtn
      signedUploadInfo.link = AppConfig.CDN_BASEPATH + '/' + signedUploadInfo.fields.key
      signedUploadInfo.fields['Content-Type'] = file.type
      const uploadOptions = {
        pickerResult: file,
        signedUploadInfo: signedUploadInfo,
        onEvent: (type, percent) => {
          console.log('progress:', type, percent)
          if (type === 'progress') {
            //this.onUploadProgress(percent)
          } else if (type === 'load') {
            resolve(signedUploadInfo)
          } else if (type === 'error') {
            return reject(Helper.getString('fileUploadErr'))
          }
        }
      }
      UploadHelper.uploadFormDataWithProgress(uploadOptions)
    })
  }

  const _rezizeAndUpload = async (options, file, dimen, actulaFileExt) => {
    let resize = true
    if (options.ext === 'svg') {
      resize = false
    } else if (selectedImgWidth <= dimen.width && selectedImgHeight <= dimen.height && actulaFileExt === props.uploadExt) {
      resize = false
    }

    let compressFormat = 'PNG' // default format
    if (options.ext === 'jpg') {
      compressFormat = 'JPEG'
    } else if (options.ext === 'png') {
      compressFormat = 'PNG'
    }

    let resizeResult
    if (resize) {
      console.log('Custom Image Uploader going for resize')
      resizeResult = await AppHelper.resizeFile({
        file,
        maxWidth: dimen.width,
        maxHeight: dimen.height,
        compressFormat: compressFormat,
        quality: 70,
        outputType: 'blob'
      })
    }

    const fileResult = resizeResult || file
    let signedUploadInfoResult

    if (props.signedUploadInfo) {
      signedUploadInfoResult = await _uploadAssetWithSignedUploadInfo(options, fileResult)
    } else {
      signedUploadInfoResult = await _fetchUploadUrl(options, fileResult)
    }

    return signedUploadInfoResult
  }

  const onUpload = async () => {
    if (!file) {
      return onError(Helper.getString('fileChoose'))
    }
    const maxSize = props.fileSizeLimit ? props.fileSizeLimit : FILE_SIZE_MB;
    if (file.size > maxSize) {
      const errMsg = maxSize / 1000000 + Helper.getString('fileSizeExceedMsg')
      return onError(errMsg)
    }
    setMessage('');
    setFetchState(ApiHelper.State.READY)
    const ext = _getFileExt()
    const options = {
      uploadLocation: props.uploadLocation,
      type: props.uploadType || 'media',
      ext: props.uploadExt || ext
    }

    onUploadProgress()
    try {
      const proms = []
      for (const dimen of props.uploadDimensions) {
        proms.push(_rezizeAndUpload(options, file, dimen, ext))
      }
      const [result1, result2, result3] = await Promise.all(proms)
      console.log('Custom Image Uploader result1, result2, result3 , ', result1, result2, result3)
      onUploadComplete([result1, result2, result3])
    } catch (err) {
      onError(err)
    }
  }

  const setDataUrl = (file, e) => {
    setFile(file);
    setMessage('')
    setFetchState(ApiHelper.State.READY)
    setUploadInProgress(true);
  }

  useEffect(() => {
    if (file) {
      setTimeout(() => {
        onUpload()
      }, 1000)
    }
  }, [file])

  const readFile = (file) => {
    props.setIsUploading && props.setIsUploading(prevState => ({ ...prevState, [props.uploadId]: true }))
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image;
      img.src = reader.result;
      img.onload = function () {
        setSelectedImgWidth(img.width)
        setSelectedImgHeight(img.height)
        setDataUrl(file, e)
      };
    }
    reader.readAsDataURL(file)
  }

  const onFileChange = (e) => {
    if (props.useSignedUploadInfo) {
      if (!props.signedUploadInfo) {
        onError(Helper.getString('fileCouldNotBeUploaded'))
        return
      }
    }
    const file = e.target.files[0]
    const acceptArray = props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'
    if (file) {
      if (acceptArray.split(', ').indexOf(file.type) > -1) {
        readFile(file)
      } else {
        onError(Helper.getString('invalidFileType'))
      }
    }
  }

  const onFileDelete = (inputTagId) => {
    setMessage('')
    setFetchState(ApiHelper.State.READY);
    props.handleImageUploadErrorMsg && props.handleImageUploadErrorMsg('')
    const e = document.getElementById(inputTagId)
    e.value = ''
    const field = {
      key: ''
    }
    props.onUploadSuccess(props.uploadId, { link: '', fields: field })
  }

  return (
    <div className={css(Styles.container)}>
      <input
        id={inputTagId}
        className={css(Styles.fileInput)}
        onChange={onFileChange}
        type='file'
        style={{ display: 'none' }}
        accept={props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'}
        disabled={props.disabled || uploadInProgress} />
      <div className={css(Styles.outerMainContainer)}>

        <div className={outerContainer}>
          <label htmlFor={inputTagId} className={(props.disabled || uploadInProgress) ? css(Styles.labelDisableContainer) : css(Styles.labelContainer)}>
            {(!props.url || uploadInProgress) &&
              <Fragment>
                <div className={css(Styles.uploadImageIconContainer, props.customImageUploadContainer)}>
                  <img src={require('../assets/images/imagetemplatenoborder.png')}
                    alt={Helper.getString('uploadImageIconAlt')}
                    className={css(Styles.uploaderImg)}
                    width={props.imageUploadIconContainerWidth}
                    height={props.imageUploadIconContainerHeight}
                  />
                  {uploadInProgress ?
                    <CircularProgress size={50} className={css(Styles.progressIcon)} />
                    :
                    <p className={css(Styles.attachFileText, props.cutomImageUploaderIcon)}>+</p>
                  }
                </div>
              </Fragment>
            }
            {props.url && !uploadInProgress &&
              <div className={css(props.imageContainer)}>
                <img src={props.url}
                  alt={Helper.getString('uploadedThumImageAlt')}
                  className={css(Styles.img)}
                  width={props.imageContainerWidth}
                  height={props.imageContainerHeight}
                />
              </div>
            }
          </label>
          {props.url && !uploadInProgress &&
            <Fragment>
              <div className={props.disabled ? css(Styles.closeIconContainer, Styles.closeIconDisableContainer) : css(Styles.closeIconContainer)}
                onClick={() => !props.disabled && onFileDelete(inputTagId)}>
                <img src={require('../assets/images/deleteasset.png')}
                  alt={Helper.getString('deleteAssetIconAlt')}
                  style={{ width: props.iconSize === 'sm' ? 35 : 45, height: props.iconSize === 'sm' ? 35 : 45 }}
                />
              </div>
            </Fragment>
          }
          {!uploadInProgress && fetchState === ApiHelper.State.ERROR && props.displayErrorInParent === false &&
            <Tooltip title={message} classes={{
              tooltip: css(Styles.titleTooltip),
              popper: css(Styles.tooltipPopper)
            }}
            className={props.disabled ? css(Styles.errorIconContainer, Styles.errorIconDisableContainer) : css(Styles.errorIconContainer)}>
              <img src={require('../assets/images/fileerror.png')}
                style={{ width: props.iconSize === 'sm' ? 27 : 35, height: props.iconSize === 'sm' ? 27 : 35 }}
              />
            </Tooltip>
          }
        </div>
      </div>
    </div>
  )
}

export default CustomImageUploaderNew;

const Styles = AppConfig.CUSTOM_STYLE ? CustomImageUploaderStyles : StyleSheet.create({
  container: {
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  fileInput: {
    width: '100%'
  },
  outerMainContainer: {
    display: 'flex'
  },
  labelDisableContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: 0,
    cursor: 'unset',
    borderRadius: 0,
  },
  labelContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    padding: 0,
    borderRadius: 0,
  },
  uploadImageIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    position: 'relative'
  },
  uploaderImg: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  progressIcon: {
    position: 'absolute',
  },
  attachFileText: {
    margin: 0,
    fontSize: 100,
    color: '#FFFFFFB3',
    position: 'absolute',
    textAlign: 'center',
    '@media (max-width: 1024px) and (min-width: 768px)': {
      fontSize: 80,
    }
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover'
  },
  closeIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    cursor: 'pointer',
  },
  closeIconDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    minHeight: 100,
    position: 'relative',
    overflow: 'hidden'
  },
  imageOuterContainer: {
    flex: 'unset',
  },
  titleTooltip: {
    padding: 30,
    backgroundColor: '#000000',
    fontSize: 18,
    lineHeight: '23px',
    whiteSpace: 'pre-wrap'
  },
  tooltipPopper: {
    zIndex: 13001
  },
  errorIconContainer: {
    position: 'absolute',
    top: 5,
    left: 5,
    display: 'flex',
    cursor: 'pointer',
  },
  errorIconDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
})