import LocalizedStrings from 'react-localization';
import { StringTable } from '../../StringTable';

const byLangs = {}
StringTable.forEach(line => {
  const keys = Object.keys(line)
  keys.forEach(s => {
    if (s !== 'key') {
      if (!(s in byLangs)) {
        byLangs[s] = {}
      }
      byLangs[s][line.key] = line[s]
    }
  })
});
//console.log('LocalizedStrings langs:', byLangs.size)
export const Strings = new LocalizedStrings(byLangs)
const appLanguage = (typeof window === 'undefined') ? 'en' : localStorage.getItem('appLanguage') || 'en'

Strings.setLanguage(appLanguage)
