import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';

import { AppConfig } from '../AppConfig';
import { CommonStyles } from '../styles/Styles';
import { HelmetComponent } from '../common/components/HelmetComponent';
import { Header } from '../common/components/Header';
import { Link } from 'react-router-dom';
import { Footer } from '../common/components/Footer';

export class Terms extends Component {
  render() {
    return (
      <div className={css(CommonStyles.container)}>
        <Header />
        <Grid container className={css(CommonStyles.textfulPageContent)}>

          <HelmetComponent page={'terms'} />
          <div className={css(CommonStyles.textfulPageContentInnerContainer)}>
            <h1 className={css(CommonStyles.title)}>Terms of Service</h1>

            <h2 className={css(CommonStyles.textfulPageSubtitle)}>1. Your Relationship with Us</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              Welcome to Razzl (the “Platform”), which is provided by Razzl LLC, a company operating under the laws of Delaware (collectively such entities will be referred to as “Razzl”, “we” or “us”).
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You are reading the terms of service (the “Terms”), which govern the relationship and serve as an agreement between you and us and set forth the terms and conditions by which you may access and use the Platform and our related websites, services, applications, products and content (collectively, the “Services”, “Service”). Our Services are provided for private, non-commercial use. For purposes of these Terms, “you” and “your” means you as the user of the Services.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              The Terms form a legally binding agreement between you and us. Please take the time to read them carefully.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You must be at least 13 years old to use the Service. If you are under age 18, you may only use the Services with the consent of your parent or legal guardian. Please be sure your parent or legal guardian has reviewed and discussed these Terms with you.
            </p>

            <h2 className={css(CommonStyles.textfulPageSubtitle)}>2. Accepting the Terms</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              By accessing or using our Services, you confirm that you can form a binding contract with Razzl, that you accept these Terms and that you agree to comply with them. Your access to and use of our Services is also subject to our <Link to='/privacy' className={css(CommonStyles.linkText)}> Privacy Policy</Link> and <Link to='/community-guidelines' className={css(CommonStyles.linkText)}>Community Guidelines</Link>. By using the Services, you consent to the terms of the <Link to='/privacy' className={css(CommonStyles.linkText)}> Privacy Policy</Link>.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If you are accessing or using the Services on behalf of a business or entity, then (a) “you” and “your” includes you and that business or entity, (b) you represent and warrant that you are an authorized representative of the business or entity with the authority to bind the entity to these Terms, and that you agree to these Terms on the entity’s behalf, and (c) your business or entity is legally and financially responsible for your access or use of the Services as well as for the access or use of your account by others affiliated with your entity, including any employees, agents or contractors.
            </p>
            {/* start from Here */}

            <h2 className={css(CommonStyles.textfulPageSubtitle)}>3. Your Account with Us</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              To access or use some of our Services, you must create an account with us. You agree that you are solely responsible (to us and to others) for the activity that occurs under your account.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We reserve the right to disable your user account at any time, including if you have failed to comply with any of the provisions of these Terms, or if activities occur on your account which, in our sole discretion, would or might cause damage to or impair the Services or infringe or violate any third-party rights, or violate any applicable laws or regulations.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If you no longer want to use our Services again, and choose to delete your account, you will not be able to reactivate your account or retrieve any of the content or information you have added.
            </p>

            <h2 className={css(CommonStyles.textfulPageSubtitle)}>4. Your Access to and Use of Our Services</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              Your access to and use of the Services is subject to these Terms and all applicable laws and regulations. You may not:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  access or use the Services if you are not fully able and legally competent to agree to these Terms or are authorized to use the Services by your parent or legal guardian;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  make unauthorized copies, modify, adapt, translate, reverse engineer, disassemble, decompile or create any derivative works of the Services or any content included therein, including any files, tables or documentation (or any portion thereof) or determine or attempt to determine any source code, algorithms, methods or techniques embodied by the Services or any derivative works thereof;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  distribute, license, transfer, or sell, in whole or in part, any of the Services or any derivative works thereof
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  market, rent or lease the Services for a fee or charge, or use the Services to advertise or perform any commercial solicitation;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  use the Services, without our express written consent, for any commercial or unauthorized purpose, including communicating or facilitating any commercial advertisement or solicitation or spamming;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  interfere with or attempt to interfere with the proper working of the Services, disrupt our website or any networks connected to the Services, or bypass any measures we may use to prevent or restrict access to the Services;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  incorporate the Services or any portion thereof into any other program or product. In such case, we reserve the right to refuse service, terminate accounts or limit access to the Services in our sole discretion;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  use automated scripts to collect information from or otherwise interact with the Services;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  impersonate any person or entity, or falsely state or otherwise misrepresent you or your affiliation with any person or entity, including giving the impression that any content you upload, post, transmit, distribute or otherwise make available emanates from the Services;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  intimidate or harass another, or promote sexually explicit material, violence or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  use or attempt to use another’s account, service or system without authorization from Razzl, or create a false identity on the Services;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  use the Services in a manner that may create a conflict of interest or undermine the purposes of the Services, such as trading reviews with other users or writing or soliciting fake reviews;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  use the Services to upload, transmit, distribute, store or otherwise make available in any way: files that contain viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  any unsolicited or unauthorized advertising, solicitations, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other prohibited form of solicitation;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  any private information of any third party, including addresses, phone numbers, email addresses, number and feature in the personal identity document (e.g., National Insurance numbers, passport numbers) or credit card numbers;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  any material which does or may infringe any copyright, trademark or other intellectual property or privacy rights of any other person;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  any material which is defamatory of any person, obscene, offensive, pornographic, hateful or inflammatory;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  any material that would constitute, encourage or provide instructions for a criminal offence, dangerous activities or self-harm;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  any material that is deliberately designed to provoke or antagonize people, especially trolling and bullying, or is intended to harass, harm, hurt, scare, distress, embarrass or upset people;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  any material that contains a threat of any kind, including threats of physical violence;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  any material that is racist or discriminatory, including discrimination on the basis of someone’s race, religion, age, gender, disability or sexuality;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  any answers, responses, comments, opinions, analysis or recommendations that you are not properly licensed or otherwise qualified to provide; or
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  material that, in the sole judgment of Razzl, is objectionable or which restricts or inhibits any other person from using the Services, or which may expose Razzl, the Services or its users to any harm or liability of any type.
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara)}>
              In addition to the above, your access to and use of the Services must, at all times, be compliant with our <Link to='/community-guidelines' className={css(CommonStyles.linkText)}>Community Guidelines</Link>.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We reserve the right, at any time and without prior notice, to remove or disable access to content at our discretion for any reason or no reason. Some of the reasons we may remove or disable access to content may include finding the content objectionable, in violation of these Terms or our Community Policy, or otherwise harmful to the Services or our users.
            </p>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>5. Intellectual Property Rights</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              We respect intellectual property rights and ask you to do the same. As a condition of your access to and use of the Services, you agree to the terms of the <Link to='/intellectual-property-policy' className={css(CommonStyles.linkText)}>Copyright Policy</Link>.
            </p>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>6. Content</h2>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Razzl Content
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              As between you and Razzl, all content, software, images, text, graphics, illustrations, logos, patents, trademarks, service marks, copyrights, photographs, audio, videos, music on and “look and feel” of the Services, and all intellectual property rights related thereto (the “Razzl Content”), are either owned or licensed by Razzl, it being understood that you or your licensors will own any User Content (as defined below) you upload or transmit through the Services. Use of the Razzl Content or materials on the Services for any purpose not expressly permitted by these Terms is strictly prohibited. Such content may not be downloaded, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed or otherwise exploited for any purpose whatsoever without our or, where applicable, our licensors’ prior written consent. We and our licensors reserve all rights not expressly granted in and to their content.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You acknowledge and agree that we may generate revenues, increase goodwill or otherwise increase our value from your use of the Services, including, by way of example and not limitation, through the sale of advertising, sponsorships, and promotions, and except as specifically permitted by us in these Terms or in another agreement you enter into with us, you will have no right to share in any such revenue, goodwill or value whatsoever. You further acknowledge that, except as specifically permitted by us in these Terms or in another agreement you enter into with us, you (i) have no right to receive any income or other consideration from any User Content (defined below), and (ii) are prohibited from exercising any rights to monetize or obtain consideration from any User Content within the Services or on any third party service.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Subject to the terms and conditions of the Terms, you are hereby granted a non-exclusive, limited, non-transferable, non-sublicensable, revocable, worldwide license to access and use the Services, including to download the Platform on a permitted device, and to access the Razzl Content solely for your personal, non-commercial use through your use of the Services and solely in compliance with these Terms. Razzl reserves all rights not expressly granted herein in the Services and the Razzl Content. You acknowledge and agree that Razzl may terminate this license at any time for any reason or no reason.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You acknowledge and agree that when you view content provided by others on the Services, you are doing so at your own risk. The content on our Services is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our Services.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We make no representations, warranties or guarantees, whether express or implied, that any Razzl Content (including User Content) is accurate, complete or up to date. Where our Services contain links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. You acknowledge that we have no obligation to pre-screen, monitor, review, or edit any content posted by you and other users on the Services (including User Content).
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              User-Generated Content
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Users of the Services may be permitted to upload, post or transmit (such as via a stream) or otherwise make available content through the Services including, without limitation, any text, photographs, user videos, sound recordings and the musical works embodied therein, including videos that incorporate locally stored sound recordings from your personal music library and ambient noise (“User Content”). Users of the Services may also extract all or any portion of User Content created by another user to produce additional User Content, including collaborative User Content with other users, that combine and intersperse User Content generated by more than one user. Users of the Services may also overlay music, graphics, stickers, and other elements provided by Razzl (“Razzl Elements”) onto this User Content and transmit this User Content through the Services. The information and materials in the User Content, including User Content that includes Razzl Elements, have not been verified or approved by us. The views expressed by other users on the Services do not represent our views or values.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Whenever you access or use a feature that allows you to upload or transmit User Content through the Services (including via certain third-party social media platforms such as Instagram, Facebook, YouTube, Twitter), or to make contact with other users of the Services, you must comply with the standards set out at “Your Access to and Use of Our Services” above. You may also choose to upload or transmit your User Content, including User Content that includes Razzl Elements, on sites or platforms hosted by third parties. If you decide to do this, you must comply with their content guidelines as well as with the standards set out at “Your Access to and Use of Our Services” above. As noted above, these features may not be available to all users of the Services, and we have no liability to you for limiting your right to certain features of the Services.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You warrant that any such contribution does comply with those standards, and you will be liable to us and indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Any User Content will be considered non-confidential and non-proprietary. You must not post any User Content on or through the Services or transmit to us any User Content that you consider to be confidential or proprietary. When you submit User Content through the Services, you agree and represent that you own that User Content, or you have received all necessary permissions, clearances from, or are authorized by, the owner of any part of the content to submit it to the Services, to transmit it from the Services to other third-party platforms, and/or adopt any third-party content.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If you only own the rights in and to a sound recording, but not to the underlying musical works embodied in such sound recordings, then you must not post such sound recordings to the Services unless you have all permissions, clearances from, or are authorized by, the owner of any part of the content to submit it to the Services
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You or the owner of your User Content still own the copyright in User Content sent to us, but by submitting User Content via the Services, you hereby grant us an unconditional irrevocable, non-exclusive, royalty-free, fully transferable, perpetual worldwide license to use, modify, adapt, reproduce, make derivative works of, publish and/or transmit, and/or distribute and to authorize other users of the Services and other third-parties to view, access, use, download, modify, adapt, reproduce, make derivative works of, publish and/or transmit your User Content in any format and on any platform, either now known or hereinafter invented.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              For the avoidance of doubt, the rights granted in the preceding paragraphs of this Section include, but are not limited to, the right to reproduce sound recordings (and make mechanical reproductions of the musical works embodied in such sound recordings), and publicly perform and communicate to the public sound recordings (and the musical works embodied therein), all on a royalty-free basis. This means that you are granting us the right to use your User Content without the obligation to pay royalties to any third party, including, but not limited to, a sound recording copyright owner (e.g., a record label), a musical work copyright owner (e.g., a music publisher), a performing rights organization (e.g., ASCAP, BMI, SESAC, etc.) (a “PRO”), a sound recording PRO (e.g., SoundExchange), any unions or guilds, and engineers, producers or other royalty participants involved in the creation of User Content.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>Specific Rules for Musical Works and for Recording Artists.</span> If you are a composer or author of a musical work and are affiliated with a PRO, then you must notify your PRO of the royalty-free license you grant through these Terms in your User Content to us. You are solely responsible for ensuring your compliance with the relevant PRO’s reporting obligations. If you have assigned your rights to a music publisher, then you must obtain the consent of such music publisher to grant the royalty-free license(s) set forth in these Terms in your User Content or have such music publisher enter into these Terms with us. If you are a recording artist under contract with a record label, then you are solely responsible for ensuring that your use of the Services is in compliance with any contractual obligations you may have to your record label, including if you create any new recordings through the Services that may be claimed by your label.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>Through-To-The-Audience Rights. </span>All of the rights you grant in your User Content in these Terms are provided on a through-to-the-audience basis, meaning the owners or operators of third-party services will not have any separate liability to you or any other third party for User Content posted or used on such third party service via the Services.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>Waiver of Rights to User Content. </span>By posting User Content to or through the Services, you waive any rights to prior inspection or approval of any marketing or promotional materials related to such User Content. You also waive any and all rights of privacy, publicity, or any other rights of a similar nature in connection with your User Content, or any portion thereof. To the extent any moral rights are not transferable or assignable, you hereby waive and agree never to assert any and all moral rights, or to support, maintain or permit any action based on any moral rights that you may have in or with respect to any User Content you Post to or through the Services.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We also have the right to disclose your identity to any third party who is claiming that any User Content posted or uploaded by you to our Services constitutes a violation of their intellectual property rights, or of their right to privacy.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We, or authorized third parties, reserve the right to cut, crop, edit or refuse to publish, your content at our or their sole discretion. We have the right to remove, disallow, block or delete any posting you make on our Services if, in our opinion, your post does not comply with the content standards set out at “Your Access to and Use of Our Services” above. In addition, we have the right – but not the obligation – in our sole discretion to remove, disallow, block or delete any User Content (i) that we consider to violate these Terms, or (ii) in response to complaints from other users or third parties, with or without notice and without any liability to you. As a result, we recommend that you save copies of any User Content that you post to the Services on your personal device(s) in the event that you want to ensure that you have permanent access to copies of such User Content. We do not guarantee the accuracy, integrity, appropriateness or quality of any User Content, and under no circumstances will we be liable in any way for any User Content.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You control whether your User Content is made publicly available on the Services to all other users of the Services or only available to people you approve. To restrict access to your User Content, you should select the privacy setting available within the Platform.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We accept no liability in respect of any content submitted by users and published by us or by authorized third parties.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Razzl takes reasonable measures to expeditiously remove from our Services any infringing material that we become aware of. It is Razzl’s policy, in appropriate circumstances and at its discretion, to disable or terminate the accounts of users of the Services who repeatedly infringe copyrights or intellectual property rights of others.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We pay close attention to the interests, feedback, comments, and suggestions we receive from the user community. If you choose to contribute by sending us or our employees any ideas for products, services, features, modifications, enhancements, content, refinements, technologies, content offerings (such as audio, visual, games, or other types of content), promotions, strategies, or product/feature names, or any related documentation, artwork, computer code, diagrams, or other materials (collectively “Feedback”), then regardless of what your accompanying communication may say, the following terms will apply, so that future misunderstandings can be avoided. Accordingly, by sending Feedback to us, you agree that:
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Razzl has no obligation to review, consider, or implement your Feedback, or to return to you all or part of any Feedback for any reason;
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Feedback is provided on a non-confidential basis, and we are not under any obligation to keep any Feedback you send confidential or to refrain from using or disclosing it in any way; and
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You irrevocably grant us perpetual and unlimited permission to reproduce, distribute, create derivative works of, modify, publicly perform (including on a through-to-the-audience basis), communicate to the public, make available, publicly display, and otherwise use and exploit the Feedback and derivatives thereof for any purpose and without restriction, free of charge and without attribution of any kind, including by making, using, selling, offering for sale, importing, and promoting commercial products and services that incorporate or embody Feedback, whether in whole or in part, and whether as provided or as modified.
            </p>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>7. Indemnity</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              You agree to defend, indemnify, and hold harmless Razzl, its parents, subsidiaries, and affiliates, and each of their respective officers, directors, employees, agents and advisors from any and all claims, liabilities, costs, and expenses, including, but not limited to, attorneys’ fees and expenses, arising out of a breach by you or any user of your account of these Terms or arising out of a breach of your obligations, representation and warranties under these Terms.
            </p>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>8. EXCLUSION OF WARRANTIES</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              NOTHING IN THESE TERMS SHALL AFFECT ANY STATUTORY RIGHTS THAT YOU CANNOT CONTRACTUALLY AGREE TO ALTER OR WAIVE AND ARE LEGALLY ALWAYS ENTITLED TO AS A CONSUMER.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              THE SERVICES ARE PROVIDED “AS IS” AND WE MAKE NO WARRANTY OR REPRESENTATION TO YOU WITH RESPECT TO THEM. IN PARTICULAR WE DO NOT REPRESENT OR WARRANT TO YOU THAT:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE; AND
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED.
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara)}>
              NO CONDITIONS, WARRANTIES OR OTHER TERMS (INCLUDING ANY IMPLIED TERMS AS TO SATISFACTORY QUALITY, FITNESS FOR PURPOSE OR CONFORMANCE WITH DESCRIPTION) APPLY TO THE SERVICES EXCEPT TO THE EXTENT THAT THEY ARE EXPRESSLY SET OUT IN THE TERMS. WE MAY CHANGE, SUSPEND, WITHDRAW OR RESTRICT THE AVAILABILITY OF ALL OR ANY PART OF OUR PLATFORM FOR BUSINESS AND OPERATIONAL REASONS AT ANY TIME WITHOUT NOTICE
            </p>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>9. LIMITATION OF LIABILITY</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              EXCEPT AS REQUIRED BY APPLICABLE LAW, RAZZL, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS WILL NOT BE RESPONSIBLE FOR ANY LOSS OF PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL, OR ANTICIPATED SAVINGS; LOSS OR CORRUPTION OF DATA; INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES CAUSED BY:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE SERVICE;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVICE;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  ANY INTERRUPTION OR CESSATION OF THE SERVICE;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  ANY CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES WITHIN THE SERVICES);
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE SERVICE;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  ANY CONTENT WHETHER SUBMITTED BY A USER OR RAZZL, INCLUDING YOUR USE OF CONTENT; AND/OR
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT.
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara)}>
              THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM ASSERTED IS BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              RAZZL AND ITS AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICE IS LIMITED TO THE GREATER OF: (A) THE AMOUNT OF REVENUE THAT RAZZL HAS PAID TO YOU FROM YOUR USE OF THE SERVICE IN THE 12 MONTHS BEFORE THE DATE OF YOUR NOTICE, IN WRITING TO RAZZL, OF THE CLAIM; AND (B) USD $500.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              THESE LIMITATIONS ON OUR LIABILITY TO YOU SHALL APPLY WHETHER OR NOT WE HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              YOU ARE RESPONSIBLE FOR ANY MOBILE CHARGES THAT MAY APPLY TO YOUR USE OF OUR SERVICE, INCLUDING TEXT-MESSAGING AND DATA CHARGES. IF YOU’RE UNSURE WHAT THOSE CHARGES MAY BE, YOU SHOULD ASK YOUR SERVICE PROVIDER BEFORE USING THE SERVICE.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH ANY THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICES, INCLUDING, BY WAY OF EXAMPLE AND NOT LIMITATION, ANY CARRIER, COPYRIGHT OWNER OR OTHER USER, IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE US AND OUR AFFILIATES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.
            </p>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>10. Other Terms</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>Open Source.</span> Some software used in our Service may be offered under an open source license that we make available to you. There may be provisions in an open source license that expressly override some of these terms, so please be sure to read those licenses.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>Entire Agreement.</span> These Terms constitute the whole legal agreement between you and Razzl and govern your use of the Services and completely replace any prior agreements between you and Razzl in relation to the Services.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>Links.</span> You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link to our Services in any website that is not owned by you. The website in which you are linking must comply in all respects with the content standards set out at “Your Access to and Use of Our Services” above. We reserve the right to withdraw linking permission without notice.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>No Waiver.</span> Our failure to insist upon or enforce any provision of these Terms shall not be construed as a waiver of any provision or right.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>Security.</span> We do not guarantee that our Services will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programs and platform to access our Services. You should use your own virus protection software.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>Severability.</span> If any court of law, having jurisdiction to decide on this matter, rules that any provision of these Terms is invalid, then that provision will be removed from the Terms without affecting the rest of the Terms, and the remaining provisions of the Terms will continue to be valid and enforceable.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>ARBITRATION AND CLASS ACTION WAIVER.</span> This Section includes an arbitration agreement and an agreement that all claims will be brought only in an individual capacity (and not as a class action or other representative proceeding). Please read it carefully. You may opt out of the arbitration agreement by following the opt out procedure described below.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Informal Process First. You agree that in the event of any dispute between you and Razzl, you will first contact Razzl and make a good faith sustained effort to resolve the dispute before resorting to more formal means of resolution, including without limitation any court action.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Arbitration Agreement. After the informal dispute resolution process any remaining dispute, controversy, or claim (collectively, “Claim”) relating in any way to your use of Razzl’s services and/or products, including the Services, or relating in any way to the communications between you and Razzl or any other user of the Services, will be finally resolved by binding arbitration. This mandatory arbitration agreement applies equally to you and Razzl. However, this arbitration agreement does not (a) govern any Claim by Razzl for infringement of its intellectual property or access to the Services that is unauthorized or exceeds authorization granted in these Terms or (b) bar you from making use of applicable small claims court procedures in appropriate cases. If you are an individual, you may opt out of this arbitration agreement within thirty (30) days of the first of the date you access or use this Services by following the procedure described below.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              You agree that the U.S. Federal Arbitration Act governs the interpretation and enforcement of this provision, and that you and Razzl are each waiving the right to a trial by jury or to participate in a class action. This arbitration provision will survive any termination of these Terms.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If you wish to begin an arbitration proceeding, after following the informal dispute resolution procedure, you must send a letter requesting arbitration and describing your claim to <a href={'mailto:' + AppConfig.SUPPORT_EMAIL} className={css(CommonStyles.linkText)}>contactus@razzl.com.</a>
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              The arbitration will be administered by the American Arbitration Association (AAA) under its rules including, if you are an individual, the AAA's Supplementary Procedures for Consumer-Related Disputes. If you are not an individual or have used the Services on behalf of an entity, the AAA's Supplementary Procedures for Consumer-Related Disputes will not be used. The AAA's rules are available at www.adr.org or by calling 1-800-778-7879.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              The arbitrator, and not any federal, state, or local court, will have exclusive authority to resolve any dispute relating to the interpretation, applicability, unconscionability, arbitrability, enforceability, or formation of this arbitration agreement, including any claim that all or any part of this arbitration agreement is void or voidable. However, the preceding sentence will not apply to the “Class Action Waiver” section below.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If you do not want to arbitrate disputes with Razzl and you are an individual, you may opt out of this arbitration agreement by sending an email to <a href={'mailto:' + AppConfig.SUPPORT_EMAIL} className={css(CommonStyles.linkText)}>contactus@razzl.com</a> within thirty (30) days of the first of the date you access or use the Services.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>Class Action Waiver.</span> Any Claim must be brought in the respective party’s individual capacity, and not as a plaintiff or class member in any purported class, collective, representative, multiple plaintiff, or similar proceeding (“Class Action”). The parties expressly waive any ability to maintain any Class Action in any forum. If the Claim is subject to arbitration, the arbitrator will not have authority to combine or aggregate similar claims or conduct any Class Action nor make an award to any person or entity not a party to the arbitration. Any claim that all or part of this Class Action Waiver is unenforceable, unconscionable, void, or voidable may be determined only by a court of competent jurisdiction and not by an arbitrator. The parties understand that any right to litigate in court, to have a judge or jury decide their case, or to be a party to a class or representative action, is waived, and that any claims must be decided individually, through arbitration.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If this class action waiver is found to be unenforceable, then the entirety of the Arbitration Agreement, if otherwise effective, will be null and void. The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim. If for any reason a claim proceeds in court rather than in arbitration, you and Razzl each waive any right to a jury trial.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If a counter-notice is received by Razzl’s Copyright Agent, we may send a copy of the counter-notice to the original complaining party informing that person that we may replace the removed content or cease disabling it. Unless the original complaining party files an action seeking a court order against the Content Provider, member or user, the removed content may be replaced, or access to it restored, in ten business days or more after receipt of the counter-notice, at Razzl’s sole discretion.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Please understand that filing a counter-notification may lead to legal proceedings between you and the complaining party to determine ownership. Be aware that there may be adverse legal consequences in your country if you make a false or bad faith allegation by using this process.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              <span className={css(CommonStyles.boldText)}>Limitation on Legal Action.</span> YOU AND RAZZL AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              App Stores
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              To the extent permitted by applicable law, the following supplemental terms shall apply when accessing the Platform through specific devices:
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Notice regarding Apple.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              By downloading the Platform from a device made by Apple, Inc. (“Apple”) or from Apple’s App Store, you specifically acknowledge and agree that:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  These Terms are between Razzl and you; Apple is not a party to these Terms.
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  The license granted to you hereunder is limited to a personal, limited, non-exclusive, non-transferable right to install the Platform on the Apple device(s) authorized by Apple that you own or control for personal, non-commercial use, subject to the Usage Rules set forth in Apple’s App Store Terms of Services.
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Apple is not responsible for the Platform or the content thereof and has no obligation whatsoever to furnish any maintenance or support services with respect to the Platform.
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  In the event of any failure of the Platform to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the Platform, if any, to you. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Platform.
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Apple is not responsible for addressing any claims by you or a third party relating to the Platform or your possession or use of the Platform, including without limitation (a) product liability claims; (b) any claim that the Platform fails to conform to any applicable legal or regulatory requirement; and (c) claims arising under consumer protection or similar legislation.
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  In the event of any third-party claim that the Platform or your possession and use of the Platform infringes such third party’s intellectual property rights, Apple is not responsible for the investigation, defense, settlement or discharge of such intellectual property infringement claim.
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  You represent and warrant that (a) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (b) you are not listed on any U.S. Government list of prohibited or restricted parties.
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Apple and its subsidiaries are third party beneficiaries of these Terms and upon your acceptance of the terms and conditions of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary hereof.
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Razzl expressly authorizes use of the Platform by multiple users through the Family Sharing or any similar functionality provided by Apple.
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Google Play.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              By downloading the Platform from Google Play (or its successors) operated by Google, Inc. or one of its affiliates (“Google”), you specifically acknowledge and agree that:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  to the extent of any conflict between (a) the Google Play Terms of Services and the Google Play Business and Program Policies or such other terms which Google designates as default end user license terms for Google Play (all of which together are referred to as the “Google Play Terms”), and (b) the other terms and conditions in these Terms, the Google Play Terms shall apply with respect to your use of the Platform that you download from Google Play, and
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  you hereby acknowledge that Google does not have any responsibility or liability related to compliance or non-compliance by Razzl or you (or any other user) under these Terms or the Google Play Terms.
                </p>
              </li>
            </ul>
          </div>
        </Grid>
        <div className={css([CommonStyles.footerContainer, CommonStyles.footerContainerV2])}>
          <Footer />
        </div>
      </div>
    );
  }
}

const Styles = StyleSheet.create({

})
