import { Link } from '@material-ui/core'
import { StyleSheet, css } from 'aphrodite'
import React from 'react'
import { AppConfig } from '../AppConfig'

const footerLinks = [
  {
    id: 1,
    title: 'CONTACT US',
    link: 'https://www.razzl.com/contact',
  },
  {
    id: 2,
    title: 'TERMS OF SERVICE',
    link: 'https://www.razzl.com/terms'
  },
  {
    id: 3,
    title: 'PRIVACY POLICY',
    link: 'https://www.razzl.com/privacy',
  },
  {
    id: 4,
    title: 'COMMUNITY GUIDELINES',
    link: 'https://www.razzl.com/community-guidelines',
  }
]

const Footer = () => {
  return (
    <div className={css(Styles.footerContainer)}>
      <a href={AppConfig.WEBSITE_LINK} className={css(Styles.logoContainerStyles)}>
        <img src={require('../assets/images/logo-group.png')} className={css(Styles.logo)} />
      </a>
      <div className={css(Styles.footerBottomContainer)}>
        <div className={css(Styles.footerLink)}>
          Copyright © 2023 RAZZL LLC. All Rights Reserved
        </div>
        <div className={css(Styles.footerLinks)}>
          {footerLinks.map((item) =>
            <Link key={item.id} href={item.link} rel='noopener noreferrer' target='_blank' className={css(Styles.footerLink)}>{item.title}</Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default Footer

const Styles = StyleSheet.create({
  footerContainer: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 80,
    paddingRight: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 5,
    fontFamily: 'Circular Std, sans-serif',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 30,
      paddingBottom: 30,
    }
  },
  footerBottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
      gap: 12
    }
  },
  logoContainerStyles: {
    cursor: 'pointer',
    width: 200,

    '@media (max-width: 768px)': {
      width: 150,
    }
  },
  logo: {
    height: 40,
    width: 200,

    '@media (max-width: 768px)': {
      height: 30,
      width: 150,
    }
  },
  footerLinks: {
    display: 'flex',
    gap: 48,

    '@media (max-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
      gap: 8,
    }
  },
  footerLink: {
    color: '#FFFFFFCC',
    textDecoration: 'none',
    fontSize: 15,

    '@media (max-width: 768px)': {
      fontSize: 10,
      textAlign: 'center'
    }
  },
})