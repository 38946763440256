import React, { Component, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';
import { Helper } from '../../common/helpers/Helper';
import { CircularProgress } from '@material-ui/core';
import { ImageUploader } from '../../common/components/ImageUploader';
import { AppConfig } from '../../AppConfig';
import { CustomImageUploaderStyles } from '../../styles/CustomStyles';
import { AppTheme } from '../../styles/AppTheme';
import AvatarEditor from 'react-avatar-editor'

const uuid = require('uuid');

const FILE_SIZE_MB = 10000000
export class ProfileImageUploader extends ImageUploader {
  render() {
    if (this.props.mode === 'fit' && this.selectedImgHeight && this.selectedImgWidth) {
      this.height = (this.selectedImgHeight / this.selectedImgWidth) * this.width
    }
    const ratio = this.width / this.height
    let height = 120
    let width = height * ratio
    if (width > 250) { // too wide
      width = 250
      height = width / ratio
    }
    const EditorStyles = StyleSheet.create({
      editor: {
        backgroundColor: 'white',
        height,
        width
      },
    })
    const imageUrl = this.image.imageUrl ? this.image.imageUrl : this.currentUrl
    const inputTagId = 'upload-button-' + moment().valueOf() + this.props.uploadId
    const outerContainer = (!this.state.uploadInProgress && (this.currentUrl || this.image.imageUrl)) ? css(Styles.outerContainer, Styles.imageOuterContainer) : css(Styles.outerContainer)
    return (
      <div className={css(Styles.container)}>
        <div className={css(Styles.imgContainer)}>
          <AvatarEditor
            ref={this.setEditorRef.bind(this)}
            className={css(EditorStyles.editor)}
            image={this.state.dataURL || this.currentUrl}
            width={this.width}
            height={this.height}
            border={1}
            borderRadius={this.borderRadius}
            color={[0, 0, 0, 0.5]} // RGBA
          //scale={1}
          //rotate={0}
          />
        </div>
        <div className={this.props.mode === 'fit' ? css(Styles.textContainer, Styles.fitContainer) : css(Styles.textContainer)} style={{ height: height, width: width }}>
          <input
            id={inputTagId}
            className={css(Styles.fileInput)}
            onChange={this.onFileChange.bind(this)}
            type='file'
            style={{ display: 'none' }}
            accept={this.props.uploadAccept || 'image/png, image/jpeg, image/svg+xml'}
            disabled={this.props.disabled} />
          <div className={css(Styles.outerMainContainer)}>

            <div className={outerContainer}>
              <label htmlFor={inputTagId} className={this.props.disabled ? css(Styles.labelDisableContainer) : css(Styles.labelContainer)}>
                {/* <FileDrop onDrop={!this.props.disabled && this.handleDrop.bind(this)} className={css(Styles.fileDrop)} targetClassName={css(Styles.targetFileDrop)}> */}
                {(!(this.currentUrl || this.image.imageUrl) || this.state.uploadInProgress) &&
                  <Fragment>
                    <div className={css(Styles.uploadImageIconContainer, this.props.customImageUploadContainer)}>
                      <img src={require('../../assets/images/profile-icon.png')}
                        alt={Helper.getString('uploadImageIconAlt')}
                        className={css(Styles.uploaderImg)}
                        width={this.props.imageContainerWidth}
                        height={this.props.imageContainerHeight}
                      />
                      {this.state.uploadInProgress &&
                        <CircularProgress size={50} className={css(Styles.progressIcon)} />

                      }
                    </div>

                  </Fragment>
                }
                {(this.currentUrl || this.image.imageUrl) && !this.state.uploadInProgress &&
                  <div className={css(this.props.imageContainer)}>
                    <img src={imageUrl}
                      alt={Helper.getString('uploadedThumImageAlt')}
                      className={css(Styles.img)}
                      width={this.props.imageContainerWidth}
                      height={this.props.imageContainerHeight}
                    />
                  </div>
                }
                <div className={css(Styles.buttonStyle)}>
                  <img src={require('../../assets/images/addassetselected.png')}
                    alt={Helper.getString('uploadImageIconAlt')}
                    className={css(Styles.uploaderImg)}
                    width='40'
                    height='40'
                  />
                </div>

              </label>
            </div>


          </div>
        </div>
        {this.state.message && !this.props.disabled && this.renderMessage()}
      </div >
    )
  }

  readFile(file) {
    this.props.handleSaveButtonDisable(true)
    super.readFile(file)
  }

  onError(err) {
    this.props.handleSaveButtonDisable(false)
    super.onError(err)
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomImageUploaderStyles : StyleSheet.create({
  container: {
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
  },
  fitContainer: {
    height: 120,
    width: 'auto'
  },
  fileInput: {
    width: '100%'
  },
  textButton: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 100,
    minHeight: 24,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10
  },
  text: {
    marginTop: 10,
    marginBottom: 3,
    fontSize: 12
  },
  textUpload: {
    color: 'black',
  },
  textError: {
    color: '#f44336',
  },
  textSuccess: {
    color: 'green',
  },
  editor: {
    width: 65,
    height: 65,
  },
  imgContainer: {
    display: 'none'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  imageContent: {
    borderRadius: 0,
    maxWidth: '100%',
    maxHeight: '100%'
  },
  closeIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    // backdropFilter: 'blur(6px)',
    // backgroundColor: 'white',
    display: 'flex',
    cursor: 'pointer',
    // border: '1px solid black',
    // padding: 2,
    // borderRadius: 4,
    width: 45,
    height: 45
  },
  closeIconDisableContainer: {
    backgroundColor: '#f5f5f5',
    cursor: 'default',
  },
  attachFileText: {
    margin: 0,
    fontSize: 100,
    color: '#FFFFFFB3',
    position: 'absolute',
    // top: 0,
    // right: 0,
    // left: 0,
    textAlign: 'center',
    '@media (max-width: 1024px) and (min-width: 768px)': {
      fontSize: 80,
    }
  },
  attachFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10
  },
  labelContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    padding: 0,
    borderRadius: 0,
  },
  labelDisableContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: 0,
    backgroundColor: '#f5f5f5',
    cursor: 'unset',
    borderRadius: 0,
    position: 'relative'
  },
  attachFileTextBrowse: {
    color: AppTheme.primaryColor,
    fontWeight: 500
  },
  outerMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    // border: '1px dashed #E5E5E5',
    // borderRadius: AppTheme.borderRadius,
    minHeight: 100,
    position: 'relative',
    overflow: 'hidden'
  },
  imageOuterContainer: {
    flex: 'unset',
    // border: '1px solid #E5E5E5'
  },
  imageIconContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageIconOuterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  reuploadIconContainer: {
    right: 34
  },
  fileDrop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    position: 'relative'
  },

  targetFileDrop: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  reuploadContainer: {
    fontSize: 16,
    color: AppTheme.primaryColor,
    margin: 20,
    display: 'flex',
    alignItems: 'center'
  },
  reuploadLabel: {
    cursor: 'pointer'
  },
  uploadImageIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    position: 'relative'
  },
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover'
  },
  uploaderImg: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  progressIcon: {
    position: 'absolute',
    // margin: 'auto',
    // top: 0,
    // right: 0,
    // left: 0,
    // bottom: 0
  },
  buttonStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 25,
    width: 50,
    height: 50,
    // border: '1px solid #223DE2',
    // backgroundColor: '#02121D',
    // marginTop: 13,
    // marginBottom: 24,
    position: 'absolute',
    bottom: -8,
    right: -5
  },
  EditText: {
    color: '#FFFFFF',
    fontSize: 13,
    margin: 0
  }
})