import { AppConfig } from '../../AppConfig';
import {
  AuthenticationDetails,
  CognitoIdentityServiceProvider,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as awsservice from 'aws-sdk/lib/service';
import * as CognitoIdentity from 'aws-sdk/clients/cognitoidentity';
import { Helper } from '../helpers/Helper';

/**
 * Created by Vladimir Budilov
 */

//TODO: below code commented out, but enabled in webapp
/*export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;
}

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface Callback {
    callback(): void;
    callbackWithParam(result: any): void;
}*/

export class CognitoMgr {

  static _REGION = AppConfig.CLOUD_REGION;

  static _IDENTITY_POOL_ID = '';
  static _IDP_ENDPOINT = '';
  static _IDENTITITY_ENDPOINT = '';

  static _USER_POOL_ID = AppConfig.COGNITO_USER_POOL;
  static _CLIENT_ID = AppConfig.COGNITO_USER_POOL_CLIENT_ID;

  static _POOL_DATA = {
    UserPoolId: CognitoMgr._USER_POOL_ID,
    ClientId: CognitoMgr._CLIENT_ID
  };

  cognitoCreds;

  getUserPool() {
    if (CognitoMgr._IDP_ENDPOINT) {
      CognitoMgr._POOL_DATA.endpoint = CognitoMgr._IDP_ENDPOINT;
    }
    return new CognitoUserPool(CognitoMgr._POOL_DATA);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  // AWS Stores Credentials in many ways, and with TypeScript this means that
  // getting the base credentials we authenticated with from the AWS globals gets really murky,
  // having to get around both class extension and unions. Therefore, we're going to give
  // developers direct access to the raw, unadulterated CognitoIdentityCredentials
  // object at all times.
  setCognitoCreds(creds) {
    this.cognitoCreds = creds;
  }

  getCognitoCreds() {
    return this.cognitoCreds;
  }

  // This method takes in a raw jwtToken and uses the global AWS config options to build a
  // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
  // to avoid unnecessary calls to setCognitoCreds.

  buildCognitoCreds(idTokenJwt) {
    let url = 'cognito-idp.' + CognitoMgr._REGION.toLowerCase() + '.amazonaws.com/' +
      CognitoMgr._USER_POOL_ID;
    if (CognitoMgr._IDP_ENDPOINT) {
      url = CognitoMgr._IDP_ENDPOINT + '/' + CognitoMgr._USER_POOL_ID;
    }
    const logins = {};
    logins[url] = idTokenJwt;
    const params = {
      IdentityPoolId: CognitoMgr._IDENTITY_POOL_ID, /* required */
      Logins: logins
    };
    const serviceConfigs = {};
    if (CognitoMgr._IDENTITITY_ENDPOINT) {
      serviceConfigs.endpoint = CognitoMgr._IDENTITITY_ENDPOINT;
    }
    const creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
    this.setCognitoCreds(creds);
    return creds;
  }


  getCognitoIdentity() {
    return this.cognitoCreds.identityId;
  }

  getAccessToken(callback) {
    if (callback == null) {
      throw (Helper.getString('cognitoMgrGetAccessToken'));
    }
    if (this.getCurrentUser() != null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          //console.log('CognitoMgr: Can\'t set the credentials:' + err);
          callback.callbackWithParam(null);
        }

        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getAccessToken().getJwtToken());
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  getIdToken(callback) {
    if (callback == null) {
      throw (Helper.getString('cognitoMgrGetIdToken'));
    }
    if (this.getCurrentUser() != null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          //console.log('CognitoMgr: Can\'t set the credentials:' + err);
          callback.callbackWithParam(null);
        }
        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
            //console.log('CognitoMgr: Got the id token, but the session isn\'t valid');
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  getRefreshToken(callback) {
    if (callback == null) {
      throw (Helper.getString('cognitoMgrGetRefreshToken'));
    }
    if (this.getCurrentUser() != null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          //console.log('CognitoMgr: Can\'t set the credentials:' + err);
          callback.callbackWithParam(null);
        }

        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getRefreshToken());
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  refresh() {
    this.getCurrentUser().getSession(function (err, session) {
      if (err) {
        //console.log('CognitoMgr: Can\'t set the credentials:' + err);
      }

      else {
        if (session.isValid()) {
          //console.log('CognitoMgr: refreshed successfully');
        } else {
          //console.log('CognitoMgr: refreshed but session is still not valid');
        }
      }
    });
  }
}
