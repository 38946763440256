import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { AppButton } from '../common/components/AppButton';
import { CircularProgress, Dialog } from '@material-ui/core';
import { Helper } from '../common/helpers/Helper';
import qrcode from 'qrcode';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { AppTheme } from '../styles/AppTheme';
import Footer from '../components/Footer';
import { AppHelper } from '../helpers/AppHelper';
import { AppConfig } from '../AppConfig';

const ProfilePage = () => {
  const { slug } = useParams();
  const [showQrModal, setShowQrModal] = useState(false);
  const [channelData, setChannelData] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [qrCodefetchState, setQrCodeFetchState] = useState(ApiHelper.State.READY);
  const [channelDataFetchState, setChannelDataFetchState] = useState(ApiHelper.State.LOADING);
  const [err, setErr] = useState(null);
  const location = useLocation()
  const [isMobile, setIsMobile] = useState(false);
  const devicesOS = Helper.getMobileOperatingSystem();
  const appstoreLink = Helper.getAppstoreLink();
  const playstoreLink = Helper.getPlaystoreLink();

  useEffect(() => {
    if (devicesOS === 'Android' || devicesOS === 'iOS') {
      setIsMobile(true);
    }
  }, []);

  const fetchChannelData = async () => {
    setChannelDataFetchState(ApiHelper.State.LOADING);
    try {
      const queryParams = {
        slug: slug,
      }
      const response = await ApiHelper.callAwait({
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['miscs', 'channelpage'], queryParams),
        authWithKey: true
      })
      console.log('response ', response)
      setChannelData(response)
      setChannelDataFetchState(ApiHelper.State.READY);
    } catch (error) {
      setChannelDataFetchState(ApiHelper.State.ERROR);
      setErr(Helper.getErrorMsg(error))
    }
  }

  useEffect(() => {
    if (devicesOS === 'Android') {
      // User-Agent indicates an Android device
      window.location.replace(playstoreLink);
    } else if (devicesOS === 'iOS') {
      // User-Agent indicates an iOS device
      window.location.replace(appstoreLink);
    }
    fetchChannelData();
  }, [])

  const handleViewMobile = () => {
    if (devicesOS === 'Android') {
      window.location.href = playstoreLink;
    } else if (devicesOS === 'iOS') {
      window.location.href = appstoreLink;
    }
  };

  const handleViewChannel = async () => {
    qrcode.toDataURL(window.location.href, { width: 360 }, (err, url) => {
      if (err) {
        setQrCodeFetchState(ApiHelper.State.ERROR);
        return setErr(Helper.getErrorMsg(err));
      }
      setQrCodeUrl(url);
      setShowQrModal(true);
    });
  };

  const readyView = () => {
    return (
      <>
        <div className={css(Styles.mainContentContainer)}>
          <div className={css(Styles.logoOuterContainer)}>
            <a href={AppConfig.WEBSITE_LINK} style={{ cursor: 'pointer' }}>
              <div className={css(Styles.logoContainer)}>
                <img
                  src={require('../assets/images/logo.png')}
                  className={css(Styles.logoImage)} />
                <img
                  src={require('../assets/images/logotextblack.png')}
                  className={css(Styles.logoText)} />
              </div>
            </a>
          </div>
          <div className={css(Styles.bottomContainer)}>
            {channelDataFetchState === ApiHelper.State.ERROR ?
              AppHelper.errorView(err, Styles.viewChannelBtn, 'Try Again', () => fetchChannelData())
              : (
                < div className={css(Styles.profileContainer)}>
                  {channelDataFetchState === ApiHelper.State.READY &&
                    (
                      <>
                        <div className={css(Styles.channelNameText)}>
                          {channelData.channelName}
                        </div>
                        <div className={css(Styles.profileInnerContainer)}>
                          <div className={css(Styles.profileInfoContainer)}>
                            <img
                              src={channelData.picture ? channelData.picture : require('../assets/images/profile-icon.png')}
                              className={css(Styles.profileIcon)} />
                            <div className={css(Styles.profileStats)}>
                              <div className={css(Styles.followersText)}>
                                {channelData.stats ? channelData.stats.followCount : ''} Followers
                              </div>
                              <div className={css(Styles.ratingContainer)}>
                                <img
                                  src={require('../assets/images/rating-on.png')}
                                  className={css(Styles.ratingIcon)} />
                                {channelData.stats ? channelData.stats.ratingAverage : ''}
                              </div>
                            </div>
                          </div>
                          <div className={css(Styles.descr)}>
                            {channelData.bio}
                          </div>
                        </div>
                        <AppButton
                          inProcess={qrCodefetchState === ApiHelper.State.LOADING}
                          onClick={isMobile ? handleViewMobile : handleViewChannel}
                          buttonStyle={Styles.viewChannelBtn}
                          buttonText={isMobile ? 'Get Razzl App' : 'View Channel'}
                          buttonTextStyle={isMobile ? Styles.viewChannelBtnMobileTextStyles : Styles.viewChannelBtnTextStyles} />
                      </>
                    )}
                  {qrCodefetchState === ApiHelper.State.ERROR
                    ? <div className={css(Styles.errorTxt)}>{err}</div>
                    : <div className={css(Styles.emptyError)} />}
                </div>
              )}
          </div>
        </div >
        <Footer />
        <Dialog
          open={showQrModal}
          onClose={() => setShowQrModal(false)}
          classes={{ paper: css(Styles.qrCodeModalPaper) }}>
          <div className={css(Styles.closeButton)}
            style={{ cursor: 'pointer' }} onClick={() => setShowQrModal(false)}>
            <img src={require('../assets/images/close.png')}
              alt={Helper.getString('closeImageAlt')}
              width='55'
              height='55'
              className={css(Styles.closeIcon)}
            />
          </div>
          <div>
            <div className={css(Styles.scanText)}>Scan to view channel</div>
            {qrCodeUrl &&
              <img src={qrCodeUrl}
                className={css(Styles.qrCodeImage)}
                alt='QR Code' />
            }
          </div>
        </Dialog>
      </>
    )
  }

  const loadingView = () => {
    return (
      <div className={css(Styles.progressContainer)}>
        <CircularProgress size={48} className={css(Styles.progress)} />
      </div>
    )
  }

  return (
    <div className={css(Styles.profilePageRoot)}>
      {channelDataFetchState !== ApiHelper.State.LOADING && readyView()}
      {channelDataFetchState === ApiHelper.State.LOADING && loadingView()}
    </div>
  )
}

export default ProfilePage

const Styles = StyleSheet.create({
  profilePageRoot: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  mainContentContainer: {
    background: AppTheme.secondaryBg,
    width: '100vw',
    paddingTop: 40,
    fontFamily: 'Circular Std, sans-serif',
    //height: 'calc(100vh - 144px - 40px)', //Footer height - Top padding,
    display: 'flex',
    flex:1,
    flexDirection: 'column',

    '@media (max-width: 768px)': {
      paddingTop: 20,
      //height: 'calc(100vh - 196px - 20px)', //Footer height - Top padding,
    }
  },
  progressContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    height: '100vh',
    width: '100vw',
    backgroundColor: AppTheme.secondaryBg,
  },
  progress: {
    backgroundColor: AppTheme.secondaryBg,
  },
  logoOuterContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 100,

    '@media (max-width: 768px)': {
      justifyContent: 'center',
      paddingLeft: 0,
    }
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    '@media (max-width: 768px)': {
      gap: 8
    }
  },
  logoImage: {
    width: 36,
    height: 'auto',

    '@media (max-width: 768px)': {
      width: 27,
    }
  },
  logoText: {
    width: 145,
    height: 'auto',

    '@media (max-width: 768px)': {
      width: 109,
    }
  },
  bottomContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,

    '@media (max-width: 768px)': {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20
    }
  },
  profileContainer: {
    background: '#2A34C308',
    padding: '62px 62px 18px 62px',
    borderRadius: 30,

    '@media (max-width: 768px)': {
      padding: '40px 20px 5px',
      width: '100%',
    }
  },
  channelNameText: {
    fontSize: 26,
    fontWeight: 'bold',

    '@media (max-width: 768px)': {
      fontSize: 19,
    }
  },
  profileInnerContainer: {
    padding: '30px 0',

    '@media (max-width: 768px)': {
      padding: '20px 0',
    }
  },
  profileInfoContainer: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  profileIcon: {
    width: 75,
    height: 75,
    borderRadius: '50%',

    '@media (max-width: 768px)': {
      width: 50,
      height: 50,
    }
  },
  profileStats: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,

    '@media (max-width: 768px)': {
      gap: 8,
    }
  },
  followersText: {
    fontSize: 21,

    '@media (max-width: 768px)': {
      fontSize: 15,
    }
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    fontSize: 21,

    '@media (max-width: 768px)': {
      fontSize: 15,
    }
  },
  ratingIcon: {
    width: 23,
    height: 23,

    '@media (max-width: 768px)': {
      width: 16,
      height: 16,
    }
  },
  descr: {
    fontSize: 20,
    width: 553,
    marginTop: 43,

    '@media (max-width: 768px)': {
      fontSize: 15,
      width: '100%',
      marginTop: 20,
    }
  },
  viewChannelBtn: {
    width: 187.5,
    height: 45,
    borderRadius: 5,
    background: '#F04037',
    margin: '0 auto',
    fontFamily: 'Circular Std, sans-serif',

    '@media (max-width: 768px)': {
      width: 150,
      height: 35,
    }
  },
  viewChannelBtnTextStyles: {
    fontSize: 17,
    fontWeight: 'normal'
  },
  viewChannelBtnMobileTextStyles: {
    fontSize: 19,
    fontWeight: 'bold',
    '@media (max-width: 767px)': {
      fontSize: 15,
      fontWeight: 'bold',
      padding: 0,
    },
  },
  qrCodeModalPaper: {
    position: 'relative',
    backgroundColor: '#902721',
    color: 'white',
    overflowY: 'auto',
    borderRadius: 30,
    padding: '50px',

    '@media (max-width: 768px)': {
      padding: '20px',
    }
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 30,
    height: 30,

    '@media (max-width: 768px)': {
      width: 20,
      height: 20
    }
  },
  qrCodeImage: {
    width: 360,
    height: 360,
    borderRadius: 30,
    marginTop: 40,

    '@media (max-width: 768px)': {
      width: '100%',
      height: 'auto',
      marginTop: 20
    }
  },
  scanText: {
    textAlign: 'center',
    fontSize: 27,
    fontWeight: 'bold',
    fontFamily: 'Circular Std, sans-serif',

    '@media (max-width: 768px)': {
      fontSize: 20
    }
  },
  errorTxt: {
    margin: '10px 0',
    fontSize: 20,
    color: '#FF0000',
    textAlign: 'center',
    maxWidth: 553,

    '@media (max-width: 768px)': {
      fontSize: 12,
      margin: '4px 0'
    }
  },
  emptyError: {
    height: 44,

    '@media (max-width: 768px)': {
      height: 35
    }
  }
})