import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { UiLoginHelper } from '../helpers/UiLoginHelper';
import { Helper } from '../helpers/Helper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { AppTheme } from '../../styles/AppTheme';
import { TextField } from '@material-ui/core';

export class NumericOTPInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={css(Styles.formSection)}>
        <p className={css(Styles.label)}>{this.props.placeholder ? this.props.placeholder : Helper.getString('otp')}</p>
        <FormControl
          fullWidth>
          <TextField
            {...this.props}
            className={css(Styles.formControl)}
            label={''}
            InputProps={{
              disableUnderline: true,
              type: 'password',
              htmlFor: Helper.getString('otp'),
              classes: {
                root: css(Styles.textInputStyles)
              }
            }}
          />
        </FormControl>
      </div>
    )
  }
}

export class PasswordInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={css(Styles.formSection)}>
        <p className={css(Styles.label)}>{this.props.placeholder ? this.props.placeholder : Helper.getString('enterPassword')}</p>
        <FormControl
          fullWidth>
          <TextField
            {...this.props}
            className={css(Styles.formControl)}
            label={''}
            InputProps={{
              disableUnderline: true,
              type: 'password',
              htmlFor: 'password',
              classes: {
                root: css(Styles.textInputStyles)
              }
            }}
          />
        </FormControl>

      </div>
    )
  }
}

export class UsernameInput extends Component {
  constructor(props) {
    super(props);
    this.userNameType = this.props.userNameType
  }

  render() {
    return (
      <div className={css(Styles.formSection)}>
        <p className={css(Styles.label)}>{this.userNameType === UiLoginHelper.UserNameType.PHONE ? Helper.getString('enterMobile') : Helper.getString('enterEmail')}</p>
        <FormControl
          fullWidth
          className={this.props.formControl}>
          <TextField
            {...this.props}
            className={css(Styles.formControl)}
            label={''}
            InputProps={{
              disableUnderline: true,
              htmlFor: this.userNameType === UiLoginHelper.UserNameType.PHONE ? 'mobile-number' : 'email',
              classes: {
                root: css(Styles.textInputStyles)
              }
            }}
          />
        </FormControl>

      </div>
    )
  }
}

const Styles = StyleSheet.create({
  formSection: {
    margin: '15px 0px',
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    fontSize: 16,
    margin: 0,
    color: '#222222',
    fontWeight: 400,
    marginBottom: 12,
  },
  textInputStyles: {
    border: 0,
    padding: '10px 15px',
    flex: 1,
    textAlign: 'left',
    minHeight: 50,
    fontSize: 18,
    color: '#FFFFFF99',
    '::placeHolder': {
      color: '#FFFFFF99',
    }
  },
  formControl: {
    flex: 1,
    backgroundColor: AppTheme.secondaryBg,
    borderRadius: 16,
    border: '1px solid ' + AppTheme.borderColor
  },
  formControlMargin: {
    marginTop: 20
  },
})
