import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from '@material-ui/core/Button';
import { AppHelper } from '../../helpers/AppHelper'
import { AppTheme } from '../../styles/AppTheme';
import { Controller } from '../Controller';
import { Helper } from '../helpers/Helper';
import { HelmetComponent } from '../components/HelmetComponent';
import { CustomNoPermissionStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { AppButton } from './AppButton';

export class NoPermission extends Component {
  render() {
    return (
      <div className={css(Styles.nfMiddle)}>

        <HelmetComponent page={'no-permission'} />

        <p className={css(Styles.text)}>{Helper.getString('permissionErr')}</p>
        <div>
          <AppButton
            onClick={this.goToHome.bind(this)}
            buttonText={Helper.getString('startAgain')}
          />
        </div>
      </div>
    )
  }
  goToHome() {
    const path = Controller.get().roleHelper().getHome()
    window.location.href = path
  }
}


const Styles = AppConfig.CUSTOM_STYLE ? CustomNoPermissionStyles : StyleSheet.create({
  nfMiddle: {
    maxWidth: 500,
    fontSize: 20,
    paddingTop: '15%',
    textAlign: 'center'
  },
  button: {
    textAlign: 'center',
    backgroundColor: 'whitesmoke',
    fontSize: 16,
    borderRadius: 0,
    cursor: 'pointer',
    padding: 10,
    color: AppTheme.primaryColor
  },
})
