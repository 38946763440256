import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Grid from '@material-ui/core/Grid';

import { AppConfig } from '../AppConfig';
import { CommonStyles } from '../styles/Styles';
import { HelmetComponent } from '../common/components/HelmetComponent';
import { Header } from '../common/components/Header';
import { Link } from 'react-router-dom';
import { Footer } from '../common/components/Footer';

export class CommunityGuidelines extends Component {
  render() {
    return (
      <div className={css(CommonStyles.container)}>
        <Header />
        <Grid container className={css(CommonStyles.textfulPageContent)}>

          <HelmetComponent page={'guidelines'} />
          <div className={css(CommonStyles.textfulPageContentInnerContainer)}>
            <h1 className={css(CommonStyles.title)}>Community Guidelines</h1>
            <p className={css(CommonStyles.textfulPagePara)}>
              The Community Guidelines below outline what we do and don’t allow on Razzl. If you see content that you think violates these guidelines, please report it to us by emailing <a href={'mailto:' + AppConfig.SUPPORT_EMAIL} className={css(CommonStyles.linkText)}>contactus@razzl.com</a> or by using the tools we provide on Razzl. We’re committed to these guidelines and we hope you are too. Overstepping these boundaries may result in deleted content, disabled accounts, or other restrictions. Under certain circumstances, we will go one step further and report the accounts to relevant legal authorities to keep our community safe.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We update our Community Guidelines from time to time to evolve with community behavior, mitigate emerging risks, and keep Razzl a safe place to share what you love.
            </p>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Adult nudity and sexual activities</h2>

            <p className={css(CommonStyles.textfulPagePara)}>
              We do not allow sexually explicit or gratifying content on Razzl, including animated content of this nature. Sexualized content carries many risks, such as triggering legal penalties in some jurisdictions and causing harm to our users through sharing non-consensual imagery (for example, revenge porn). Also, overtly sexual content can be offensive within certain cultures. We do allow exceptions around nudity and sexually explicit content for educational, documentary, scientific, or artistic purposes.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Sexual exploitation
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Sexual exploitation is any actual or attempted abuse of a position of vulnerability, power, or trust for sexual purposes, including but not limited to profiting monetarily, socially, or politically from the sexual exploitation of another.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts, commits, or incites non-consensual sexual acts
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that commits, promotes, or glorifies sexual solicitation or sexual objectification
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Pornography and nudity involving adults
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Pornography explicitly depicts sexual organs and/or activities for the purpose of sexual gratification.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts sexual activities such as penetration, non-penetrative sex, or oral sex
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that shows human genitalia, female nipples, or buttocks
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts sexual arousal
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts a sexual fetish
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Dangerous individuals and organizations</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              We do not allow dangerous individuals or organizations to use our platform to promote terrorism, crime, or other types of behavior that could cause harm. When there is a credible threat to public safety, we handle the issue by banning the account and cooperating with relevant legal authorities.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Terrorists and terrorist organizations
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Terrorists and terrorist organizations are any non-state actors that use premeditated violence or threats of violence to cause harm to non-combatant individuals, in order to intimidate or threaten a population, government, or international organization in the pursuit of political, religious, ethnic, or ideological objectives.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Other dangerous individuals and organizations
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We define dangerous individuals and organizations as those that commit crimes or cause other types of severe harm. The types of groups and crimes include, but are not limited to:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Hate groups
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Violent extremist organizations
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Homicide
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Human trafficking
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Organ trafficking
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Arms trafficking
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Drug trafficking
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Kidnapping
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Extortion
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Blackmailing
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Money laundering
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Fraud
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Cybercrime
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Names, symbols, logos, flags, slogans, uniforms, gestures, portraits, or other objects meant to represent dangerous individuals and/or organizations
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that praises, glorifies, or supports dangerous individuals and/or organizations
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  <span className={css(CommonStyles.boldText)}>Exceptions:</span> Educational, historical, satirical, artistic, and other content that can be clearly identified as counter-speech or aims to raise awareness of the harm caused by dangerous individuals and/or organizations
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Harassment and bullying</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              Users should feel safe to express themselves without fear of being shamed, humiliated, bullied, or harassed. We deeply understand the psychological distress that abusive content can have on individuals, and we do not tolerate abusive content or behavior on our platform.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Abusive behavior
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We remove all expressions of abuse, including but not limited to violent threats, sexual harassment, disparaging statements regarding appearance, intellect, personality traits, and hygiene.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that threatens an individual with violence
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that incites violence or coordinated harassment
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that sexually harasses a user by disparaging their sexual activities or attempting to make unwanted sexual contact
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that disparages a private individual on the basis of attributes such as intellect, appearance, personality traits, or hygiene
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that praises violent tragedies and disparages their victims
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that uses the duet, react, or effects features of Razzl to create abusive content targeting other users
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Infringement of others' privacy
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Revealing or threatening to reveal personally identifiable information can cause serious emotional distress and lead to real-world harm. We consider it a form of abuse and do not allow it on Razzl.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that reveals or threatens to reveal personally identifiable information, including but not limited to residential address, private email address, private phone number, bank statement, social security number, or passport number
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Threats to reveal sexual imagery or non-consensual intimate imagery
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Hate speech</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              We do not tolerate content that attacks or incites violence against an individual or a group of individuals on the basis of protected attributes. We do not allow content that includes hate speech, and we remove it from our platform. We also suspend or ban accounts that have multiple hate speech violations.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Attacks on protected groups
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We define hate speech as content that does or intends to attack, threaten, incite violence against, or dehumanize an individual or a group of individuals on the basis of protected attributes. We also do not allow content that verbally or physically threatens violence or depicts harm to an individual or a group based on any of the following protected attributes:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Race
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Ethnicity
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  National origin
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Religion
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Caste
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Sexual orientation
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Sex
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Gender
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Gender identity
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Serious disease or disability
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Immigration status
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Content that dehumanizes or incites violence or hatred against individuals or groups, based on the attributes listed above, including but not limited to:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  claiming that they are physically or morally inferior
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  calling for or justifying violence against them
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  claiming that they are criminals
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  referring negatively to them as animals, inanimate objects, or other non-human entities
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  promoting or justifying exclusion, segregation, or discrimination against them
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Slurs
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Slurs are defined as derogatory terms that are intended to disparage an ethnicity, race, or any other protected attributes listed above. They are not tolerated on our platform because we do not want to contribute to the spread of egregiously offensive terms. However, we are aware of the fact that slurs can be used self-referentially or have been reappropriated, and we may give exceptions when slurs are used in a song or in other instances of a self-referential satirical context and/or reappropriation.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that invokes non-consensual slurs
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Hateful ideology
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Hateful ideologies are incompatible with the inclusive and supportive community that our platform provides. We remove content that promotes hateful ideologies.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that promotes any hateful ideologies by talking positively about or displaying logos, symbols, flags, slogans, uniforms, salutes, gestures, portraits, illustrations, or names of individuals related to these ideologies
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that denies well-documented and violent events have taken place
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Music or lyrics that promote hateful ideologies
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Illegal activities and regulated goods</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              We prohibit the trade, sale, promotion, and use of certain regulated goods, as well as the depiction or promotion of criminal activities. Some content may be removed if it relates to activities or goods that are illegal or regulated in the majority of the region or world, even if the activities or goods in question are legal in the jurisdiction of posting. We allow exceptions for content that provides value to the public, such as educational, scientific, artistic, and newsworthy content.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Promotion of criminal activities
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Criminal activities cover a wide spectrum of acts punishable by law, including theft, assault, human exploitation, and other harmful behavior. Because we do not want such behavior to be normalized or imitated, we remove content that promotes criminal activities.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that promotes acts of physical harm, such as assaulting or kidnapping
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that risks the safety of others, including pranks like swatting
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that promotes human exploitation or wildlife trafficking
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that offers the purchase, sale, or trade of unlawfully acquired goods
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that provides instructions on how to conduct criminal activities
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Sale or use of weapons
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              To protect our community, we generally do not allow the depiction, trade, or promotion of firearms, ammunition, firearm accessories, or explosive weapons. We also prohibit instructions on how to manufacture those weapons. We may give exceptions to content that displays firearms or other types of weapons in the following scenarios: in a fictional setting, as part of a museum's collection, carried by a police officer, in a military parade, or used in a safe and controlled environment such as a shooting range.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that displays firearms, firearm accessories, ammunition, or explosive weapons
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that offers, sells, trades, or solicits firearms, accessories, ammunition, explosive weapons, or instructions on how to manufacture them
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Drugs and controlled substances
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              To protect our community, we do not allow content that depicts the use or trade of drugs or other controlled substances.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that displays drugs, drug consumption, or encourages others to make, use, or trade drugs or other controlled substances
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that sells, offers, trades, or solicits drugs or other controlled substances
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that provides information on how to buy illegal or controlled substances
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Frauds and scams
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We do not permit anyone to exploit our platform to harm others, which includes schemes to defraud individuals or steal assets. We remove content that deliberately deceives people in order to gain an unlawful financial advantage.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that promotes phishing
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that promotes Ponzi or pyramid schemes
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that promotes fixed betting, get-rich-quick schemes, or any other types of scams
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Integrity and authenticity</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              Content that is intended to deceive or mislead any of our community members endangers our trust-based community. We do not allow such content on our platform. This includes activities such as spamming, impersonation, and disinformation campaigns.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Spam
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Content or activity that seeks to artificially inflate popularity on the platform is prohibited. We also prohibit any attempts to manipulate platform mechanisms to increase interaction metrics.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Share instructions on how to artificially increase views, likes, followers, shares, or comments
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Attempt to or engage in selling or buying views, likes, followers, shares, or comments
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Promote artificial traffic generation services
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Operate multiple Razzl accounts under false or fraudulent pretenses, including coordinated attempts to manufacture inauthentic activity, distribute commercial spam, or otherwise coordinate a scaled violation of Razzl policies
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Impersonation
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We do not allow users to impersonate other individuals or organizations in order to deceive the public. When we confirm reports of impersonation, we remove the violating accounts. We do allow exceptions for parody, commentary, or fan accounts, as long as the account does not mislead others with regard to its identity or purpose on Razzl.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  As another person or organization by using someone else's name, biographical details, or profile picture in a misleading manner
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Misleading information
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              While we encourage our users to have respectful conversations about subjects that matter to them, we do not permit misinformation that causes harm to individuals, our community or the larger public.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Misinformation that incites hate or prejudice
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Misinformation related to emergencies that induces panic
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Medical misinformation that causes harm to an individual's physical health
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Hoaxes or phishing attempts
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Digital Forgeries (Synthetic Media or Manipulated Media) that mislead users by distorting the truth of events and cause harm to the subject of the video or society
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Engage in coordinated inauthentic activities (such as the creation of accounts) to exert influence and sway public opinion while misleading individuals, our community or the larger public about the account's identity, location or purpose
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Intellectual property
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We encourage everyone to create and share original content. We do not allow users to publish or distribute content that infringes someone else's intellectual property rights.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that violates or infringes someone else's copyrights, trademarks, or other intellectual property rights
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Minor safety</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              We are deeply committed to child safety and have zero tolerance for predatory or grooming behavior toward minors. We do not allow content that depicts or disseminates child abuse, child nudity, or sexual exploitation of children in both digital and real world format, and we report such content to relevant legal authorities. We also do not allow content depicting minors engaged in delinquent behavior.Users must meet minimum age requirements to use Razzl (as stipulated in our <Link to='/terms' className={css(CommonStyles.linkText)}>Terms of Service</Link>). When underage account holders are identified, we remove their accounts from our platform and may direct them to a more appropriate app experience, if available in their region.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Nudity and sexual exploitation involving minors
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Nudity includes content that shows visible private parts. Sexual exploitation is any actual or attempted abuse of a position of vulnerability, power, or trust for sexual purposes, including but not limited to profiting monetarily, socially, or politically from the sexual exploitation of another.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that shows the private parts of minors
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts sexual exploitation of minors
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts sexual activities such as penetration, non-penetrative sex, or oral sex involving minors
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts sexual arousal involving minors
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts a sexual fetish involving minors
                </p>
              </li>
            </ul>
            {/* 2nd time  */}
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Pornography and nudity involving adults
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Pornography explicitly depicts sexual organs and/or activities for the purpose of sexual gratification.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts sexual activities such as penetration, non-penetrative sex, or oral sex
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that shows human genitalia, female nipples, or buttocks
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts sexual arousal
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts a sexual fetish
                </p>
              </li>
            </ul>
            {/* end here */}
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Underage delinquent behavior
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Delinquent behavior includes but is not limited to the consumption or use of drugs, alcohol, and tobacco.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts minors consuming, possessing, or suspected of consuming alcoholic beverages, drugs, or tobacco
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Child abuse
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Child abuse is defined as a person in power inflicting physical and/or psychological trauma on a minor. Physical abuse is an act that deliberately inflicts injury on a child's body. Psychological abuse is berating a child with threats of either physical or sexual violence or via bully tactics.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts physical or psychological abuse involving minors
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Grooming behavior
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Grooming is an adult building an emotional relationship with a minor to gain their trust for the purposes of sexual abuse, sexual exploitation, or sexual trafficking.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that encourages, instructs, or justifies grooming behavior
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that engages a minor in a sexually explicit conversation
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that incentivizes or blackmails a child to transmit sexually explicit material
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Sexualization of minors
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We do not allow content that imposes sexuality on or sexually objectifies minors.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts erotic dances involving minors
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that contains sexual or erotic language involving minors
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Suicide, self-harm, and dangerous acts</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              We do not promote participation in activities that could lead to harm. We also do not permit users to encourage others to take part in dangerous activities. We do not allow content that promotes self-harm or suicide, but we do allow our users to share their experiences in order to raise awareness about these issues.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We encourage users who are struggling with thoughts of self-harm or suicide, or who know someone is seriously considering suicide, to immediately contact legal authorities or a suicide prevention hotline.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Suicide
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We remove content that displays suicide, suicidal tendencies, or content that might encourage participation in similar actions. This includes attempted suicide or content featuring a person committing or intending to commit acts that are likely to lead to self-inflicted death. We prohibit any form of content that praises, glorifies, or promotes suicide, or instructs others on how to commit suicide.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that provides instructions on how to commit suicide
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that praises, promotes, or glorifies suicide
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Suicide challenges
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  <span className={css(CommonStyles.boldText)}>Exceptions:</span> Content that provides support, resources, or coping mechanisms for those going through suicidal ideation
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Self-harm
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              To avoid normalizing, encouraging, or triggering self-harm behavior, we do not allow imagery that depicts such behavior, regardless of the user's intention of posting it. We remove content that encourages or may encourage acts that are likely to lead to physical self-inflicted injury. Content that promotes eating habits that are likely to cause health issues is also not allowed on the platform.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that shows self-inflicted wounds
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that provides instructions on how to commit self-harm
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that supports pro-ana or other dangerous behavior to lose weight
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  <span className={css(CommonStyles.boldText)}>Exceptions:</span> Content that provides support, resources, or coping mechanisms for those suffering from eating disorders or participating in self-harm
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Dangerous acts
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Risky activities or other dangerous behavior conducted in an unprofessional context or without the necessary skills may lead to serious injury or death for the user or the public. We do not allow content that encourages, promotes, or glorifies such behavior, including amateur stunts or dangerous challenges.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that shows the inappropriate use of dangerous tools
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts drinking liquids or eating substances not meant for consumption
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Dangerous challenges that might lead to injury
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Content that depicts minors below the lawful age operating motor vehicles
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Threats to platform security</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              In addition to the content and behavior outlined above, our policies prohibit activities that undermine the Razzl service:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Do not hack the Razzl website, app, or associated networks, or bypass its measures to restrict users’ access
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Do not distribute files that contain viruses, Trojan horses, worms, logic bombs, or other materials that are malicious or harmful
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Do not modify, adapt, translate, reverse engineer, disassemble, decompile, or create any derivative products based on Razzl, including any files, tables or documentation, nor attempt to regenerate any source code, algorithms, methods, or techniques embodied in Razzl
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Do not use automated scripts to collect information from Razzl
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Violent and graphic content</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              We do not allow content that is excessively gruesome or shocking, especially that promotes or glorifies abject violence or suffering. We do allow exceptions for certain circumstances, for example, content that is newsworthy or meant to raise awareness about issues. When we identify a genuine risk of violence or threat to public safety, we ban the account and work with relevant legal authorities as necessary and when appropriate.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Violent and graphic content: Human
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              We do not permit anyone to exploit our platform to harm others, which includes schemes to defraud individuals or steal assets. We remove content that deliberately deceives people in order to gain an unlawful financial advantage.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Content that is gratuitously shocking, sadistic, or excessively graphic, including but not limited to:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Depictions of violent or accidental deaths involving real people
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Depictions of dismembered, mutilated, charred, or burned human remains
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Depictions of gore in which an open wound or injury is the core focus
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Depictions of severe physical violence
                </p>
              </li>
            </ul>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Violent and graphic content: Animals
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Do not post:
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Content that is gratuitously shocking, sadistic, or excessively graphic, including but not limited to:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Depictions of the slaughter of real animals
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Depictions of dismembered, mutilated, charred, or burned animal remains
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  Depictions of animal cruelty
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Enforcement</h2>
            <p className={css(CommonStyles.textfulPagePara)}>
              Use of any Razzl offering is not allowed for any purpose other than the specific limited purposes described in our <Link to='/terms' className={css(CommonStyles.linkText)}>Terms of Service</Link>.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If we learn of content or activity on the Site that violates the <Link to='/terms' className={css(CommonStyles.linkText)}>Terms of Service</Link>, including these Community Guidelines, we will enforce them as we see fit, at our sole discretion. In addition, we may determine it is necessary to temporarily suspend your Razzl account while we are reviewing a possible violation and evaluating appropriate enforcement.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Depending on the severity of the violation, Razzl may enforce these Community Guidelines by:
            </p>
            <ul>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  issuing a warning;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  removing videos or other content;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  temporarily suspending account access for a specified period of time;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  permanently suspending account access;
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  contacting law enforcement; or
                </p>
              </li>
              <li className={css(CommonStyles.dotColor)}>
                <p className={css(CommonStyles.textfulPagePara)}>
                  other action in Razzl’s sole discretion.
                </p>
              </li>
            </ul>
            <h2 className={css(CommonStyles.textfulPageSubtitle)}>Appeal Process</h2>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Appeal
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If you believe that our enforcement decision against you was incorrect, you may request an appeal in writing to <a href={'mailto:' + AppConfig.SUPPORT_EMAIL} className={css(CommonStyles.linkText)}>contactus@razzl.com</a>. You must use the email address associated with the Razzl account connected to our enforcement decision and include detailed reasons why you believe the decision was incorrect. As with all other enforcement decisions, the granting of an appeal is at Razzl’s sole discretion, and these decisions are final and binding.
            </p>
            <p className={css(CommonStyles.textfulPagePara, CommonStyles.boldText)}>
              Reinstatement After Suspension
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              If we suspend your Razzl account for a violation of the <Link to='/terms' className={css(CommonStyles.linkText)}>Terms of Service</Link>, inducing these Community Guidelines, you may request reinstatement after the suspension ends. Any request for reinstatement must be in writing and sent to <a href={'mailto:' + AppConfig.SUPPORT_EMAIL} className={css(CommonStyles.linkText)}>contactus@razzl.com</a> from the email address associated with the suspended Razzl account. The request for reinstatement must include: (a) a detailed description of why you would like to rejoin Razzl; (b) confirmation that you have read and will abide by Razzl’s <Link to='/terms' className={css(CommonStyles.linkText)}>Terms of Service</Link>, including these Community Guidelines; and (c) any other confirmations, acknowledgements or agreements that we request. A request for reinstatement in accordance with the above does not guarantee reinstatement, as decisions are subject to Razzl’s sole discretion.
            </p>
            <p className={css(CommonStyles.textfulPagePara)}>
              Thank you for being a part of our vibrant global community and working with us to maintain a safe space for all users. If you come across content or accounts that you believe may violate our Community Guidelines, please let us know by emailing <a href={'mailto:' + AppConfig.SUPPORT_EMAIL} className={css(CommonStyles.linkText)}>contactus@razzl.com</a> so we can review and take appropriate action.
            </p>
          </div>
        </Grid>
        <div className={css([CommonStyles.footerContainer, CommonStyles.footerContainerV2])}>
          <Footer />
        </div>
      </div>
    );
  }
}

const Styles = StyleSheet.create({

})