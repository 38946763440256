import React, { useState, useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { AppTheme } from '../styles/AppTheme';
import { Controller } from '../common/Controller';
import { AppHelper } from '../helpers/AppHelper';
import { DrawerMenuDashboard } from './DrawerMenuDashboard';
import { AppConfig } from '../AppConfig';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { AppContext } from '../common/contexts/AppContext';
import { Helper } from '../common/helpers/Helper';
import { SettingsMenu } from '../common/components/SettingsMenu';
// import {PersonOutlineOutlinedIcon} from '@material-ui/icons/PersonOutlineOutlined';
import { GetStartedPopup } from '../components/GetStartedPopup';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#02121D',
    // width: `calc(100% - ${theme.spacing(7) + 30}px)`,
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: 28
  },
  appBarShift: {
    // width: `calc(100% - ${AppTheme.drawerWidth + 12}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

function HeaderInternal(props) {
  const [state, setState] = useState({
    anchorEl: null,
    drawerIsOpen: window.innerWidth <= 1024 ? false : true,
    settingPopover: null,
    orgSwitcherDialog: false,
  })
  const menuItems = AppHelper.getDrawerMenuItems()
  const { appContextStateHandler } = useContext(AppContext)

  const handleMenu = event => {
    setState({ ...state, anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setState({ ...state, anchorEl: null });
  };

  const drawerIsOpen = (value) => {
    setState({
      ...state, drawerIsOpen: value
    })
    appContextStateHandler({ toggleDrawer: value })
  }

  const settingOpen = (e) => {
    setState({ ...state, settingPopover: e.currentTarget })
  }

  const settingClose = () => {
    setState({ ...state, settingPopover: null })
  }

  const orgSwitcherDialogOpen = () => {
    setState({ ...state, orgSwitcherDialog: true, settingPopover: null });
  }

  const appUser = Controller.get().userMgr().getAppUser()
  const { anchorEl } = state;
  const open = Boolean(anchorEl);
  const { classes, theme } = props;
  const profilePic = appUser && appUser.picture ? appUser.picture : require('../assets/images/profile-icon.png')

  const [getStartedDialogOpen,  setGetStartedDialogOpen] = useState(false);

  const onClose = () => {
    setGetStartedDialogOpen(false);
    settingClose();
  }

  return (
    <Fragment>
      <AppBar
        color='primary' position='fixed' elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: state.drawerIsOpen,
        })}>
        <div className={css(Styles.drawerMobileView)}>
          {AppHelper.isDrawerMenuEnabled() &&
            <DrawerMenuDashboard
              onLogoutClick={props.onLogoutClick}
              drawerIsOpen={drawerIsOpen}
              menuItems={menuItems} />
          }
        </div>
        <div className={css(Styles.profilePicContainer)} onClick={(e) => settingOpen(e)}>
          <img src={require('../assets/images/settings.png')}
            alt={Helper.getString('settingsImageAlt')}
            className={css(Styles.img)}
            width='40'
            height='40'
          />
        </div>

        <SettingsMenu
          settingPopover={state.settingPopover}
          settingClose={settingClose}
          // orgId={state.selectedOrgId}
          orgSwitcherDialogOpen={orgSwitcherDialogOpen}
          setGetStartedDialogOpen={setGetStartedDialogOpen} />
      </AppBar>

      <div className={css(Styles.drawerDektopView)}>
        {AppHelper.isDrawerMenuEnabled() &&
          <DrawerMenuDashboard
            onLogoutClick={props.onLogoutClick}
            drawerIsOpen={drawerIsOpen}
            menuItems={menuItems} />
        }
      </div>
      <GetStartedPopup showGetStartedPopup={getStartedDialogOpen} onClose={onClose} />
    </Fragment>
  );
}

HeaderInternal.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

const Styles = StyleSheet.create({
  appbar: {
    backgroundColor: 'white', // Lets keep white admin bar always
  },
  toolbar: {
    minHeight: AppTheme.toolbarHeight,
    paddingLeft: 10,
    paddingRight: 10,
  },
  logoImage: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  logoContainer: {
    flex: 1,
    textAlign: 'left'
  },
  logoImageContainer: {
    height: 40
  },
  drawerMobileView: {
    display: 'none',
    '@media(max-width: 767px)': {
      display: 'block'
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      display: 'block'
    }
  },
  drawerDektopView: {
    display: 'block',
    '@media(max-width: 1024px)': {
      display: 'none'
    }
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute',
    // right: 120
    marginRight: 120
  },
  profilePicContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    // borderRadius: '50%',
    overflow: 'hidden',
    marginRight: 245,
    cursor: 'pointer'
  },
  userNameText: {
    fontSize: 16,
    margin: 0,
    marginLeft: 15
  }
})

export const Header = withStyles(styles, { withTheme: true })(HeaderInternal);
