import { Controller } from '../Controller';
import { Helper } from './Helper';
import { AppConfig } from '../../AppConfig';
import { AuthError, ApiDataError, ApiError } from '../errors';

export class ApiHelper {

  static State = Object.freeze({
    LOADING: 1,
    READY: 2,
    ERROR: 3
  });

  static callWithProgressView(caller, options, callback) {
    caller.refs.apiProgressView.progress();
    ApiHelper.call(options, function (err, data) {
      if (err) {
        caller.refs.apiProgressView.error(err);
        return callback(err)
      } else {
        const successMsg = options.successMsg || Helper.getString('successful')
        caller.refs.apiProgressView.success(successMsg);
        return callback(err, data)
      }
    })
  }

  static async callSSAwait(options) {
    try {
      const response = await ApiHelper.callAwait(options)
      return { props: {
        datass: response,
        pageMetaData: Helper.selectDict('title,descr,metaTitle,metaDescr,metaKeywords', response)
      }}
    } catch(err) {
      return { props: { err: { errMsg: Helper.getErrorMsg(err) } } }
    }
  }

  static async callAwait(options) {
    return await Helper.promisify(ApiHelper.call)(options)
  }

  static call(inputOptions, callback) {
    const options = Object.assign({}, inputOptions)
    if (!options.endPoint) {
      options.endPoint = ApiHelper.makeUrlPath(options.paths, options.queryParams)
    }
    // console.log('call:', options.endPoint)
    if (AppConfig.ALLOWMOCK && Controller.get().mockHelper().hasMock(options)) {
      return Controller.get().mockHelper().call(options, callback)
    }
    ApiHelper._fetch(options).then((response) => {
      response.json().then((responseJson) => {
        return callback(null, responseJson)
      }).catch((parseError) => {
        return callback(new ApiDataError())
      });
    }).catch((error) => {
      //console.log('error:', error)
      if (error.status === 401) {
        return callback(new AuthError())
      }
      if (!error.json) {
        return callback(new ApiError())
      }
      error.json().then((errorJson) => {
        return callback(errorJson)
      }).catch((parseError) => {
        return callback(new ApiDataError())
      });
    });
  }

  static makeUrlPath(paths, queryParams) {
    let url = AppConfig.ENDPOINT;
    if (AppConfig.ENDPOINT_AUTH_MICROSERVICE && Helper.inArray(['orgs', 'users'], paths[0])) {
      url = AppConfig.ENDPOINT_AUTH_MICROSERVICE
    }
    for (const path of paths) {
      url = url + '/' + path;
    }
    if (queryParams) {
      const qs = Helper.encodeQueryString(queryParams);
      if (qs) {
        url += '?' + qs;
      }
    }
    return url;
  }

  static _fetch(apiOptions) {
    const options = Object.assign({}, apiOptions)
    //console.log('_fetch:', options.method, options.endPoint)
    return new Promise((resolve, reject) => {
      ApiHelper._preAuth(options, function (err) {
        if (err) {
          //console.log('Auth Failed');
          reject(err);
        } else {
          const input = {
            method: options.method,
            headers: {}
          };
          if (options.jsonBody) {
            input.body = JSON.stringify(options.jsonBody)
          }
          if (options.authWithKey) {
            input.headers.Authorization = 'Key ' + AppConfig.APPAK;
          } else {
            input.headers.Authorization = 'Bearer ' + Controller.get().userMgr().getUserToken();
          }
          //console.log('fetch:', options.method, options.endPoint, input)
          if (!options.endPoint) {
            options.endPoint = ApiHelper.makeUrlPath(options.paths, options.queryParams)
          }
          fetch(options.endPoint, input).then((response) => {
            //console.log('_fetch response:', response);
            if (response.ok) {
              resolve(response);
            } else {
              console.error('_fetch not ok:', response);
              reject(response);
            }
          }).catch((error) => {
            console.error('_fetch error:', error);
            reject(error);
          });
        }
      });
    });
  }

  static _preAuth(options, callback) {
    //console.log('_preAuth');

    if (options.authWithKey) {
      callback(null);
      return;
    }

    const cognitoUser = Controller.get().loginMgr().getCurrentUser()
    if (!cognitoUser) {
      callback(Helper.getString('loginErr'));
      return
    }
    Controller.get().loginMgr().fetchUserToken(cognitoUser, function (err) {
      if (err) {
        console.error(err)
        callback(err);
        return;
      }
      // Token should by now be cached in
      callback(null);
    })
  }


}
